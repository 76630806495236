<template>
  <div class="subject">
    <home-title
      name="学科"
      :percentage="percentage"
      :width="width"
    ></home-title>
    <div class="tabs">
      <div class="tab">
        <ul>
          <li
            v-for="(item, index) in tabs"
            :key="index"
            @click="changeTab(index)"
          >
            <div :class="['img', tabIndex === index ? 'imgCur' : '']">
              <img
                :src="tabIndex === index ? item.selectIcon : item.icon"
                @mouseenter="enter($event, index)"
                @mouseleave="leave($event, index)"
                alt=""
              />
            </div>
            <div class="name" :class="tabIndex === index ? 'cur' : ''">
              {{ item.name }}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import homeTitle from "@/components/title.vue";
export default {
  components: {
    homeTitle,
  },
  props: {
    tabs: {
      type: Array,
      require: true,
    },
    tabIndex: {
      type: Number,
      require: true,
    },
  },
  data() {
    return {
      percentage: 4.4,
      width: "36px",
      enterIndex: 0,
    };
  },
  mounted() {},
  methods: {
    // 移入
    enter(val, index) {
      this.enterIndex = index;
      this.tabs.forEach((item) => {
        if (item.id == this.enterIndex) {
          val.srcElement.src = item.selectIcon;
        }
      });
    },
    // 移开
    leave(val, index) {
      if (index == this.tabIndex) {
        val.srcElement.src = this.tabs[this.tabIndex].selectIcon;
      } else {
        this.tabs.forEach((item) => {
          if (item.id == this.enterIndex) {
            val.srcElement.src = item.icon;
          }
        });
      }
    },
    // 导航切换
    changeTab(index) {
      this.$emit("changeTab", index);
    },
  },
};
</script>
<style lang="scss" scoped>
.subject {
  width: 800px;
  height: 164px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #f0f0f0;
  .tabs {
    margin-top: 14px;
    .tab {
      ul {
        height: 54px;
        display: flex;
        padding: 0 16px;
        box-sizing: border-box;
        li {
          text-align: center;
          margin-right: 8.4px;
          .img {
            width: 54px;
            height: 54px;
            background: #ffffff;
            border: 1px solid #f0f0f0;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 27px;
            img {
              width: 32px;
              height: 32px;
              background: rgba(255, 255, 255, 0.01);
            }
          }
          .imgCur {
            background: #e6f2ff;
          }
          .enterCur {
          }
          .name {
            width: 28px;
            height: 22px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #434343;
            line-height: 22px;
            margin: 0 auto;
            margin-top: 6px;
          }
          .cur {
            font-weight: 400;
            color: #0055ff;
          }
          &:nth-child(11) {
            .img img {
              width: 26px;
              height: 26px;
            }
          }
          &:last-child {
            .img img {
              width: 26px;
              height: 26px;
            }
          }
        }
      }
    }
  }
}
</style>
