<template>
  <div class="database">
    <home-title
      :name="name"
      :percentage="percentage"
      :weight="weight"
      :width='width'
      :fontSize='fontSize'
      :is_right='is_right'
    ></home-title>
    <div class="base">
      <div class="wrap">
        <div
          class="content"
          v-for="(item, index) in reslist"
          :key="index"
          @click="jump(item.url)"
          :class="(index + 1) % 4 === 0 ? 'cur' : ''"
        >
          <div class="name">
            <p>{{ item.name }}</p>
          </div>
          <div class="introduce">
            <span>{{ item.introduce }}</span>
          </div>
        </div>
      </div>
    </div>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="list.length"
      :page-size="pageSize"
      :current-page="pagenum"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
</template>
<script>
import homeTitle from "@/components/title.vue";
import list from "../views/list.js";
export default {
  components: {
    homeTitle,
  },
  props: {
    list: {
      type: Array,
      require: true,
    },
    name: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      percentage: 7,
      weight: 600,
      width:'96px',
      fontSize:'16px',
      pageSize: 16, //每页显示条目个数
      pagenum: 1,
      is_right:true
    };
  },
  computed: {
    reslist() {
      return this.list.slice(
        this.pageSize * (this.pagenum - 1),
        this.pageSize * this.pagenum
      );
    },
  },
  watch: {
    list(newval) {
      this.pagenum = 1;
    },
  },
  mounted() {},
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
    },
    // 当页码发生变化时触发
    handleCurrentChange(val) {
      // console.log(val);
      this.pagenum = val;
    },
    jump(url) {
      if (localStorage.getItem("token")) {
        window.open(url, "_blank");
      } else {
        console.log(url);
        let dialogShow = true;
        this.$emit("jump", dialogShow, url);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.database {
  width: 800px;
  height: 558px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #f0f0f0;
  margin-top: 8px;
  .base {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;

    .wrap {
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-around;

      .content {
        width: 186px;
        height: 104px;
        border-radius: 4px;
        border: 1px solid #f0f0f0;
        box-sizing: border-box;
        margin-bottom: 8px;
        margin-right: 5px;
        .name {
          width: 168px;
          margin: 0 auto;
          margin-top: 16px;
          height: 22px;
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #1f1f1f;
          line-height: 22px;
          overflow: hidden; //多出部分隐藏
          white-space: nowrap; //一行显示 nowrap不换行
          text-overflow: ellipsis; //是否显示省略号
          p {
            overflow: hidden; //多出部分隐藏
            white-space: nowrap; //一行显示 nowrap不换行
            text-overflow: ellipsis; //是否显示省略号
          }
        }
        .introduce {
          width: 170px;
          margin: 0 auto;
          margin-top: 2px;
          height: 36px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #525252;
          line-height: 18px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2; // 显示两行
          -webkit-box-orient: vertical;
          margin-top: 4px;
          span {
            font-size: 12px;
            transform: scale(0.8);
          }
        }
        &:hover {
          width: 186px;
          height: 104px;
          background: #f0f7ff;
          border-radius: 4px;
          .name {
            // a {
            color: #ff500a;
            // }
          }
        }
      }
      .cur {
        margin-right: 0;
      }
    }
  }
  .el-pagination {
    display: flex;
    justify-content: flex-end;
    padding-right: 18px;
    box-sizing: border-box;
  }
}
</style>
