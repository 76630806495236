<template>
  <div class="application">
    <home-title
      name="AI应用"
      :percentage="percentage"
      :width="width"
    ></home-title>
    <div class="wrap a1">
      <div class="item">
        <div
          class="ky"
          v-for="(item, index) in list"
          :key="index"
          @mouseenter="enter(index)"
          @mouseleave="leave"
        >
          <div class="left">
            <div class="img">
              <img :src="item.pic" alt="" />
            </div>

            <p
              v-if="moveIndex === index"
              :style="{ filter: moveIndex === index ? 'blur(1px)' : '' }"
            >
              进入系统
            </p>
            <p v-else>进入系统</p>
          </div>
          <div class="right">
            <div class="title">{{ item.title }}</div>
            <div
              v-if="moveIndex === index"
              class="con"
              :style="{ filter: moveIndex === index ? 'blur(1px)' : '' }"
            >
              {{ item.con }}
            </div>
            <div class="con" v-else>
              {{ item.con }}
            </div>
          </div>
          <div class="is_qx" v-if="moveIndex === index" @click="show">
            <div class="else">
              <div class="is_filter"></div>
              <div class="filter">
                <div class="imgBack"></div>
                <div class="img">
                  <img src="@/assets/images/code.png" alt="" />
                </div>
                <div class="wen">
                  若未开通，请联系客服微信，免费专业资讯<br />
                  若已开通，请点此进入
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import homeTitle from "@/components/title.vue";
export default {
  components: {
    homeTitle,
  },
  props: {
    loginIs: {
      type: Boolean,
      require: true,
      default: false,
    },
    qx:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      percentage: 16,
      list: [
        {
          id: 0,
          pic: require("@/assets/images/sy.png"),
          title: "实验室",
          con: "零代码——8小时掌握AI技术，做科研得到新发现",
        },
      ],
      isMove: false, //鼠标没有移入
      moveIndex: -1, //鼠标移入
      width: "54px",
      qx_index: 1,
      data_index: -1,
      workorder_index: -1,
    };
  },
  watch: {},
  mounted() {},

  methods: {
    // 客服
    show() {
      // console.log(1);
      this.$emit("show");
    },
    // 鼠标移入
    enter(index) {
      this.moveIndex = index;
    },
    // 鼠标移出
    leave() {
      this.moveIndex = -1;
    },
  },
};
</script>
<style lang="scss" scoped>
.application {
  width: 384px;
  // height: 478px;
  height: 274px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #f0f0f0;
  margin-top: 16px;
  .wrap {
    width: 100%;
    .ky {
      width: 352px;
      margin: 0 auto;
      margin-bottom: 16px;
      height: 191px;
      background: rgba(246, 235, 255, 0.08);
      border-radius: 4px;
      border: 1px solid #f0f0f0;
      display: flex;
      position: relative;
      .left {
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: center;
        margin-top: 16px;
        margin-left: 32px;
        .img {
          width: 80px;
          height: 80px;
          border-radius: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 40px;
            height: 40px;
          }
        }
        p {
          margin-top: 9px;
          background: #fa8c16;
          padding: 8px 14px;
          border-radius: 5px;
          color: #fff;
        }
      }
      .right {
        flex: 1;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        margin-left: 38px;
        box-sizing: border-box;
        .title {
          margin-top: 20px;
          font-weight: 700;
          font-size: 18px;
          margin-top: 32px;
        }
        .con {
          width: 170px;
          height: 44px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #434343;
          line-height: 22px;
          margin-top: 8px;
        }
      }
      .else {
        width: 352px;
        height: 191px;
        position: absolute;
        top: 0;
        left: 0;
        .is_filter {
          width: 352px;
          height: 191px;
          background: rgba(0, 0, 0, 0.48) !important;
          background-color: #000000;
          border-radius: 4px;
          filter: blur(1px);
          position: absolute;
          top: 0;
          left: 0;
        }
        .filter {
          position: static;
          .imgBack {
            width: 96px;
            height: 96px;
            background: #ffffff;
            border-radius: 3px;
            border: 1px solid #f3f3f3;
            margin-top: 23px;
            margin-left: 129px;
            filter: blur(1px);
          }
          .img {
            width: 96px;
            height: 96px;
            background: #ffffff;
            border-radius: 3px;
            border: 1px solid #f3f3f3;
            position: absolute;
            top: 0;
            margin-top: 23px;
            margin-left: 129px;
            img {
              width: 87px;
              height: 89px;
              margin-top: 3px;
              margin-left: 5px;
            }
          }
          .img {
          }
          .wen {
            // width: 234px;
            height: 48px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 24px;
            text-align: center;
            // margin-left: 59px;
            margin-bottom: 16px;
            position: relative;
            filter: none;
          }
        }
      }

      &:first-child {
        .left .img {
          background: #a25ef6;
        }
        .right .title {
          color: #8d0bff;
        }
      }
      &:nth-child(2) {
        .left .img {
          background: #0a7bff;
        }
        .right .title {
          color: #0a7bff;
        }
      }
    }
  }
  /deep/.el-progress-bar__outer {
    height: 2.4px !important;
  }
  .home-title {
    padding: 20px 15px 0 16px;
    box-sizing: border-box;
  }
}
</style>
