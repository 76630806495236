<template>
  <div class="user">
    <home-title
      name="用户服务"
      :percentage="percentage"
      :width="width"
    ></home-title>
    <div class="wrap">
      <div class="imgLogin" :class="is_login ? 'cur' : ''">
        <div v-if="!is_login">
          <div class="imgElse" v-if="!qrurl"></div>
        </div>
        <!-- <div class="imgElse" v-if="!is_login"></div> -->
        <div v-if="qrurl">
          <img :src="qrurl" alt="" v-if="!is_login" @click="Refresh"/>
        </div>
        <div class="wxname" v-if="is_login">{{ lastname }}</div>
        <p :style="{ 'margin-top': !qrurl ? '13px' : 0 }">
          {{ is_login ? wxName : "微信登录" }}
        </p>
      </div>
      <div class="right">
        <div class="wx" @click="kf">
          <img src="@/assets/images/wx.png" alt="" />
          <p>微信客服</p>
        </div>
        <div class="js" @click="jump">
          <img src="@/assets/images/zl.png" alt="" />
          <p>用AI？</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import homeTitle from "@/components/title.vue";
export default {
  components: {
    homeTitle,
  },
  props: {
    qrurl: {
      type: String,
      require: true,
    },
    loginIs: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      percentage: 21,
      num: 10,
      strokeWidth: 2.4,
      width: "72px",
      is_login: false, //localStorage是否存token
      lastname: "", //姓
      wxName: "", //昵称
    };
  },
  watch: {
    loginIs(newval, oldval) {
      console.log(newval, oldval);
      this.is_login = newval;
      this.setName();
    },
  },
  mounted() {
    if (localStorage.getItem("token")) {
      setTimeout(() => {
        this.is_login = localStorage.getItem("token") ? true : false;
        this.$emit('login')
      }, 1000);
      this.setName();
    }
  },
  methods: {
    Refresh(){
      this.$emit('RefreshLogin')
    },
    jump() {
      this.$emit("js");
    },
    setName() {
      this.lastname = localStorage.getItem("wxname").substr(0, 1);
      this.wxName = localStorage.getItem("wxname");
    },
    kf() {
      this.$emit("kf");
    },
  },
};
</script>
<style lang="scss" scoped>
.user {
  width: 384px;
  height: 236px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #f0f0f0;
  .wrap {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;

    .imgLogin {
      width: 128px;
      height: 128px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #f3f3f3;
      text-align: center;
      .imgElse {
        width: 115px;
        height: 117px;
        background: #ffffff;
      }
      img {
        width: 115px;
        height: 117px;
        margin: 5px 6px 6px 7px;
      }
      .wxname {
        width: 96px;
        margin: 16px;
        height: 96px;
        border-radius: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #7fd8e6;
      }
      p {
        color: #434343;
        margin-top: 14px;
        width: 128px;
        height: 24px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #434343;
        line-height: 24px;
      }
    }
    .cur {
      border: none;
    }
    .right {
      margin-top: 20px;
      .wx,
      .js {
        width: 144px;
        height: 40px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        img {
          width: 24px;
          height: 24px;
          margin-left: 24px;
          margin-right: 8px;
        }
        p {
          width: 64px;
          height: 24px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 24px;
        }
      }
      .wx {
        background: #09bb07;
      }
      .js {
        background: #fa8c16;
        margin-top: 16px;
      }
    }
  }
  .home-title {
    padding: 20px 15px 0 16px;
    box-sizing: border-box;
  }
}
</style>
