export default [{
      id: 0,
      name: '推荐',
      list: [{
         name: "国家高能物理主题数据库",
         url: "http://www.hep.nsdc.cn/",
         introduce: "宇宙线观测数据库、高能天体物理数据库、核分析数据库和中微子实验数据库"
      }, {
         name: "国家极地科学数据中心",
         url: "https://www.chinare.org.cn/",
         introduce: "主要开展极地雪冰-海洋与全球变化、极区电离层-磁层耦合与空间天气、极地生态环境及其生命过程以及极地科学基础平台技术等领域的研究"
      }, {
         name: "国家青藏高原科学数据中心",
         url: "https://data.tpdc.ac.cn/zh-hans/",
         introduce: "数据中心以青藏高原及周边地区各类科学数据为主，已整合的数据资源包括：大气、冰冻圈、水文、生态、地质、地球物理、自然资源、基础地理、社会经济等，开发了在线大数据分析、模型应用等功能，实现青藏高原科学数据、方法、模型与服务的广泛集成。"
      }, {
         name: "全球科研项目数据库",
         url: "http://project.llas.ac.cn/",
         introduce: "汇聚了全球主要发达国家重要基金组织2000年以来的科研项目数据200多万条"
      }, {
         name: "国家空间科学数据中心",
         url: "https://www.nssdc.ac.cn/mhsy/html/index.html",
         introduce: "数据中心以中科院国家空间科学中心为依托单位，联合中科院国家天文台（探月工程中心）、中国科学技术大学、中科院国家授时中心和中科院计算机网络信息中心等单位共同建设和运行"
      }, {
         name: "UCI数据集",
         url: "https://link.zhihu.com/?target=https%3A//archive.ics.uci.edu/ml/index.php",
         introduce: "是加州大学欧文分校(University of CaliforniaIrvine)提出的用于机器学习的数据库，这个数据库目前共有559个数据集，其数目还在不断增加"
      }, {
         name: "NASA Reverb ",
         url: "http://reverb.echo.nasa.gov/reverb/",
         introduce: "美国航空航天局，此地数据源来自业界宗师"
      }, {
         name: "中国科技共享网",
         url: "https://escience.org.cn/",
         introduce: "涉及到地理学、气象学、微生物学、基因组学、医学、农学、物理学等多个学科"
      }, {
         name: "欧洲核子研究",
         url: "http://opendata.cern.ch/",
         introduce: "是世界上最大型的粒子物理学实验室，也是万维网的发源地"
      }, {
         name: "Meter CO Survey Dataverse",
         url: "https://dataverse.harvard.edu/dataverse/rtdc",
         introduce: "提供来自SMA和CfA毫米波望远镜的数据  "
      }, {
         name: "Advanced Spaceborne Thermal Emission and Reflection Radiometer Ground Data Systems",
         url: "http://www.jspacesystems.or.jp/en_project_aster/",
         introduce: "主要包括ASTER仪器，ASTER地面数据系统，ASTER科学活动，ASTER数据分布等数据"
      }, {
         name: "Alaska Satellite Facility SAR Data Center",
         url: "https://www.asf.alaska.edu/",
         introduce: "ERS-1，ERS-2，JERS-1，RADARSAT-1和PALSAR ALOS的数据"
      }, {
         name: "An Atomic Database for Spectroscopic Diagnostics of Astrophysical Plasmas",
         url: "http://www.chiantidatabase.org/",
         introduce: "CHIANTI包括一组经过严格评估的最新原子数据，以及以交互数据语言（IDL）和Python编写的用户友好程序，用于计算天体物理等离子体的光谱  "
      }, {
         name: "Astronomical Data Archives Center",
         url: "http://dbc.nao.ac.jp/",
         introduce: "包括在线数据目录，期刊档案，成像服务和数据档案。通过请求密码访问可访问VizieR目录服务以及Hubble超深度字段数据。还提供通过夏威夷斯巴鲁望远镜获得的SMOKA公共科学数据以及东京大学和MITSuME的Schmidt望远镜和东广岛天文台的KANATA望远镜产生的数据。"
      }, {
         name: "ATNF Pulsar Catalogue",
         url: "http://www.atnf.csiro.au/research/pulsar/psrcat/",
         introduce: "Parkes无线电望远镜获得的观测资料"
      }, {
         name: "Barbara A. Mikulski Archive for Space Telescopes（MAST，空间望远镜档案）",
         url: "http://archive.stsci.edu/contacts.html",
         introduce: "侧重于光学，紫外和近红外部分的科学相关数据集"
      }, {
         name: "British Geological Survey（英国地质调查局）",
         url: "http://www.bgs.ac.uk/",
         introduce: "是世界上最古老的国家地质调查，有超过400个数据集，包括环境监测数据，数字数据库，物理收集（钻孔核心，岩石，矿物和化石）的记录和档案。"
      }, {
         name: "Clouds and the Earth's Radiant Energy System(云和地球的辐射能量系统)",
         url: "http://ceres.larc.nasa.gov/index.php",
         introduce: "云和地球辐射能量系统（CERES）是地球观测系统（EOS）计划的关键组成部分。 CERES仪器从三个宽带通道提供地球大气的辐射测量。 CERES产品包括从大气顶部到地球表面的太阳能反射和地球发射的辐射。"
      }, {
         name: "Cornell University Library Astrophysics",
         url: "https://arxiv.org/archive/astro-ph",
         introduce: "主要包括：GA - Astrophysics of Galaxies、Cosmology and Nongalactic Astrophysics、Earth and Planetary Astrophysics、High Energy Astrophysical Phenomena、Instrumentation and Methods for Astrophysics、Solar and Stellar Astrophysics等观测数据。"
      }, {
         name: "Geostationary Operational Environmental Satellites SEM",
         url: "https://www.ngdc.noaa.gov/stp/satellite/goes/",
         introduce: "GOES卫星携带一个空间环境监测子系统，用于测量航天器的X射线，能量粒子和磁场"
      }, {
         name: "GISS Surface Temperature Analysis(GISTEMP，GISS表面温度分析)",
         url: "http://data.giss.nasa.gov/gistemp/",
         introduce: "GISS的计划领域大致可以分为气候强迫类别、 气候模式发展、 地球观测、 大气辐射、 大气化学、 气候影响、 行星大气层，系外行星和天体生物学、 古气候 和其他学科。"
      }, {
         name: "GOES Space Environment Monitor(GOES空间环境监测)",
         url: "http://www.ngdc.noaa.gov/stp/satellite/goes/",
         introduce: "GOES空间环境监测档案是国家空间气象计划的重要组成部分 - 提供及时可靠的空间环境观测和预报机构间方案。 GOES卫星载有一个空间环境监测子系统，用于测量航天器的X射线，能量粒子和磁场。"
      }, {
         name: "High Energy Astrophysics Science Archive Research Center（heasarc，高能天体物理学研究中心科学数据库）",
         url: "http://heasarc.gsfc.nasa.gov/",
         introduce: "高能天体研究数据"
      }, {
         name: "HyperLeda（星系物理数据库）",
         url: "http://leda.univ-lyon1.fr/",
         introduce: "Database for physics of galaxies。包含超过300万个对象。包含LEDA: The richest catalogue of homogeneous parameters of galaxies for the largest available sample子集"
      }, {
         name: "IAU Minor Planet Center （MPC，国际天文学联合会小行星中心）",
         url: "http://www.minorplanetcenter.net/",
         introduce: "接收和分配主要行星、彗星和主要行星外部不规则自然卫星位置测量等数据。"
      }, {
         name: "Infrared Processing and Analysis Center（IPAC，美国 NASA 红外处理分析中心）",
         url: "http://www.ipac.caltech.edu/",
         introduce: "美国 NASA 红外处理分析中心（IPAC）天文学和太阳系科学任务、数据存档，包括红外亚毫米天文学和外行星科学等数据。旗下NED、IRSA、KOA、NASA Explanet Archive等多个子集。"
      }, {
         name: "International Gamma-Ray Astrophysics Laboratory data Archives",
         url: "https://www.cosmos.esa.int/web/integral/home",
         introduce: "ISDC数据、OMC数据等"
      }, {
         name: "ISAS Data Archive and Transmission System",
         url: "http://www.darts.isas.jaxa.jp/",
         introduce: "JAXA的空间科学任务的天体物理（X射线、无线电、红外线）、太阳物理、太阳物理、和月球与行星科学的相关数据"
      }, {
         name: "KASCADE Cosmic Ray Data Centre",
         url: "https://kcdc.ikp.kit.edu/",
         introduce: "KASCADE实验数据，高能量天体粒子物理学"
      }, {
         name: "NOAA: National Geophysical Data Center",
         url: "http://www.ngdc.noaa.gov/",
         introduce: "NGDC为地球和地球的海底和地球的地球物理数据提供管理、产品和服务，包括来自太空的地球观测"
      }, {
         name: "GEOSS Portal",
         url: "https://www.geoportal.org/?m%3AactiveLayerTileId=osm&f%3AdataSource=dab",
         introduce: "收集全球范围内机构的公开的地球观测数据，并提供给用户免费使用"
      }, {
         name: "NASA/IPAC Extragalactic Database",
         url: "http://ned.ipac.caltech.edu/",
         introduce: "美国河外天体多波段数据库。内容包括不同天体的主索引，多波长交叉识别、协会、位置、红移，红移测光，独立的距离，直径，图像，光谱，并有详细的注释。导出量包括银河灭绝，速度修正，哈勃流的距离尺度上，宇宙的修正，快看光度和光谱能量分布（SED）。"
      }, {
         name: "National Solar Observatory",
         url: "https://www.nso.edu/",
         introduce: "美国太阳天文台数据集，专门观测太阳的数据。数据不多"
      }, {
         name: "RAVE database",
         url: "https://www.rave-survey.org/project/",
         introduce: "英澳天文台的1.2米UK施密特望远镜（AAO）在银河系恒星的多光纤光谱天文测量数据"
      }, {
         name: "Sloan Digital Sky Survey",
         url: "http://www.sdss.org/",
         introduce: "包含超过93万个星系和超过12万个类星体的数据"
      }, {
         name: "Spitzer Science Archive",
         url: "http://ssc.spitzer.caltech.edu/",
         introduce: "包含四个轨道观测站的数据，每个观测站用不同种类的光（可见光，γ射线，X射线和红外线）观测宇宙"
      }, {
         name: "SPT Galaxy Cluster Followup Dataverse",
         url: "https://dataverse.harvard.edu/dataverse/SPT_Clusters",
         introduce: "南极望远镜SZ调查中发现的星系团。包括11A-0034（PI：Christopher Stubbs）的低至中等红移星系的GMOS光谱"
      }, {
         name: "The Canadian Astronomy Data Centre",
         url: "http://www3.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/en/",
         introduce: "加拿大的天文数据中心"
      }, {
         name: "THEMIS（Time History of Events and Macroscale Interactions During Substorms",
         url: "http://themis.ssl.berkeley.edu/index.shtml",
         introduce: "与北美大陆20个地面观测站的极光观测相结合的卫星数据"
      }, {
         name: "UK Solar System Data Centre",
         url: "http://www.ukssdc.ac.uk/",
         introduce: "支持整个英国太阳能研究的数据存档，包括太阳能，行星间，磁层，电离层和地磁科学"
      }, {
         name: "WDC Sunspot Index and Long-term Solar Observations",
         url: "http://sidc.be/silso/",
         introduce: "SILSO是世界数据中心，用于生产，保存和传播国际太阳黑子数量"
      }, {
         name: "World Data Center for Remote Sensing of the Atmosphere",
         url: "http://wdc.dlr.de/",
         introduce: "世界大气遥感数据中心WDC-RSAT为科学家和公众免费提供（以“一站式服务”的意义）获取不断增长的大气相关卫星数据集（从原始数据到增值数据），信息产品和服务。重点是大气痕量气体，气溶胶，动力学，辐射和云物理参数。还提供了有关地表参数（如植被指数，地表温度）的补充信息和数据。"
      }, {
         name: "World Data Center for Solid Earth Physics",
         url: "http://www.wdcb.ru/sep/",
         introduce: "固体地球物理世界数据中心收集、存储和传播固体地球物理学科的大量数据：地震学、重力测量、热流、磁测量（主磁场）、考古和古磁学等，这些数据被用作基础和应用科学研究和教育的基础"
      }, {
         name: "SpringerMaterials",
         url: "https://materials.springer.com/",
         introduce: "内容涵盖物理化学和工程科学的各个领域"
      }, {
         name: "mendeley Data",
         url: "https://data.mendeley.com/",
         introduce: "是一个安全的基于云的存储库，您可以存储您的数据，确保无论您身在何处，都可以轻松共享、访问和引用数据"
      }, {
         name: "NIST Chemistry WebBook",
         url: "https://www.nist.gov/",
         introduce: "包括原子和分子物理、化学和晶体结构、流体、材料特性、生物技术、光学字符识别等"
      }, {
         name: "NASA Space Science Data Coordinated Archive",
         url: "http://nssdc.gsfc.nasa.gov/",
         introduce: "天文学和天体物理学，太阳和空间等离子体物理学，以及行星和月球科学。还可以访问几个地球物理模型和来自一些非NASA任务数据的数据。"
      }, {
         name: "HARVARD Dataverse",
         url: "https://dataverse.harvard.edu/",
         introduce: "是一个免费的数据存储库，向哈佛社区内外任何学科的所有研究人员开放"
      }, {
         name: "IceCube-南极中微子天文台",
         url: "https://link.zhihu.com/?target=http%3A//icecube.wisc.edu/science/data",
         introduce: "是第一个此类探测器，旨在从南极冰层深处观测宇宙。一个负责科学研究的国际科学家小组组成了“冰立方”合作组织。"
      }, {
         name: "SDSS",
         url: "https://www.sdss.org/",
         introduce: "创造了有史以来最详细的宇宙三维地图，有三分之一天空的深彩色图像，以及300多万个天体的光谱"
      }, {
         name: "Barbara A. Mikulski Archive for Space Telescopes",
         url: "http://archive.stsci.edu/contacts.html",
         introduce: "侧重于光学，紫外和近红外部分的科学相关数据集"
      }, {
         name: "Centre for Environmental Data Analysis",
         url: "http://catalogue.ceda.ac.uk/uuid/7f1280cf215da6f8001eae5c2f019fe8",
         introduce: "各种光学收集和雷达收集数据"
      }, {
         name: "中国国家地球系统科学数据中心",
         url: "http://geospace.geodata.cn/",
         introduce: "提供电离层、地磁、中高层大气、固体地球等方面的数据"
      }, {
         name: "郭守敬望远镜",
         url: "http://www.lamost.org/public/pilot/data",
         introduce: "先导巡天、正式巡天的观测数据"
      }, {
         name: "毫米波射电天文数据库",
         url: "http://www.radioast.csdb.cn/",
         introduce: "针对中科院紫金山天文台13.7米毫米波射电望远镜近十年所观测的分子谱线数据"
      }, {
         name: "IAU Minor Planet Center ",
         url: "http://www.minorplanetcenter.net/",
         introduce: "接收和分配主要行星、彗星和主要行星外部不规则自然卫星位置测量等数据"
      }, {
         name: "International Service of Geomagnetic Indices",
         url: "http://isgi.unistra.fr/",
         introduce: "地磁指数和显著地磁事件列表构成的系列数据，旨在描述行星尺度上或组成部分的磁活动"
      }, {
         name: "American Mineralogist Crystal Structure Database",
         url: "http://rruff.geo.arizona.edu/AMS/amcsd.php",
         introduce: "这个网站是一个包括发表在美国矿物学家，加拿大矿物学家，欧洲矿物学和物理化学矿物学报，以及从其他期刊中选取数据集的晶体结构数据库的接口。该数据库是由美国矿物学会和加拿大矿物学协会监管，以及由美国国家科学基金会资助。数据库提供交互式软件套件，可以用于查看和设置晶体结构和计算晶体的不同性质，例如几何形状，衍射图案，和晶体电子密度。"
      }, {
         name: "Atmospheric Chemistry Experiment",
         url: "http://www.ace.uwaterloo.ca/",
         introduce: "SCISAT也称为大气化学实验（ACE），是加拿大航天局用太阳遮蔽遥感地球大气的小型卫星任务。主要任务的目标是提高对化学和动力过程的理解，从而控制臭氧在平流层和对流层上层的分布，特别是北极地区"
      }, {
         name: "Benchmark Energy & Geometry Database",
         url: "http://www.begdb.com/",
         introduce: "基准能源与几何数据库，收集高度精确计算的量子结构，能量和特性。这些数据可以作为其他计算方法的测试和参数化的基准。"
      }, {
         name: "BindingDB",
         url: "http://bindingdb.org/bind/index.jsp",
         introduce: "公开访问的针对化合物相互作用、结合、结构活性关系的数据库，储存包含化学生物学、化学基因组学、分子式别的基本物理化学等方面的建模方法，以及测量技术等"
      }, {
         name: "British Geological Survey",
         url: "http://www.bgs.ac.uk/",
         introduce: "是世界上最古老的国家地质调查，有超过400个数据集，包括环境监测数据，数字数据库，物理收集（钻孔核心，岩石，矿物和化石）的记录和档案。"
      }, {
         name: "BSRN ",
         url: "http://www.bsrn.awi.de/",
         introduce: "包括LR 0100 + LR 0300 (基本辐射和其他辐射测量)等十余个子集"
      }, {
         name: "Cambridge Structural Database",
         url: "http://webcsd.ccdc.cam.ac.uk/",
         introduce: "Cambridge Structural Database (CSD，剑桥晶体结构数据库)由剑桥晶体数据中心 (CCDC, Cambridge Crystallographic Data Centre)基于 X 光和中子衍射实验唯一的小分子及金属有机分子晶体的结构数据库，基本上包括已发表的所有原子个数 (包括氢原子)在 500 以内的有机及金属有机化合物晶体数据。随着 PDB和NDB(Nucleic Acid Database)快速发展，CSD 不再包括低核苷酸的数据，但增加了高分子的数据。"
      }, {
         name: "Chemical Carcinogenesis Research Information System",
         url: "http://toxnet.nlm.nih.gov/cgi-bin/sis/htmlgen?CCRIS=",
         introduce: "CCRIS含有超过9000化学记录具有致癌性、致突变性，促进肿瘤生长，肿瘤抑制试验结果。数据来源于初级期刊引用的研究、当前的认识工具、NCI报告和其他特殊来源。实验结果已受到致癌和突变方面专家们的审查。化学致癌作用研究信息系统内含9000多种化学物质短期或长期生物分析所得的评估数据及信息。这些分析涉及到致癌物、诱变剂、辅致癌物质和肿瘤启动物质、致癌物的抑制剂和代谢物。"
      }, {
         name: "CHEMOTION ",
         url: "http://www.chemotion.net/",
         introduce: "化学结构和数据集存储库"
      }, {
         name: "GEOROC",
         url: "http://georoc.mpch-mainz.gwdg.de/georoc/Entry.html",
         introduce: "该库主要针对海洋和大陆岩石，提供地理位置，经纬度，岩类和岩石类型，蚀变等级，分析方法，实验室，参考资料和参考文献等信息。"
      }, {
         name: "GISS Surface Temperature Analysis(GISTEMP，GISS表面温度分析)",
         url: "http://data.giss.nasa.gov/gistemp/",
         introduce: "GISS的计划领域大致可以分为气候强迫类别、 气候模式发展、 地球观测、 大气辐射、 大气化学、 气候影响、 行星大气层，系外行星和天体生物学、 古气候 和其他学科。"
      }, {
         name: "github",
         url: "https://github.com/caesar0301/awesome-public-datasets",
         introduce: "为用户整理了非常全面的数据获取渠道，包含各个细分领域的数据库资源，自然科学和社会科学的覆盖都很全面，用户可以自己获取数据去做研究和数据分析"
      }, {
         name: "Global Rivers Observatory",
         url: "http://www.globalrivers.org/",
         introduce: "测量全球18个流域流入海洋点的河流化学成分"
      }, {
         name: "International Arctic Systems for Observing the Atmosphere",
         url: "http://www.esrl.noaa.gov/psd/iasoa/dataataglance",
         introduce: "数据内容有标准气象，温室气体，大气辐射，云，污染物，化学，气溶胶和地表能量平衡"
      }, {
         name: "mendeley Data",
         url: "https://data.mendeley.com/",
         introduce: "是一个安全的基于云的存储库，您可以存储您的数据，确保无论您身在何处，都可以轻松共享、访问和引用数据"
      }, {
         name: "Microstructure Database",
         url: "http://cams.mse.ufl.edu/microstructures",
         introduce: "数据库提供基于原子结构模型所建立的原子微观结构的集合"
      }, {
         name: "NAVDAT",
         url: "http://navdat.org/",
         introduce: "NAVDAT提供中生代和年轻的火成岩的年龄、化学和同位素的数据"
      }, {
         name: "NMRshiftDB2",
         url: "http://nmrshiftdb.nmr.uni-koeln.de/",
         introduce: "nmrshiftdb2是有机结构及核磁共振（NMR）光谱的网络数据库。它提供频谱预测（13C、1H以及其它原子核）以及搜索光谱、结构和其它特性。最重要的是，它提供由用户提交的经过同行评审认可的数据集的提交。Nmrshiftdb2的软件是开源的，数据是开放内容许可下发布。请参考文档以更详细的信息。Nmrshiftdb2配套数据添加、错误修正与修改等功能，依托nmrshiftdb项目是可持续性的。"
      }, {
         name: "Oak Ridge National Laboratory Distributed Active Archive Center for Biogeochemical Dynamics",
         url: "https://daac.ornl.gov/",
         introduce: "橡树岭国家实验室的生物地球化学动力学的分布式归档中心是国家航空航天局（NASA）的地球观测系统数据和地球科学数据和信息系统项目（ESDIS）管理的信息系统管理数据中心之一。 ORNL DAAC归档NASA陆地生态计划生成的数据。提供数据和信息，有关生物地球化学动力学、生态数据和环境过程，关键的研究涉及生物、地质、化学成分与地球的环境。"
      }, {
         name: "PubChem",
         url: "https://pubchem.ncbi.nlm.nih.gov/",
         introduce: "是美国国家卫生研究院（NIH）的一个开放化学数据库"
      }, {
         name: "Reciprocal Net",
         url: "http://www.reciprocalnet.org/",
         introduce: "被研究晶体学的专家们用来存储分子结构信息；大部分的数据对公众开放访问"
      }, {
         name: "Rhea",
         url: "http://www.rhea-db.org/",
         introduce: "Rhea是一个免费的并拥有全面资源的生化反应专业数据库。它被设计为应用程序提供非冗余组的化学变化信息，如酶的功能注释、途径推理和代谢网络重建等。"
      }, {
         name: "SABIO-RK",
         url: "http://sabiork.h-its.org/",
         introduce: "包含关于生化反应的信息"
      }, {
         name: "South Florida Information Access",
         url: "http://sofia.usgs.gov/",
         introduce: "提供水化学等数据"
      }, {
         name: "SpringerMaterials",
         url: "https://materials.springer.com/",
         introduce: "内容涵盖物理化学和工程科学的各个领域"
      }, {
         name: "The Cambridge Structural Database",
         url: "http://www.ccdc.cam.ac.uk/solutions/csd-system/components/csd/",
         introduce: "成立于1965年,CSD是世界范围的小分子有机和有机晶体结构信息存储库。包含超过50万的x射线以及中子衍射的精确3D结构数据库，已成为全世界科学家重要的资源库。"
      }, {
         name: "The Canadian National Atmospheric Chemistry Database",
         url: "http://www.ec.gc.ca/natchem/",
         introduce: "包含来自北美许多主要区域规模网络的空气和降水化学数据。测量包括：粒子、气体、结合测量颗粒和气体、沉淀化学、特别研究、网络和研究。"
      }, {
         name: "Water Isotope System for Data Analysis, Visualization, and Electronic Retrieval",
         url: "http://www-naweb.iaea.org/napc/ih/IHS_resources_isohis.html",
         introduce: "降水同位素的全球网络数据平台"
      }, {
         name: "World Data Centre for Aerosols",
         url: "https://www.gaw-wdca.org/",
         introduce: "世界气溶胶数据中心（WDCA）是世界气象组织（WMO）全球大气监测（GAW）计划的大气气溶胶的微物理，光学和化学性质的数据存储库和存档"
      }, {
         name: "国家材料科学数据共享",
         url: "http://www.materdata.cn/",
         introduce: "涵盖逾3000种钢铁材料及材料基础的高质量数据近11万条"
      }, {
         name: "国家极地科学数据中心",
         url: "https://www.chinare.org.cn/",
         introduce: "主要开展极地雪冰-海洋与全球变化、极区电离层-磁层耦合与空间天气、极地生态环境及其生命过程以及极地科学基础平台技术等领域的研究"
      }, {
         name: "全球科研项目数据库",
         url: "http://project.llas.ac.cn/",
         introduce: "汇聚了全球主要发达国家重要基金组织2000年以来的科研项目数据200多万条"
      }, {
         name: "亚马逊公共数据集",
         url: "https://amazonaws-china.com/cn/public-datasets/",
         introduce: "包含化学、生物、经济等多个领域的数据集，当数据在 AWS 上公开提供后，任何人都可以分析任意数量的数据，而无需自行下载或存储这些数据。"
      }, {
         name: "中国产业信息",
         url: "https://www.chyxx.com/",
         introduce: "工业和信息化部主管,人民邮电报社主办,是我国通信行业唯一拥有国务院新闻办授予新闻发布权的新闻网站。"
      }, {
         name: "The Cambridge Crystallographic Data Centre",
         url: "https://www.ccdc.cam.ac.uk/",
         introduce: "汇集目标为有机物和金属有机物的晶体结构资源库"
      }, {
         name: "ChemSpider",
         url: "http://www.chemspider.com/",
         introduce: "汇集目标以化学结构式为基础的化学信息资源，涵盖物质基础理化性能、物质合成的路线以及参考文献等多种信息，专业数据库，超过四千三百万种的结构、属性以及相关信息数据推荐对象"
      }, {
         name: "ChemSub Online",
         url: "http://chemsub.online.fr/",
         introduce: "汇集目标以分子信息为主的化学物检索，涵盖目标化合物的基本信息，为个人数据库"
      }, {
         name: "ChemSynthesis",
         url: "http://www.chemsynthesis.com/",
         introduce: "汇集目标已知化学物的物化性能，如：熔点、沸点，可作为合成的已知物的参照物，数据涵盖化学超过40,000，参照物超过45,000。"
      }, {
         name: "NIST Chemistry WebBook",
         url: "https://www.nist.gov/",
         introduce: "包括原子和分子物理、化学和晶体结构、流体、材料特性、生物技术、光学字符识别等"
      }, {
         name: "Org Syn(Organic Synthesis)",
         url: "http://www.orgsyn.org/",
         introduce: "汇集目标有机化合物合成反应以及合成路线，操作详细，可以作为文献参考使用"
      }, {
         name: "IUPAC Standards Online",
         url: "https://iupac.org/",
         introduce: "国际理论和应用化学联合会（IUPAC）标准数据库，提取自Pure and Applied Chemistry杂志"
      }, {
         name: "CIRX",
         url: "http://www.cheminform.com/rxnfinder/",
         introduce: "由有机化学家生产，作为合成有机化学家的有用工具。数据是从大约100份期刊中根据精确的选择标准选择的。"
      }, {
         name: "Drug Bank",
         url: " http://www.drugbank.ca",
         introduce: "将详细药物数据与综合全面药物标靶信息结合的生物信息学与化学信息学数据库"
      }, {
         name: "eMolecules",
         url: "https://www.chemeurope.com/en/encyclopedia/EMolecules.html",
         introduce: "该数据库包含来自商业供应商的 700 多万个独特分子"
      }, {
         name: "The Human Metabolome Database",
         url: "http://www.hmdb.ca/",
         introduce: "人类内发现的小分子代谢物数据的详细信息"
      }, {
         name: "Spectral Database for Organic Compounds ",
         url: "https://sdbs.db.aist.go.jp/sdbs/cgi-bin/cre_list.cgi",
         introduce: "有机化合物的综合光谱数据库系统，包括34.600个化合物的六种不同类型的光谱（EI-MS、1H-NMR、13C-NMR、FT-IR、Raman、ESR）"
      }, {
         name: "ZINC",
         url: " http://zinc.docking.org",
         introduce: "专门为虚拟筛选搜集的商用化合物，包含三千五百多万中化合物的3D格式"
      }, {
         name: "HARVARD Dataverse",
         url: "https://dataverse.harvard.edu/",
         introduce: "是一个免费的数据存储库，向哈佛社区内外任何学科的所有研究人员开放"
      }, {
         name: "有机化合物核磁共振碳谱数据库",
         url: "http://210.32.137.90/s/lib/libtb/turning/1373",
         introduce: "微谱数据，即有机化合物核磁共振碳谱数据库，是专门为天然产物研究、有机合成和药物开发等研究工作设计的数据库。现已收录有机化合物约88万余个，是全球最大的有机化合物核磁共振碳谱数据库，适用于药学、化学、生命科学等相关专业"
      }, {
         name: "百料汇",
         url: "https://db.buychemi.cn/",
         introduce: "化工类数据库"
      }, {
         name: "上海有机所化学数据库",
         url: "https://link.zhihu.com/?target=http%3A//202.127.145.134/scdb/",
         introduce: "由多个数据库组成，包括化合物结构数据库、核磁谱图数据库、质谱谱图数据库、红外谱图数据库、物化性质数据库、农药高分辨质谱数据库、中药与有效成分数据库、植物化学成分数据库、药品数据库、药物和天然产物数据库、化学品安全技术说明书(MSDS)数据库、环境化学毒物防治数据库、中国化学专利数据库、中国化学文献数据库、化学反应数据库、化学品纯化数据库等，注册后可免费使用。"
      }, {
         name: "中科院长春应用化学研究所应用化学数据库",
         url: "https://link.zhihu.com/?target=http%3A//www.appchem.csdb.cn/index.jsp",
         introduce: " 是中科院长春应用化学研究所承担建设的综合科技信息数据库的重要组成部分，是中科院知识创新工程信息化建设的重大专项。目前, 应用化学数据库中心包括：稀土萃取数据库，稀土物理化学性质数据库，碳-13 NMR数据库和化合物活性数据库"
      }, {
         name: "中国科学院数据云",
         url: "https://link.zhihu.com/?target=http%3A//www.csdb.cn/pageDataResource",
         introduce: "整合中科院所有数据库资源，覆盖有机所、过程所、长春应化所所有数据库."
      }, {
         name: "化合物图谱数据库查询系统",
         url: "https://link.zhihu.com/?target=http%3A//www.analysis.org.cn/cdb/index.aspx",
         introduce: "该图谱资源库在搭建分析测试技术体系框架的基础上，设计了图谱资源数据库的基本架构和功能，通过搜索引擎，由化合物的分子式、分子量、中英文name、CAS号码、CDB编号等信息可在数据库中检索到该化合物的红外、紫外、质谱及核磁等各种谱图及分子结构。能够容纳大约10万张谱图，目前已有320个化合物的标准红外图进入数据库。该网站对公众免费开放。"
      }, {
         name: "Spectral Database for Organic Compounds",
         url: "https://link.zhihu.com/?target=https%3A//sdbs.db.aist.go.jp/sdbs/cgi-bin/direct_frame_top.cgi",
         introduce: "由日本国家先进工业科学技术研究所创办，是一个比较综合的有机化合物的光谱数据库系统，它包含7种不同类型的光谱图。其中有EI-MS,FT-IR,1H-NMR,13C-NMR,Laser Raman, ESR谱图"
      }, {
         name: "上海研发服务公共平台",
         url: "https://link.zhihu.com/?target=http%3A//chemdb.sgst.cn/ssdb/chem/login_sso.asp",
         introduce: "可以提供化合物有关的命名、结构、基本性质、毒性、谱学、鉴定方法、专利、生物活性、化学反应、医药农药应用、天然产物、相关文献和市场供应，以及精细化学品、农用化学品和工程塑料等信息。其数据中心提供大量的关于化合物的红外波谱和质谱数据。"
      }, {
         name: "Organic compounds Database",
         url: "https://link.zhihu.com/?target=http%3A//www.colby.edu/chemistry/cmp/cmp.html",
         introduce: "包含有2483个化合物，是由哈佛大学弗吉尼亚研究所编撰的"
      }, {
         name: "北京微量化学研究所分析中心",
         url: "https://link.zhihu.com/?target=http%3A//www.microchem.org.cn/",
         introduce: "该中心拥有的Sadtler(萨特勒)红外光谱数据库共计13万3仟多张，其中凝聚相纯化合物标准红外光谱库74126张，气相纯化合物标准红外光谱库9350张"
      }, {
         name: "BioMagResBank",
         url: "https://link.zhihu.com/?target=http%3A//ftp.bmrb.wisc.edu",
         introduce: "BMRB与蛋白质数据库（PDB，布鲁克海文国家实验室）和核酸数据库（NDB，罗格斯大学）合作，致力于开发蛋白质和核酸结构NMR数据的收集站点"
      }, {
         name: "美国标准与技术研究院NIST原子光谱数据库",
         url: "https://link.zhihu.com/?target=http%3A//physics.nist.gov/cgi",
         introduce: "包含辐射数据原子和原子离子的跃迁和能级。包括观察到的99个元素的跃迁和52个元素的能级的数据"
      }, {
         name: "SciFinder",
         url: "https://link.zhihu.com/?target=https%3A//scifinder.cas.org/",
         introduce: "不仅可以检索文献、结构，还能检索合成方法和结构的物理性质"
      }, {
         name: "Crystallography Open Database",
         url: "https://link.zhihu.com/?target=http%3A//www.crystallography.net/",
         introduce: "开放获取有机，无机，金属有机化合物和矿物的晶体结构的集合"
      }, {
         name: "Materials Projec",
         url: "https://materialsproject.org/",
         introduce: "利用超级计算的力量和先进的电子结构方法，Materials Project提供了基于Web的开放式访问，可以访问已知和预测的材料上的计算信息，还可以使用强大的分析工具来激发和设计新颖的材料。"
      }, {
         name: "国家基因组科学数据中心",
         url: "https://bigd.big.ac.cn/",
         introduce: "是国家科技资源共享服务平台之一，依托单位中国科学院北京基因组研究所"
      }, {
         name: "国家微生物科学数据中心",
         url: "http://www.nmdc.cn/",
         introduce: "是国家科技资源共享服务平台，依托单位中国科学院微生物研究所"
      }, {
         name: " 国家人口健康科学数据中心",
         url: "https://www.ncmi.cn/index.html",
         introduce: "承担起国家科技重大专项、科技计划、重大公益专项等人口健康领域科学数据汇交、数据加工、数据存储、数据挖掘和数据共享服务的任务。"
      }, {
         name: "全球科研项目数据库",
         url: "http://project.llas.ac.cn/",
         introduce: "汇聚了全球主要发达国家重要基金组织2000年以来的科研项目数据200多万条"
      }, {
         name: "组学原始数据归档库",
         url: "https://bigd.big.ac.cn/gsa/",
         introduce: "组学原始数据汇交、存储、管理与共享系统，是国内首个被国际期刊认可的组学数据发布平台"
      }, {
         name: "人类基因数据库",
         url: "https://www.ncbi.nlm.nih.gov/genbank/dbest/",
         introduce: "GenBank的一个子数据，包含来源于不同物种的表达序列数据和表达序列标签序列的其他信息"
      }, {
         name: "GenBank",
         url: "https://www.ncbi.nlm.nih.gov/genbank/",
         introduce: "NCBI建立的DNA序列数据库"
      }, {
         name: "微生物组数据云平台",
         url: "https://gcmeta.wdcm.org/",
         introduce: "一个微生物基因组及微生物数据的管理、分析和发布平台，为国内外用户提供一站式的数据存储、数据分析到数据发布的服务，目前已经整合了来自中国科学院微生物组计划及国内外多个重要项目的数据"
      }, {
         name: "生命与健康大数据中心",
         url: "http://bigd.big.ac.cn/",
         introduce: "围绕国家精准医学和重要战略生物资源的组学数据，建立海量生物组学大数据储存、整合与挖掘分析研究体系，发展组学大数据系统构建、挖掘与分析的新技术、新方法，建设组学大数据汇交、应用与共享平台，力争建成支撑我国生命科学发展、国际知名的生命与健康大数据中心。"
      }, {
         name: "生物医学大数据中心",
         url: "https://www.biosino.org/bigbim/index",
         introduce: "聚焦于提升生物与医学大数据存储、管理、挖掘能力，立足上海生科院，服务上海、辐射全国"
      }, {
         name: "亚马逊公共数据集",
         url: "https://amazonaws-china.com/cn/public-datasets/",
         introduce: "包含化学、生物、经济等多个领域的数据集，当数据在 AWS 上公开提供后，任何人都可以分析任意数量的数据，而无需自行下载或存储这些数据。"
      }, {
         name: "github",
         url: "https://github.com/caesar0301/awesome-public-datasets",
         introduce: "为用户整理了非常全面的数据获取渠道，包含各个细分领域的数据库资源，自然科学和社会科学的覆盖都很全面，用户可以自己获取数据去做研究和数据分析"
      }, {
         name: "和鲸社区",
         url: "https://www.heywhale.com/home/dataset",
         introduce: "是国内知名的开放数据科学社区,是最早一批专注于大数据算法比赛的平台,支持在线分享代码、项目和数据集"
      }, {
         name: "UCI数据集",
         url: "https://link.zhihu.com/?target=https%3A//archive.ics.uci.edu/ml/index.php",
         introduce: "是加州大学欧文分校(University of CaliforniaIrvine)提出的用于机器学习的数据库，这个数据库目前共有559个数据集，其数目还在不断增加"
      }, {
         name: "中国科技共享网",
         url: "https://escience.org.cn/",
         introduce: "涉及到地理学、气象学、微生物学、基因组学、医学、农学、物理学等多个学科"
      }, {
         name: "国家材料科学数据共享",
         url: "http://www.materdata.cn/",
         introduce: "涵盖逾3000种钢铁材料及材料基础的高质量数据近11万条"
      }, {
         name: "Golm Metabolome Database",
         url: "http://gmd.mpimp-golm.mpg.de/",
         introduce: "是由德国Max Planck的科学家建立的植物代谢组学数据库。侧重于非靶向代谢组学。该数据库的最大特点是含有大量的植物代谢产物的GC-MS图谱（特别是衍生化后的），用户可以导入自己GC-MS数据进行搜索比对和鉴定。另外，该数据库还含有部分代谢产物在植物中的浓度，可以按照植物名、部位等进行搜索。"
      }, {
         name: "Birdbase",
         url: "http://birdbase.arizona.edu/birdbase/",
         introduce: "平台提供禽类基因信息，为研究禽类的养殖产量、抗病性、行为及骨骼发育等性状提供数据支持。此外，BirdBase还可以为生物医学和农业研究人员提供数据支持服务"
      }, {
         name: "German Federation for Biological Data",
         url: "https://www.gfbio.org/",
         introduce: "德国联邦生物学数据(GFBio)提供生态环境相关的生物学数据，其目的是提供持续性的，以服务为导向的国家数据服务平台"
      }, {
         name: "Primate Life Histories database",
         url: "https://plhdb.org/",
         introduce: "数据库提供野生灵长类种群的观测数据，该数据由九个工作组历时19年独立收集"
      }, {
         name: "The Zebrafish Information Network",
         url: "http://zfin.org/",
         introduce: "斑马鱼作为广泛应用的模式生物，ZFIN提供与其相关的生物学数据。旨在帮助实验室合理使用斑马鱼资源，研究斑马鱼遗传信息、发育过程，对比斑马鱼与人类数据库中的相应数据，满足学术界对于斑马鱼资源的需求。"
      }, {
         name: "PeptideAtlas",
         url: "http://www.peptideatlas.org/",
         introduce: "许多肽段能在质谱中被检测，而这些信息被统计在一个数据库，叫PeptideAtlas。2005年，PeptideAtlas数据库成立，从开始到现在，来自多种不同物种的质谱数据仍在大幅增加和更新。它既是一个庞大的数据库，同时也对蛋白质组学相关实验提供了重要的查询资源。"
      }, {
         name: "IGSR",
         url: "http://www.1000genomes.org/data",
         introduce: "国际基因组样本数据，支持开放的人类变异数据"
      }, {
         name: "BBBC",
         url: "https://www.broadinstitute.org/bbbc",
         introduce: "是可自由下载的显微镜图像集的集合"
      }, {
         name: "CELL IMAGE LIBRARY",
         url: "http://www.cellimagelibrary.org/",
         introduce: "由美国NIH资助的公开免费的资源数据库，其中包含细胞的图像、视频和动画，可以捕获多种生物、细胞类型和细胞过程"
      }, {
         name: "Complete GENOMICS",
         url: "http://www.completegenomics.com/public-data/69-genomes/",
         introduce: "全人类基因组的不同数据集可免费供公众使用，以加强任何基因组研究或评估完整的基因组数据结果和文件格式"
      }, {
         name: "ArrayExpress",
         url: "http://www.ebi.ac.uk/arrayexpress/",
         introduce: "ArrayExpress功能基因组学数据档案可存储来自高通量功能基因组学实验的数据，并将这些数据提供给研究团体重用"
      }, {
         name: "EMBL-EBI",
         url: "http://www.ebi.ac.uk/arrayexpress/",
         introduce: "是欧洲生命科学旗舰实验室欧洲分子生物学实验室 （EMBL） 的一部分,提供数据服务和培训，帮助科学家认识到\"大数据\"在生物科学中的潜力，为造福人类的发现铺平道路"
      }, {
         name: "ENCODE",
         url: "https://www.encodeproject.org/",
         introduce: "是由国家人类基因组研究所（NHGRI）资助的研究小组的国际合作。ENCODE的目标是在人类基因组中构建全面的功能元件清单，包括在蛋白质和RNA水平上起作用的元件，以及控制基因活跃的细胞和环境的调控元件"
      }, {
         name: "EMPIAR",
         url: "http://www.ebi.ac.uk/pdbe/emdb/empiar/",
         introduce: "电子显微镜公共图像档案馆，是原始二维电子显微镜图像的公共资源"
      }, {
         name: " 恩森布尔基因组",
         url: "https://ensemblgenomes.org/",
         introduce: "提供非脊椎动物物种的基因组数据，以及用于处理，分析和可视化该数据的工具"
      }, {
         name: "Gene Expression Omnibus",
         url: "http://www.ncbi.nlm.nih.gov/geo/",
         introduce: "GEO是一个公共功能基因组学数据库，支持符合MIAME的数据提交。 接受基于数组和序列的数据。 提供的工具可帮助用户查询和下载实验以及精选的基因表达谱。"
      }, {
         name: "Gene Ontology",
         url: "http://geneontology.org/docs/download-go-annotations/",
         introduce: "基因本体论资源以计算方式表示了我们当前有关从人类到细菌的许多不同生物体的基因功能（或更恰当地说，是由基因产生的蛋白质和非编码RNA分子）的科学知识。 它被广泛用于支持科学研究，并已在成千上万的出版物中引用。"
      }, {
         name: "Global Biotic Interactions (GloBI)",
         url: "https://github.com/jhpoelen/eol-globi-data/wiki#accessing-species-interaction-data",
         introduce: "该项目的任务是找到标准化和整合物种相互作用数据的有效方法。 通过使这些数据随时可用，GloBI将使研究人员和爱好者能够回答有关本地化，一对一物种相互作用以及物种相互作用随时间变化的总体情况的问题。"
      }, {
         name: "Harvard Medical School (HMS) LINCS Center",
         url: "http://lincs.hms.harvard.edu/",
         introduce: "收集和传播理解人类细胞如何响应药物，环境和突变的扰动所需的数据和分析工具"
      }, {
         name: "Stanford HGDP SNP Genotyping Data",
         url: "http://www.hagsc.org/hgdp/files.html",
         introduce: "人类基因组多样性项目（HGDP）与巴黎埃图德多态性中心（CEPH）合作，从这些完全同意的个体中采集了基因组DNA样本"
      }, {
         name: "NIH Human Microbiome Project",
         url: "http://www.hmpdacc.org/reference_genomes/reference_genomes.php",
         introduce: "其任务是创造资源，使人类微生物群能够全面定性，并分析其在人类健康和疾病中的作用"
      }, {
         name: " ICOS PSP ",
         url: "http://ico2s.org/datasets/psp_benchmark.html",
         introduce: "ICOS小组的任务是在计算科学和复杂生物系统的界面上进行突破性研究"
      }, {
         name: "NCBI",
         url: "https://www.ncbi.nlm.nih.gov/",
         introduce: "保管GenBank的基因测序数据和Medline的生物医学研究论文索引"
      }, {
         name: "Cancer Program Datasets",
         url: "http://www.broadinstitute.org/cgi-bin/cancer/datasets.cgi",
         introduce: "麻省理工学院癌症基因组学数据"
      }, {
         name: "Genomic Data Commons ",
         url: "https://gdc.cancer.gov/access-data/gdc-data-portal",
         introduce: "基因组数据共享（GDC）是国家癌症研究所（NCI）的一个研究项目。GDC 的使命是为癌症研究界提供统一的数据库，使癌症基因组研究的数据共享能够支持精密医学"
      }, {
         name: "palmerpenguins",
         url: "https://allisonhorst.github.io/palmerpenguins/",
         introduce: "目标是提供一个伟大的数据集的数据探索和可视化"
      }, {
         name: "Pathguide",
         url: "http://www.pathguide.org/",
         introduce: "包含有关702生物通路相关资源和分子相互作用相关资源的信息"
      }, {
         name: "COSMIC",
         url: "https://cancer.sanger.ac.uk/cosmic",
         introduce: "是探索体质突变对人类癌症影响的最大和最全面的资源"
      }, {
         name: "Genomics of Drug Sensitivity in Cancer",
         url: "https://www.cancerrxgene.org/",
         introduce: "已对1000条人类癌细胞系进行了特征，并筛选出100种化合物。在这个网站上，你会发现药物反应数据和基因组标记的敏感性"
      }, {
         name: "SSBD",
         url: "http://ssbd.qbic.riken.jp/",
         introduce: "为分析单分子、细胞、基因表达核等生物物体的定量数据和显微镜图像提供了丰富的开放资源"
      }, {
         name: "Catalogue of life ",
         url: "http://www.catalogueoflife.org/content/annual-checklist-archive",
         introduce: "世界上最完整的权威物种列表 - 由数百名全球分类学家维护"
      }, {
         name: "The Personal Genome Project",
         url: "http://www.personalgenomes.org/",
         introduce: "个人基因组计划始于2005年，是全世界致力于创建公共基因组、健康和特征数据的项目的愿景和联盟"
      }, {
         name: "UCSC",
         url: "http://hgdownload.soe.ucsc.edu/downloads.html",
         introduce: "包括广泛的脊椎动物和模型有机体组合和注释集合，以及一大套用于查看、分析和下载数据的工具"
      }, {
         name: "Rfam",
         url: "https://docs.rfam.org/en/latest/database.html",
         introduce: "是非编码 RNA 家族的集合，以手动策划的序列排列、共识的次要结构和预测的同源表示"
      }, {
         name: "World Health Organization",
         url: "http://www.who.int/gho/en/",
         introduce: "探索健康数据的世界"
      }, {
         name: "Kaggle",
         url: "https://www.kaggle.com/datasets",
         introduce: "是由联合创始人、首席执行官安东尼·高德布卢姆（Anthony Goldbloom）2010年在墨尔本创立的，主要为开发商和数据科学家提供举办机器学习竞赛、托管数据库、编写和分享代码的平台。该平台已经吸引了80万名数据科学家的关注，这些用户资源或许正是吸引谷歌的主要因素"
      }, {
         name: "LILA BC",
         url: "http://lila.science/",
         introduce: "是一个与生物学和保护相关的数据集的存储库，旨在为机器学习（ML）研究人员和希望利用ML进行生物学和保护的研究人员提供资源。 "
      }, {
         name: "mendeley Data",
         url: "https://data.mendeley.com/",
         introduce: "是一个安全的基于云的存储库，您可以存储您的数据，确保无论您身在何处，都可以轻松共享、访问和引用数据"
      }, {
         name: "NIST Chemistry WebBook",
         url: "https://www.nist.gov/",
         introduce: "包括原子和分子物理、化学和晶体结构、流体、材料特性、生物技术、光学字符识别等"
      }, {
         name: "HARVARD Dataverse",
         url: "https://dataverse.harvard.edu/",
         introduce: "是一个免费的数据存储库，向哈佛社区内外任何学科的所有研究人员开放"
      }, {
         name: "有机化合物核磁共振碳谱数据库",
         url: "http://210.32.137.90/s/lib/libtb/turning/1373",
         introduce: "微谱数据，即有机化合物核磁共振碳谱数据库，是专门为天然产物研究、有机合成和药物开发等研究工作设计的数据库。现已收录有机化合物约88万余个，是全球最大的有机化合物核磁共振碳谱数据库，适用于药学、化学、生命科学等相关专业"
      }, {
         name: "DDBJ",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttps%253A%252F%252Fwww.ddbj.nig.ac.jp%252Findex-e.html",
         introduce: "生物信息和DDBJ中心为生命科学研究和科学进步的数据提供共享和分析服务"
      }, {
         name: "Bioinformatics.org",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttp%253A%252F%252Fwww.bioinformatics.org",
         introduce: "国际生物信息学动态及会议"
      }, {
         name: "RefSeq",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttps%253A%252F%252Fwww.ncbi.nlm.nih.gov%252Frefseq%252F",
         introduce: "一整套全面，完整，非冗余，带有注释的参考序列，包括基因组，转录本和蛋白质。"
      }, {
         name: "PIR",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttps%253A%252F%252Fproteininformationresource.org",
         introduce: "为科学界提供了单一的，集中的，权威的蛋白质序列和功能信息资源"
      }, {
         name: "UniProt",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttps%253A%252F%252Fwww.uniprot.org",
         introduce: "为科学界提供蛋白质序列和功能信息的全面，高质量且可自由访问的资源"
      }, {
         name: "InterPro",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttp%253A%252F%252Fwww.ebi.ac.uk%252Finterpro%252F",
         introduce: "通过将蛋白质分类为家族并预测领域和重要部位来提供蛋白质的功能分析"
      }, {
         name: "ExPASy",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttps%253A%252F%252Fprosite.expasy.org",
         introduce: "是蛋白质家族和结构域的数据库。 基于这样的观察，尽管存在大量不同的蛋白质，但根据其序列的相似性，大多数蛋白质可以分为有限的家族。 属于特定家族的蛋白质或蛋白质结构域通常共享功能属性，并且源自共同的祖先"
      }, {
         name: "RCSB PDB",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttp%253A%252F%252Fwww.rcsb.org",
         introduce: "该资源由蛋白质数据库提供的有关蛋白质，核酸和复杂装配体3D形状的档案信息提供支持，可帮助学生和研究人员了解生物医学和农业的各个方面，从蛋白质合成到健康和疾病。"
      }, {
         name: "SCOP",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttp%253A%252F%252Fscop.mrc-lmb.cam.ac.uk",
         introduce: "旨在详细和全面地描述蛋白质之间的结构和进化关系，其三维结构已知并存入蛋白质数据库"
      }, {
         name: "PDBe",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttps%253A%252F%252Fwww.ebi.ac.uk%252Fpdbe%252F",
         introduce: "PDBe是用于收集，组织和传播生物大分子结构数据的欧洲资源"
      }, {
         name: "ConsensusPathDB",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttp%253A%252F%252Fcpdb.molgen.mpg.de",
         introduce: "将人与人之间的相互作用网络整合在一起，包括二元和复杂的蛋白质-蛋白质，遗传，代谢，信号传导，基因调控和药物-靶标相互作用，以及生化途径"
      }, {
         name: "CORUM",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttps%253A%252F%252Fmips.helmholtz-muenchen.de%252Fcorum%252F",
         introduce: "提供了来自哺乳动物的人工注释的蛋白质复合物的资源。 注释包括蛋白质复合物功能，定位，亚基组成，文献参考等。 所有信息均来自发表在科学文章中的单个实验，不包括高通量实验的数据"
      }, {
         name: "KEGG",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttps%253A%252F%252Fwww.genome.jp%252Fkegg%252F",
         introduce: "用于从分子水平信息，特别是基因组测序和其他高通量实验技术产生的大规模分子数据集中了解生物系统的高层次功能和实用功能，如细胞、生物体和生态系统"
      }, {
         name: "Plantcy",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttps%253A%252F%252Fwww.plantcyc.org",
         introduce: "PMN当前包含一个称为PlantCyc的多物种参考数据库和126种特定物种的生物分类数据库"
      }, {
         name: "SMPDB",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttps%253A%252F%252Fsmpdb.ca",
         introduce: "是一个交互式的可视化数据库，其中包含仅在人类中发现的3万多个小分子途径。SMPDB专为支持代谢组学，转录组学，蛋白质组学和系统生物学中的途径阐明和途径发现而设计"
      }, {
         name: "piRNABank",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttp%253A%252F%252Fpirnabank.ibab.ac.in",
         introduce: "可提供有关三种被广泛研究的哺乳动物（人，小鼠，大鼠和一种果蝇果蝇）中piRNA的全面信息。 它编译了所有可能的piRNA簇，还描绘了piRNA以及相关的基因组元件（如基因），并在全基因组范围内重复显示"
      }, {
         name: "miRBase",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttp%253A%252F%252Fwww.mirbase.org",
         introduce: "是已发布的miRNA序列和注释的可搜索数据库。 miRBase序列数据库中的每个条目均代表miRNA转录本的预测发夹部分（在数据库中称为mir），其中包含有关成熟miRNA序列（称为miR）的位置和序列的信息。"
      }, {
         name: "GtRNAdb",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttp%253A%252F%252Fgtrnadb.ucsc.edu",
         introduce: "基因组tRNA数据库包含tRNAscan-SE对完整或几乎完整的基因组进行的tRNA基因预测"
      }, {
         name: "Silva",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttps%253A%252F%252Fwww.arb-silva.de",
         introduce: "为所有三个生命域（细菌、古细菌和真核生物）提供全面、质量检查和定期更新的小（16S/18S，SSU）和大亚基（23S/28S，LSU）核糖体RNA（rRNA）序列数据集。"
      }, {
         name: "NONCODE",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttp%253A%252F%252Fwww.noncode.org",
         introduce: "是专用于非编码RNA（tRNA和rRNA除外）的集成知识数据库"
      }, {
         name: "LncRBase",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttp%253A%252F%252Fbicresources.jcbose.ac.in%252Fzhumur%252Flncrbase%252F",
         introduce: "是人和小鼠中长非编码RNA（lncRNA）的综合数据库"
      }, {
         name: "GO",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttp%253A%252F%252Fgeneontology.org",
         introduce: "是有关基因功能的全球最大信息来源。 这些知识既是人类可读的，也是机器可读的，并且是生物医学研究中大规模分子生物学和遗传学实验的计算分析的基础"
      }, {
         name: "DAVID",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttps%253A%252F%252Fdavid.ncifcrf.gov",
         introduce: "为研究人员提供了一套全面的功能注释工具，以了解大量基因背后的生物学含义"
      }, {
         name: "GeneCard",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttps%253A%252F%252Fwww.genecards.org%252F",
         introduce: "自动整合125个数据库，包含基因组、转录组、蛋白组、遗传、临床和功能信息的庞大人基因组数据库"
      }, {
         name: "GENCODE",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttps%253A%252F%252Fwww.gencodegenes.org%252F",
         introduce: "人和小鼠基因组中的所有基因特征进行识别和分类，并释放这些注释以利于生物医学研究和基因组解释"
      }, {
         name: "mBiobank",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttp%253A%252F%252Fwww.mbiobank.com%252F",
         introduce: "中国代谢解析计划"
      }, {
         name: "Human protein atlas",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttps%253A%252F%252Fwww.proteinatlas.org",
         introduce: "人体蛋白在细胞、组织、病理条件下的表达"
      }, {
         name: "Personal Genome Project",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttps%253A%252F%252Fwww.personalgenomes.org",
         introduce: "来自世界各地的100,00名志愿者的人类基因组计划"
      }, {
         name: "OMIM",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttps%253A%252F%252Fomim.org",
         introduce: "一个管理人类基因和人类遗传疾病特征的数据库"
      }, {
         name: "Legume Information System",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttps%253A%252F%252Flegumeinfo.org",
         introduce: "豆科植物的基因组数据库"
      }, {
         name: "Rat Genome Database",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttps%253A%252F%252Frgd.mcw.edu%252F",
         introduce: "大鼠基因组数据库"
      }, {
         name: "Mouse genome",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttp%253A%252F%252Fwww.informatics.jax.org",
         introduce: "可以比较小鼠和人类基因组的区别；查找基因功能及敲除某个基因后导致的表型；人类-小鼠相关疾病；基因表达等"
      }, {
         name: "Mouse Genome Database",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttp%253A%252F%252Fwww.nervenet.org%252Fmain%252Fdictionary.html",
         introduce: "整合了国际上实验室小鼠生物数据的资源库，提供小鼠相关的基因组、综合遗传等信息"
      }, {
         name: "FlyBase",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttp%253A%252F%252Fflybase.org",
         introduce: "模式生物果蝇的基因组数据库"
      }, {
         name: "Zebrafish genome",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttp%253A%252F%252Fzfin.org",
         introduce: "斑马鱼的基因组数据库"
      }, {
         name: "Xenbase",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttp%253A%252F%252Fwww.xenbase.org%252Fentry%252F",
         introduce: "模式生物非洲爪蟾（Xenopus tropicalis）和非洲爪蟾（Xenopus laevis）的基因组数据库"
      }, {
         name: "Wormbase",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttps%253A%252F%252Fwormbase.org%252F%2523012-34-5",
         introduce: "关于线虫模式生物秀丽隐杆线虫的生物学和基因组在线生物数据库，还包含其他相关线虫的信息"
      }, {
         name: "PomBase",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttps%253A%252F%252Fwww.pombase.org%252F",
         introduce: "裂殖酵母(Schizosaccharomyces pombe)的知识库"
      }, {
         name: "Saccharomyces Genome Database",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttps%253A%252F%252Fwww.yeastgenome.org%252F",
         introduce: "酵母模型生物的基因组数据库"
      }, {
         name: "STD序列数据库",
         url: "http://www.stdgen.lanl.gov/",
         introduce: "粒性结膜炎（沙眼）衣原体，性病支原体，梅毒螺旋体，人类乳头（状）瘤病毒 基因数据库"
      }, {
         name: "Boola Boola Forest bird study",
         url: "http://data.cerdi.edu.au/gippsland_birds.php",
         introduce: "平台提供位于澳大利亚东南部Gippsland地区不同种类林地中鸟类生物多样性数据"
      }, {
         name: "Freshwater Information Platform",
         url: "http://www.freshwaterplatform.eu/",
         introduce: "提供与淡水资源及其生物多样性有关的数据、地图、工具和其他资源的链接的平台"
      }, {
         name: "全球生物多样性信息网络",
         url: "https://link.zhihu.com/?target=https%3A//www.gbif.org/",
         introduce: "可以下载全球范围内的动植物多样性数据"
      }, {
         name: "Dryad",
         url: "https://datadryad.org/",
         introduce: "是一个储存医学、生物学、生态学数据的开放数据知识库，为学术文献提供基础设施，促进其重用，让学术论文的数据可被发现，可自由重复使用和可引用"
      }, {
         name: "家犬和野生犬科动物的综合性数据资源库",
         url: "http://bigd.big.ac.cn/idog/",
         introduce: "是家犬和野生犬的综合性数据资源库，为全球狗研究领域提供各种数据服务。 这包括基因，基因组，SNP，品种/疾病特征，基因表达，GO功能注释，狗 - 人类同源疾病和文献。 此外，iDog还提供用于执行基因组数据可视化和分析的在线工具。"
      }, {
         name: "北京大学生命科学学院生物信息学中心",
         url: "https://opendata.pku.edu.cn/dataverse/CBI",
         introduce: "是欧洲分子生物学网络组织EMBnet的中国国家节点。几年来，已经与多个国家的生物信息中心建立了合作关系，其中包括：欧洲生物信息学研究所（EBI）、国际蛋白质数据库和分析中心（ExPASy） 、国际遗传工程和生物技术研究所、德国生物工程研究所、英国基因组资源中心、英国基因组研究中心（Sanger Centre）、荷兰生物信息中心、澳大利亚基因组信息中心、新加坡生物信息中心等等。目前是国内数据库种类最多，数据量最大的生物信息站点， 为国内外用户提供了多项生物信息服务。"
      }, {
         name: "国家空间科学数据中心",
         url: "https://www.nssdc.ac.cn/mhsy/html/index.html",
         introduce: "数据中心以中科院国家空间科学中心为依托单位，联合中科院国家天文台（探月工程中心）、中国科学技术大学、中科院国家授时中心和中科院计算机网络信息中心等单位共同建设和运行"
      }, {
         name: "国家天文科学数据中心",
         url: "https://nadc.china-vo.org/",
         introduce: "国内各大望远镜的观测数据、国际知名数据库的镜像"
      }, {
         name: "国家对地观测科学数据中心",
         url: "http://www.chinageoss.cn/dsp/home/index.jsp",
         introduce: "全国规模最大的地球观测数据免费共享资源"
      }, {
         name: "国家极地科学数据中心",
         url: "https://www.chinare.org.cn/",
         introduce: "主要开展极地雪冰-海洋与全球变化、极区电离层-磁层耦合与空间天气、极地生态环境及其生命过程以及极地科学基础平台技术等领域的研究"
      }, {
         name: "国家地震科学数据中心",
         url: "https://data.earthquake.cn/index.html",
         introduce: "国家科技资源共享服务平台，依托单位中国科学院昆明植物研究所"
      }, {
         name: "国家地球系统科学数据中心",
         url: "http://www.geodata.cn/index.html",
         introduce: "整合集成分布在国内外数据库中心群、高等院校、科研院所以及科学家个人产生的数据资源，引进国际数据资源，接收国家重点科研项目产生的数据资源，在此基础上生产加工数据产品"
      }, {
         name: "北京离子探针中心",
         url: "http://eds.ceode.ac.cn/sjglb/dataservice.htm",
         introduce: "由科技部命名的国家大型科学仪器中心，其核心仪器是两台“高分辨二次离子探针质谱计（SHRIMPⅡ ）”。中心主要从事地质年代学和宇宙年代学研究；发展定年新技术新方法；进行必要的矿物微区稀土地球化学研究；解决重大地球科学研究课题中的时序问题，特别是太阳系和地球的形成及早期历史研究；主要造山带的构造演化研究；地质年代表研究；大型和特殊矿床成矿时代研究并从事科学仪器研发。"
      }, {
         name: "PANGAEA",
         url: "https://www.pangaea.de/",
         introduce: "地球和环境科学数据的发布平台，由德国阿尔弗雷德-魏格纳极地与海洋研究所、布莱梅大学海洋环境科学中心共建"
      }, {
         name: "地理空间数据云",
         url: "http://www.gscloud.cn/",
         introduce: "以中国科学院及国家的科学研究为主要需求，逐渐引进当今国际上不同领域内的国际数据资源，并对其进行加工、整理、集成，最终实现数据的集中式公开服务、在线计算等"
      }, {
         name: "国家综合地球观测数据共享平台",
         url: "http://www.chinageoss.org/dsp/home/index.jsp",
         introduce: "是由科技部主导，国家遥感中心牵头，各卫星中心、数据单位贡献数据、参与建设，数据主要为遥感卫星数据，涵盖主要的国内卫星和国际卫星，也包括相关科学研究数据。"
      }, {
         name: "USGS Data",
         url: "https://earthexplorer.usgs.gov/",
         introduce: "美国地质调查局Landsat、MODIS等卫星影像下载"
      }, {
         name: "ESA's Sentinel",
         url: "https://scihub.copernicus.eu/dhus/#/home",
         introduce: "欧洲航天局哨兵系列卫星，以高分辨率著称"
      }, {
         name: "Landcover 衍生数据",
         url: "http://landcover.org/",
         introduce: "全球土地覆盖研究机构，衍生卫星数据"
      }, {
         name: "DigitalGlobe样本",
         url: "http://www.digitalglobe.com/product-samples",
         introduce: "目前最大的商业卫星数据供应商"
      }, {
         name: "RapidEye",
         url: "https://www.planet.com/",
         introduce: "来自RapidEye的免费卫星数据"
      }, {
         name: "Open Data Inception",
         url: "https://www.directionsmag.com/data-resources/8242",
         introduce: "收录全球不同国家地区2600多数据库"
      }, {
         name: "中国科技共享网",
         url: "https://escience.org.cn/",
         introduce: "涉及到地理学、气象学、微生物学、基因组学、医学、农学、物理学等多个学科"
      }, {
         name: "Atmospheric Science Data Center，NASA Langley Research Center",
         url: "https://eosweb.larc.nasa.gov/",
         introduce: "NASA Langley研究中心的大气科学数据中心（ASDC）负责NASA地球科学数据在辐射预算，云层，气溶胶和对流层构成等领域的处理，存档和分发工作。"
      }, {
         name: "Computational and Information Systems Laboratory Research Data Archive",
         url: "http://rda.ucar.edu/",
         introduce: "由NCAR的计算和信息系统实验室的数据支持部门管理的CISL研究数据存档（RDA），包含大量多样的气象和海洋学系列 观测，运行和再分析模型输出以及支持大气和地球科学研究的遥感数据集，以及地形/测深，植被和土地利用等辅助数据集。"
      }, {
         name: "Crustal Dynamics Data Information System",
         url: "https://cddis.nasa.gov/",
         introduce: "空间大地测量学和地球动力学有关的数据产品"
      }, {
         name: "Data Center for Aurora in NIPR",
         url: "http://polaris.nipr.ac.jp/~aurora/",
         introduce: "数据库包括了截至到2003年12月的极光世界数据中心数据集，数据手册，相关出版物和设施的收集情况"
      }, {
         name: "中国国家地球系统科学数据中心-地球物理分中心",
         url: "http://geospace.geodata.cn/",
         introduce: "提供电离层、地磁、中高层大气、固体地球等方面的数据"
      }, {
         name: "FLUXNET(全球采集的通量和气象数据)",
         url: "https://fluxnet.fluxdata.org/",
         introduce: "全球采集的通量和气象数据已提交给中央数据库（www.fluxdata.org）。每个塔站点，涡流协方差方法用于量化生物圈和大气之间的标量通量（例如CO2，CH4，水蒸气）和能量（例如，敏感，潜热）。此外，从高时间分辨率的大量传感器获取辅助物理变量（例如，气温，降水，辐射）的连续测量。"
      }, {
         name: "GGOS Atmosphere(全球大地测量观测系统的大气信息)",
         url: "http://ggosatm.hg.tuwien.ac.at/proj_ggosatm.html",
         introduce: "由奥地利维也纳大学测绘与地理信息系运营，用于归档，发布和分发描述空间大地测量学中大气效应的参数。 这些影响包括大气中的传播延迟（例如，GPS信号），大气负载或地球旋转的大气激发。"
      }, {
         name: "GISS Surface Temperature Analysis(GISTEMP，GISS表面温度分析)",
         url: "http://data.giss.nasa.gov/gistemp/",
         introduce: "GISS的计划领域大致可以分为气候强迫类别、 气候模式发展、 地球观测、 大气辐射、 大气化学、 气候影响、 行星大气层，系外行星和天体生物学、 古气候 和其他学科。"
      }, {
         name: "Goddard Earth Sciences Data and Information Services Center(戈达德地球科学数据和信息服务中心)",
         url: "https://daac.gsfc.nasa.gov/",
         introduce: "数据中心作为NASA科学任务监测（SMD）之一，为研究科学家、应用科学家、应用用户和学生提供地球科学数据、信息和服务。 GES DISC是NASA降水和水文学的归档，以及大气组成和动力学遥感数据和信息。"
      }, {
         name: "Indian Space Science Data Center（ISSDC，印度空间科学数据中心）",
         url: "http://www.issdc.gov.in/",
         introduce: "有效载荷数据及相关辅助数据空间科学任务的数据"
      }, {
         name: "Civil Aircraft for the Regular Investigation of the atmosphere Based on an Instrument Container",
         url: "http://www.caribic-atmospheric.com/",
         introduce: "CARIBIC是基于飞机的科学项目，研究全球大气成分和气候变化，是EUROPE的全球大气监测解决方案。CARIBIC容器包括臭氧，一氧化碳，二氧化碳，氮氧化物，甲烷，水蒸气，水同位素，丙酮和乙腈，汞，生物气溶胶，尘埃颗粒等等的现场测量设备。CARIBIC容器包括一个用于收集超过100个空气样本的系统。这些空气样本在每个飞行系列后都会在实验室进行分析。对于每个样品，我们测量超过40种气体，包括臭氧杀手（CFC's）和所有温室气体。"
      }, {
         name: "中国遥感数据共享网",
         url: "http://rs.ceode.ac.cn/",
         introduce: "国内存档周期最长的数据网站，对Landsat数据免费共享，也可订购国外商业卫星数据。注册账号，通过审核就可直接下载"
      }, {
         name: "中国资源卫星应用中心",
         url: "http://www.cresda.com/CN/sjfw/zxsj/index.shtml",
         introduce: "我国三大卫星应用中心之一，汇集国产卫星数据，注册后可下载HJ卫星数据，高分数据下载需要审核。"
      }, {
         name: "全球变化科学研究数据出版系统",
         url: "http://www.geodoi.ac.cn/WebCn/Default.aspx",
         introduce: "数据种类非常丰富，涉及领域较多，根据需要自己查找。账号注册，直接下载即可"
      }, {
         name: "空间科学虚拟观测台",
         url: "http://vsso.cssdc.ac.cn",
         introduce: "空间科学虚拟观测台数据资源覆盖空间物理、空间天文、行星科学、空间地球科学四大学科，已建成24个专业数据库"
      }, {
         name: "中国南北极数据中心",
         url: "http://www.chinare.org.cn/en/index/",
         introduce: "国家海洋局中国极地中心主办的极地数据共享与服务管理平台，开展极地科学数据的国内外交流与共享"
      }, {
         name: "地理国情监测云平台",
         url: "http://www.dsac.cn/",
         introduce: "云平台主要包含两部分——时空数据平台和数值模拟研究平台，特别是在现已建成的生态环境科学模型库的基础上，发展了数值模拟相关的工具库，并与时空数据平台进行集成，形成了具有国内领先水平的生态环境科学数值研究环境。"
      }, {
         name: "遥感集市",
         url: "http://www.rscloudmart.com/",
         introduce: "是遥感行业首个一站式遥感云服务平台，由中科遥感集团与中国资源卫星应用中心联合发布"
      }, {
         name: "美国地质调查局USGS",
         url: "https://www.usgs.gov/",
         introduce: "数据资源有Landsat、MODIS、时序产品等"
      }, {
         name: "欧空局ESA 哨兵数据",
         url: "https://scihub.copernicus.eu/",
         introduce: "sentinel-1号SAR数据和sentinel-2号光学数据免费下载，数据质量很好"
      }, {
         name: "美国国家航空航天局地球观测系统数据和信息系统",
         url: "http://sedac.ciesin.columbia.edu/data/sets/browse",
         introduce: "NASA联合美国大学做的社会经济数据共享系统，涉及的领域广泛，数据的描述十分详细"
      }, {
         name: "Open Topography",
         url: "http://www.opentopography.org",
         introduce: "提供高空间分辨率的地形数据和操作工具的门户网站，可以在此下载Lidar数据"
      }, {
         name: "Landsat8下载共享系统",
         url: "http://ids.ceode.ac.cn/query.html",
         introduce: "提供THEOS-1、LANDSAT系列、IRS-P6、ENVISAT-1、ERS-2数据"
      }, {
         name: "欧洲航天局对地观测目标订购服务中心",
         url: "https://earth.esa.int/web/guest/eoli",
         introduce: "收录数据主要包括Envisat, ERS, IKONOS, DMC, ALOS, SPOT, Kompsat, Proba, IRS, SCISAT等"
      }, {
         name: "National Institute for Space Research",
         url: "http://www.dgi.inpe.br/CDSR/",
         introduce: "是由中国与巴西合作支持，主要数据源自中巴合作项目，两颗资源卫星CBERS-2和CBERS-2b，也收录了CBERS、Landsat,、ResourceSat、S-NPP、Terra & UK-DMC 2等项目的数据，但所包含数据仅局限在南美以及非洲地区"
      }, {
         name: "印度航天研究组织Bhuvan Indian Geo-Platform of ISRO",
         url: "http://bhuvan.nrsc.gov.in/data/download/index.php",
         introduce: "主要数据源为IMS-1、Cartosat、OceanSat、ResourceSat等印度自己的卫星。该平台还提供印度以外的全球NDVI指数，南亚地区的3R1数据和印度洋周边国家的气候产品数据"
      }, {
         name: "JAXA’s Global ALOS 3D World–日本宇宙航空研究机构",
         url: "http://www.eorc.jaxa.jp/ALOS/en/aw3d30/",
         introduce: "ALOS World 3D是一个30米空间分辨率的数字地表模型"
      }, {
         name: "Global Land Cover Facility",
         url: "http://landcover.org/",
         introduce: "提供由Landsat、MODIS和AVHRR遥感影像衍生的数据产品GLCF Landcover"
      }, {
         name: "Geo-Airbus",
         url: "http://www.intelligence-airbusds.com/en/23-sample-imagery",
         introduce: "提供的卫星数据源包括SPOT、Pleiades,、RapidEye以及TerraSAR等"
      }, {
         name: "UNAVCO Research Data",
         url: "http://www.unavco.org/data/imaging/sar/data-access-methods/SarArchive/flexweb/SearchSarScene.html",
         introduce: "主要利用合成孔径雷达卫星数据研究洪水、板块构造、地震等方面，直接注册，数据免费。"
      }, {
         name: "Alaska Science Center",
         url: "http://alaska.usgs.gov/index.php",
         introduce: "通过提供及时客观的动植物、矿产资源的观测数据及研究成果，帮助政府和公众理解高度复杂多样化的阿拉斯加-北极生态系统综合景观，为矿产和能源开发决策提供数据支持"
      }, {
         name: "British Antarctic Survey",
         url: "https://www.bas.ac.uk/data/our-data/",
         introduce: "提供南极大陆的大气、生物圈、岩石圈、水圈、冰雪圈、太阳和地球相互作用的元数据和数据"
      }, {
         name: "中国国家地球系统科学数据中心",
         url: "http://geospace.geodata.cn/",
         introduce: "提供电离层、地磁、中高层大气、固体地球等方面的数据"
      }, {
         name: "全国地理信息资源目录服务系统",
         url: "https://link.zhihu.com/?target=http%3A//www.webmap.cn/main.do%3Fmethod%3Dindex",
         introduce: "各类空间数据，包括地表覆盖、测量成果、遥感影像、高程模型、栅格地图等"
      }, {
         name: "自然资源部标准地图服务",
         url: "https://link.zhihu.com/?target=http%3A//bzdt.ch.mnr.gov.cn/",
         introduce: "标准地图依据中国和世界各国国界线画法标准编制而成。社会公众可以免费浏览、下载标准地图，直接使用标准地图时需要标注审图号"
      }, {
         name: "资源环境数据云平台",
         url: "https://link.zhihu.com/?target=http%3A//www.resdc.cn/",
         introduce: "中科院建立的资源环境科学数据集成、派生、共享与数值模拟的平台。数据与我国现状契合度很高。"
      }, {
         name: "土地调查成果共享应用服务平台",
         url: "https://link.zhihu.com/?target=http%3A//tddc.mnr.gov.cn/to_Login",
         introduce: "以行政区为划分依据，提供包含土地利用数据在内的多种数据。"
      }, {
         name: "Natural Earth",
         url: "https://link.zhihu.com/?target=http%3A//www.naturalearthdata.com/",
         introduce: "提供了全球范围内的矢量和影像数据，且数据是开放性的"
      }, {
         name: "OpenStreetMap",
         url: "https://link.zhihu.com/?target=https%3A//www.openstreetmap.org/",
         introduce: "一款由网络大众共同打造的免费开源、可编辑的地图服务，可以下载到很多建筑、道路等数据。"
      }, {
         name: "天地图-国家地理信息公共服务平台",
         url: "https://link.zhihu.com/?target=https%3A//www.tianditu.gov.cn/",
         introduce: "可以下载矢量地图、影像地图、地形渲染、地名注记等内容的开放性资源。"
      }, {
         name: "OpenTopography",
         url: "https://link.zhihu.com/?target=https%3A//opentopography.org/",
         introduce: "提供全球范围内高空间分辨率的地形数据和操作工具的门户网站"
      }, {
         name: "SRTM数据",
         url: "https://link.zhihu.com/?target=https%3A//opentopography.org/",
         introduce: "由美国航空航天局（NASA）和国防部国家测绘局（NIMA)联合测量的，SRTM的全称Shuttle Radar Topography Mission，即航天飞机雷达地形测绘使命。SRTM的数据是用16位的数值表示高程数值的，最大的正高程9000m，负高程海平面以下12000m。"
      }, {
         name: "中国科学院遥感与数字地球研究所对地观测数据共享计划",
         url: "https://link.zhihu.com/?target=http%3A//ids.ceode.ac.cn/",
         introduce: "中国科学院空天信息创新研究院开发，数据主要是不同时期的影像地图等"
      }, {
         name: "国家地理空间信息中心",
         url: "https://link.zhihu.com/?target=http%3A//sgic.geodata.gov.cn/",
         introduce: "提出地理空间信息整合共享的政策措施和标准规范；承担国家自然资源和地理空间基础信息库建设与运行的具体工作；为国家及有关部门和社会提供地理空间信息产品及相关服务；指导地方开展自然资源和地理空间基础信息库建设与应用服务。"
      }, {
         name: "USGS Earthquake Hazards Program",
         url: "https://link.zhihu.com/?target=https%3A//earthquake.usgs.gov/",
         introduce: "记录的所有地震的KML文件。可用作一个数据集或按大小或年份分组。"
      }, {
         name: "全球地震灾害评估计划(GSHAP)",
         url: "https://link.zhihu.com/?target=http%3A//gmo.gfz-potsdam.de/",
         introduce: "通过网格化数据展示全球地震活动的危险风险"
      }, {
         name: "中分辨率成像光谱仪（MODIS）",
         url: "https://link.zhihu.com/?target=https%3A//modis.gsfc.nasa.gov/",
         introduce: "是美国宇航局研制大型空间遥感仪器，以了解全球气候的变化情况以及人类活动对气候的影响。"
      }, {
         name: "寒区旱区科学数据中心",
         url: "https://link.zhihu.com/?target=http%3A//data.casnw.net/portal/",
         introduce: "包含冰川、冻土、积雪、气象、水文、基础地理多个子库。收集、整合我国冰雪、多年冻土各类调查和监测资料以及生态和社会经济发展基础数据以及已建立的不同类型的数据库数据资源和各重大项目已经产生、正在产生和持续采集的冰川、冻土、积雪、水文、生态、社会经济相关数据。"
      }, {
         name: "CASEarth Databank",
         url: "http://databank.casearth.cn/",
         introduce: "提供长时序的多源对地观测数据即得即用产品集，包括1986年中国遥感卫星地面站建设以来20万景（每景12种产品，共计240万个产品）的长时序陆地卫星数据产品，基于高分卫星1/2、资源3号卫星等国产高分辨率遥感卫星数据制作的2米分辨率动态全国一张图，利用高分卫星、陆地卫星等国内外卫星数据制作的30米分辨率动态全球一张图，以及重点区域的亚米级即得即用产品集等。"
      }, {
         name: "环境云",
         url: "http://www.envicloud.cn/home?title=0",
         introduce: "提供气象、环境、灾害、地理数据共四个方面的内容"
      }, {
         name: "北京大学地理数据平台",
         url: "https://geodata.pku.edu.cn/",
         introduce: "是由北京大学城市与环境学院于2017年建立。平台建立的宗旨是为本院和地理专业的学生、教师以及科研人员提供全面、丰富、专业的数据信息，力图搭建一个地理数据共享交流的平台，构建从不同方向描述地球系统的数据库。数据包括自然地理、人文地理、历史地理等多个地理专业的图形图像、统计图表、文本文件及监测和模型运算得到的数据，数据范围包括全国及全球范围，数据来源于我院科研成果和积累的数据，以及平台收集整理的数据。数据平台的建设得到了学校科研部、设备部的大力支持，也得到了中国宇航学会卫星专业委员会提供的帮助。平台管理由北京大学城市与环境学院教授组成的地理数据平台小组负责，平台运行维护由人地系统与环境遥感实验室负责"
      }, {
         name: "国家极地科学数据中心",
         url: "https://www.chinare.org.cn/",
         introduce: "主要开展极地雪冰-海洋与全球变化、极区电离层-磁层耦合与空间天气、极地生态环境及其生命过程以及极地科学基础平台技术等领域的研究"
      }, {
         name: "国家青藏高原科学数据中心",
         url: "https://data.tpdc.ac.cn/zh-hans/",
         introduce: "数据中心以青藏高原及周边地区各类科学数据为主，已整合的数据资源包括：大气、冰冻圈、水文、生态、地质、地球物理、自然资源、基础地理、社会经济等，开发了在线大数据分析、模型应用等功能，实现青藏高原科学数据、方法、模型与服务的广泛集成。"
      }, {
         name: "国家生态科学数据中心",
         url: "http://www.cnern.org/",
         introduce: "野外生态系统观测研究台站资源整合、标准化和规范化"
      }, {
         name: "国家气象科学数据中心",
         url: "http://data.cma.cn",
         introduce: "中国气象局面向国内和全球用户开放气象数据资源的权威的、统一的共享服务平台"
      }, {
         name: "国家地球系统科学数据中心",
         url: "http://www.geodata.cn/index.html",
         introduce: "整合集成分布在国内外数据库中心群、高等院校、科研院所以及科学家个人产生的数据资源，引进国际数据资源，接收国家重点科研项目产生的数据资源，在此基础上生产加工数据产品"
      }, {
         name: " 国家冰川冻土沙漠科学数据中心",
         url: "http://www.ncdc.ac.cn/portal/",
         introduce: "收集整理、存储我国乃至世界范围内寒区旱区领域的科学数据，为我国寒区旱区科学研究服务"
      }, {
         name: "国家材料腐蚀与防护科学数据中心",
         url: "http://www.corrdata.org.cn/index.php",
         introduce: "由北京科技大学牵头，联合民口部门和国防部门共同建设运行的国家级材料环境腐蚀和防护数据生产积累平台"
      }, {
         name: "国家农业科学数据中心",
         url: "https://www.agridata.cn/#/home",
         introduce: "由中国农业科学院农业信息研究所主持，中国农业科学院部分专业研究所、中国水产科学研究所、中国热带农业科学院等单位参加"
      }, {
         name: "国家林业和草原科学数据中心",
         url: "http://www.forestdata.cn/index.html",
         introduce: "集成并建立了168个数据库，数据实体总量达1111GB，初步形成了全面、系统的林业科学数据体系。"
      }, {
         name: "国家海洋科学数据中心",
         url: "http://mds.nmdis.org.cn/",
         introduce: "国家海洋信息中心、北海分局信息中心、东海分局信息中心及南海分局信息中心组成平台主中心，负责统筹海洋科学数据汇交和国家级平台建设运维工作"
      }, {
         name: "青悦开放环境数据中心",
         url: "http://data.epmap.org/",
         introduce: "政府发布的公开数据，青悦获取并整理、开放。"
      }, {
         name: "全球科研项目数据库",
         url: "http://project.llas.ac.cn/",
         introduce: "汇聚了全球主要发达国家重要基金组织2000年以来的科研项目数据200多万条"
      }, {
         name: "中国通量观测研究网络",
         url: "http://www.chinaflux.org/index.aspx",
         introduce: "8个生态站所获取的常规气象数据、通量数据以及部分辅助数据。"
      }, {
         name: "资源环境科学与数据中心",
         url: "http://www.resdc.cn/Default.aspx",
         introduce: "“中心本部”和9个“分中心”，一个科学数据集与共享平台"
      }, {
         name: "资源学科创新平台",
         url: "http://www.data.ac.cn/",
         introduce: "以人口、资源、环境和发展（PRED）为核心的数据库服务"
      }, {
         name: "AWS公共数据集",
         url: "https://registry.opendata.aws/",
         introduce: "地理空间和环境、基因组和生命科学、机器学习数据集"
      }, {
         name: "欧洲开放数据门户",
         url: "https://data.europa.eu/euodp/en",
         introduce: "社会、科学、环境、经济、金融等数据"
      }, {
         name: "PANGAEA",
         url: "https://www.pangaea.de/",
         introduce: "地球和环境科学数据的发布平台，由德国阿尔弗雷德-魏格纳极地与海洋研究所、布莱梅大学海洋环境科学中心共建"
      }, {
         name: "地理空间数据云",
         url: "http://www.gscloud.cn/",
         introduce: "以中国科学院及国家的科学研究为主要需求，逐渐引进当今国际上不同领域内的国际数据资源，并对其进行加工、整理、集成，最终实现数据的集中式公开服务、在线计算等"
      }, {
         name: "NOAA CLASS",
         url: "https://www.avl.class.noaa.gov/saa/products/welcome;jsessionid=B3B84D7D221AF40B4FC13BBAEADB34C0",
         introduce: "美国国家海洋和大气管理局，面向大海的星辰"
      }, {
         name: "Data Access Viewer",
         url: "https://coast.noaa.gov/dataviewer/#",
         introduce: "NOAA美国国家海洋和大气管理局"
      }, {
         name: "国务院",
         url: "http://www.gov.cn/index.htm",
         introduce: "各类全国基础数据，34个职部委职能相关数据"
      }, {
         name: "欧盟数据门户",
         url: "https://www.europeandataportal.eu/",
         introduce: "包括地理、统计、气象数据，以及来自公共资金研究项目的数据和数字化图书等，平台中的数据集均由欧盟收集、购买，再免费提供给所有的用户，来自欧盟以及其他地区的用户都可以到平台上直接下载数据用于分析、开发创新服务或应用等用途。"
      }, {
         name: "和鲸社区",
         url: "https://www.heywhale.com/home/dataset",
         introduce: "是国内知名的开放数据科学社区,是最早一批专注于大数据算法比赛的平台,支持在线分享代码、项目和数据集"
      }, {
         name: "中国科技共享网",
         url: "https://escience.org.cn/",
         introduce: "涉及到地理学、气象学、微生物学、基因组学、医学、农学、物理学等多个学科"
      }, {
         name: "中国工程科技知识中心",
         url: "http://www.ckcest.cn/home/",
         introduce: "收录了全球250个国家/地区、20多个国内外主要机构的770条指标、近700万条数据,覆盖经济、人口、资源、科技、环境等领域"
      }, {
         name: "Atlantic Oceanographic and Meteorological Laboratory",
         url: "http://www.aoml.noaa.gov/data/",
         introduce: "大西洋海洋与气象实验室的环境数据服务器（ENVIDS）提供交互式在线访问位于AOML的各种海洋和大气数据集。内部数据集包括大西洋消耗热水图（XBT），全球拉格朗日漂流浮标，飓风飞行高度和大西洋飓风曲目（北大西洋最佳轨道和天气）。另外，可用数据集还包括太平洋电导/温度/深度记录仪（CTD）和1998年世界海洋图集。"
      }, {
         name: "Atmospheric Infrared Sounder",
         url: "https://airs.jpl.nasa.gov/data/overview",
         introduce: "AIRS进行气候研究和天气预测，美国宇航局地球观测系统的一部分，是六个仪器之一。与微波仪器先进微波探测装置单元AMSU-A一起，代表在太空中部署的最先进的大气探测系统。这些仪器一起观测全球水和能源循环、气候变化和趋势，以及气候系统对温室气体增加的反应。"
      }, {
         name: "Atmospheric Science Data Center，NASA Langley Research Center",
         url: "https://eosweb.larc.nasa.gov/",
         introduce: "NASA Langley研究中心的大气科学数据中心（ASDC）负责NASA地球科学数据在辐射预算，云层，气溶胶和对流层构成等领域的处理，存档和分发工作。"
      }, {
         name: "British Geological Survey（英国地质调查局）",
         url: "http://www.bgs.ac.uk/",
         introduce: "是世界上最古老的国家地质调查，有超过400个数据集，包括环境监测数据，数字数据库，物理收集（钻孔核心，岩石，矿物和化石）的记录和档案。"
      }, {
         name: "Clouds and the Earth's Radiant Energy System(云和地球的辐射能量系统)",
         url: "http://ceres.larc.nasa.gov/index.php",
         introduce: "云和地球辐射能量系统（CERES）是地球观测系统（EOS）计划的关键组成部分。 CERES仪器从三个宽带通道提供地球大气的辐射测量。 CERES产品包括从大气顶部到地球表面的太阳能反射和地球发射的辐射。"
      }, {
         name: "Computational and Information Systems Laboratory Research Data Archive",
         url: "http://rda.ucar.edu/",
         introduce: "由NCAR的计算和信息系统实验室的数据支持部门管理的CISL研究数据存档（RDA），包含大量多样的气象和海洋学系列 观测，运行和再分析模型输出以及支持大气和地球科学研究的遥感数据集，以及地形/测深，植被和土地利用等辅助数据集。"
      }, {
         name: "FLUXNET(全球采集的通量和气象数据)",
         url: "https://fluxnet.fluxdata.org/",
         introduce: "全球采集的通量和气象数据已提交给中央数据库（www.fluxdata.org）。每个塔站点，涡流协方差方法用于量化生物圈和大气之间的标量通量（例如CO2，CH4，水蒸气）和能量（例如，敏感，潜热）。此外，从高时间分辨率的大量传感器获取辅助物理变量（例如，气温，降水，辐射）的连续测量。"
      }, {
         name: "GISS Surface Temperature Analysis(GISTEMP，GISS表面温度分析)",
         url: "http://data.giss.nasa.gov/gistemp/",
         introduce: "GISS的计划领域大致可以分为气候强迫类别、 气候模式发展、 地球观测、 大气辐射、 大气化学、 气候影响、 行星大气层，系外行星和天体生物学、 古气候 和其他学科。"
      }, {
         name: "Goddard Earth Sciences Data and Information Services Center(戈达德地球科学数据和信息服务中心)",
         url: "https://daac.gsfc.nasa.gov/",
         introduce: "数据中心作为NASA科学任务监测（SMD）之一，为研究科学家、应用科学家、应用用户和学生提供地球科学数据、信息和服务。 GES DISC是NASA降水和水文学的归档，以及大气组成和动力学遥感数据和信息。"
      }, {
         name: "GOES Space Environment Monitor(GOES空间环境监测)",
         url: "http://www.ngdc.noaa.gov/stp/satellite/goes/",
         introduce: "GOES空间环境监测档案是国家空间气象计划的重要组成部分 - 提供及时可靠的空间环境观测和预报机构间方案。 GOES卫星载有一个空间环境监测子系统，用于测量航天器的X射线，能量粒子和磁场。"
      }, {
         name: "International Arctic Systems for Observing the Atmosphere(国际北极观测系统)",
         url: "http://www.esrl.noaa.gov/psd/iasoa/dataataglance",
         introduce: "国际北极观测系统（IASOA）提供并收集了有关北极大气条件的多年连续数据。国际极地年（IPY）与来自瑞典，加拿大，美国，俄罗斯，挪威，芬兰和格陵兰的北极天文台保持合作。数据内容有标准气象，温室气体，大气辐射，云，污染物，化学，气溶胶和地表能量平衡。"
      }, {
         name: "Bedford Institute of Oceanography - Oceanographic Databases",
         url: "http://www.bio.gc.ca/science/data-donnees/base/index-en.php",
         introduce: "有海洋观测数据"
      }, {
         name: "British Geological Survey",
         url: "http://www.bgs.ac.uk/",
         introduce: "英国地质调查局（BGS）是世界上最古老的国家地质调查，有超过400个数据集，包括环境监测数据，数字数据库，物理收集（钻孔核心，岩石，矿物和化石）的记录和档案。"
      }, {
         name: "Civil Aircraft for the Regular Investigation of the atmosphere Based on an Instrument Container",
         url: "http://www.caribic-atmospheric.com/",
         introduce: "CARIBIC是基于飞机的科学项目，研究全球大气成分和气候变化，是EUROPE的全球大气监测解决方案。CARIBIC容器包括臭氧，一氧化碳，二氧化碳，氮氧化物，甲烷，水蒸气，水同位素，丙酮和乙腈，汞，生物气溶胶，尘埃颗粒等等的现场测量设备。CARIBIC容器包括一个用于收集超过100个空气样本的系统。这些空气样本在每个飞行系列后都会在实验室进行分析。对于每个样品，我们测量超过40种气体，包括臭氧杀手（CFC's）和所有温室气体。"
      }, {
         name: "CUAHSI ",
         url: "https://www.cuahsi.org/",
         introduce: "提供水文分析工具标准平台及数据等。提供一些水资源相关的数据门户"
      }, {
         name: "Database for Hydrological Time Series of Inland Waters",
         url: "http://dahiti.dgfi.tum.de/en/",
         introduce: "提供湖泊、水库、江河等水温数据"
      }, {
         name: "Earth System Research Laboratory Global Monitoring Division",
         url: "https://www.esrl.noaa.gov/gmd/dv/data/",
         introduce: "美国国家海洋和大气管理局NOAA / ESRL全球监测司（前称CMDL）检测改变地球气候的大气成分的来源、趋势、全球分布，研究大气辐射环境、可能会导致全球臭氧层枯竭的因素，以及影响基准空气质量的因素等等。GMD主要通过长期测量全球各地的关键大气成分，包括二氧化碳、一氧化碳、甲烷、一氧化二氮、地表和平流层臭氧、CFC替代物在内的卤化物、碳氢化合物、含硫气体、气溶胶以及太阳和红外辐射"
      }, {
         name: "ECCAD",
         url: "http://eccad.aeris-data.fr/",
         introduce: "ECCAD项目的主要目标是为科学和政策用户提供大气化合物的表面排放数据及辅助数据，即估计或量化表面排放所需的数据。辅助数据例如人口密度图，火灾地图，燃烧区域，土地覆盖物等可以帮助改善和鼓励新的排放数据集的开发。ECCAD是一个动态和交互式数据库，提供最新的数据集，包括正在进行的项目中使用的数据。"
      }, {
         name: "EDGAR",
         url: "http://edgar.jrc.ec.europa.eu/",
         introduce: "全球大气排放研究数据库(EDGAR)提供了全球过去和现今人为排放温室气体和空气污染物的国家和空间网格分布。"
      }, {
         name: "Environmental Information Platform",
         url: "http://www.nerc.ac.uk/",
         introduce: "提供有水质的相关数据"
      }, {
         name: "European Soil Data Centre",
         url: "https://esdac.jrc.ec.europa.eu/",
         introduce: "提供与欧洲土壤相关的数据"
      }, {
         name: "Experimental Lakes Area",
         url: "https://www.iisd.org/ela/",
         introduce: "淡水研究"
      }, {
         name: "Historical hydrographic data from BSH",
         url: "http://icdc.cen.uni-hamburg.de/daten/ocean/historical-hydrographic-bsh.html",
         introduce: "海洋水文研究相关数据"
      }, {
         name: "Woods Hole Open Access Server",
         url: "http://darchive.mblwhoilibrary.org/",
         introduce: "该网站是伍兹霍尔的数据仓储，提供有海洋水文相关的数据"
      }, {
         name: "Ozone Mapping and Profiler Suite",
         url: "http://ozoneaq.gsfc.nasa.gov/omps/",
         introduce: "测量上层大气中的臭氧层，跟踪全球臭氧分布状况，包括“臭氧空洞”，还监测对流层的臭氧水平"
      }, {
         name: "地球系统科学数据共享网、可再生资源与环境世界数据中心",
         url: " http://eng.wdc.cn/",
         introduce: "中国科学院地理科学与资源研究所主办，中国资源和环境领域的长期数据归档和共享中心"
      }, {
         name: "VITO Vision",
         url: "http://www.vito-eodata.be/PDF/portal/Application.html#Home",
         introduce: "该网站提供全球的低分辨率植被指数"
      }, {
         name: "NOAA Digital Coast",
         url: "https://coast.noaa.gov/digitalcoast/",
         introduce: "提供沿海区域的数据，主要包括海底数据、海拔、图像、土地覆盖以及社会经济数据等，数据包括雷达、红外和真彩色影像数据等"
      }, {
         name: "Aquastat",
         url: "http://www.fao.org/nr/water/aquastat/main/index.stm",
         introduce: "粮农组织的全球水信息系统，提供水资源、水利用、灌溉和排水、废水处理等各个主题的数据"
      }, {
         name: "World Health Organization",
         url: "http://www.who.int/gho/en/",
         introduce: "探索健康数据的世界"
      }, {
         name: "全球大气研究排放数据库",
         url: "https://data.jrc.ec.europa.eu/collection/EDGAR",
         introduce: "包括所有温室气体，空气污染物和气溶胶的排放。列出了所有国家/地区的数据，并按主要排放源类别提供了排放量，并在全球范围内按0.1x0.1网格对空间进行了分配。"
      }, {
         name: "Centre for Ecology & Hydrology: Data",
         url: "http://www.ceh.ac.uk/data",
         introduce: "英国生态水文中心提供的长期的全国规模的包括降雨、干旱、湖泊等水数据"
      }, {
         name: "Eurostat Water statistics",
         url: "http://ec.europa.eu/eurostat/statistics-explained/index.php/Water_statistics",
         introduce: "提供欧盟淡水资源、取水、用水和废水处理和处置相关的统计数据"
      }, {
         name: "FAO GeoNetwork",
         url: "http://www.fao.org/geonetwork/srv/en/main.home",
         introduce: "一个全球地理信息系统数据集，提供农业、渔业、土地资源相关的GIS数据以及相关卫星图像数据"
      }, {
         name: "Fishbase",
         url: "http://www.fishbase.org/home.htm",
         introduce: "Fishbase是拥有超过30,000种鱼类物种信息的全球鱼类百科全书。fishbase的信息包括鱼类的常用name、属名、物种名、地理分布、生态系统地位、相关参考文献等"
      }, {
         name: "Global Groundwater Information System",
         url: "https://www.un-igrac.org/global-groundwater-information-system-ggis",
         introduce: "提供地下水相关信息"
      }, {
         name: "History of Marine Animal Populations",
         url: "https://hydra.hull.ac.uk/resources?utf8=%E2%9C%93&search_field=all_fields&q=hmap",
         introduce: "HMAP数据页面向研究者提供渔业捕获量随时间空间的变化数据，旨在揭示生态环境动态改变、人类活动对生态环境的影响、海洋资源在人类社会发展过程中的作用"
      }, {
         name: "WorldClim",
         url: "https://link.zhihu.com/?target=https%3A//www.worldclim.org/",
         introduce: "世界范围内的气候变化数据"
      }, {
         name: "国NCEP/NCAR气候变化情景",
         url: "https://link.zhihu.com/?target=https%3A//ral.ucar.edu/solutions/products/gis-climate-change-scenarios",
         introduce: "针对NCEP/NCAR再分析数据集是由美国气象环境预报中心（NCEP）和美国国家大气研究中心（NCAR）联合制作的，他们采用了当今最先进的全球资料同化系统和完善的数据库，对各种来源（地面、船舶、无线电探空、测风气球、飞机、卫星等）的观测资料进行质量控制和同化处理，获得了一套完整的再分析资料集。"
      }, {
         name: "世界海洋气候学数据库（CLIWOC）",
         url: "https://link.zhihu.com/?target=https%3A//link.springer.com/article/10.1007/s10584-005-6952-6",
         introduce: "从1750年至1850年的航行期间编制的船舶日志汇编的数据。包括按日期，船舶和年份分类的各种气象观测资料。"
      }, {
         name: "全球水库和大坝数据库",
         url: "https://link.zhihu.com/?target=https%3A//www.taodocs.com/p-39742537.html",
         introduce: "文字版数据"
      }, {
         name: "IIWQ",
         url: "https://link.zhihu.com/?target=http%3A//sdg6.worldwaterquality.org/",
         introduce: "提供几乎全球每个角落海岸线的四项水质情况数据(南极洲和北极圈部分陆域除外)，所涉及的水质指标包括浊度(Turbidity)、叶绿素a(Chlorophyll-a)、有害藻类指数(HAB Indicator)和总吸收度(Total Absorption)，通过不同的颜色表真相应的浓度级别，通过地图可直观了解全球各地区水质情况。"
      }, {
         name: "生物圈图集",
         url: "https://link.zhihu.com/?target=http%3A//www.mab.cas.cn/tpk/",
         introduce: "网格化人类数据"
      }, {
         name: "ReefBase GIS",
         url: "https://link.zhihu.com/?target=http%3A//www.reefbase.org/gis_maps/datasets.aspx",
         introduce: "全球珊瑚礁的GIS数据，包括广泛的属性数据。"
      }, {
         name: "世界土壤数据库",
         url: "https://link.zhihu.com/?target=http%3A//westdc.westgis.ac.cn/data/611f7d50-b419-4d14-b4dd-4a944b141175",
         introduce: "将来自许多国家的区域和国家土壤数据库和地图结合起来，分辨率为30弧秒。"
      }, {
         name: "Dryad",
         url: "https://datadryad.org/",
         introduce: "是一个储存医学、生物学、生态学数据的开放数据知识库，为学术文献提供基础设施，促进其重用，让学术论文的数据可被发现，可自由重复使用和可引用"
      }, {
         name: "数字丝路地球大数据平台",
         url: "http://www.opendbar.cn/",
         introduce: "该平台以“数字连接，共享繁荣”为主旨，包括“一带一路”资源、环境、气候、灾害、遗产等专题数据集94套、自主知识产权数据产品57类"
      }, {
         name: "微生物组数据云平台",
         url: "https://gcmeta.wdcm.org/",
         introduce: "是一个微生物基因组及微生物组数据的管理、分析和发布平台，为国内外用户提供一站式的从数据存储、数据分析到数据发布的服务，目前已经整合了来自中国科学院微生物组计划及国内外多个重要项目的数据"
      }, {
         name: "环境云",
         url: "http://www.envicloud.cn/home?title=0",
         introduce: "提供气象、环境、灾害、地理数据共四个方面的内容"
      }, {
         name: "IPE",
         url: "http://www.ipe.org.cn/index.html",
         introduce: "在分享数据之余，还出具相关报告，对企业『绿色供应链』、『绿色金融』等进行评估"
      }, {
         name: "EPS数据平台",
         url: "http://www.epsnet.com.cn/",
         introduce: "是由北京福卡斯特信息技术有限公司提供的“专业数据+预测分析”平台。收录国内外的经济、贸易、行业、科技领域的统计数据；利用内嵌的数据分析预测软件可完成对数据的分析和预测"
      }, {
         name: "北京大学地理数据平台",
         url: "https://geodata.pku.edu.cn/",
         introduce: "是由北京大学城市与环境学院于2017年建立。平台建立的宗旨是为本院和地理专业的学生、教师以及科研人员提供全面、丰富、专业的数据信息，力图搭建一个地理数据共享交流的平台，构建从不同方向描述地球系统的数据库。数据包括自然地理、人文地理、历史地理等多个地理专业的图形图像、统计图表、文本文件及监测和模型运算得到的数据，数据范围包括全国及全球范围，数据来源于我院科研成果和积累的数据，以及平台收集整理的数据。数据平台的建设得到了学校科研部、设备部的大力支持，也得到了中国宇航学会卫星专业委员会提供的帮助。平台管理由北京大学城市与环境学院教授组成的地理数据平台小组负责，平台运行维护由人地系统与环境遥感实验室负责"
      }, {
         name: " 国家人口健康科学数据中心",
         url: "https://www.ncmi.cn/index.html",
         introduce: "承担起国家科技重大专项、科技计划、重大公益专项等人口健康领域科学数据汇交、数据加工、数据存储、数据挖掘和数据共享服务的任务。"
      }, {
         name: "全球科研项目数据库",
         url: "http://project.llas.ac.cn/",
         introduce: "汇聚了全球主要发达国家重要基金组织2000年以来的科研项目数据200多万条"
      }, {
         name: "中医药学科学数据中心",
         url: "http://dbcenter.cintcm.com/",
         introduce: "中医药学领域各类科学数据的共建挖掘分析工作"
      }, {
         name: "公共卫生科学数据",
         url: "http://www.phsciencedata.cn/",
         introduce: "中国疾控中心信息中心负责"
      }, {
         name: "基础医学科学数据",
         url: "http://www.bmicc.cn/",
         introduce: "基础医学研究数据的收集、加工和汇总"
      }, {
         name: "国家药学科学数据",
         url: "http://www.pharmdata.ac.cn/",
         introduce: "药学及相关学科科学数据实现共享的管理和技术服务"
      }, {
         name: "临床医学科学数据",
         url: "http://101.201.55.39/",
         introduce: "由北京协和医院和中国人民解放军总医院共同承担"
      }, {
         name: "地方服务中心",
         url: "http://www.hcn2020.cn/",
         introduce: "为广大农村、社区居民等提供一个医学信息服务平台"
      }, {
         name: "和鲸社区",
         url: "https://www.heywhale.com/home/dataset",
         introduce: "是国内知名的开放数据科学社区,是最早一批专注于大数据算法比赛的平台,支持在线分享代码、项目和数据集"
      }, {
         name: "中国产业信息",
         url: "https://www.chyxx.com/",
         introduce: "工业和信息化部主管,人民邮电报社主办,是我国通信行业唯一拥有国务院新闻办授予新闻发布权的新闻网站。"
      }, {
         name: "Behavioral Risk Factor Surveillance System",
         url: "http://www.cdc.gov/brfss/",
         introduce: "是全美通过电话进行健康调查的第一大系统，调查收集美国居民的健康相关危险因素、慢性疾病以及对预防服务的使用。调查每年完成对超过40万人的采访，是世界最大的持续性健康调查系统。"
      }, {
         name: "European Prospective Investigation into Cancer and Nutrition Study",
         url: "http://epic.iarc.fr/index.php",
         introduce: "欧洲癌症和营养的前瞻研究，来自欧洲10多个国家23个中心52万多人参与研究，是世界上最大的有关癌症和其他慢性病的生化和遗传学调查的数据库之一，包括饮食、营养、生活方式、环境等因素与癌症和其他慢性病之间的关系。"
      }, {
         name: "National Addiction & HIV Data Archive Program",
         url: "http://www.icpsr.umich.edu/icpsrweb/NAHDAP/",
         introduce: "全国吸毒与艾滋病数据库收集、保存和分享与药物成瘾和艾滋病研究相关的数据。数据范围包括合法和非法的各种药物以及对这些药物的使用方式和使用结果预测。该数据库可为学者提供关于社会重大问题、行为科学与公共政策等方面的二次分析的机会。"
      }, {
         name: "Surveillance Epidemiology and End Results",
         url: "http://seer.cancer.gov/",
         introduce: "SEER是美国癌症统计数据的主要来源，收集与发病率、患病率和生存率相关的信息，这些地区占总人口的28%，编制有关国家癌症死亡率的相关报告。他们的目标是提供与癌症统计数据相关的信息，并减少国家人口中癌症的负担。SEER从1973年起就开始收集癌症病例的数据。"
      }, {
         name: "Medical Expenditure Panel Survey",
         url: "http://meps.ahrq.gov/mepsweb/",
         introduce: "医疗支出小组调查(MEPS)是对美国各地的家庭和个人，及其医疗提供者和雇主进行的大规模调查，是关于医疗保健和医疗保险的成本和使用的最完整的数据来源"
      }, {
         name: "癌症图像档案",
         url: "https://www.cancerimagingarchive.net/",
         introduce: "是一项可以de-identify和托管可供公众下载的大量癌症医学图像的服务"
      }, {
         name: "中经网产业数据库",
         url: "https://cyk.cei.cn/",
         introduce: "是国家信息中心中经网精心打造的、覆盖机械、汽车、电子通讯、医药、石油化工、能源、交通、房地产、钢铁、旅游等10多个产业集群的全新数据库产品。"
      }, {
         name: "Agency for Healthcare Research and Quality, Data & Surveys",
         url: "http://www.ahrq.gov/research/data/index.html",
         introduce: "包含美国健康信息知识库(USHIK)、系统评价数据存储库(SRDR)以及有关医疗和医疗保险的成本、质量、可访问性和评估等其他信息，帮助人们做出更加明智的决策，提高卫生保健服务的质量。"
      }, {
         name: "疾病预防控制中心",
         url: "https://data.cdc.gov/Case-Surveillance/COVID-19-Case-Surveillance-Public-Use-Data/vbim-akqf",
         introduce: "是由政府举办的实施国家级疾病预防控制与公共卫生技术管理和服务的公益事业单位。其使命是通过对疾病、残疾和伤害的预防控制，创造健康环境，维护社会稳定，保障国家安全，促进人民健康；其宗旨是以科研为依托、以人才为根本、以疾控为中心"
      }, {
         name: "美国农业部",
         url: "https://healthdata.gov/dataset/covid-19-reported-patient-impact-and-hospital-capacity-facility?SorourMo/38-Cloud-A-Cloud-Segmentation-Dataset",
         introduce: "通过获取、策划和传播解决当前和未来农业挑战所需的信息，促进农业知识的创造"
      }, {
         name: "The COVID Tracking Project ",
         url: "https://covidtracking.com/",
         introduce: "COVID 跟踪项目收集并发布美国各州和地区最完整的测试数据"
      }, {
         name: "EHDP",
         url: "http://www.ehdp.com/vitalnet/datasets.htm",
         introduce: "是用于分析健康数据集的现成的商用数据仓库/数据查询软件"
      }, {
         name: "NCI 的基因组数据共享 （GDC）",
         url: "https://gdc.cancer.gov/",
         introduce: "为癌症研究界提供了统一的数据存储库，使癌症基因组研究的数据共享能够支持精密医学。"
      }, {
         name: "The Centers for Medicare & Medicaid Services",
         url: "https://data.medicare.gov/",
         introduce: "探索和下载医疗保险提供商数据"
      }, {
         name: "OpenPaymentsData",
         url: "https://openpaymentsdata.cms.gov/",
         introduce: "用于搜索药品和医疗器械公司向医生和教学医院支付的款项"
      }, {
         name: "PhysioNet",
         url: "https://www.physionet.org/physiobank/database/",
         introduce: "开展和催化生物医学研究和教育，部分通过提供免费获取大量生理和临床数据以及相关的开源软件"
      }, {
         name: "The Cancer Imaging Archive",
         url: "https://portal.gdc.cancer.gov/",
         introduce: "可识别并托管大量癌症医学图像的存档，以供公众下载"
      }, {
         name: "Kaggle",
         url: "https://www.kaggle.com/datasets",
         introduce: "是由联合创始人、首席执行官安东尼·高德布卢姆（Anthony Goldbloom）2010年在墨尔本创立的，主要为开发商和数据科学家提供举办机器学习竞赛、托管数据库、编写和分享代码的平台。该平台已经吸引了80万名数据科学家的关注，这些用户资源或许正是吸引谷歌的主要因素"
      }, {
         name: "ALLEN BRAIN MAP",
         url: "https://portal.brain-map.org/",
         introduce: "提供神经影像学数据"
      }, {
         name: "CRCNS",
         url: "http://crcns.org/data-sets",
         introduce: "计算神经科学合作研究数据"
      }, {
         name: "HCP",
         url: "https://www.humanconnectome.org/study/hcp-young-adult",
         introduce: "包括1100多个健康年轻人的高质量神经影像数据"
      }, {
         name: "NIMH Data Archive",
         url: "https://nda.nih.gov/",
         introduce: "提供了从许多科学领域的数百个研究项目中收集到的人类受试者数据"
      }, {
         name: "NEURODATA",
         url: "https://neurodata.io/",
         introduce: "目标是与神经实验学家合作，通过构建和部署大规模运行的开源数据驱动工具，来发现控制思想与大脑之间关系的基本原理"
      }, {
         name: "NeuroMorpho.Org",
         url: "http://neuromorpho.org/",
         introduce: "是可公开访问的3D神经元重建和相关元数据的最大集合"
      }, {
         name: "NeuroElectro ",
         url: "https://neuroelectro.org/",
         introduce: "是从现有文献中提取有关各种神经元类型的电生理特性（例如静息膜电位和膜时间常数）的信息，并将其放入中央数据库中。 "
      }, {
         name: "OASIS-3",
         url: "https://www.oasis-brains.org/",
         introduce: "是影像研究开放获取系列（OASIS）中的最新版本，旨在使科学界免费使用神经影像数据集"
      }, {
         name: "OpenfMRI.org",
         url: "https://openfmri.org/",
         introduce: "是一个致力于自由共享原始磁共振成像（MRI）数据集的项目。 "
      }, {
         name: "GENIE",
         url: "https://www.synapse.org/#!Synapse:syn7222066/wiki/405659",
         introduce: "是一个可公开访问的国际癌症注册中心，它通过世界19个领先癌症中心之间的数据共享来组装真实数据"
      }, {
         name: "mendeley Data",
         url: "https://data.mendeley.com/",
         introduce: "是一个安全的基于云的存储库，您可以存储您的数据，确保无论您身在何处，都可以轻松共享、访问和引用数据"
      }, {
         name: "Tissue Image Analytics (TIA) Centre",
         url: "https://warwick.ac.uk/fac/cross_fac/tia/data/crchistolabelednucleihe/",
         introduce: "此数据集涉及 100 张结肠直肠癌的 H&E 染色组织学图像"
      }, {
         name: "DRIVE",
         url: "https://drive.grand-challenge.org/",
         introduce: "DRIVE数据库的照片来自荷兰的糖尿病视网膜病变筛查项目"
      }, {
         name: "European Prospective Investigation into Cancer and Nutrition Study ",
         url: "http://epic.iarc.fr/index.php",
         introduce: "是世界上最大的有关癌症和其他慢性病的生化和遗传学调查的数据库之一，包括饮食、营养、生活方式、环境等因素与癌症和其他慢性病之间的关系。"
      }, {
         name: "阿里云天池",
         url: "https://tianchi.aliyun.com/dataset/",
         introduce: "阿里旗下数据科学竞赛平台"
      }, {
         name: "HARVARD Dataverse",
         url: "https://dataverse.harvard.edu/",
         introduce: "是一个免费的数据存储库，向哈佛社区内外任何学科的所有研究人员开放"
      }, {
         name: "有机化合物核磁共振碳谱数据库",
         url: "http://210.32.137.90/s/lib/libtb/turning/1373",
         introduce: "微谱数据，即有机化合物核磁共振碳谱数据库，是专门为天然产物研究、有机合成和药物开发等研究工作设计的数据库。现已收录有机化合物约88万余个，是全球最大的有机化合物核磁共振碳谱数据库，适用于药学、化学、生命科学等相关专业"
      }, {
         name: "Medscape",
         url: "https://link.zhihu.com/?target=https%3A//reference.medscape.com/",
         introduce: "是世界上最大的免费提供临床医学全文文献和提供最新的医学新闻和专家视角；基本的护理药物和疾病信息；以及相关的专业教育和cme。也是最早的优秀医学专业门户之一，主要为临床医生和医务工作者提供高质量的专业医学信息"
      }, {
         name: "ClinicalTrials.gov",
         url: "https://clinicaltrials.gov/",
         introduce: "临床试验数据库"
      }, {
         name: "Dailymed",
         url: "https://link.zhihu.com/?target=https%3A//dailymed.nlm.nih.gov/dailymed/index.cfm",
         introduce: "该数据库可以检索到几乎所有药品的说明书"
      }, {
         name: "中国健康网",
         url: "http://www.healthoo.com/",
         introduce: "以健康,营养,健康知识为主体的,为网民提供健康、营养、医疗等健康信息和服务"
      }, {
         name: "CDC",
         url: "https://www.cdc.gov/",
         introduce: "美国疾控中心"
      }, {
         name: "National Center for Biotechnology Information (NCBI)",
         url: "http://www.ncbi.nlm.nih.gov/",
         introduce: "美国国家生物技术信息中心有基因库、（毒物）分子数据库、文献数据库等"
      }, {
         name: "MDchoice",
         url: "http://www.mdchoice.com/",
         introduce: "是一个由专家手工编制的医学专业搜索引擎，可按主题词进行检索，也可按提供的分类进行检索，其特点是影像学方面的内容特别丰富， 该网站提供了大量的病理切片、ECG、CT、MRI、核医学、超声医学方面的内容，其形式包括各种图片、照片、视频和动画"
      }, {
         name: "约翰霍普金斯传染病数据库",
         url: "http://www.hopkins-id.edu/",
         introduce: "包括肝炎、艾滋病、结核病"
      }, {
         name: "加利福尼亚大学，圣地亚哥-生物化学基因实验室 ",
         url: "https://www.ucsdbglab.org/",
         introduce: "为先天代谢缺陷提供各种诊断测试，有一数据库可帮你找到能做某种测试的实验室"
      }, {
         name: "STD序列数据库",
         url: "http://www.stdgen.lanl.gov/",
         introduce: "粒性结膜炎（沙眼）衣原体，性病支原体，梅毒螺旋体，人类乳头（状）瘤病毒 基因数据库"
      }, {
         name: "Dryad",
         url: "https://datadryad.org/",
         introduce: "是一个储存医学、生物学、生态学数据的开放数据知识库，为学术文献提供基础设施，促进其重用，让学术论文的数据可被发现，可自由重复使用和可引用"
      }, {
         name: "生命与健康大数据中心",
         url: "http://bigd.big.ac.cn/",
         introduce: "面向我国人口健康和社会可持续发展的重大战略需求，围绕国家精准医学和重要战略生物资源的组学数据，建立海量生物组学大数据储存、整合与挖掘分析研究体系，发展组学大数据系统构建、挖掘与分析的新技术、新方法，建设组学大数据汇交、应用与共享平台"
      }, {
         name: "ClinicalTrials",
         url: "https://www.clinicaltrials.gov/",
         introduce: "是一种基于网络的资源，为患者，其家庭成员，医疗保健专业人员，研究人员和公众提供方便地获取有关各种疾病和病症的公共和私人支持的临床研究的信息"
      }, {
         name: "中研网数据",
         url: "https://link.zhihu.com/?target=http%3A//www.chinairn.com/data/",
         introduce: "提供医疗、房产、制造业、服务业、零售消费、车辆等全行业数据"
      }, {
         name: "CINAHL",
         url: "http://nrs.harvard.edu/urn-3:hul.eresource:cinahlpl",
         introduce: "护理和其他健康相关信息；患者护理问题的绝佳来源"
      }, {
         name: "WHO Global Index Medicus",
         url: "https://www.globalindexmedicus.net/",
         introduce: "搜索世卫组织所有区域索引，包括东南亚和西太平洋区域数据库。"
      }, {
         name: "Aberdeen Birth Cohorts ",
         url: "http://www.abdn.ac.uk/birth-cohorts/",
         introduce: "跟踪1921年、1936年以及 1950-1956年在阿伯丁出生的孩子的成长、变老过程，研究生物和社会对人一生以及两代人健康的影响。"
      }, {
         name: "Pacific Islands Families Study ",
         url: "http://www.niphmhr.aut.ac.nz/research-centres/centre-for-pacific-health-and-development-research/pacific-islands-families-study",
         introduce: "太平洋岛屿家庭研究是对1398名儿童13岁之前开展的一项纵向研究项目，主要涉及这些儿童以及他们的家庭在不同时间阶段的健康状况以及心理社会功能。"
      }, {
         name: "Tracking Adolescents' Individual Lives Survey ",
         url: "http://www.trails.nl/",
         introduce: "青少年个人生活追踪调查是对青少年心理、社会、生理发展进行的一种多学科研究，有2500多名青少年自10、11岁之后参加了调查，这些参与者在过去的15年中每隔2、3年通过问卷调查、访谈、测试以及物理检测来接受一次检查。该调查近期发起的一项研究将重点放在这些参与者的孩子身上"
      }, {
         name: "Korean Social Science Data Archive",
         url: "http://www.kossda.or.kr/",
         introduce: "研究领域：政治、公共行政和法律、经济和商业管理、社会文化、社会问题、社会福利、心理学和教育和区域研究。"
      }, {
         name: "American Psychological Association",
         url: "http://www.apa.org/research/responsible/data-links.aspx",
         introduce: "拥有大量的共享数据可用于心理学的科学研究，并且这些数据涵盖了多种研究方向"
      }, {
         name: "UCLA Library",
         url: "https://www.library.ucla.edu/",
         introduce: "网站中关于心理学数据集共有三个子目录可供选择：数据集和存储库列表(Data Set and Repository Lists)、其他数据存储库和资源(Other Data Repositories and Resources)及心理学数据资源(Sources for Psychological Datasets)"
      }, {
         name: "Inter-university Consortium for Political and Social Research",
         url: "https://www.icpsr.umich.edu/icpsrweb/content/about/",
         introduce: "ICPSR是一个由超过750个学术机构和研究机构组成的国际联盟，其目的是为社会科学研究提供开源的数据访问、管理以及分析方法的领导和培训"
      }, {
         name: "The Journal of Open Psychology Data",
         url: "https://openpsychologydata.metajnl.com",
         introduce: "该杂志的特点是可对具有高复用潜力的心理学数据集进行同行评议。其中，数据可以使未发表研究的数据（包括重复实验），也可以是传统期刊上发表的论文"
      }, {
         name: "Curated list of open-access databases with human structural MRI data",
         url: "https://github.com/cMadan/openMorph",
         introduce: "本网站为开放式的人体结构MRI数据库，并且登陆后可随时添加/更新数据，操作十分人性化。并且，网站中还介绍了关于将大脑数据开放储存的众多益处，值得一读。"
      }, {
         name: "Integrated Analysis of Longitudinal Studies of Aging",
         url: "http://www.ialsa.org/Home",
         introduce: "主要为关于人类一生的可重复的纵向研究，其研究包括健康、人格、幸福感等等"
      }, {
         name: "myPersonality Project",
         url: "https://mypersonality.org",
         introduce: "通过这个程序用户可以进行真实的心理测试，并允许我们记录他们的测试结果和个人信息。目前，这个数据库收集了超过6,000,000个测试结果，以及超过4,000,000个Facebook个人资料。"
      }, {
         name: "Wisconsin Longitudinal Study",
         url: "https://www.ssc.wisc.edu/wlsresearch/",
         introduce: "WLS为研究者提供了一个研究纵向生命历程、代际关系、家庭功能、身体和心理健康及幸福感的机会。"
      }, {
         name: "DHS Data Access ",
         url: "http://www.dhsdata.nl/site/users/login",
         introduce: "CentERdata每年通过DNB家庭调查来收集经济数据，调查的目的是研究家庭储蓄行为的经济和心理因素，数据通过CentERpanel来收集，每周是不同的主题"
      }, {
         name: "Estonian Social Science Data Archive",
         url: "http://esta.ut.ee/",
         introduce: "由塔尔图大学的一些社会学家、心理学家、政治学家和人文地理学家共同建立。"
      }, {
         name: "World Health Organization",
         url: "http://www.who.int/gho/en/",
         introduce: "探索健康数据的世界"
      }, {
         name: "Wilma Bainbridge",
         url: "http://wilmabainbridge.com/index.html",
         introduce: "致力于通过心理物理实验，神经影像学和绘画研究的视角来检验感知与记忆之间的桥梁"
      }, {
         name: "HARVARD Dataverse",
         url: "https://dataverse.harvard.edu/",
         introduce: "是一个免费的数据存储库，向哈佛社区内外任何学科的所有研究人员开放"
      }, {
         name: "British Social Attitudes Survey",
         url: "https://link.zhihu.com/?target=http%3A//www.bsa.natcen.ac.uk/%3F_ga%3D1.30131903.1647202288.1447343086",
         introduce: "收入较低的人群最关心的问题是什么？登陆网站，用数据说话"
      }, {
         name: "EVS",
         url: "https://link.zhihu.com/?target=http%3A//www.europeanvaluesstudy.eu/",
         introduce: "是一项关于人类价值观的大规模的、跨国家和纵向的调查研究项目。它提供了欧洲各地公民的思想、信仰、偏好、态度、价值观和观点的信息数据。"
      }, {
         name: "IPSR",
         url: "https://link.zhihu.com/?target=https%3A//ipsr.berkeley.edu/",
         introduce: "是一个全球性的人格和社会方向的研究中心"
      }, {
         name: "MIDUS",
         url: "https://link.zhihu.com/?target=http%3A//midus.wisc.edu/",
         introduce: "是一项关于健康及幸福感的国际纵向研究"
      }, {
         name: "NatCen British Social Attitudes",
         url: "https://link.zhihu.com/?target=http%3A//www.britsocat.com/",
         introduce: "每年英国社会态度调查都会询问3000多人生活在英国的感觉，以及他们认为英国是如何“运作”的"
      }, {
         name: "Raw personality datasets",
         url: "https://link.zhihu.com/?target=http%3A//personality-testing.info/_rawdata/",
         introduce: "拥有丰富的人格测试数据及信息资源"
      }, {
         name: "Social Justice Sexuality Project",
         url: "https://link.zhihu.com/?target=http%3A//socialjusticesexuality.com/projects/",
         introduce: "是对黑人、拉丁裔、亚裔和太平洋岛屿居民、多种族女同性恋、男同性恋、双性恋和变性人(LGBT)的全国性调查中规模最大的一次。在超过5000个受访者中，最终的样本包括来自五十个州的受访者：华盛顿、哥伦比亚特区和波多黎各等，并且其地区包含有农村、郊区以及城市。项目从不同的年龄、种族身份、性取向、性别认同出发，获取了大量的数据信息。"
      }, {
         name: "1958 National Child Development Study",
         url: "https://cls.ucl.ac.uk/cls-studies/1958-national-child-development-study-2/",
         introduce: "跟踪了1958年在英格兰、苏格兰和威尔士出生的最初17，415人的生活。收集了关于他们身心发展、经济环境、就业、家庭生活、健康行为、福祉、社会参与和态度的信息。"
      }, {
         name: "1970 British Cohort Study",
         url: "https://cls.ucl.ac.uk/cls-studies/1970-british-cohort-study/",
         introduce: "跟踪了1970年一周内在英格兰、苏格兰和威尔士出生的大约17，000人的生活,收集了有关健康、身体、教育和社会发展以及经济状况等因素的信息"
      }, {
         name: "Adolescent Brain Cognitive Development",
         url: "https://www.drugabuse.gov/drug-topics/adolescent-brain/longitudinal-study-adolescent-brain-cognitive-development-abcd-study",
         introduce: "是美国国家卫生研究院发布的一个资料库，其数据来自于对青少年大脑发育的大规模研究"
      }, {
         name: "Baylor Religion Survey",
         url: "https://www.baylor.edu/mediacommunications/index.php?id=93052",
         introduce: "是有史以来对美国宗教态度、行为和信仰进行的最广泛和最敏感的研究"
      }, {
         name: "Born in Bradford",
         url: "https://borninbradford.nhs.uk/",
         introduce: "世界上最大的研究之一，跟踪超过30，000名布拉德福德人的生活，找出什么影响家庭的健康和福祉"
      }, {
         name: "Centre for Longitudinal Studies",
         url: "https://www.ucl.ac.uk/",
         introduce: "是一个经济和社会研究中心（ESRC网站）资源中心"
      }, {
         name: "Danish twin registry",
         url: "https://www.sdu.dk/en/om_sdu/institutter_centre/ist_sundhedstjenesteforsk/centre/dtr/researcher/data",
         introduce: "由140个出生群体中的大约175，000对双胞胎组成。登记册包括广泛的调查数据，包括对同一个人的重复测量，并收集了一部分双胞胎的生物样本。"
      }, {
         name: "English Longitudinal Study of Aging",
         url: "https://www.elsa-project.ac.uk/",
         introduce: "收集有关人们身心健康、福祉、财务状况和老龄化态度的信息，以及这些信息如何随着时间而变化"
      }, {
         name: "European Social Survey",
         url: "https://www.europeansocialsurvey.org/about/",
         introduce: "调查衡量了三十多个国家不同人口的态度、信仰和行为模式"
      }, {
         name: "世界价值观调查数据中国卷",
         url: "http://www.cpc.unc.edu/projects/china",
         introduce: "社会价值观、社会规范、社会距离、工作问题、劳工组织、就业问题等"
      }, {
         name: "北京大学健康老龄与发展研究中心",
         url: "https://opendata.pku.edu.cn/dataverse/CHADS",
         introduce: "调查范围覆盖全国23个省市自治区，调查对象为65岁及以上老年人和35-64岁成年子女，调查问卷分为存活被访者问卷和死亡老人家属问卷两种。存活被访者问卷的调查内容包括老人及家庭基本状况、社会经济背景及家庭结构、经济来源和经济状况、健康和生活质量自评、认知功能、性格心理特征、日常活动能力、生活方 式、生活照料、疾病治疗和医疗费承担；死亡老人家属问卷的调查内容包括老人死亡时间、死因等内容"
      }, {
         name: "PsycINFO",
         url: "http://nrs.harvard.edu/urn-3:hul.eresource:psycinfo",
         introduce: "认知疗法和行为疗法都有很好的介绍"
      }, {
         name: "国家统计局",
         url: "https://data.stats.gov.cn/",
         introduce: "国家统计数据库，GDP PMI CPI PPT，大中城市房价"
      }, {
         name: "世界银行公开数据",
         url: "https://data.worldbank.org.cn/",
         introduce: "免费公开获取世界各国的发展数据"
      }, {
         name: "联合国综合数据库",
         url: "http://data.un.org/",
         introduce: "联合国数据库（UN data）是世界上重要的数据库，涵盖政治、经济、人口、交通、能源等"
      }, {
         name: "财富500强",
         url: "http://www.fortunechina.com/rankings/index.htm",
         introduce: "《财富》杂志推出的全球最大五百家公司排行榜"
      }, {
         name: "搜数网",
         url: "http://www.soshoo.com/index.do#",
         introduce: "专门面向统计和调查数据的专业垂直搜索网站"
      }, {
         name: "全球科研项目数据库",
         url: "http://project.llas.ac.cn/",
         introduce: "汇聚了全球主要发达国家重要基金组织2000年以来的科研项目数据200多万条"
      }, {
         name: "资源学科创新平台",
         url: "http://www.data.ac.cn/",
         introduce: "以人口、资源、环境和发展（PRED）为核心的数据库服务"
      }, {
         name: "中国学术调查数据资料库",
         url: "http://cnsda.ruc.edu.cn/index.php",
         introduce: "受中国国家自然科学基金重点项目资助的、由中国人民大学中国调查与数据中心（National Survey Research Center, NSRC)负责执行的经济与社会数据共享平台"
      }, {
         name: "欧洲开放数据门户",
         url: "https://data.europa.eu/euodp/en",
         introduce: "社会、科学、环境、经济、金融等数据"
      }, {
         name: "社科网",
         url: "https://www.sinoss.net/",
         introduce: "为人文科学服务的专业性门户网站"
      }, {
         name: "国务院",
         url: "http://www.gov.cn/index.htm",
         introduce: "各类全国基础数据，34个职部委职能相关数据"
      }, {
         name: "欧盟数据门户",
         url: "https://www.europeandataportal.eu/",
         introduce: "包括地理、统计、气象数据，以及来自公共资金研究项目的数据和数字化图书等，平台中的数据集均由欧盟收集、购买，再免费提供给所有的用户，来自欧盟以及其他地区的用户都可以到平台上直接下载数据用于分析、开发创新服务或应用等用途。"
      }, {
         name: "Open Data 500",
         url: "http://www.opendata500.com/us/",
         introduce: "首个以开放政府数据来产生新的业务和开发新的产品和服务的综合研究性美国公司。研究机构可以发布网站上收集的数据，企业、组织和公民也可以分析网站上的数据，并使用这些信息。"
      }, {
         name: "IPUMS",
         url: "https://www.ipums.org/",
         introduce: "是普查数据的集成网站，用户可以查询包括人口、地理、居民生活、收入和消费信息等普查数据"
      }, {
         name: "数据世界",
         url: "https://ourworldindata.org/",
         introduce: "涵盖了健康趋势，粮食供应，收入的增长和分配，暴力，权利，战争，文化，能源使用等主题的分析和可视化数据，所有用户可以在上面免费下载数据和代码"
      }, {
         name: "github",
         url: "https://github.com/caesar0301/awesome-public-datasets",
         introduce: "为用户整理了非常全面的数据获取渠道，包含各个细分领域的数据库资源，自然科学和社会科学的覆盖都很全面，用户可以自己获取数据去做研究和数据分析"
      }, {
         name: "优易数据",
         url: "http://www.youedata.com/index.html",
         introduce: "由国家信息中心发起，拥有国家级信息资源的数据平台，国内领先的数据交易平台。平台有B2B、B2C两种交易模式，包含政务、社会、社交、教育、消费、交通、能源、金融、健康等多个领域的数据资源"
      }, {
         name: "UCI数据集",
         url: "https://link.zhihu.com/?target=https%3A//archive.ics.uci.edu/ml/index.php",
         introduce: "是加州大学欧文分校(University of CaliforniaIrvine)提出的用于机器学习的数据库，这个数据库目前共有559个数据集，其数目还在不断增加"
      }, {
         name: "中国工程科技知识中心",
         url: "http://www.ckcest.cn/home/",
         introduce: "收录了全球250个国家/地区、20多个国内外主要机构的770条指标、近700万条数据,覆盖经济、人口、资源、科技、环境等领域"
      }, {
         name: "Census of Agriculture",
         url: "https://www.agcensus.usda.gov/",
         introduce: "提供美国各州和地区的农业人口普查数据，美国农业人口普查每5年进行一次，它收集关于美国农场和牧场及其经营者的统一、详细的数据"
      }, {
         name: "Data Sharing for Demographic Research",
         url: "http://www.icpsr.umich.edu/icpsrweb/DSDR/",
         introduce: "由尤尼斯·肯尼迪·施莱佛儿童健康与人类发展国家研究院的人口动态分院支持建立，它的目标是：提供人口研究数据的获取、传播和保存服务；改善数据共享；支持共享限制使用的数据；为机构及个人提供支持服务以及扩展服务。"
      }, {
         name: "Fragile Families and Child Wellbeing Study ",
         url: "http://www.fragilefamilies.princeton.edu/",
         introduce: "脆弱家庭（未婚家长与孩子组成的家庭）与孩子福利”项目跟踪研究1998-2000年出生在美国大城市的近5000名儿童（大约四分之三是未婚父母所生），这些儿童所在的“家庭”比普通家庭面临更大的分裂和贫困的危险。"
      }, {
         name: "Gateway to Global Aging Data",
         url: "http://g2aging.org/",
         introduce: "全球老龄化数据门户是有关全球老龄化调查的一个平台。该门户提供相关调查问卷的数字图书馆，提供对于跨调查中一些可比较问题的查询。"
      }, {
         name: "Health and Retirement Study",
         url: "http://hrsonline.isr.umich.edu/",
         introduce: "健康和退休研究”是一个纵向研究小组，每两年研究调查具有代表性的26000多万50岁以上的美国人，收集其收入、工作、资产、养老金计划、健康保险、残疾、认知功能、遗传信息和卫生保健支出等信息。"
      }, {
         name: "Human Mortality Database",
         url: "http://www.mortality.org/",
         introduce: "该项目起源于美国加州大学伯克利分校人口学系以及德国罗斯托克马克斯普朗克人口研究所的早期项目。人类死亡率数据库提供39个国家详细的死亡率和人口数据。"
      }, {
         name: "Human Sciences Research Council Research Data Service",
         url: "http://datacuration.hsrc.ac.za/",
         introduce: "通过广泛收集数据，为相关研究、政策制定和社会发展问题的公开讨论等提供数据支持，通过编制全面的元数据和向合适的受众传播数据及相关文件来促进数据的使用。"
      }, {
         name: "Survey of Health, Ageing and Retirement in Europe",
         url: "http://www.share-project.org/",
         introduce: "欧洲健康、老龄化和退休调查是一个多学科和跨国家的有关12万50岁以上老年人的健康、社会经济状况和社会与家庭网络的数据库，涵盖了27个欧洲国家和以色列。"
      }, {
         name: "UK Data Archive",
         url: "http://www.data-archive.ac.uk/",
         introduce: "大规模的政府调查，例如劳动力调查；英国主要的调查，例如英国家庭小组调查、民意测验以及环境研究项目的多媒体定性数据资源。"
      }, {
         name: "Data.gov.uk",
         url: "https://data.gov.uk/data/search",
         introduce: "由英国政府创办的可以快速查找政府服务和信息，英国政府部门，英国相关政策等与政府和民生息息相关的网站，并且所有政府部门和许多其他机构、公共机构的网站都被收录到了这里"
      }, {
         name: "nter-university Consortium for Political and Social Research",
         url: "http://www.icpsr.umich.edu",
         introduce: "以大量收集系统化的社会科学数据著称，是世界上最大的社会科学数据中心"
      }, {
         name: "National Data Archive on Child Abuse and Neglect",
         url: "http://www.ndacan.cornell.edu",
         introduce: "由美国康奈尔大学人类生态学学院创建。是一个用于促进儿童虐待领域研究者之间进行学术交流的资源库"
      }, {
         name: "中国国家调查数据库",
         url: "http://www.cnsda.org/",
         introduce: "数据涵盖了经济、综合、健康、社会、教育、企业、宗教、政治、科学和历史等领域"
      }, {
         name: "Korean Social Science Data Archive",
         url: "http://www.kossda.or.kr/",
         introduce: "研究领域：政治、公共行政和法律、经济和商业管理、社会文化、社会问题、社会福利、心理学和教育和区域研究。"
      }, {
         name: "Gateway to Global Aging",
         url: "https://g2aging.org/?",
         introduce: "可以看到全球老龄化的相关数据"
      }, {
         name: "PolData: A dataset with political datasets",
         url: "https://github.com/erikgahner/PolData",
         introduce: "这是一个政治数据库的集合。这些数据包括：内阁、公民、宪法、政治机构、政党和政治家、民主、经济、选举、国际关系、媒体、政策等。所有数据都在网页中按列表展示，一目了然，非常便捷。"
      }, {
         name: "British Social Attitudes Survey",
         url: "http://www.bsa.natcen.ac.uk/?_ga=1.30131903.1647202288.1447343086",
         introduce: "收入较低的人群最关心的问题是什么？登陆网站，用数据说话。"
      }, {
         name: "EVS",
         url: "http://www.europeanvaluesstudy.eu/",
         introduce: "它提供了欧洲各地公民的思想、信仰、偏好、态度、价值观和观点的信息数据。"
      }, {
         name: "National Survey of Children’ s Health and National Survey of Children with Special Healthcare Needs",
         url: "http://www.childhealthdata.org/",
         introduce: "本站的数据还可以根据地图（美国）对所需数据进行搜索。"
      }, {
         name: "NHANES",
         url: "http://www.cdc.gov/nchs/nhanes.htm",
         introduce: "是一项旨在评估美国成年人和儿童健康和营养状况的研究项目。这项调查的独特之处在于它结合了面试和体检两个方面的数据资料。"
      }, {
         name: "National Historical Geographic Information System",
         url: "https://www.nhgis.org/",
         introduce: "提供1790年至今的免费的美国人口普查和其他全国性调查的在线汇总统计和地理信息系统边界文件。包括带有地理空间属性信息的人口、农业、经济等方面的数据"
      }, {
         name: "London Datastore",
         url: "http://data.london.gov.uk/",
         introduce: "是由大伦敦当局(GLA)发起的一项倡议，目的是释放尽可能多的数据。数据主题涉及伦敦的人口、就业、环境、住房、交通、规划等各个主题"
      }, {
         name: "Government of Canada—Open Data",
         url: "http://open.canada.ca/en/open-data",
         introduce: "包括经济、健康、教育等各方面的政府公开数据。包括历次人口普查的数据"
      }, {
         name: "Databank of Official Statistics on Quebec ",
         url: "http://www.bdso.gouv.qc.ca/",
         introduce: "包括农业和生物食品产业、文化与传播、经济、教育、培训和儿童保育、能量、环境、金融与非金融合作、林业、健康、福利、卫生和社会服务、劳动补偿、生活条件与社会、制造业、采矿业、市政事务、人口、科学技术创新、可持续发展、旅游、交通、用户和基础设施等多方面的统计数据和信息"
      }, {
         name: "The German Socio-Economic Panel Study",
         url: "http://www.diw.de/en/diw_02.c.221180.en/research_data_center_soep.html",
         introduce: "每年超过2万人到近1.1万户家庭进行现场数据调查。数据包含所有家庭成员信息，涵盖住在新旧德国的德国人、外国人和最近的移民，调研数据包括家庭组成、职业、就业、收入、健康和满意度等指标，并提供使用这些数据进行分析的研究论文。"
      }, {
         name: "The Centro de Investigaciones Sociológicas",
         url: "http://www.cis.es/cis/opencms/EN/index.html",
         introduce: "CIS收集从民意调查到应用研究等不同领域的研究数据，因进行各种调查而闻名，通过调查来搜集关于西班牙社会及其演变的知识。CIS数据银行将CIS进行的所有研究提供给大众，公众可以通过本网站免费访问该数据库"
      }, {
         name: "Estonian Social Science Data Archive",
         url: "http://esta.ut.ee/",
         introduce: "由塔尔图大学的一些社会学家、心理学家、政治学家和人文地理学家共同建立。"
      }, {
         name: "Gapminder",
         url: "https://www.gapminder.org/",
         introduce: "是瑞士 Gapminder 基金会开发的一个统计软件，你可以形象地看见用世界银行提供数据绘制的的世界各国各项发展指数"
      }, {
         name: "World Health Organization",
         url: "http://www.who.int/gho/en/",
         introduce: "探索健康数据的世界"
      }, {
         name: "ALLBUS",
         url: "https://www.gesis.org/allbus/download/download-querschnitte/",
         introduce: "收集德国在态度、行为和社会结构方面最新的数据"
      }, {
         name: "Avon Longitudinal Study of Parents and Children",
         url: "http://www.bristol.ac.uk/alspac/",
         introduce: "以布里斯托大学为基础，埃文河父母与子女纵向研究也被称为一个世界领先的对90后孩子进行研究的项目。从90年代初到现在，该项目已经对1991年4月至1992年12月间出生的研究对象进行了二十多年的跟踪研究。该项研究可以为国际研究界提供用于研究影响人类健康和大战的环境及遗传因素的丰富资源，并为后代创造更好的生活"
      }, {
         name: "北京大学开放研究数据平台",
         url: "https://opendata.pku.edu.cn/",
         introduce: "主要是社会学相关的数据"
      }, {
         name: "HARVARD Dataverse",
         url: "https://dataverse.harvard.edu/",
         introduce: "是一个免费的数据存储库，向哈佛社区内外任何学科的所有研究人员开放"
      }, {
         name: "国家健康和营养检查调查",
         url: "https://link.zhihu.com/?target=http%3A//www.cdc.gov/nchs/nhanes.htm",
         introduce: "是一项旨在评估美国成年人和儿童健康和营养状况的研究项目。这项调查的独特之处在于它结合了面试和体检两个方面的数据资料"
      }, {
         name: "Wisconsin Longitudinal Study",
         url: "https://link.zhihu.com/?target=https%3A//www.ssc.wisc.edu/wlsresearch/",
         introduce: "是一项对10317名男性和女性随机抽样的长期调查研究，他们都是1957年从威斯康森高中毕业的。WLS为研究者提供了一个研究纵向生命历程、代际关系、家庭功能、身体和心理健康及幸福感的机会。WLS数据中还包括了人们的社会背景、抱负、教育、从军、劳动、家庭特征、社会参与情况、心理特征和退休等因素。"
      }, {
         name: "Centre for Longitudinal Studies",
         url: "https://www.ucl.ac.uk/",
         introduce: "是一个经济和社会研究中心（ESRC网站）资源中心"
      }, {
         name: "Danish twin registry",
         url: "https://www.sdu.dk/en/om_sdu/institutter_centre/ist_sundhedstjenesteforsk/centre/dtr/researcher/data",
         introduce: "由140个出生群体中的大约175，000对双胞胎组成。登记册包括广泛的调查数据，包括对同一个人的重复测量，并收集了一部分双胞胎的生物样本。"
      }, {
         name: "青少年科学学习调查数据库",
         url: "http://css.sysu.edu.cn/Data",
         introduce: "该调查由中山大学社会学与社会工作系王进副教授主持，得到中国科普研究所、瑞典哥德堡大学和中山大学社会科学调查中心资助。"
      }, {
         name: "国人民大学中国调查与数据中心",
         url: "http://ceps.ruc.edu.cn/",
         introduce: "揭示家庭、学校、社区以及宏观社会结构对于个人教育产出的影响"
      }, {
         name: "WorldPop",
         url: "https://link.zhihu.com/?target=https%3A//www.worldpop.org/",
         introduce: "全球多个大洲人口的高分辨率当代数据合集。"
      }, {
         name: "GeoHive",
         url: "https://link.zhihu.com/?target=https%3A//geohive.ie/",
         introduce: "全球人口和国家统计数据，CSV格式"
      }, {
         name: "EPS数据平台",
         url: "http://www.epsnet.com.cn/",
         introduce: "是由北京福卡斯特信息技术有限公司提供的“专业数据+预测分析”平台。收录国内外的经济、贸易、行业、科技领域的统计数据；利用内嵌的数据分析预测软件可完成对数据的分析和预测"
      }, {
         name: "北京大学地理数据平台",
         url: "https://geodata.pku.edu.cn/",
         introduce: "是由北京大学城市与环境学院于2017年建立。平台建立的宗旨是为本院和地理专业的学生、教师以及科研人员提供全面、丰富、专业的数据信息，力图搭建一个地理数据共享交流的平台，构建从不同方向描述地球系统的数据库。数据包括自然地理、人文地理、历史地理等多个地理专业的图形图像、统计图表、文本文件及监测和模型运算得到的数据，数据范围包括全国及全球范围，数据来源于我院科研成果和积累的数据，以及平台收集整理的数据。数据平台的建设得到了学校科研部、设备部的大力支持，也得到了中国宇航学会卫星专业委员会提供的帮助。平台管理由北京大学城市与环境学院教授组成的地理数据平台小组负责，平台运行维护由人地系统与环境遥感实验室负责"
      }, {
         name: "北京大学中国调查数据资料库",
         url: "https://opendata.pku.edu.cn/dataverse/CSDA;jsessionid=4ef9c4ece41184276bf1b0a1724d",
         introduce: "成立于2015年，是国家自然科学基金-北京大学管理科学数据中心根据国内管理科学量化研究对于调查数据的需求而设立的项目。该项目的目标是收录整合国内优质调查科研数据资源，通过专业的数据管理与监护，实现便捷的数据共享，为科学研究和决策管理提供高水平的数据服务"
      }, {
         name: "中国家庭追踪调查",
         url: "https://opendata.pku.edu.cn/dataverse/CFPS;jsessionid=4ef9c4ece41184276bf1b0a1724d",
         introduce: "旨在通过跟踪收集个体、家庭、社区三个层次的数据，反映中国社会、经济、人口、教育和健康的变迁，为学术研究和公共政策分析提供数据基础"
      }, {
         name: "中国健康与养老追踪调查",
         url: "https://opendata.pku.edu.cn/dataverse/CHARLS",
         introduce: "旨在收集一套代表中国45岁及以上中老年人家庭和个人的高质量微观数据，用以分析我国人口老龄化问题，推动老龄化问题的跨学科研究。CHARLS全国基线调查于2011年开展，覆盖150个县级单位，450个村级单位，约1万户家庭中的1.7万人。这些样本以后每两年追踪一次，调查结束一年后，数据将对学术界展开。"
      }, {
         name: "北京大学中国国情研究中心",
         url: "https://opendata.pku.edu.cn/dataverse/RCCC",
         introduce: "其宗旨在于发挥北京大学的人才和知识密集的优势，组织和开展多种研究项目、学术交流活动和培训工作，推进对中国社会、政治、经济发展状况的多学科综合研究。重点在于推动实证的、量化的科学方法在学术界、政策研究，以及市场调研等方面的应用。"
      }, {
         name: "北京大学健康老龄与发展研究中心",
         url: "https://opendata.pku.edu.cn/dataverse/CHADS",
         introduce: "调查范围覆盖全国23个省市自治区，调查对象为65岁及以上老年人和35-64岁成年子女，调查问卷分为存活被访者问卷和死亡老人家属问卷两种。存活被访者问卷的调查内容包括老人及家庭基本状况、社会经济背景及家庭结构、经济来源和经济状况、健康和生活质量自评、认知功能、性格心理特征、日常活动能力、生活方 式、生活照料、疾病治疗和医疗费承担；死亡老人家属问卷的调查内容包括老人死亡时间、死因等内容"
      }, {
         name: "Sociological Abstracts",
         url: "http://nrs.harvard.edu/urn-3:hul.eresource:sociofil",
         introduce: "社会学文献的主要索引。可能对社区相关研究或人际问题有用"
      }, {
         name: "3ie Impact Evaluation Repository",
         url: "http://www.3ieimpact.org/en/evidence/impact-evaluations/impact-evaluation-repository/",
         introduce: "调查经济或社会干预，用于证明低收入和中等收入国家在国际发展中的作用。"
      }, {
         name: "国家青藏高原科学数据中心",
         url: "https://data.tpdc.ac.cn/zh-hans/",
         introduce: "数据中心以青藏高原及周边地区各类科学数据为主，已整合的数据资源包括：大气、冰冻圈、水文、生态、地质、地球物理、自然资源、基础地理、社会经济等，开发了在线大数据分析、模型应用等功能，实现青藏高原科学数据、方法、模型与服务的广泛集成。"
      }, {
         name: "国家统计局",
         url: "https://data.stats.gov.cn/",
         introduce: "国家统计数据库，GDP PMI CPI PPT，大中城市房价"
      }, {
         name: "CEIC",
         url: "https://www.ceicdata.com/en",
         introduce: "195+个国家的经济数据库，GDP、GNP、FDI和CPI等"
      }, {
         name: "联合国综合数据库",
         url: "http://data.un.org/",
         introduce: "联合国数据库（UN data）是世界上重要的数据库，涵盖政治、经济、人口、交通、能源等"
      }, {
         name: "全球科研项目数据库",
         url: "http://project.llas.ac.cn/",
         introduce: "汇聚了全球主要发达国家重要基金组织2000年以来的科研项目数据200多万条"
      }, {
         name: "资源环境科学与数据中心",
         url: "http://www.resdc.cn/Default.aspx",
         introduce: "“中心本部”和9个“分中心”，一个科学数据集与共享平台"
      }, {
         name: "千顺",
         url: "http://www.qcqs.com.cn/",
         introduce: "金融门户网站。聚焦金融领域，专注金融产业发展和风险防范化解的“大数据+研究+咨询+落地”服务，及系统运维，推动大数据与金融行业深度融合"
      }, {
         name: "中国金融信息网",
         url: "http://dc.xinhua08.com/",
         introduce: "由新华社主管、中国经济信息社主办,是配合新华财经金融信息平台项目打造的国家级专业财经网站,定位于建设中国财经金融信息领域的权威发布和服务平台。"
      }, {
         name: "中国金融期货交易所",
         url: "http://www.cffex.com.cn/",
         introduce: "国务院同意，中国证监会批准设立的，专门从事金融期货、期权等金融衍生品交易与结算的公司制交易所"
      }, {
         name: "欧洲开放数据门户",
         url: "https://data.europa.eu/euodp/en",
         introduce: "社会、科学、环境、经济、金融等数据"
      }, {
         name: "地理空间数据云",
         url: "http://www.gscloud.cn/",
         introduce: "以中国科学院及国家的科学研究为主要需求，逐渐引进当今国际上不同领域内的国际数据资源，并对其进行加工、整理、集成，最终实现数据的集中式公开服务、在线计算等"
      }, {
         name: "howmuch.net",
         url: "https://howmuch.net/",
         introduce: "一个非常有意思的金融数据可视化网站"
      }, {
         name: "全球经济指标",
         url: "https://zh.tradingeconomics.com/",
         introduce: "提供196个国家的准确经济信息，包括超过2000万经济指标、汇率、股票市场指数、政府债券收益率和商品价格的历史数据"
      }, {
         name: "欧盟数据门户",
         url: "https://www.europeandataportal.eu/",
         introduce: "包括地理、统计、气象数据，以及来自公共资金研究项目的数据和数字化图书等，平台中的数据集均由欧盟收集、购买，再免费提供给所有的用户，来自欧盟以及其他地区的用户都可以到平台上直接下载数据用于分析、开发创新服务或应用等用途。"
      }, {
         name: "中国保险网——数据中心",
         url: "http://www.china-insurance.com/info/list.aspx?cid=21",
         introduce: "在中国保险网内的数据中心里，用户可以查到具体一家保险公司的各项数据情况"
      }, {
         name: "Wind",
         url: "http://www.wind.com.cn/",
         introduce: "被誉为中国的Bloomberg，在金融业有着全面的数据覆盖，金融数据的类目更新极快，很受国内的商业分析者和投资人的亲睐。"
      }, {
         name: "亚马逊公共数据集",
         url: "https://amazonaws-china.com/cn/public-datasets/",
         introduce: "包含化学、生物、经济等多个领域的数据集，当数据在 AWS 上公开提供后，任何人都可以分析任意数量的数据，而无需自行下载或存储这些数据。"
      }, {
         name: "优易数据",
         url: "http://www.youedata.com/index.html",
         introduce: "由国家信息中心发起，拥有国家级信息资源的数据平台，国内领先的数据交易平台。平台有B2B、B2C两种交易模式，包含政务、社会、社交、教育、消费、交通、能源、金融、健康等多个领域的数据资源"
      }, {
         name: "Kaggle",
         url: "https://www.kaggle.com/datasets",
         introduce: "主要为开发商和数据科学家提供举办机器学习竞赛、托管数据库、编写和分享代码的平台"
      }, {
         name: "和鲸社区",
         url: "https://www.heywhale.com/home/dataset",
         introduce: "是国内知名的开放数据科学社区,是最早一批专注于大数据算法比赛的平台,支持在线分享代码、项目和数据集"
      }, {
         name: "中国工程科技知识中心",
         url: "http://www.ckcest.cn/home/",
         introduce: "收录了全球250个国家/地区、20多个国内外主要机构的770条指标、近700万条数据,覆盖经济、人口、资源、科技、环境等领域"
      }, {
         name: "中国产业信息",
         url: "https://www.chyxx.com/",
         introduce: "工业和信息化部主管,人民邮电报社主办,是我国通信行业唯一拥有国务院新闻办授予新闻发布权的新闻网站。"
      }, {
         name: "Korean Social Science Data Archive",
         url: "http://www.kossda.or.kr/",
         introduce: "研究领域：政治、公共行政和法律、经济和商业管理、社会文化、社会问题、社会福利、心理学和教育和区域研究。"
      }, {
         name: "世界银行空间数据库",
         url: "http://www.worldbank.org/",
         introduce: "世界银行是全球社会经济数据最全面、最丰富和最权威的数据发布机构，对于研究全球问题需要的社会经济数据有很好的共享。"
      }, {
         name: "NOAA Digital Coast",
         url: "https://coast.noaa.gov/digitalcoast/",
         introduce: "提供沿海区域的数据，主要包括海底数据、海拔、图像、土地覆盖以及社会经济数据等，数据包括雷达、红外和真彩色影像数据等"
      }, {
         name: "国务院发展研究中心信息网",
         url: "http://www.drcnet.com.cn/www/int/",
         introduce: "由国务院发展研究中心信息中心主办，创建于1998年3月，是中国最著名的大型经济类专业网站"
      }, {
         name: "中经网产业数据库",
         url: "https://cyk.cei.cn/",
         introduce: "是国家信息中心中经网精心打造的、覆盖机械、汽车、电子通讯、医药、石油化工、能源、交通、房地产、钢铁、旅游等10多个产业集群的全新数据库产品。"
      }, {
         name: "CSMAR",
         url: "http://cn.gtadata.com/#/index",
         introduce: "CSMAR 数据库已涵盖因子研究、人物特征、绿色经济、股票、公司、海外、资讯、基金、债券、行业、经济、商品期货等18大系列，包含150+个数据库、4000多张表、4万多个字段。涵盖中国证券、期货、外汇、宏观、行业等经济金融主要领域，是投资和实证研究的基础工具"
      }, {
         name: "CVSource数据库",
         url: "https://www.cnrds.com/Home/Login#/",
         introduce: "CVSource数据终端是投中集团旗下专注于中国私募股权、战略投资与并购交易等股权投资市场的在线信息产品"
      }, {
         name: "中国研究数据服务平台",
         url: "http://new.ccerdata.cn/",
         introduce: "参阅各领域重点文献，整合了学术研究过程中可能用到的大部分基础数据。"
      }, {
         name: "中国微观经济数据查询系统",
         url: "http://microdata.sozdata.com/login.html",
         introduce: "是由北京搜知数据科技有限公司提供的一款以企业层面的微观数据为基础，集数据查询、数据匹配、数据可视化于一体的数据查询系统"
      }, {
         name: "PolData: A dataset with political datasets",
         url: "https://github.com/erikgahner/PolData",
         introduce: "这是一个政治数据库的集合。这些数据包括：内阁、公民、宪法、政治机构、政党和政治家、民主、经济、选举、国际关系、媒体、政策等。所有数据都在网页中按列表展示，一目了然，非常便捷。"
      }, {
         name: "National Historical Geographic Information System",
         url: "https://www.nhgis.org/",
         introduce: "提供1790年至今的免费的美国人口普查和其他全国性调查的在线汇总统计和地理信息系统边界文件。包括带有地理空间属性信息的人口、农业、经济等方面的数据"
      }, {
         name: "Government of Canada—Open Data",
         url: "http://open.canada.ca/en/open-data",
         introduce: "包括经济、健康、教育等各方面的政府公开数据。包括历次人口普查的数据"
      }, {
         name: "Databank of Official Statistics on Quebec ",
         url: "http://www.bdso.gouv.qc.ca/",
         introduce: "包括农业和生物食品产业、文化与传播、经济、教育、培训和儿童保育、能量、环境、金融与非金融合作、林业、健康、福利、卫生和社会服务、劳动补偿、生活条件与社会、制造业、采矿业、市政事务、人口、科学技术创新、可持续发展、旅游、交通、用户和基础设施等多方面的统计数据和信息"
      }, {
         name: "UK Data Service",
         url: "http://ukdataservice.ac.uk/",
         introduce: "由ESRC资助的全面资源库，可帮助研究人员、教师和决策者使用高质量的社会和经济数据进行研究与决策。同时，可访问众多二手数据包括政府大调查、国际宏观数据、商业微数据、定性研究、1971年到2011年的人口普查数据等。"
      }, {
         name: "The German Socio-Economic Panel Study",
         url: "http://www.diw.de/en/diw_02.c.221180.en/research_data_center_soep.html",
         introduce: "每年超过2万人到近1.1万户家庭进行现场数据调查。数据包含所有家庭成员信息，涵盖住在新旧德国的德国人、外国人和最近的移民，调研数据包括家庭组成、职业、就业、收入、健康和满意度等指标，并提供使用这些数据进行分析的研究论文。"
      }, {
         name: "The Economics & Business Data Center",
         url: "http://www.cesifo-group.de/ifoHome/facts/EBDC.html",
         introduce: "是德维希马克西米利安慕尼黑大学和IFO研究院联合成立的一个对企业管理和经济学进行实证研究的综合平台，旨在为企业管理和经济学实证研究开辟新领域"
      }, {
         name: "Penn World Table 9.0",
         url: "http://www.rug.nl/ggdc/productivity/pwt/",
         introduce: "宾夕法尼亚大学世界数据库9.0版包含全球182个国家1950-2014年的收入、投入、产出和生产效率等数据信息。"
      }, {
         name: "International Data Service Center of the Institute for the Study of Labor ",
         url: "http://idsc.iza.org/",
         introduce: "IDSC的目标是成为有经济头脑的技术专家以及技术高超的经济学家寻求劳动经济学相关数据支持、数据获取和服务的地方。IDSC积极参与面向数据专家、数据分析师、科研数据使用者以及乐于讨论和分享研究结论的年轻研究人员的各种活动。"
      }, {
         name: "DHS Data Access ",
         url: "http://www.dhsdata.nl/site/users/login",
         introduce: "CentERdata每年通过DNB家庭调查来收集经济数据，调查的目的是研究家庭储蓄行为的经济和心理因素，数据通过CentERpanel来收集，每周是不同的主题"
      }, {
         name: "美国经济协会",
         url: "https://www.aeaweb.org/resources/data",
         introduce: "是在位于美国纽约州东部的村落——萨拉托加（Saratoga, New York）的一次会议上由一小部分对经济学感兴趣的人组织起来"
      }, {
         name: "Inforum",
         url: "http://inforumweb.umd.edu/econdata/econdata.html",
         introduce: "是50多年前由马里兰大学名誉教授克洛珀·阿尔蒙博士创立的。它致力于改善商业规划、政府政策分析和对经济环境的普遍了解"
      }, {
         name: "FRASER INSTITUTE",
         url: "https://www.fraserinstitute.org/",
         introduce: "是一家加拿大非盈利智库，成立于1974年，以弗雷泽河命名"
      }, {
         name: "DBnomics ",
         url: "https://db.nomics.world/",
         introduce: "世界经济数据库"
      }, {
         name: "JEDH",
         url: "http://www.jedh.org/",
         introduce: "由国际清算银行、国际货币基金组织（货币基金组织）、经济合作与发展组织（经合组织）和世界银行（世界银行）联合开发的联合外债中心汇集了外债数据以及来自国际债权国/市场和国家债务来源的选定外国资产"
      }, {
         name: "OpenCorporates",
         url: "https://opencorporates.com/",
         introduce: "世界需要的高质量公司数据"
      }, {
         name: "Our World in Data",
         url: "http://ourworldindata.org/",
         introduce: "是由英国牛津大学的Leszell创建的网站，他致力于研究几十年来各国关于人类生活水平的数据，以揭示全世界的生活状况是如何潜移默化地发生变化的，并对未来产生了什么影响"
      }, {
         name: "ATLAS OF ECONOMIC COMPLEXITY",
         url: "http://atlas.cid.harvard.edu/",
         introduce: "哈佛增长实验室的研究和数据可视化工具用于了解全球每个国家的经济动态和新的增长机会。"
      }, {
         name: "The Center For International Data",
         url: "http://cid.econ.ucdavis.edu/",
         introduce: "该中心的目的是收集、加强、创建和传播国际经济数据，包括在线和离线分发"
      }, {
         name: "UN Comtrade Database",
         url: "https://comtrade.un.org/data/",
         introduce: "是官方国际贸易统计数据和相关分析表的储存库"
      }, {
         name: "联合国人类发展报告",
         url: "http://hdr.undp.org/en",
         introduce: "旨在将发展经济学的重点从国民收入核算转移到以人为本的政策"
      }, {
         name: "World Health Organization",
         url: "http://www.who.int/gho/en/",
         introduce: "探索健康数据的世界"
      }, {
         name: "中国人民银行",
         url: "http://www.pbc.gov.cn/diaochatongjisi/116219/index.html",
         introduce: "主要包括社会融资规模、金融统计数据、货币统计、金融机构信贷收支统计、金融市场统计、企业商品价格指数等等，数据权威且容易查找，实用性强"
      }, {
         name: "中国银行业监督管理委员会",
         url: "http://www.cbrc.gov.cn/chinese/home/docViewPage/110009.html",
         introduce: "主要包括银行业的数据统计，包括资产负债规模、主要监管数据等"
      }, {
         name: "联合国商品贸易统计数据库",
         url: "http://comtrade.un.org/",
         introduce: "全球最大、最权威的国际商品贸易数据型资源每年超过200个国家和地区提供其官方年度商品贸易数据涵盖全球99%的商品贸易收集超过6000种商品、数据回溯至1962年各国上报的数据均转换成统一格式"
      }, {
         name: "中国私营企业调查",
         url: "http://finance.sina.com.cn/nz/pr/",
         introduce: "针对私营企业和私营企业主的综合状况进行调查，内容详细，关注和研究我国私营企业发展"
      }, {
         name: "世界银行中国企业调查数据",
         url: "http://www.enterprisesurveys.org/data",
         introduce: "主要关注一个国家的商业环境变化以及公司效率和性能特征"
      }, {
         name: "中国小微企业调查数据CMES",
         url: "http://chfs.swufe.edu.cn/",
         introduce: "小微企业和个体工商户经营"
      }, {
         name: "中国专利数据库",
         url: "http://new.ccerdata.cn/Home/Special#h3",
         introduce: "准确地反映中国最新的专利发明"
      }, {
         name: "中国工业企业数据库",
         url: "http://www.allmyinfo.com/data/zggyqysjk.asp",
         introduce: "全国最为详细、所用最为广泛的微观数据库，主要研究工业企业的相关问题"
      }, {
         name: "海关数据",
         url: "http://new.ccerdata.cn/Home/Special",
         introduce: "主要是海关履行进出口贸易统计职能中产生的各项进出口统计数据，专注企业进出口情况"
      }, {
         name: "农业部农村经济研究中心",
         url: "http://www.moa.gov.cn/sydw/ncjjzx/gcdgzdt/gzdtg/201302/t20130225_3225848.htm",
         introduce: "通过观察点对农村社会经济进行长期的连续调查，掌握生产力、生产关系和上层建筑领域的变化，了解不同村庄和农户的动态、要求，从而取得系统周密的资料。"
      }, {
         name: "中国城乡流动调查数据",
         url: "https://www.iza.org/en/research/idsc/services",
         introduce: "本项目主要在人口流出或流入的大省进行调查。其中，农村住户调查是在以下9个省市进行：安徽，重庆，广东，河北，河南，湖北，江苏，四川，浙江；城市流动人口调查是在以下15个城市进行：蚌埠，成都，重庆，东莞，广州，合肥，杭州，洛阳，南京，宁波，上海，深圳，武汉，无锡，郑州。城镇住户调查是在19个城市进行，包括城市流动人口调查以外的四个城市：安阳市，建德市，乐山，绵阳。"
      }, {
         name: "中山大学社会科学调查中心",
         url: "http://css.sysu.edu.cn/Data",
         introduce: "系统地监测社区社会结构和家庭、劳动力个体的变化与相互影响"
      }, {
         name: "城市农民工调查数据库",
         url: "http://css.sysu.edu.cn/Data",
         introduce: "由中山大学社会学与社会工作系蔡禾教授主持，得到国家哲学社会科学基金和中山大学社会科学调查中心资助。"
      }, {
         name: "北京大学中国社会科学调查中心",
         url: "http://www.isss.edu.cn/cfps/",
         introduce: "中国社会、经济、人口、教育和健康的变迁"
      }, {
         name: "阿里云天池",
         url: "https://tianchi.aliyun.com/dataset/",
         introduce: "阿里旗下数据科学竞赛平台"
      }, {
         name: "BankFocus",
         url: "http://210.32.137.90/s/lib/libtb/turning/1806",
         introduce: "是银行研究的专业解决方案，是全球银行数据研究的一种新方法，结合了来自Bureau van Dijk (BvD)和穆迪投资者服务公司的核心数据，以及穆迪分析公司的专业知识，创造的一个全面的银行数据库，您可以使用它来识别、分析和研究银行和其他金融机构"
      }, {
         name: "Osiris",
         url: "http://210.32.137.90/s/lib/libtb/turning/226",
         introduce: "是研究全球各国证券交易所内155个国家超过80,000家上市公司的大型专业财务分析库(含中国深/沪及海外上市公司数据)，向专业用户提供了深入分析各国上市公司所需的详细财务经营报表与分析比率、股权结构、企业评级数据、历年股价系列、企业行业分析报告等（含已下市公司数据）"
      }, {
         name: "同花顺数据中心",
         url: "https://link.zhihu.com/?target=http%3A//data.10jqka.com.cn/",
         introduce: "提供股票债券等金融数据"
      }, {
         name: "和讯数据",
         url: "https://link.zhihu.com/?target=http%3A//data.hexun.com/",
         introduce: "提供各种股票、基金、外汇、债券等实时数据，有付费，有免费"
      }, {
         name: "零壹财经",
         url: "https://link.zhihu.com/?target=https%3A//www.01caijing.com/data/index.htm",
         introduce: "大大小小的网贷数据、排行榜等"
      }, {
         name: "金融数据网",
         url: "https://link.zhihu.com/?target=http%3A//dc.xinhua08.com/",
         introduce: "提供每日的黄金、汇率、农产品、汽油价格的相关数据"
      }, {
         name: "萝卜投研",
         url: "https://link.zhihu.com/?target=https%3A//robo.datayes.com/v2/home",
         introduce: "提供股市、证券等数据，多以数据研究报告形式展示"
      }, {
         name: "金融界",
         url: "https://link.zhihu.com/?target=http%3A//www.jrj.com.cn/",
         introduce: "提供股市、融资等各种资金流向数据，以及丰富的财报和研报"
      }, {
         name: "东方财富网",
         url: "https://link.zhihu.com/?target=http%3A//www.eastmoney.com/",
         introduce: "提供多国的股票、财税、行业、消费等大量丰富的经济数据"
      }, {
         name: "吉林金融网",
         url: "https://link.zhihu.com/?target=https%3A//www.51test.net/jinrong/jilin/jilin/",
         introduce: "提供吉林的融资、市场数据"
      }, {
         name: "搜狐证券",
         url: "https://link.zhihu.com/?target=http%3A//q.stock.sohu.com/zs/000001/index.shtml",
         introduce: "提供货币、外汇、行业、市场等宏观数据"
      }, {
         name: "CCER经济金融数据库",
         url: "https://link.zhihu.com/?target=http%3A//www.wisers.com.cn/finance",
         introduce: "提供企业财务年度数据、股票收益数据等数据库下载"
      }, {
         name: "香港金融管理局",
         url: "https://link.zhihu.com/?target=https%3A//www.hkma.gov.hk/chi/",
         introduce: "提供香港的各种经济及金融数据，以宏观数据为主"
      }, {
         name: "世纪未来",
         url: "https://link.zhihu.com/?target=http%3A//www.wefore.com/show-model3-7.html",
         introduce: "数据服务平台，提供银行业务研究、金融大数据等各种付费数据"
      }, {
         name: "新浪财经",
         url: "https://link.zhihu.com/?target=http%3A//finance.sina.com.cn/mac/",
         introduce: "提供国内国民经济、行业信息、对外经贸、居民收入等各种宏观的经济数据"
      }, {
         name: "司尔亚司数据信息有限公司",
         url: "https://link.zhihu.com/?target=https%3A//www.ceicdata.com/zh-hans",
         introduce: "覆盖超过195+个国家的经济数据库，数据全面但收费"
      }, {
         name: "INSEE数据",
         url: "https://link.zhihu.com/?target=https%3A//www.insee.fr/en/accueil",
         introduce: "法国统计与经济研究院公开数据"
      }, {
         name: "投中研究院",
         url: "https://link.zhihu.com/?target=https%3A//www.chinaventure.com.cn/cmsmodel/report/list.shtml",
         introduce: "提供投资领域的分析报告，网站更新频率较高"
      }, {
         name: " BvD",
         url: "https://www.bvdinfo.com/en-gb",
         introduce: "是欧洲著名的全球金融与企业资信分析数据库电子提供商。BvD为各国政府金融监管部门、银行与金融机构、证券投资公司等提供国际金融与各国宏观经济走势分析等专业数据"
      }, {
         name: "EPS数据平台",
         url: "http://www.epsnet.com.cn/",
         introduce: "是由北京福卡斯特信息技术有限公司提供的“专业数据+预测分析”平台。收录国内外的经济、贸易、行业、科技领域的统计数据；利用内嵌的数据分析预测软件可完成对数据的分析和预测"
      }, {
         name: "塔塔统计数据库",
         url: "https://cndata.datesdata.com.cn/",
         introduce: "其数据主要来源：国家统计局、国家海关总署、工商总局、税务总局；中国工业和信息化部、发改委、文化部、教育部、商务部等部门；中国证监会、中国保监会、中国银监会及各个行业协会；塔塔资讯自有的信息采集系统、市场调查机构、研究机构等。"
      }, {
         name: "Compustat",
         url: "http://wrds-web.wharton.upenn.edu/wrds/",
         introduce: "可提供美加地区超过24000家上市公司的详细季度和年度财务报表与财务指标的历史数据。该库收录北美及全球上市公司近20年的财务数据，另外亦提供北美回溯版，包括400家公司自1950年以来的财务资料；提供约180种模板报表、上市或下市公司财务数据等信息；并可整合最新的或历史性的主要财务数据以制作所需的报表和图表。应用的研究领域包括：资产分析、计量分析、竞争者分析、公司资本结构、财务比率、合并与购并、R&D、资本及存货投资、股市报酬、及资本市场效率等主题。"
      }, {
         name: "国家信息中心：土地市场数据库",
         url: "http://tlink.lib.tsinghua.edu.cn/go?url=http://www.crei.cn/tudi/",
         introduce: "全国及各城市土地市场监测数据，包括地块详情、土地供应与成交数据、重点城市地价数据及基准地价信息等。可查询2007年起土块招拍挂的85万条地块信息（含地块名称、位置、面积、土地类型、成交价款、溢价率、成交人、成交日期等）"
      }, {
         name: " RESSET金融研究数据库",
         url: "http://tlink.lib.tsinghua.edu.cn/go?url=http://db.resset.com/UserLogin?loginName=qhlib&loginPwd=qhlib123",
         introduce: "内容涵盖股票、债券、基金、研究报告、融资融券、宏观、行业、金融统计、外汇、期货、黄金等11大系列。包含近百个子库，包括中英文各数百张表，近两万个字段的内容，囊括了经济、金融、会计实证与投资研究所需的绝大部分数据"
      }, {
         name: "北京大学地理数据平台",
         url: "https://geodata.pku.edu.cn/",
         introduce: "是由北京大学城市与环境学院于2017年建立。平台建立的宗旨是为本院和地理专业的学生、教师以及科研人员提供全面、丰富、专业的数据信息，力图搭建一个地理数据共享交流的平台，构建从不同方向描述地球系统的数据库。数据包括自然地理、人文地理、历史地理等多个地理专业的图形图像、统计图表、文本文件及监测和模型运算得到的数据，数据范围包括全国及全球范围，数据来源于我院科研成果和积累的数据，以及平台收集整理的数据。数据平台的建设得到了学校科研部、设备部的大力支持，也得到了中国宇航学会卫星专业委员会提供的帮助。平台管理由北京大学城市与环境学院教授组成的地理数据平台小组负责，平台运行维护由人地系统与环境遥感实验室负责"
      }, {
         name: "北京大学中国调查数据资料库",
         url: "https://opendata.pku.edu.cn/dataverse/CSDA;jsessionid=4ef9c4ece41184276bf1b0a1724d",
         introduce: "成立于2015年，是国家自然科学基金-北京大学管理科学数据中心根据国内管理科学量化研究对于调查数据的需求而设立的项目。该项目的目标是收录整合国内优质调查科研数据资源，通过专业的数据管理与监护，实现便捷的数据共享，为科学研究和决策管理提供高水平的数据服务"
      }, {
         name: "中国家庭追踪调查",
         url: "https://opendata.pku.edu.cn/dataverse/CFPS;jsessionid=4ef9c4ece41184276bf1b0a1724d",
         introduce: "旨在通过跟踪收集个体、家庭、社区三个层次的数据，反映中国社会、经济、人口、教育和健康的变迁，为学术研究和公共政策分析提供数据基础"
      }, {
         name: "北京大学中国国情研究中心",
         url: "https://opendata.pku.edu.cn/dataverse/RCCC",
         introduce: "其宗旨在于发挥北京大学的人才和知识密集的优势，组织和开展多种研究项目、学术交流活动和培训工作，推进对中国社会、政治、经济发展状况的多学科综合研究。重点在于推动实证的、量化的科学方法在学术界、政策研究，以及市场调研等方面的应用。"
      }, {
         name: "EconLit",
         url: "http://nrs.harvard.edu/urn-3:hul.eresource:econlitx",
         introduce: "经济学家们几乎对任何社会干预和许多医疗干预都进行了研究"
      }, {
         name: "Violent-Flows ",
         url: "https://www.openu.ac.il/home/hassner/data/violentflows/",
         introduce: "真实世界的数据库，人群暴力视频片段以及旨在测试暴力/非暴力分类和暴力爆发检测的标准基准协议"
      }, {
         name: "ALLEN BRAIN MAP",
         url: "https://portal.brain-map.org/",
         introduce: "提供神经影像学数据"
      }, {
         name: "BBBC",
         url: "https://www.broadinstitute.org/bbbc",
         introduce: "是可自由下载的显微镜图像集的集合"
      }, {
         name: "CELL IMAGE LIBRARY",
         url: "http://www.cellimagelibrary.org/",
         introduce: "由美国NIH资助的公开免费的资源数据库，其中包含细胞的图像、视频和动画，可以捕获多种生物、细胞类型和细胞过程"
      }, {
         name: "Danbooru2020 ",
         url: "https://www.gwern.net/Danbooru",
         introduce: "一个大型动漫图像数据库"
      }, {
         name: "Digital Lunar Orbiter Photographic Atlas of the Moon",
         url: "http://www.lpi.usra.edu/resources/lunar_orbiter/",
         introduce: "月球轨道器摄影卫星阿特拉斯是一个参考手册。阿特拉斯中包含的图像可用于研究月球形态"
      }, {
         name: "EMPIAR",
         url: "http://www.ebi.ac.uk/pdbe/emdb/empiar/",
         introduce: "电子显微镜公共图像档案馆，是原始二维电子显微镜图像的公共资源"
      }, {
         name: "ESA's Sentinel",
         url: "https://scihub.copernicus.eu/dhus/#/home",
         introduce: "欧洲航天局哨兵系列卫星，以高分辨率著称"
      }, {
         name: "Face Image Project",
         url: "http://www.openu.ac.il/home/hassner/Adience/data.html",
         introduce: "提供开放的研究资源，以反映现代人脸识别和处理任务的挑战。"
      }, {
         name: "FAO GeoNetwork",
         url: "http://www.fao.org/geonetwork/srv/en/main.home",
         introduce: "一个全球地理信息系统数据集，提供农业、渔业、土地资源相关的GIS数据以及相关卫星图像数据"
      }, {
         name: "Figshare",
         url: "https://figshare.com/",
         introduce: "一个在线数据知识库，用户可以存储、分享和发现科研数据的平台"
      }, {
         name: "GDC",
         url: "https://portal.gdc.cancer.gov/",
         introduce: "GDC 数据门户是一个强大的数据驱动平台，允许癌症研究人员和生物信息学家搜索和下载癌症数据进行分析"
      }, {
         name: "Goddard Space Flight Center（戈达德太空飞行中心）",
         url: "https://www.nasa.gov/goddard",
         introduce: "哈勃望远镜和詹姆斯·韦伯空间望远镜数据。包含图片、视频、和多媒体资源三个子库"
      }, {
         name: "Google 地图",
         url: "http://ditu.google.com/",
         introduce: "截获Goole Map卫星影像、地图、地形图，能够进行海量下载，自由拼图，关键还在于能够赋予精确的投影坐标"
      }, {
         name: "HCP",
         url: "https://www.humanconnectome.org/study/hcp-young-adult",
         introduce: "包括1100多个健康年轻人的高质量神经影像数据"
      }, {
         name: "Indoor Scene Recognition",
         url: "http://web.mit.edu/torralba/www/indoor.html",
         introduce: "该数据库包含67个室内类别，共15620张图像。 图像的数量因类别而异，但每个类别至少有100张图像"
      }, {
         name: "Insect Images",
         url: "https://www.insectimages.org/",
         introduce: "昆虫图像提供昆虫学相关的高质量图像档案，数据由俄亥俄州立大学、南部森林昆虫工作会议、佛罗里达州农业和消费者服务部等共同提供。"
      }, {
         name: "Kaggle",
         url: "https://www.kaggle.com/datasets",
         introduce: "是由联合创始人、首席执行官安东尼·高德布卢姆（Anthony Goldbloom）2010年在墨尔本创立的，主要为开发商和数据科学家提供举办机器学习竞赛、托管数据库、编写和分享代码的平台。该平台已经吸引了80万名数据科学家的关注，这些用户资源或许正是吸引谷歌的主要因素"
      }, {
         name: "LILA BC",
         url: "http://lila.science/",
         introduce: "是一个与生物学和保护相关的数据集的存储库，旨在为机器学习（ML）研究人员和希望利用ML进行生物学和保护的研究人员提供资源。 "
      }, {
         name: "mendeley Data",
         url: "https://data.mendeley.com/",
         introduce: "是一个安全的基于云的存储库，您可以存储您的数据，确保无论您身在何处，都可以轻松共享、访问和引用数据"
      }, {
         name: "NOAA Digital Coast",
         url: "https://coast.noaa.gov/digitalcoast/",
         introduce: "提供沿海区域的数据，主要包括海底数据、海拔、图像、土地覆盖以及社会经济数据等，数据包括雷达、红外和真彩色影像数据等"
      }, {
         name: "OpenfMRI.org",
         url: "https://openfmri.org/",
         introduce: "是一个致力于自由共享原始磁共振成像（MRI）数据集的项目。 "
      }, {
         name: "Stanford Dogs Dataset",
         url: "http://vision.stanford.edu/aditya86/ImageNetDogs/",
         introduce: "包含来自世界各地的120种犬的图像"
      }, {
         name: "studyforrest",
         url: "https://www.studyforrest.org/",
         introduce: "包含了数小时的fMRI扫描，脑部结构扫描，眼动数据以及电影的大量注释"
      }, {
         name: "SUN",
         url: "http://groups.csail.mit.edu/vision/SUN/hierarchy.html",
         introduce: "目标是为计算机视觉，人类感知，认知和神经科学，机器学习和数据挖掘，计算机图形学和机器人技术的研究人员提供涵盖各种环境场景，地点和环境的带注释图像的全面集合"
      }, {
         name: "SVIRO",
         url: "https://sviro.kl.dfki.de/",
         introduce: "是用于车辆内部后排座椅占用率检测和分类的综合数据集"
      }, {
         name: "The Action Similarity Labeling (ASLAN) Challenge",
         url: "https://talhassner.github.io/home/projects/ASLAN/ASLAN-main.html",
         introduce: "是一个视频动作数据库和用于研究视频动作相似性的完整测试协议"
      }, {
         name: "The Animals with Attributes dataset",
         url: "https://cvml.ist.ac.at/AwA2/",
         introduce: "由 50 个动物类的 37322 张图像组成，每个图像都有预提取的特征表示"
      }, {
         name: "The Cancer Imaging Archive",
         url: "https://portal.gdc.cancer.gov/",
         introduce: "可识别并托管大量癌症医学图像的存档，以供公众下载"
      }, {
         name: "The Oxford-IIIT Pet Dataset",
         url: "https://www.robots.ox.ac.uk/~vgg/data/pets/",
         introduce: "创建了37个类别的宠物数据集，每个类别大约有200张图像。 图像在比例，姿势和照明方面有很大的差异。 所有图像均具有品种，头部ROI和像素级三图分割的相关地面真相注释"
      }, {
         name: "USGS Data",
         url: "https://earthexplorer.usgs.gov/",
         introduce: "美国地质调查局Landsat、MODIS等卫星影像下载"
      }, {
         name: "Visual Genome",
         url: "http://visualgenome.org/about",
         introduce: "视觉基因组是一个数据集，一个知识库，将结构化图像概念与语言联系在一起"
      }, {
         name: "Wilma Bainbridge",
         url: "http://wilmabainbridge.com/index.html",
         introduce: "致力于通过心理物理实验，神经影像学和绘画研究的视角来检验感知与记忆之间的桥梁"
      }, {
         name: "World Health Organization",
         url: "http://www.who.int/gho/en/",
         introduce: "探索健康数据的世界"
      }, {
         name: "YouTube Faces DB",
         url: "http://www.cs.tau.ac.il/~wolf/ytfaces/",
         introduce: "是一个人脸视频数据库，旨在研究视频中人脸识别不受约束的问题"
      }, {
         name: "癌症图像档案",
         url: "https://www.cancerimagingarchive.net/",
         introduce: "是一项可以de-identify和托管可供公众下载的大量癌症医学图像的服务"
      }, {
         name: "北京大学地理数据平台",
         url: "https://geodata.pku.edu.cn/",
         introduce: "是由北京大学城市与环境学院于2017年建立。平台建立的宗旨是为本院和地理专业的学生、教师以及科研人员提供全面、丰富、专业的数据信息，力图搭建一个地理数据共享交流的平台，构建从不同方向描述地球系统的数据库。数据包括自然地理、人文地理、历史地理等多个地理专业的图形图像、统计图表、文本文件及监测和模型运算得到的数据，数据范围包括全国及全球范围，数据来源于我院科研成果和积累的数据，以及平台收集整理的数据。数据平台的建设得到了学校科研部、设备部的大力支持，也得到了中国宇航学会卫星专业委员会提供的帮助。平台管理由北京大学城市与环境学院教授组成的地理数据平台小组负责，平台运行维护由人地系统与环境遥感实验室负责"
      }, {
         name: "地理空间数据云",
         url: "http://www.gscloud.cn/",
         introduce: "以中国科学院及国家的科学研究为主要需求，逐渐引进当今国际上不同领域内的国际数据资源，并对其进行加工、整理、集成，最终实现数据的集中式公开服务、在线计算等"
      }, {
         name: "国家综合地球观测数据共享平台",
         url: "http://www.chinageoss.org/dsp/home/index.jsp",
         introduce: "是由科技部主导，国家遥感中心牵头，各卫星中心、数据单位贡献数据、参与建设，数据主要为遥感卫星数据，涵盖主要的国内卫星和国际卫星，也包括相关科学研究数据。"
      }, {
         name: "和鲸社区",
         url: "https://www.heywhale.com/home/dataset",
         introduce: "是国内知名的开放数据科学社区,是最早一批专注于大数据算法比赛的平台,支持在线分享代码、项目和数据集"
      }, {
         name: "加州理工学院行人数据集",
         url: "http://www.vision.caltech.edu/Image_Datasets/CaltechPedestrians/",
         introduce: "包括大约 10 小时的 640x480 30Hz 视频，这些视频取自在城市环境中通过正常交通行驶的车辆"
      }, {
         name: "数据堂",
         url: "https://www.datatang.com/",
         introduce: "专注于互联网综合数据交易，提供数据交易、处理和数据API服务，包含语音识别、医疗健康、交通地理、电子商务、社交网络、图像识别等方面的数据。"
      }, {
         name: "中国科学院遥感与数字地球研究所",
         url: "http://eds.ceode.ac.cn/sjglb/dataservice.htm",
         introduce: "提供多种标准景卫星遥感数据产品免费下载服务"
      }, {
         name: "中国植物图像库",
         url: "http://www.plantphoto.cn/",
         introduce: "已收录植物图片482科4582属28220种4001809幅"
      }, {
         name: "Medical segmentation decathlon (MSD)",
         url: "http://medicaldecathlon.com/index.html",
         introduce: "Simpson 等人创建了各种解剖部件的大型开源，手工注释的医学图像数据集"
      }, {
         name: "JSRT",
         url: "http://db.jsrt.or.jp/eng-01.php",
         introduce: "JSRT致力于放射科学和技术的发展，推动了从日本到世界其他地区的发展和进步。放射技术的学术范围与医学、工程学和物理学密切相关，因此放射技术的发展需要广阔的视野。"
      }, {
         name: "Graviti",
         url: "https://gas.graviti.cn/open-datasets",
         introduce: "提供面向机器学习的数据管理 SaaS 产品和构建以 AI 开发者为核心的开源数据集社区，降低运用机器学习创新的团队和个人在AI开发过程中因处理非结构化数据带来的不断增长的数据获取、存储和处理成本，加速AI开发流程，提供坚实的人工智能基础设施"
      }, {
         name: "ISLES",
         url: "http://www.isles-challenge.org/",
         introduce: "缺血性中风病变分割"
      }, {
         name: "LiTS",
         url: "https://competitions.codalab.org/competitions/17094",
         introduce: "这些数据和细分由世界各地的不同临床站点提供，用于肝肿瘤和肝肿瘤的分割"
      }, {
         name: "PROMISE12",
         url: "https://promise12.grand-challenge.org/",
         introduce: "此数据集用于前列腺分割。这些数据包括良性疾病（如良性前列腺增生）和前列腺癌患者"
      }, {
         name: "LIDC-IDRI",
         url: "https://wiki.cancerimagingarchive.net/display/Public/LIDC-IDRI",
         introduce: "包括诊断和肺癌筛查胸腔计算断层扫描 （CT） 扫描与标记注释病变。它是一个可上网的国际资源，用于开发、培训和评估用于肺癌检测和诊断的计算机辅助诊断 （CAD） 方法"
      }, {
         name: "OASIS",
         url: "https://www.oasis-brains.org/",
         introduce: "是一个旨在使大脑的神经成像数据集免费提供给科学界的项目。通过编制和自由分发神经成像数据集，希望促进基础和临床神经科学的未来发现"
      }, {
         name: "Sunnybrook cardiac data",
         url: "https://hyper.ai/datasets/5413",
         introduce: "又称 2009 年心脏 MR 左心室分割挑战数据集，被用于心脏病研究，它由来自患者和病例样本的 45 个影像组成，分别为健康、肥大、心肌梗塞和心脏衰竭"
      }, {
         name: " MIAS",
         url: "https://www.repository.cam.ac.uk/",
         introduce: "是一个乳腺癌X射线图像数据库，由英国一个研究机构于1995年创建。"
      }, {
         name: "BRATS",
         url: "https://www.med.upenn.edu/sbia/brats2018/data.html",
         introduce: "此数据集是脑肿瘤分割竞争数据集，与 MICCAI 会议相结合。为了评估最好的脑肿瘤分割方法和比较不同的方法，自2012年以来每年举行一次。"
      }, {
         name: "DDSM",
         url: "http://www.eng.usf.edu/cvprg/Mammography/Database.html",
         introduce: "是乳腺造影图像分析研究界使用的资源，被研究人员广泛使用"
      }, {
         name: "DRIVE",
         url: "https://drive.grand-challenge.org/",
         introduce: "此数据集用于比较视网膜图像中血管的分割"
      }, {
         name: "HVSMR2018 ",
         url: "http://segchd.csail.mit.edu/",
         introduce: "MICCAI 先天性心脏病 3D 心血管 MRI 全心和大血管分割研讨会"
      }, {
         name: "SKI10",
         url: "http://www.ski10.org/",
         introduce: "SKI10 的目标是比较膝盖 MRI 数据中软骨和骨细分的不同算法。膝软骨分割是近年来相当重要的临床相关分割问题。"
      }, {
         name: "CHASEDB1",
         url: "https://blogs.kingston.ac.uk/retinal/chasedb1/",
         introduce: "视网膜图像数据库"
      }, {
         name: "STARE",
         url: "http://homes.esat.kuleuven.be/~mblaschk/projects/retina/",
         introduce: ""
      }, {
         name: "NASA Reverb ",
         url: "http://reverb.echo.nasa.gov/reverb/",
         introduce: "美国航空航天局，此地数据源来自业界宗师"
      }, {
         name: "交通科技信息资源",
         url: "http://www.transst.cn/PlatForm/Resource.aspx",
         introduce: "交通运输部科学研究院建设信息资源共享平台"
      }, {
         name: "数据堂",
         url: "https://www.datatang.com/",
         introduce: "专注于互联网综合数据交易，提供数据交易、处理和数据API服务，包含语音识别、医疗健康、交通地理、电子商务、社交网络、图像识别等方面的数据。"
      }, {
         name: "猫眼专业版",
         url: "http://piaofang.maoyan.com/dashboard",
         introduce: "电影票房统计分析平台，猫眼专业版有实时的票房统计，影片的排盘情况、上座率和影院数据，对于当前电影的分析是必不可少的"
      }, {
         name: "中国产业信息",
         url: "https://www.chyxx.com/",
         introduce: "工业和信息化部主管,人民邮电报社主办,是我国通信行业唯一拥有国务院新闻办授予新闻发布权的新闻网站。"
      }, {
         name: "国家材料科学数据共享",
         url: "http://www.materdata.cn/",
         introduce: "涵盖逾3000种钢铁材料及材料基础的高质量数据近11万条"
      }, {
         name: "FAOSTAT",
         url: "http://www.fao.org/faostat/en/#home",
         introduce: "提供1961年到现在的关于农业、营养、渔业、林业和粮食援助的时间序列数据，是一个多语言数据库，可以搜索、浏览和下载数据。"
      }, {
         name: "国家林业和草原科学数据中心",
         url: "http://www.forestdata.cn/",
         introduce: "林业数据中心整合了森林资源、草原资源、湿地资源、荒漠化资源、自然保护资源、林业生态环境资源、森林保护、森林培育、木材科学与技术、林业科技文献、林业科技项目及林业行业发展等科学数据，以提供数据共享服务。"
      }, {
         name: "中经网产业数据库",
         url: "https://cyk.cei.cn/",
         introduce: "是国家信息中心中经网精心打造的、覆盖机械、汽车、电子通讯、医药、石油化工、能源、交通、房地产、钢铁、旅游等10多个产业集群的全新数据库产品。"
      }, {
         name: "Databank of Official Statistics on Quebec ",
         url: "http://www.bdso.gouv.qc.ca/",
         introduce: "包括农业和生物食品产业、文化与传播、经济、教育、培训和儿童保育、能量、环境、金融与非金融合作、林业、健康、福利、卫生和社会服务、劳动补偿、生活条件与社会、制造业、采矿业、市政事务、人口、科学技术创新、可持续发展、旅游、交通、用户和基础设施等多方面的统计数据和信息"
      }, {
         name: "Aquastat",
         url: "http://www.fao.org/nr/water/aquastat/main/index.stm",
         introduce: "粮农组织的全球水信息系统，提供水资源、水利用、灌溉和排水、废水处理等各个主题的数据"
      }, {
         name: "Agency for Healthcare Research and Quality, Data & Surveys",
         url: "http://www.ahrq.gov/research/data/index.html",
         introduce: "包含美国健康信息知识库(USHIK)、系统评价数据存储库(SRDR)以及有关医疗和医疗保险的成本、质量、可访问性和评估等其他信息，帮助人们做出更加明智的决策，提高卫生保健服务的质量。"
      }, {
         name: "American National Election Studies",
         url: "http://www.electionstudies.org/",
         introduce: "美国国家选举研究(ANES)进行全国调查和试点研究，提供各种类型的数据集"
      }, {
         name: "The China Biographical Database",
         url: "https://projects.iq.harvard.edu/cbdb",
         introduce: "其中包含截至2020年11月（主要是从7世纪到19世纪）约471,000个人的传记信息"
      }, {
         name: "mendeley Data",
         url: "https://data.mendeley.com/",
         introduce: "是一个安全的基于云的存储库，您可以存储您的数据，确保无论您身在何处，都可以轻松共享、访问和引用数据"
      }, {
         name: "维基百科语料",
         url: "https://yq.aliyun.com/go/articleRenderRedirect?url=https%3A%2F%2Fcorpus.byu.edu%2Fwiki%2F",
         introduce: "它包含来自400多万篇文章的将近19亿字。这个强大的NLP数据集你可以通过单词，短语或段落本身的一部分进行搜索"
      }, {
         name: "中国互联网信息中心",
         url: "http://www.cnnic.cn/",
         introduce: "主要包括互联网发展相关基础数据，相对第三方机构的互联网数据而言，数据更宏观且权威。"
      }, {
         name: "植物表型数据集",
         url: "http://www.plant-phenotyping.org/datasets-home",
         introduce: "适用于植物和叶片检测，分割，跟踪和物种识别的植物数据"
      }, {
         name: "History of Marine Animal Populations",
         url: "https://hydra.hull.ac.uk/resources?utf8=%E2%9C%93&search_field=all_fields&q=hmap",
         introduce: "HMAP数据页面向研究者提供渔业捕获量随时间空间的变化数据，旨在揭示生态环境动态改变、人类活动对生态环境的影响、海洋资源在人类社会发展过程中的作用。"
      }, {
         name: "阿里云天池",
         url: "https://tianchi.aliyun.com/dataset/",
         introduce: "阿里旗下数据科学竞赛平台"
      }, {
         name: "PolData",
         url: "https://link.zhihu.com/?target=https%3A//github.com/erikgahner/PolData",
         introduce: "这是一个政治数据库的集合。这些数据包括：内阁、公民、宪法、政治机构、政党和政治家、民主、经济、选举、国际关系、媒体、政策等。所有数据都在网页中按列表展示，一目了然，非常便捷。"
      }, {
         name: "STR",
         url: "https://link.zhihu.com/?target=http%3A//ki.se/en/research/for-researchers",
         introduce: "来自瑞典卡罗林斯卡学院，其包含了双生子信息数据的注册和研究"
      }, {
         name: "社会网与求职调查数据库",
         url: "http://css.sysu.edu.cn/Data",
         introduce: "中国大城市社会网与求职调查是由美国明尼苏达大学边燕杰教授发起，在中国八大城市（广州、上海、西安、天津、兰州、厦门、济南、长春）开展的一项调查，其广州地区的调查由中山大学社会学与社会工作系梁玉成副教授主持，得到中山大学社会科学调查中心资助。"
      }, {
         name: "中国人民大学社会学系",
         url: "http://www.sachina.edu.cn/modules/datacenter/viewdata.php?channelid=5&projid=13&dataid=119",
         introduce: "主要研究大学生在校行为与未来发展的关系"
      }, {
         name: "中国电信股份有限公司北京研究院",
         url: "http://www.ctbigdata.cn/BackgroundInvestigation/",
         introduce: "个人信用背景调查、画像分析"
      }, {
         name: "Global Carbon Budget",
         url: "https://www.icos-cp.eu/global-carbon-budget-2019",
         introduce: "数据库详细描述了数据库的所有数据集和模型结果，将对全球碳预算做出贡献的所有数据集集成在两个Excel文件中，一个为全球碳预算，一个为国家层面排放清单"
      }, {
         name: "二氧化碳信息分析中心",
         url: "https://doi.org/10.3334/CDIAC/00001_V2017",
         introduce: "提供的化石燃料二氧化碳排放数据形式丰富，包括了多尺度年度时间序列、全球碳预算（2016年之前）、全球历史数据、网格数据、高频数据等各种类型"
      }, {
         name: "英国石油公司",
         url: "https://www.bp.com/en/global/corporate/energy-economics/statistical-review-of-world-energy.html",
         introduce: "每年发布的报告不仅对世界能源相关形势进行深入分析，同时公开其收集且使用各类能源相关数据包括排放数据"
      }, {
         name: "AMPds ",
         url: "http://ampds.org/",
         introduce: "包含电力、水和天然气测量数据"
      }, {
         name: "COMBED",
         url: "http://combed.github.io/",
         introduce: "COMBED 是来自商业建筑的第一个与能源相关的数据集"
      }, {
         name: "ORBIS AMERICA",
         url: "http://210.32.137.90/s/lib/libtb/turning/279",
         introduce: "是一个包含了美洲地区1亿家企业的财务、管理层、董监高管、股权关系、评级报告、原始财务报表、并购记录和行业信息的大型企业分析库。"
      }, {
         name: "BYU语料库",
         url: "http://210.32.137.90/s/lib/libtb/turning/1611",
         introduce: "由Brigham Young University（杨百翰大学）语言学教授Mark Davies创立，每月使用人数超过13万人，已成为目前使用最广泛的语料库之一"
      }, {
         name: "LDC语料库",
         url: "http://210.32.137.90/s/lib/libtb/turning/1545",
         introduce: "包括阿拉伯语、汉语和英语新闻文本、布朗语料库全文、来自交换机与费舍库（Fisher Collection）数以百万计的英语电话语音以及美国英语口语词汇"
      }, {
         name: "Metropolitan Travel Survey Archive",
         url: "http://www.surveyarchive.org/",
         introduce: "通过互联网进行美国都市圈、州际间以及小地区的旅行调查，并存储相关的调研数据"
      }, {
         name: "HJ Andrews Experimental Forest ",
         url: "http://andrewsforest.oregonstate.edu/",
         introduce: "HJ安德鲁斯成立于1948年，位于俄勒冈州卡斯卡德山脉西部，占地16000英亩，作为生态研究基地提供关于森林，溪流及流域的相关信息，促进生态系统与科学，教育，自然资源管理和人文科学之间的密切合作"
      }, {
         name: "ACLED",
         url: "https://link.zhihu.com/?target=https%3A//acleddata.com/%23/dashboard",
         introduce: "全球武装冲突地点和事件数据"
      }, {
         name: "Global Terrorism Database",
         url: "https://link.zhihu.com/?target=https%3A//www.start.umd.edu/gtd/",
         introduce: "迄今为止全球恐怖主义研究领域涵盖恐怖主义事件最为全面的数据库，收录了1970-2017年（每年更新）超过17万条恐怖主义袭击事件相关数据，并且针对每起恐怖袭击事件都有近135个变量来记录其相关情况"
      }, {
         name: "数字丝路地球大数据平台",
         url: "http://www.opendbar.cn/",
         introduce: "该平台以“数字连接，共享繁荣”为主旨，包括“一带一路”资源、环境、气候、灾害、遗产等专题数据集94套、自主知识产权数据产品57类"
      }, {
         name: "全球研究标识符数据库",
         url: "https://www.grid.ac/",
         introduce: "是一个可供公众访问的全球教育和研究组织数据库"
      }, {
         name: "5118",
         url: "https://link.zhihu.com/?target=https%3A//www.5118.com/",
         introduce: "支持查询网站排名及发展趋势、百度收录情况等信息"
      }, {
         name: "百度指数",
         url: "https://link.zhihu.com/?target=http%3A//index.baidu.com/v2/index.html%23/",
         introduce: "百度旗下/以百度网民行为数据为基础的数据分享平台，支持查询需求图谱"
      }, {
         name: "移动观象台",
         url: "https://link.zhihu.com/?target=http%3A//mi.talkingdata.com/",
         introduce: "基于移动设备用户的操作行为，提供应用、公众号排行等，以及大量数据报告"
      }, {
         name: "中研网数据",
         url: "https://link.zhihu.com/?target=http%3A//www.chinairn.com/data/",
         introduce: "提供医疗、房产、制造业、服务业、零售消费、车辆等全行业数据"
      }, {
         name: "CBO中国票房",
         url: "https://link.zhihu.com/?target=http%3A//www.cbooo.cn/",
         introduce: "基于国内票房数据，统计票房排行、上座率等信息"
      }, {
         name: "易车指数",
         url: "https://link.zhihu.com/?target=http%3A//index.bitauto.com/login",
         introduce: "反映国内汽车销售市场，为购车者或汽车从业者提供参考的数据指数"
      }, {
         name: "房天下",
         url: "https://link.zhihu.com/?target=https%3A//fdc.fang.com/index/",
         introduce: "提供中国指数研究院和CREIS中指数据，展示国内房地产数据情况"
      }, {
         name: "优易数据",
         url: "https://www.youedata.com/",
         introduce: "拥有国家级信息资源的数据平台。包含政务、社会、社交、教育、消费、交通、能源、金融、健康等多个领域的数据资源"
      }, {
         name: "HotspotQA数据集",
         url: " https://hotpotqa.github.io/",
         introduce: "具有自然、多跳问题的问答数据集，具有支持事实的强大监督，以实现更易于解释的问答系统"
      }, {
         name: "安然数据集",
         url: "https://link.zhihu.com/?target=https%3A//www.cs.cmu.edu/~./enron/",
         introduce: "来自安然高级管理层的电子邮件数据"
      }, {
         name: "Gutenberg电子书列表",
         url: "https://link.zhihu.com/?target=http%3A//www.gutenberg.org/wiki/Gutenberg%3AOffline_Catalogs",
         introduce: "Gutenberg项目中带注释的电子书书单"
      }, {
         name: " Hansards加拿大议会文本",
         url: "https://link.zhihu.com/?target=http%3A//www.isi.edu/natural-language/download/hansard/",
         introduce: "来自第36届加拿大议会记录的130万组文本"
      }, {
         name: "eopardy",
         url: " http://www.reddit.com/r/datasets/comments/1uyd0t/200000_jeopardy_questions_in_a_json_file/",
         introduce: "来自问答节目Jeopardy的超过200,000个问题的归档"
      }, {
         name: "英文垃圾短信收集",
         url: "https://link.zhihu.com/?target=http%3A//www.dt.fee.unicamp.br/~tiago/smsspamcollection/",
         introduce: "由5574条英文垃圾短信组成的数据集"
      }, {
         name: "UCI的Spambase",
         url: "https://link.zhihu.com/?target=https%3A//archive.ics.uci.edu/ml/datasets/Spambase",
         introduce: "一个大型垃圾邮件数据集，对垃圾邮件过滤非常有用"
      }, {
         name: "Sentiment140",
         url: "https://yq.aliyun.com/go/articleRenderRedirect?url=http%3A%2F%2Fhelp.sentiment140.com%2Ffor-students%2F",
         introduce: "一个可用于情感分析的数据集"
      }, {
         name: "维基百科语料库",
         url: "https://yq.aliyun.com/go/articleRenderRedirect?url=https%3A%2F%2Fcorpus.byu.edu%2Fwiki%2F",
         introduce: "该数据集是维基百科全文的集合。它包含来自400多万篇文章的将近19亿字。这个强大的NLP数据集你可以通过单词，短语或段落本身的一部分进行搜索"
      }, {
         name: "The GDELT Project ",
         url: "https://www.gdeltproject.org/data.html",
         introduce: "是有史以来建立的最大，最全面，最高分辨率的人类社会开放数据库。 仅2015年的数据就记录了近四千亿兆的情感快照和超过15亿的位置引用，而其总存档时间超过215年，使其成为现有的最大的开放式时空数据集之一，并推动了 全球人类社会“大数据”研究的边界。 它的全球知识图谱将全世界的人们，组织，位置，主题，数量，图像和情感连接到整个星球上的单个整体网络中"
      }, {
         name: "GSS",
         url: "http://gss.norc.org/",
         introduce: "为政治家、决策者和学者提供了清晰和公正的观点，了解美国人对国家支出优先事项、犯罪和惩罚、群体间关系和对机构的信心等问题的看法和感受。"
      }, {
         name: "GLOBAL RELIGIOUS FUTURS",
         url: "http://www.globalreligiousfutures.org/",
         introduce: "探索到2050年世界迅速变化的宗教形象"
      }, {
         name: "INED",
         url: "https://www.ined.fr/en/",
         introduce: "是一家专门从事人口研究的公共研究所，与国家和国际一级的学术界和研究界合作"
      }, {
         name: "TRAC",
         url: "https://www.trackingterrorism.org/",
         introduce: "是世界上最全面的恐怖组织和活动信息和分析简编之一"
      }, {
         name: "Materials Projec",
         url: "https://materialsproject.org/",
         introduce: "利用超级计算的力量和先进的电子结构方法，Materials Project提供了基于Web的开放式访问，可以访问已知和预测的材料上的计算信息，还可以使用强大的分析工具来激发和设计新颖的材料。"
      }, {
         name: "北京大学地理数据平台",
         url: "https://geodata.pku.edu.cn/",
         introduce: "是由北京大学城市与环境学院于2017年建立。平台建立的宗旨是为本院和地理专业的学生、教师以及科研人员提供全面、丰富、专业的数据信息，力图搭建一个地理数据共享交流的平台，构建从不同方向描述地球系统的数据库。数据包括自然地理、人文地理、历史地理等多个地理专业的图形图像、统计图表、文本文件及监测和模型运算得到的数据，数据范围包括全国及全球范围，数据来源于我院科研成果和积累的数据，以及平台收集整理的数据。数据平台的建设得到了学校科研部、设备部的大力支持，也得到了中国宇航学会卫星专业委员会提供的帮助。平台管理由北京大学城市与环境学院教授组成的地理数据平台小组负责，平台运行维护由人地系统与环境遥感实验室负责"
      }, {
         name: "北京大学中国调查数据资料库",
         url: "https://opendata.pku.edu.cn/dataverse/CSDA;jsessionid=4ef9c4ece41184276bf1b0a1724d",
         introduce: "成立于2015年，是国家自然科学基金-北京大学管理科学数据中心根据国内管理科学量化研究对于调查数据的需求而设立的项目。该项目的目标是收录整合国内优质调查科研数据资源，通过专业的数据管理与监护，实现便捷的数据共享，为科学研究和决策管理提供高水平的数据服务"
      }, {
         name: "综合型语言知识库",
         url: "https://opendata.pku.edu.cn/dataverse/clkb",
         introduce: "属于文理结合的\"中国语言文字信息处理\"学科。语言信息处理旨在让机器理解和运用人类语言。我国语言信息处理的上规模研究始于20世纪80年代中期，当时国内外汉语语言基础资源几乎是空白。与英语、日语不同，汉语缺乏形式标记，汉语语言知识库的建设尤显迫切和艰巨。基于对母语知识与文化的认知优势，项目组从1986年开始研究汉语计算模型和语言知识形式化描述方法，并构建语言知识库。历时20余年，建成综合型语言知识库，有力地支持了中文信息处理的原创性科学研究和应用技术开发。"
      }, {
         name: "北京大学可视化与可视分析研究",
         url: "https://opendata.pku.edu.cn/dataverse/vis",
         introduce: "主要从事科学可视化、信息可视化与可视分析的相关研究"
      }, {
         name: "北京大学数据与信息管理研究组",
         url: "https://opendata.pku.edu.cn/dataverse/DAIM",
         introduce: "隶属于北京大学信息学院网络与信息系统研究所，负责人为崔斌研究员。主要研究方向包括面向互联网应用的大规模数据库管理系统、社交网络和社会化媒体的数据管理与挖掘等。我们的研究受核高基、国家自然科学基金、863项目以及微软亚洲研究院等资助和支持。欢迎访问研究组主页：http://daim.pku.edu.cn。 主要提供：社交网络、社会化媒体、多媒体数据、存储日志等"
      }, {
         name: "北京大学中国古代史研究中心",
         url: "https://opendata.pku.edu.cn/dataverse/crach",
         introduce: "中国历代人物传记资料库"
      }, {
         name: "北京大学软件与微电子学院自然语言处理研究组",
         url: "https://opendata.pku.edu.cn/dataverse/SSNLP",
         introduce: "双语句子与文章"
      }, {
         name: "科学评价与大数据应用实验室",
         url: "https://opendata.pku.edu.cn/dataverse/scie",
         introduce: "学科数据"
      }, {
         name: "全国高校数据驱动创新研究大赛",
         url: "https://opendata.pku.edu.cn/dataverse/contest",
         introduce: "由国家信息中心大数据发展部、北京市信息资源管理中心作为行业指导单位，北京大学图书馆、北京大学信息管理系、北京大学中国社会科学调查中心、重庆西部大数据前沿应用研究院主办，高等教育文献保障系统、重庆大学图书馆协办，面向高校、研究院（所）在读学生，开展数据驱动创新研究大赛"
      }]
   },
   {
      id: 1,
      name: '物理',
      list: [{
         name: "国家高能物理主题数据库",
         url: "http://www.hep.nsdc.cn/",
         introduce: "宇宙线观测数据库、高能天体物理数据库、核分析数据库和中微子实验数据库"
      }, {
         name: "国家极地科学数据中心",
         url: "https://www.chinare.org.cn/",
         introduce: "主要开展极地雪冰-海洋与全球变化、极区电离层-磁层耦合与空间天气、极地生态环境及其生命过程以及极地科学基础平台技术等领域的研究"
      }, {
         name: "国家青藏高原科学数据中心",
         url: "https://data.tpdc.ac.cn/zh-hans/",
         introduce: "数据中心以青藏高原及周边地区各类科学数据为主，已整合的数据资源包括：大气、冰冻圈、水文、生态、地质、地球物理、自然资源、基础地理、社会经济等，开发了在线大数据分析、模型应用等功能，实现青藏高原科学数据、方法、模型与服务的广泛集成。"
      }, {
         name: "全球科研项目数据库",
         url: "http://project.llas.ac.cn/",
         introduce: "汇聚了全球主要发达国家重要基金组织2000年以来的科研项目数据200多万条"
      }, {
         name: "国家空间科学数据中心",
         url: "https://www.nssdc.ac.cn/mhsy/html/index.html",
         introduce: "数据中心以中科院国家空间科学中心为依托单位，联合中科院国家天文台（探月工程中心）、中国科学技术大学、中科院国家授时中心和中科院计算机网络信息中心等单位共同建设和运行"
      }, {
         name: "UCI数据集",
         url: "https://link.zhihu.com/?target=https%3A//archive.ics.uci.edu/ml/index.php",
         introduce: "是加州大学欧文分校(University of CaliforniaIrvine)提出的用于机器学习的数据库，这个数据库目前共有559个数据集，其数目还在不断增加"
      }, {
         name: "NASA Reverb ",
         url: "http://reverb.echo.nasa.gov/reverb/",
         introduce: "美国航空航天局，此地数据源来自业界宗师"
      }, {
         name: "中国科技共享网",
         url: "https://escience.org.cn/",
         introduce: "涉及到地理学、气象学、微生物学、基因组学、医学、农学、物理学等多个学科"
      }, {
         name: "欧洲核子研究",
         url: "http://opendata.cern.ch/",
         introduce: "是世界上最大型的粒子物理学实验室，也是万维网的发源地"
      }, {
         name: "Meter CO Survey Dataverse",
         url: "https://dataverse.harvard.edu/dataverse/rtdc",
         introduce: "提供来自SMA和CfA毫米波望远镜的数据  "
      }, {
         name: "Advanced Spaceborne Thermal Emission and Reflection Radiometer Ground Data Systems",
         url: "http://www.jspacesystems.or.jp/en_project_aster/",
         introduce: "主要包括ASTER仪器，ASTER地面数据系统，ASTER科学活动，ASTER数据分布等数据"
      }, {
         name: "Alaska Satellite Facility SAR Data Center",
         url: "https://www.asf.alaska.edu/",
         introduce: "ERS-1，ERS-2，JERS-1，RADARSAT-1和PALSAR ALOS的数据"
      }, {
         name: "An Atomic Database for Spectroscopic Diagnostics of Astrophysical Plasmas",
         url: "http://www.chiantidatabase.org/",
         introduce: "CHIANTI包括一组经过严格评估的最新原子数据，以及以交互数据语言（IDL）和Python编写的用户友好程序，用于计算天体物理等离子体的光谱  "
      }, {
         name: "Astronomical Data Archives Center",
         url: "http://dbc.nao.ac.jp/",
         introduce: "包括在线数据目录，期刊档案，成像服务和数据档案。通过请求密码访问可访问VizieR目录服务以及Hubble超深度字段数据。还提供通过夏威夷斯巴鲁望远镜获得的SMOKA公共科学数据以及东京大学和MITSuME的Schmidt望远镜和东广岛天文台的KANATA望远镜产生的数据。"
      }, {
         name: "ATNF Pulsar Catalogue",
         url: "http://www.atnf.csiro.au/research/pulsar/psrcat/",
         introduce: "Parkes无线电望远镜获得的观测资料"
      }, {
         name: "Barbara A. Mikulski Archive for Space Telescopes（MAST，空间望远镜档案）",
         url: "http://archive.stsci.edu/contacts.html",
         introduce: "侧重于光学，紫外和近红外部分的科学相关数据集"
      }, {
         name: "British Geological Survey（英国地质调查局）",
         url: "http://www.bgs.ac.uk/",
         introduce: "是世界上最古老的国家地质调查，有超过400个数据集，包括环境监测数据，数字数据库，物理收集（钻孔核心，岩石，矿物和化石）的记录和档案。"
      }, {
         name: "Clouds and the Earth's Radiant Energy System(云和地球的辐射能量系统)",
         url: "http://ceres.larc.nasa.gov/index.php",
         introduce: "云和地球辐射能量系统（CERES）是地球观测系统（EOS）计划的关键组成部分。 CERES仪器从三个宽带通道提供地球大气的辐射测量。 CERES产品包括从大气顶部到地球表面的太阳能反射和地球发射的辐射。"
      }, {
         name: "Cornell University Library Astrophysics",
         url: "https://arxiv.org/archive/astro-ph",
         introduce: "主要包括：GA - Astrophysics of Galaxies、Cosmology and Nongalactic Astrophysics、Earth and Planetary Astrophysics、High Energy Astrophysical Phenomena、Instrumentation and Methods for Astrophysics、Solar and Stellar Astrophysics等观测数据。"
      }, {
         name: "Geostationary Operational Environmental Satellites SEM",
         url: "https://www.ngdc.noaa.gov/stp/satellite/goes/",
         introduce: "GOES卫星携带一个空间环境监测子系统，用于测量航天器的X射线，能量粒子和磁场"
      }, {
         name: "GISS Surface Temperature Analysis(GISTEMP，GISS表面温度分析)",
         url: "http://data.giss.nasa.gov/gistemp/",
         introduce: "GISS的计划领域大致可以分为气候强迫类别、 气候模式发展、 地球观测、 大气辐射、 大气化学、 气候影响、 行星大气层，系外行星和天体生物学、 古气候 和其他学科。"
      }, {
         name: "GOES Space Environment Monitor(GOES空间环境监测)",
         url: "http://www.ngdc.noaa.gov/stp/satellite/goes/",
         introduce: "GOES空间环境监测档案是国家空间气象计划的重要组成部分 - 提供及时可靠的空间环境观测和预报机构间方案。 GOES卫星载有一个空间环境监测子系统，用于测量航天器的X射线，能量粒子和磁场。"
      }, {
         name: "High Energy Astrophysics Science Archive Research Center（heasarc，高能天体物理学研究中心科学数据库）",
         url: "http://heasarc.gsfc.nasa.gov/",
         introduce: "高能天体研究数据"
      }, {
         name: "HyperLeda（星系物理数据库）",
         url: "http://leda.univ-lyon1.fr/",
         introduce: "Database for physics of galaxies。包含超过300万个对象。包含LEDA: The richest catalogue of homogeneous parameters of galaxies for the largest available sample子集"
      }, {
         name: "IAU Minor Planet Center （MPC，国际天文学联合会小行星中心）",
         url: "http://www.minorplanetcenter.net/",
         introduce: "接收和分配主要行星、彗星和主要行星外部不规则自然卫星位置测量等数据。"
      }, {
         name: "Infrared Processing and Analysis Center（IPAC，美国 NASA 红外处理分析中心）",
         url: "http://www.ipac.caltech.edu/",
         introduce: "美国 NASA 红外处理分析中心（IPAC）天文学和太阳系科学任务、数据存档，包括红外亚毫米天文学和外行星科学等数据。旗下NED、IRSA、KOA、NASA Explanet Archive等多个子集。"
      }, {
         name: "International Gamma-Ray Astrophysics Laboratory data Archives",
         url: "https://www.cosmos.esa.int/web/integral/home",
         introduce: "ISDC数据、OMC数据等"
      }, {
         name: "ISAS Data Archive and Transmission System",
         url: "http://www.darts.isas.jaxa.jp/",
         introduce: "JAXA的空间科学任务的天体物理（X射线、无线电、红外线）、太阳物理、太阳物理、和月球与行星科学的相关数据"
      }, {
         name: "KASCADE Cosmic Ray Data Centre",
         url: "https://kcdc.ikp.kit.edu/",
         introduce: "KASCADE实验数据，高能量天体粒子物理学"
      }, {
         name: "NOAA: National Geophysical Data Center",
         url: "http://www.ngdc.noaa.gov/",
         introduce: "NGDC为地球和地球的海底和地球的地球物理数据提供管理、产品和服务，包括来自太空的地球观测"
      }, {
         name: "GEOSS Portal",
         url: "https://www.geoportal.org/?m%3AactiveLayerTileId=osm&f%3AdataSource=dab",
         introduce: "收集全球范围内机构的公开的地球观测数据，并提供给用户免费使用"
      }, {
         name: "NASA/IPAC Extragalactic Database",
         url: "http://ned.ipac.caltech.edu/",
         introduce: "美国河外天体多波段数据库。内容包括不同天体的主索引，多波长交叉识别、协会、位置、红移，红移测光，独立的距离，直径，图像，光谱，并有详细的注释。导出量包括银河灭绝，速度修正，哈勃流的距离尺度上，宇宙的修正，快看光度和光谱能量分布（SED）。"
      }, {
         name: "National Solar Observatory",
         url: "https://www.nso.edu/",
         introduce: "美国太阳天文台数据集，专门观测太阳的数据。数据不多"
      }, {
         name: "RAVE database",
         url: "https://www.rave-survey.org/project/",
         introduce: "英澳天文台的1.2米UK施密特望远镜（AAO）在银河系恒星的多光纤光谱天文测量数据"
      }, {
         name: "Sloan Digital Sky Survey",
         url: "http://www.sdss.org/",
         introduce: "包含超过93万个星系和超过12万个类星体的数据"
      }, {
         name: "Spitzer Science Archive",
         url: "http://ssc.spitzer.caltech.edu/",
         introduce: "包含四个轨道观测站的数据，每个观测站用不同种类的光（可见光，γ射线，X射线和红外线）观测宇宙"
      }, {
         name: "SPT Galaxy Cluster Followup Dataverse",
         url: "https://dataverse.harvard.edu/dataverse/SPT_Clusters",
         introduce: "南极望远镜SZ调查中发现的星系团。包括11A-0034（PI：Christopher Stubbs）的低至中等红移星系的GMOS光谱"
      }, {
         name: "The Canadian Astronomy Data Centre",
         url: "http://www3.cadc-ccda.hia-iha.nrc-cnrc.gc.ca/en/",
         introduce: "加拿大的天文数据中心"
      }, {
         name: "THEMIS（Time History of Events and Macroscale Interactions During Substorms",
         url: "http://themis.ssl.berkeley.edu/index.shtml",
         introduce: "与北美大陆20个地面观测站的极光观测相结合的卫星数据"
      }, {
         name: "UK Solar System Data Centre",
         url: "http://www.ukssdc.ac.uk/",
         introduce: "支持整个英国太阳能研究的数据存档，包括太阳能，行星间，磁层，电离层和地磁科学"
      }, {
         name: "WDC Sunspot Index and Long-term Solar Observations",
         url: "http://sidc.be/silso/",
         introduce: "SILSO是世界数据中心，用于生产，保存和传播国际太阳黑子数量"
      }, {
         name: "World Data Center for Remote Sensing of the Atmosphere",
         url: "http://wdc.dlr.de/",
         introduce: "世界大气遥感数据中心WDC-RSAT为科学家和公众免费提供（以“一站式服务”的意义）获取不断增长的大气相关卫星数据集（从原始数据到增值数据），信息产品和服务。重点是大气痕量气体，气溶胶，动力学，辐射和云物理参数。还提供了有关地表参数（如植被指数，地表温度）的补充信息和数据。"
      }, {
         name: "World Data Center for Solid Earth Physics",
         url: "http://www.wdcb.ru/sep/",
         introduce: "固体地球物理世界数据中心收集、存储和传播固体地球物理学科的大量数据：地震学、重力测量、热流、磁测量（主磁场）、考古和古磁学等，这些数据被用作基础和应用科学研究和教育的基础"
      }, {
         name: "SpringerMaterials",
         url: "https://materials.springer.com/",
         introduce: "内容涵盖物理化学和工程科学的各个领域"
      }, {
         name: "mendeley Data",
         url: "https://data.mendeley.com/",
         introduce: "是一个安全的基于云的存储库，您可以存储您的数据，确保无论您身在何处，都可以轻松共享、访问和引用数据"
      }, {
         name: "NIST Chemistry WebBook",
         url: "https://www.nist.gov/",
         introduce: "包括原子和分子物理、化学和晶体结构、流体、材料特性、生物技术、光学字符识别等"
      }, {
         name: "NASA Space Science Data Coordinated Archive",
         url: "http://nssdc.gsfc.nasa.gov/",
         introduce: "天文学和天体物理学，太阳和空间等离子体物理学，以及行星和月球科学。还可以访问几个地球物理模型和来自一些非NASA任务数据的数据。"
      }, {
         name: "HARVARD Dataverse",
         url: "https://dataverse.harvard.edu/",
         introduce: "是一个免费的数据存储库，向哈佛社区内外任何学科的所有研究人员开放"
      }, {
         name: "IceCube-南极中微子天文台",
         url: "https://link.zhihu.com/?target=http%3A//icecube.wisc.edu/science/data",
         introduce: "是第一个此类探测器，旨在从南极冰层深处观测宇宙。一个负责科学研究的国际科学家小组组成了“冰立方”合作组织。"
      }, {
         name: "SDSS",
         url: "https://www.sdss.org/",
         introduce: "创造了有史以来最详细的宇宙三维地图，有三分之一天空的深彩色图像，以及300多万个天体的光谱"
      }, {
         name: "Barbara A. Mikulski Archive for Space Telescopes",
         url: "http://archive.stsci.edu/contacts.html",
         introduce: "侧重于光学，紫外和近红外部分的科学相关数据集"
      }, {
         name: "Centre for Environmental Data Analysis",
         url: "http://catalogue.ceda.ac.uk/uuid/7f1280cf215da6f8001eae5c2f019fe8",
         introduce: "各种光学收集和雷达收集数据"
      }, {
         name: "中国国家地球系统科学数据中心",
         url: "http://geospace.geodata.cn/",
         introduce: "提供电离层、地磁、中高层大气、固体地球等方面的数据"
      }, {
         name: "郭守敬望远镜",
         url: "http://www.lamost.org/public/pilot/data",
         introduce: "先导巡天、正式巡天的观测数据"
      }, {
         name: "毫米波射电天文数据库",
         url: "http://www.radioast.csdb.cn/",
         introduce: "针对中科院紫金山天文台13.7米毫米波射电望远镜近十年所观测的分子谱线数据"
      }, {
         name: "IAU Minor Planet Center ",
         url: "http://www.minorplanetcenter.net/",
         introduce: "接收和分配主要行星、彗星和主要行星外部不规则自然卫星位置测量等数据"
      }, {
         name: "International Service of Geomagnetic Indices",
         url: "http://isgi.unistra.fr/",
         introduce: "地磁指数和显著地磁事件列表构成的系列数据，旨在描述行星尺度上或组成部分的磁活动"
      }]
   },
   {
      id: 2,
      name: '化学',
      list: [{
         name: "American Mineralogist Crystal Structure Database",
         url: "http://rruff.geo.arizona.edu/AMS/amcsd.php",
         introduce: "这个网站是一个包括发表在美国矿物学家，加拿大矿物学家，欧洲矿物学和物理化学矿物学报，以及从其他期刊中选取数据集的晶体结构数据库的接口。该数据库是由美国矿物学会和加拿大矿物学协会监管，以及由美国国家科学基金会资助。数据库提供交互式软件套件，可以用于查看和设置晶体结构和计算晶体的不同性质，例如几何形状，衍射图案，和晶体电子密度。"
      }, {
         name: "Atmospheric Chemistry Experiment",
         url: "http://www.ace.uwaterloo.ca/",
         introduce: "SCISAT也称为大气化学实验（ACE），是加拿大航天局用太阳遮蔽遥感地球大气的小型卫星任务。主要任务的目标是提高对化学和动力过程的理解，从而控制臭氧在平流层和对流层上层的分布，特别是北极地区"
      }, {
         name: "Benchmark Energy & Geometry Database",
         url: "http://www.begdb.com/",
         introduce: "基准能源与几何数据库，收集高度精确计算的量子结构，能量和特性。这些数据可以作为其他计算方法的测试和参数化的基准。"
      }, {
         name: "BindingDB",
         url: "http://bindingdb.org/bind/index.jsp",
         introduce: "公开访问的针对化合物相互作用、结合、结构活性关系的数据库，储存包含化学生物学、化学基因组学、分子式别的基本物理化学等方面的建模方法，以及测量技术等"
      }, {
         name: "British Geological Survey",
         url: "http://www.bgs.ac.uk/",
         introduce: "是世界上最古老的国家地质调查，有超过400个数据集，包括环境监测数据，数字数据库，物理收集（钻孔核心，岩石，矿物和化石）的记录和档案。"
      }, {
         name: "BSRN ",
         url: "http://www.bsrn.awi.de/",
         introduce: "包括LR 0100 + LR 0300 (基本辐射和其他辐射测量)等十余个子集"
      }, {
         name: "Cambridge Structural Database",
         url: "http://webcsd.ccdc.cam.ac.uk/",
         introduce: "Cambridge Structural Database (CSD，剑桥晶体结构数据库)由剑桥晶体数据中心 (CCDC, Cambridge Crystallographic Data Centre)基于 X 光和中子衍射实验唯一的小分子及金属有机分子晶体的结构数据库，基本上包括已发表的所有原子个数 (包括氢原子)在 500 以内的有机及金属有机化合物晶体数据。随着 PDB和NDB(Nucleic Acid Database)快速发展，CSD 不再包括低核苷酸的数据，但增加了高分子的数据。"
      }, {
         name: "Chemical Carcinogenesis Research Information System",
         url: "http://toxnet.nlm.nih.gov/cgi-bin/sis/htmlgen?CCRIS=",
         introduce: "CCRIS含有超过9000化学记录具有致癌性、致突变性，促进肿瘤生长，肿瘤抑制试验结果。数据来源于初级期刊引用的研究、当前的认识工具、NCI报告和其他特殊来源。实验结果已受到致癌和突变方面专家们的审查。化学致癌作用研究信息系统内含9000多种化学物质短期或长期生物分析所得的评估数据及信息。这些分析涉及到致癌物、诱变剂、辅致癌物质和肿瘤启动物质、致癌物的抑制剂和代谢物。"
      }, {
         name: "CHEMOTION ",
         url: "http://www.chemotion.net/",
         introduce: "化学结构和数据集存储库"
      }, {
         name: "GEOROC",
         url: "http://georoc.mpch-mainz.gwdg.de/georoc/Entry.html",
         introduce: "该库主要针对海洋和大陆岩石，提供地理位置，经纬度，岩类和岩石类型，蚀变等级，分析方法，实验室，参考资料和参考文献等信息。"
      }, {
         name: "GISS Surface Temperature Analysis(GISTEMP，GISS表面温度分析)",
         url: "http://data.giss.nasa.gov/gistemp/",
         introduce: "GISS的计划领域大致可以分为气候强迫类别、 气候模式发展、 地球观测、 大气辐射、 大气化学、 气候影响、 行星大气层，系外行星和天体生物学、 古气候 和其他学科。"
      }, {
         name: "github",
         url: "https://github.com/caesar0301/awesome-public-datasets",
         introduce: "为用户整理了非常全面的数据获取渠道，包含各个细分领域的数据库资源，自然科学和社会科学的覆盖都很全面，用户可以自己获取数据去做研究和数据分析"
      }, {
         name: "Global Rivers Observatory",
         url: "http://www.globalrivers.org/",
         introduce: "测量全球18个流域流入海洋点的河流化学成分"
      }, {
         name: "International Arctic Systems for Observing the Atmosphere",
         url: "http://www.esrl.noaa.gov/psd/iasoa/dataataglance",
         introduce: "数据内容有标准气象，温室气体，大气辐射，云，污染物，化学，气溶胶和地表能量平衡"
      }, {
         name: "mendeley Data",
         url: "https://data.mendeley.com/",
         introduce: "是一个安全的基于云的存储库，您可以存储您的数据，确保无论您身在何处，都可以轻松共享、访问和引用数据"
      }, {
         name: "Microstructure Database",
         url: "http://cams.mse.ufl.edu/microstructures",
         introduce: "数据库提供基于原子结构模型所建立的原子微观结构的集合"
      }, {
         name: "NAVDAT",
         url: "http://navdat.org/",
         introduce: "NAVDAT提供中生代和年轻的火成岩的年龄、化学和同位素的数据"
      }, {
         name: "NMRshiftDB2",
         url: "http://nmrshiftdb.nmr.uni-koeln.de/",
         introduce: "nmrshiftdb2是有机结构及核磁共振（NMR）光谱的网络数据库。它提供频谱预测（13C、1H以及其它原子核）以及搜索光谱、结构和其它特性。最重要的是，它提供由用户提交的经过同行评审认可的数据集的提交。Nmrshiftdb2的软件是开源的，数据是开放内容许可下发布。请参考文档以更详细的信息。Nmrshiftdb2配套数据添加、错误修正与修改等功能，依托nmrshiftdb项目是可持续性的。"
      }, {
         name: "Oak Ridge National Laboratory Distributed Active Archive Center for Biogeochemical Dynamics",
         url: "https://daac.ornl.gov/",
         introduce: "橡树岭国家实验室的生物地球化学动力学的分布式归档中心是国家航空航天局（NASA）的地球观测系统数据和地球科学数据和信息系统项目（ESDIS）管理的信息系统管理数据中心之一。 ORNL DAAC归档NASA陆地生态计划生成的数据。提供数据和信息，有关生物地球化学动力学、生态数据和环境过程，关键的研究涉及生物、地质、化学成分与地球的环境。"
      }, {
         name: "PubChem",
         url: "https://pubchem.ncbi.nlm.nih.gov/",
         introduce: "是美国国家卫生研究院（NIH）的一个开放化学数据库"
      }, {
         name: "Reciprocal Net",
         url: "http://www.reciprocalnet.org/",
         introduce: "被研究晶体学的专家们用来存储分子结构信息；大部分的数据对公众开放访问"
      }, {
         name: "Rhea",
         url: "http://www.rhea-db.org/",
         introduce: "Rhea是一个免费的并拥有全面资源的生化反应专业数据库。它被设计为应用程序提供非冗余组的化学变化信息，如酶的功能注释、途径推理和代谢网络重建等。"
      }, {
         name: "SABIO-RK",
         url: "http://sabiork.h-its.org/",
         introduce: "包含关于生化反应的信息"
      }, {
         name: "South Florida Information Access",
         url: "http://sofia.usgs.gov/",
         introduce: "提供水化学等数据"
      }, {
         name: "SpringerMaterials",
         url: "https://materials.springer.com/",
         introduce: "内容涵盖物理化学和工程科学的各个领域"
      }, {
         name: "The Cambridge Structural Database",
         url: "http://www.ccdc.cam.ac.uk/solutions/csd-system/components/csd/",
         introduce: "成立于1965年,CSD是世界范围的小分子有机和有机晶体结构信息存储库。包含超过50万的x射线以及中子衍射的精确3D结构数据库，已成为全世界科学家重要的资源库。"
      }, {
         name: "The Canadian National Atmospheric Chemistry Database",
         url: "http://www.ec.gc.ca/natchem/",
         introduce: "包含来自北美许多主要区域规模网络的空气和降水化学数据。测量包括：粒子、气体、结合测量颗粒和气体、沉淀化学、特别研究、网络和研究。"
      }, {
         name: "Water Isotope System for Data Analysis, Visualization, and Electronic Retrieval",
         url: "http://www-naweb.iaea.org/napc/ih/IHS_resources_isohis.html",
         introduce: "降水同位素的全球网络数据平台"
      }, {
         name: "World Data Centre for Aerosols",
         url: "https://www.gaw-wdca.org/",
         introduce: "世界气溶胶数据中心（WDCA）是世界气象组织（WMO）全球大气监测（GAW）计划的大气气溶胶的微物理，光学和化学性质的数据存储库和存档"
      }, {
         name: "国家材料科学数据共享",
         url: "http://www.materdata.cn/",
         introduce: "涵盖逾3000种钢铁材料及材料基础的高质量数据近11万条"
      }, {
         name: "国家极地科学数据中心",
         url: "https://www.chinare.org.cn/",
         introduce: "主要开展极地雪冰-海洋与全球变化、极区电离层-磁层耦合与空间天气、极地生态环境及其生命过程以及极地科学基础平台技术等领域的研究"
      }, {
         name: "全球科研项目数据库",
         url: "http://project.llas.ac.cn/",
         introduce: "汇聚了全球主要发达国家重要基金组织2000年以来的科研项目数据200多万条"
      }, {
         name: "亚马逊公共数据集",
         url: "https://amazonaws-china.com/cn/public-datasets/",
         introduce: "包含化学、生物、经济等多个领域的数据集，当数据在 AWS 上公开提供后，任何人都可以分析任意数量的数据，而无需自行下载或存储这些数据。"
      }, {
         name: "中国产业信息",
         url: "https://www.chyxx.com/",
         introduce: "工业和信息化部主管,人民邮电报社主办,是我国通信行业唯一拥有国务院新闻办授予新闻发布权的新闻网站。"
      }, {
         name: "The Cambridge Crystallographic Data Centre",
         url: "https://www.ccdc.cam.ac.uk/",
         introduce: "汇集目标为有机物和金属有机物的晶体结构资源库"
      }, {
         name: "ChemSpider",
         url: "http://www.chemspider.com/",
         introduce: "汇集目标以化学结构式为基础的化学信息资源，涵盖物质基础理化性能、物质合成的路线以及参考文献等多种信息，专业数据库，超过四千三百万种的结构、属性以及相关信息数据推荐对象"
      }, {
         name: "ChemSub Online",
         url: "http://chemsub.online.fr/",
         introduce: "汇集目标以分子信息为主的化学物检索，涵盖目标化合物的基本信息，为个人数据库"
      }, {
         name: "ChemSynthesis",
         url: "http://www.chemsynthesis.com/",
         introduce: "汇集目标已知化学物的物化性能，如：熔点、沸点，可作为合成的已知物的参照物，数据涵盖化学超过40,000，参照物超过45,000。"
      }, {
         name: "NIST Chemistry WebBook",
         url: "https://www.nist.gov/",
         introduce: "包括原子和分子物理、化学和晶体结构、流体、材料特性、生物技术、光学字符识别等"
      }, {
         name: "Org Syn(Organic Synthesis)",
         url: "http://www.orgsyn.org/",
         introduce: "汇集目标有机化合物合成反应以及合成路线，操作详细，可以作为文献参考使用"
      }, {
         name: "IUPAC Standards Online",
         url: "https://iupac.org/",
         introduce: "国际理论和应用化学联合会（IUPAC）标准数据库，提取自Pure and Applied Chemistry杂志"
      }, {
         name: "CIRX",
         url: "http://www.cheminform.com/rxnfinder/",
         introduce: "由有机化学家生产，作为合成有机化学家的有用工具。数据是从大约100份期刊中根据精确的选择标准选择的。"
      }, {
         name: "Drug Bank",
         url: " http://www.drugbank.ca",
         introduce: "将详细药物数据与综合全面药物标靶信息结合的生物信息学与化学信息学数据库"
      }, {
         name: "eMolecules",
         url: "https://www.chemeurope.com/en/encyclopedia/EMolecules.html",
         introduce: "该数据库包含来自商业供应商的 700 多万个独特分子"
      }, {
         name: "The Human Metabolome Database",
         url: "http://www.hmdb.ca/",
         introduce: "人类内发现的小分子代谢物数据的详细信息"
      }, {
         name: "Spectral Database for Organic Compounds ",
         url: "https://sdbs.db.aist.go.jp/sdbs/cgi-bin/cre_list.cgi",
         introduce: "有机化合物的综合光谱数据库系统，包括34.600个化合物的六种不同类型的光谱（EI-MS、1H-NMR、13C-NMR、FT-IR、Raman、ESR）"
      }, {
         name: "ZINC",
         url: " http://zinc.docking.org",
         introduce: "专门为虚拟筛选搜集的商用化合物，包含三千五百多万中化合物的3D格式"
      }, {
         name: "HARVARD Dataverse",
         url: "https://dataverse.harvard.edu/",
         introduce: "是一个免费的数据存储库，向哈佛社区内外任何学科的所有研究人员开放"
      }, {
         name: "有机化合物核磁共振碳谱数据库",
         url: "http://210.32.137.90/s/lib/libtb/turning/1373",
         introduce: "微谱数据，即有机化合物核磁共振碳谱数据库，是专门为天然产物研究、有机合成和药物开发等研究工作设计的数据库。现已收录有机化合物约88万余个，是全球最大的有机化合物核磁共振碳谱数据库，适用于药学、化学、生命科学等相关专业"
      }, {
         name: "百料汇",
         url: "https://db.buychemi.cn/",
         introduce: "化工类数据库"
      }, {
         name: "上海有机所化学数据库",
         url: "https://link.zhihu.com/?target=http%3A//202.127.145.134/scdb/",
         introduce: "由多个数据库组成，包括化合物结构数据库、核磁谱图数据库、质谱谱图数据库、红外谱图数据库、物化性质数据库、农药高分辨质谱数据库、中药与有效成分数据库、植物化学成分数据库、药品数据库、药物和天然产物数据库、化学品安全技术说明书(MSDS)数据库、环境化学毒物防治数据库、中国化学专利数据库、中国化学文献数据库、化学反应数据库、化学品纯化数据库等，注册后可免费使用。"
      }, {
         name: "中科院长春应用化学研究所应用化学数据库",
         url: "https://link.zhihu.com/?target=http%3A//www.appchem.csdb.cn/index.jsp",
         introduce: " 是中科院长春应用化学研究所承担建设的综合科技信息数据库的重要组成部分，是中科院知识创新工程信息化建设的重大专项。目前, 应用化学数据库中心包括：稀土萃取数据库，稀土物理化学性质数据库，碳-13 NMR数据库和化合物活性数据库"
      }, {
         name: "中国科学院数据云",
         url: "https://link.zhihu.com/?target=http%3A//www.csdb.cn/pageDataResource",
         introduce: "整合中科院所有数据库资源，覆盖有机所、过程所、长春应化所所有数据库."
      }, {
         name: "化合物图谱数据库查询系统",
         url: "https://link.zhihu.com/?target=http%3A//www.analysis.org.cn/cdb/index.aspx",
         introduce: "该图谱资源库在搭建分析测试技术体系框架的基础上，设计了图谱资源数据库的基本架构和功能，通过搜索引擎，由化合物的分子式、分子量、中英文name、CAS号码、CDB编号等信息可在数据库中检索到该化合物的红外、紫外、质谱及核磁等各种谱图及分子结构。能够容纳大约10万张谱图，目前已有320个化合物的标准红外图进入数据库。该网站对公众免费开放。"
      }, {
         name: "Spectral Database for Organic Compounds",
         url: "https://link.zhihu.com/?target=https%3A//sdbs.db.aist.go.jp/sdbs/cgi-bin/direct_frame_top.cgi",
         introduce: "由日本国家先进工业科学技术研究所创办，是一个比较综合的有机化合物的光谱数据库系统，它包含7种不同类型的光谱图。其中有EI-MS,FT-IR,1H-NMR,13C-NMR,Laser Raman, ESR谱图"
      }, {
         name: "上海研发服务公共平台",
         url: "https://link.zhihu.com/?target=http%3A//chemdb.sgst.cn/ssdb/chem/login_sso.asp",
         introduce: "可以提供化合物有关的命名、结构、基本性质、毒性、谱学、鉴定方法、专利、生物活性、化学反应、医药农药应用、天然产物、相关文献和市场供应，以及精细化学品、农用化学品和工程塑料等信息。其数据中心提供大量的关于化合物的红外波谱和质谱数据。"
      }, {
         name: "Organic compounds Database",
         url: "https://link.zhihu.com/?target=http%3A//www.colby.edu/chemistry/cmp/cmp.html",
         introduce: "包含有2483个化合物，是由哈佛大学弗吉尼亚研究所编撰的"
      }, {
         name: "北京微量化学研究所分析中心",
         url: "https://link.zhihu.com/?target=http%3A//www.microchem.org.cn/",
         introduce: "该中心拥有的Sadtler(萨特勒)红外光谱数据库共计13万3仟多张，其中凝聚相纯化合物标准红外光谱库74126张，气相纯化合物标准红外光谱库9350张"
      }, {
         name: "BioMagResBank",
         url: "https://link.zhihu.com/?target=http%3A//ftp.bmrb.wisc.edu",
         introduce: "BMRB与蛋白质数据库（PDB，布鲁克海文国家实验室）和核酸数据库（NDB，罗格斯大学）合作，致力于开发蛋白质和核酸结构NMR数据的收集站点"
      }, {
         name: "美国标准与技术研究院NIST原子光谱数据库",
         url: "https://link.zhihu.com/?target=http%3A//physics.nist.gov/cgi",
         introduce: "包含辐射数据原子和原子离子的跃迁和能级。包括观察到的99个元素的跃迁和52个元素的能级的数据"
      }, {
         name: "SciFinder",
         url: "https://link.zhihu.com/?target=https%3A//scifinder.cas.org/",
         introduce: "不仅可以检索文献、结构，还能检索合成方法和结构的物理性质"
      }, {
         name: "Crystallography Open Database",
         url: "https://link.zhihu.com/?target=http%3A//www.crystallography.net/",
         introduce: "开放获取有机，无机，金属有机化合物和矿物的晶体结构的集合"
      }, {
         name: "Materials Projec",
         url: "https://materialsproject.org/",
         introduce: "利用超级计算的力量和先进的电子结构方法，Materials Project提供了基于Web的开放式访问，可以访问已知和预测的材料上的计算信息，还可以使用强大的分析工具来激发和设计新颖的材料。"
      }]
   },


   {
      id: 3,
      name: '生物',
      list: [{
         name: "国家基因组科学数据中心",
         url: "https://bigd.big.ac.cn/",
         introduce: "是国家科技资源共享服务平台之一，依托单位中国科学院北京基因组研究所"
      }, {
         name: "国家微生物科学数据中心",
         url: "http://www.nmdc.cn/",
         introduce: "是国家科技资源共享服务平台，依托单位中国科学院微生物研究所"
      }, {
         name: " 国家人口健康科学数据中心",
         url: "https://www.ncmi.cn/index.html",
         introduce: "承担起国家科技重大专项、科技计划、重大公益专项等人口健康领域科学数据汇交、数据加工、数据存储、数据挖掘和数据共享服务的任务。"
      }, {
         name: "全球科研项目数据库",
         url: "http://project.llas.ac.cn/",
         introduce: "汇聚了全球主要发达国家重要基金组织2000年以来的科研项目数据200多万条"
      }, {
         name: "组学原始数据归档库",
         url: "https://bigd.big.ac.cn/gsa/",
         introduce: "组学原始数据汇交、存储、管理与共享系统，是国内首个被国际期刊认可的组学数据发布平台"
      }, {
         name: "人类基因数据库",
         url: "https://www.ncbi.nlm.nih.gov/genbank/dbest/",
         introduce: "GenBank的一个子数据，包含来源于不同物种的表达序列数据和表达序列标签序列的其他信息"
      }, {
         name: "GenBank",
         url: "https://www.ncbi.nlm.nih.gov/genbank/",
         introduce: "NCBI建立的DNA序列数据库"
      }, {
         name: "微生物组数据云平台",
         url: "https://gcmeta.wdcm.org/",
         introduce: "一个微生物基因组及微生物数据的管理、分析和发布平台，为国内外用户提供一站式的数据存储、数据分析到数据发布的服务，目前已经整合了来自中国科学院微生物组计划及国内外多个重要项目的数据"
      }, {
         name: "生命与健康大数据中心",
         url: "http://bigd.big.ac.cn/",
         introduce: "围绕国家精准医学和重要战略生物资源的组学数据，建立海量生物组学大数据储存、整合与挖掘分析研究体系，发展组学大数据系统构建、挖掘与分析的新技术、新方法，建设组学大数据汇交、应用与共享平台，力争建成支撑我国生命科学发展、国际知名的生命与健康大数据中心。"
      }, {
         name: "生物医学大数据中心",
         url: "https://www.biosino.org/bigbim/index",
         introduce: "聚焦于提升生物与医学大数据存储、管理、挖掘能力，立足上海生科院，服务上海、辐射全国"
      }, {
         name: "亚马逊公共数据集",
         url: "https://amazonaws-china.com/cn/public-datasets/",
         introduce: "包含化学、生物、经济等多个领域的数据集，当数据在 AWS 上公开提供后，任何人都可以分析任意数量的数据，而无需自行下载或存储这些数据。"
      }, {
         name: "github",
         url: "https://github.com/caesar0301/awesome-public-datasets",
         introduce: "为用户整理了非常全面的数据获取渠道，包含各个细分领域的数据库资源，自然科学和社会科学的覆盖都很全面，用户可以自己获取数据去做研究和数据分析"
      }, {
         name: "和鲸社区",
         url: "https://www.heywhale.com/home/dataset",
         introduce: "是国内知名的开放数据科学社区,是最早一批专注于大数据算法比赛的平台,支持在线分享代码、项目和数据集"
      }, {
         name: "UCI数据集",
         url: "https://link.zhihu.com/?target=https%3A//archive.ics.uci.edu/ml/index.php",
         introduce: "是加州大学欧文分校(University of CaliforniaIrvine)提出的用于机器学习的数据库，这个数据库目前共有559个数据集，其数目还在不断增加"
      }, {
         name: "中国科技共享网",
         url: "https://escience.org.cn/",
         introduce: "涉及到地理学、气象学、微生物学、基因组学、医学、农学、物理学等多个学科"
      }, {
         name: "国家材料科学数据共享",
         url: "http://www.materdata.cn/",
         introduce: "涵盖逾3000种钢铁材料及材料基础的高质量数据近11万条"
      }, {
         name: "Golm Metabolome Database",
         url: "http://gmd.mpimp-golm.mpg.de/",
         introduce: "是由德国Max Planck的科学家建立的植物代谢组学数据库。侧重于非靶向代谢组学。该数据库的最大特点是含有大量的植物代谢产物的GC-MS图谱（特别是衍生化后的），用户可以导入自己GC-MS数据进行搜索比对和鉴定。另外，该数据库还含有部分代谢产物在植物中的浓度，可以按照植物名、部位等进行搜索。"
      }, {
         name: "Birdbase",
         url: "http://birdbase.arizona.edu/birdbase/",
         introduce: "平台提供禽类基因信息，为研究禽类的养殖产量、抗病性、行为及骨骼发育等性状提供数据支持。此外，BirdBase还可以为生物医学和农业研究人员提供数据支持服务"
      }, {
         name: "German Federation for Biological Data",
         url: "https://www.gfbio.org/",
         introduce: "德国联邦生物学数据(GFBio)提供生态环境相关的生物学数据，其目的是提供持续性的，以服务为导向的国家数据服务平台"
      }, {
         name: "Primate Life Histories database",
         url: "https://plhdb.org/",
         introduce: "数据库提供野生灵长类种群的观测数据，该数据由九个工作组历时19年独立收集"
      }, {
         name: "The Zebrafish Information Network",
         url: "http://zfin.org/",
         introduce: "斑马鱼作为广泛应用的模式生物，ZFIN提供与其相关的生物学数据。旨在帮助实验室合理使用斑马鱼资源，研究斑马鱼遗传信息、发育过程，对比斑马鱼与人类数据库中的相应数据，满足学术界对于斑马鱼资源的需求。"
      }, {
         name: "PeptideAtlas",
         url: "http://www.peptideatlas.org/",
         introduce: "许多肽段能在质谱中被检测，而这些信息被统计在一个数据库，叫PeptideAtlas。2005年，PeptideAtlas数据库成立，从开始到现在，来自多种不同物种的质谱数据仍在大幅增加和更新。它既是一个庞大的数据库，同时也对蛋白质组学相关实验提供了重要的查询资源。"
      }, {
         name: "IGSR",
         url: "http://www.1000genomes.org/data",
         introduce: "国际基因组样本数据，支持开放的人类变异数据"
      }, {
         name: "BBBC",
         url: "https://www.broadinstitute.org/bbbc",
         introduce: "是可自由下载的显微镜图像集的集合"
      }, {
         name: "CELL IMAGE LIBRARY",
         url: "http://www.cellimagelibrary.org/",
         introduce: "由美国NIH资助的公开免费的资源数据库，其中包含细胞的图像、视频和动画，可以捕获多种生物、细胞类型和细胞过程"
      }, {
         name: "Complete GENOMICS",
         url: "http://www.completegenomics.com/public-data/69-genomes/",
         introduce: "全人类基因组的不同数据集可免费供公众使用，以加强任何基因组研究或评估完整的基因组数据结果和文件格式"
      }, {
         name: "ArrayExpress",
         url: "http://www.ebi.ac.uk/arrayexpress/",
         introduce: "ArrayExpress功能基因组学数据档案可存储来自高通量功能基因组学实验的数据，并将这些数据提供给研究团体重用"
      }, {
         name: "EMBL-EBI",
         url: "http://www.ebi.ac.uk/arrayexpress/",
         introduce: "是欧洲生命科学旗舰实验室欧洲分子生物学实验室 （EMBL） 的一部分,提供数据服务和培训，帮助科学家认识到\"大数据\"在生物科学中的潜力，为造福人类的发现铺平道路"
      }, {
         name: "ENCODE",
         url: "https://www.encodeproject.org/",
         introduce: "是由国家人类基因组研究所（NHGRI）资助的研究小组的国际合作。ENCODE的目标是在人类基因组中构建全面的功能元件清单，包括在蛋白质和RNA水平上起作用的元件，以及控制基因活跃的细胞和环境的调控元件"
      }, {
         name: "EMPIAR",
         url: "http://www.ebi.ac.uk/pdbe/emdb/empiar/",
         introduce: "电子显微镜公共图像档案馆，是原始二维电子显微镜图像的公共资源"
      }, {
         name: " 恩森布尔基因组",
         url: "https://ensemblgenomes.org/",
         introduce: "提供非脊椎动物物种的基因组数据，以及用于处理，分析和可视化该数据的工具"
      }, {
         name: "Gene Expression Omnibus",
         url: "http://www.ncbi.nlm.nih.gov/geo/",
         introduce: "GEO是一个公共功能基因组学数据库，支持符合MIAME的数据提交。 接受基于数组和序列的数据。 提供的工具可帮助用户查询和下载实验以及精选的基因表达谱。"
      }, {
         name: "Gene Ontology",
         url: "http://geneontology.org/docs/download-go-annotations/",
         introduce: "基因本体论资源以计算方式表示了我们当前有关从人类到细菌的许多不同生物体的基因功能（或更恰当地说，是由基因产生的蛋白质和非编码RNA分子）的科学知识。 它被广泛用于支持科学研究，并已在成千上万的出版物中引用。"
      }, {
         name: "Global Biotic Interactions (GloBI)",
         url: "https://github.com/jhpoelen/eol-globi-data/wiki#accessing-species-interaction-data",
         introduce: "该项目的任务是找到标准化和整合物种相互作用数据的有效方法。 通过使这些数据随时可用，GloBI将使研究人员和爱好者能够回答有关本地化，一对一物种相互作用以及物种相互作用随时间变化的总体情况的问题。"
      }, {
         name: "Harvard Medical School (HMS) LINCS Center",
         url: "http://lincs.hms.harvard.edu/",
         introduce: "收集和传播理解人类细胞如何响应药物，环境和突变的扰动所需的数据和分析工具"
      }, {
         name: "Stanford HGDP SNP Genotyping Data",
         url: "http://www.hagsc.org/hgdp/files.html",
         introduce: "人类基因组多样性项目（HGDP）与巴黎埃图德多态性中心（CEPH）合作，从这些完全同意的个体中采集了基因组DNA样本"
      }, {
         name: "NIH Human Microbiome Project",
         url: "http://www.hmpdacc.org/reference_genomes/reference_genomes.php",
         introduce: "其任务是创造资源，使人类微生物群能够全面定性，并分析其在人类健康和疾病中的作用"
      }, {
         name: " ICOS PSP ",
         url: "http://ico2s.org/datasets/psp_benchmark.html",
         introduce: "ICOS小组的任务是在计算科学和复杂生物系统的界面上进行突破性研究"
      }, {
         name: "NCBI",
         url: "https://www.ncbi.nlm.nih.gov/",
         introduce: "保管GenBank的基因测序数据和Medline的生物医学研究论文索引"
      }, {
         name: "Cancer Program Datasets",
         url: "http://www.broadinstitute.org/cgi-bin/cancer/datasets.cgi",
         introduce: "麻省理工学院癌症基因组学数据"
      }, {
         name: "Genomic Data Commons ",
         url: "https://gdc.cancer.gov/access-data/gdc-data-portal",
         introduce: "基因组数据共享（GDC）是国家癌症研究所（NCI）的一个研究项目。GDC 的使命是为癌症研究界提供统一的数据库，使癌症基因组研究的数据共享能够支持精密医学"
      }, {
         name: "palmerpenguins",
         url: "https://allisonhorst.github.io/palmerpenguins/",
         introduce: "目标是提供一个伟大的数据集的数据探索和可视化"
      }, {
         name: "Pathguide",
         url: "http://www.pathguide.org/",
         introduce: "包含有关702生物通路相关资源和分子相互作用相关资源的信息"
      }, {
         name: "COSMIC",
         url: "https://cancer.sanger.ac.uk/cosmic",
         introduce: "是探索体质突变对人类癌症影响的最大和最全面的资源"
      }, {
         name: "Genomics of Drug Sensitivity in Cancer",
         url: "https://www.cancerrxgene.org/",
         introduce: "已对1000条人类癌细胞系进行了特征，并筛选出100种化合物。在这个网站上，你会发现药物反应数据和基因组标记的敏感性"
      }, {
         name: "SSBD",
         url: "http://ssbd.qbic.riken.jp/",
         introduce: "为分析单分子、细胞、基因表达核等生物物体的定量数据和显微镜图像提供了丰富的开放资源"
      }, {
         name: "Catalogue of life ",
         url: "http://www.catalogueoflife.org/content/annual-checklist-archive",
         introduce: "世界上最完整的权威物种列表 - 由数百名全球分类学家维护"
      }, {
         name: "The Personal Genome Project",
         url: "http://www.personalgenomes.org/",
         introduce: "个人基因组计划始于2005年，是全世界致力于创建公共基因组、健康和特征数据的项目的愿景和联盟"
      }, {
         name: "UCSC",
         url: "http://hgdownload.soe.ucsc.edu/downloads.html",
         introduce: "包括广泛的脊椎动物和模型有机体组合和注释集合，以及一大套用于查看、分析和下载数据的工具"
      }, {
         name: "Rfam",
         url: "https://docs.rfam.org/en/latest/database.html",
         introduce: "是非编码 RNA 家族的集合，以手动策划的序列排列、共识的次要结构和预测的同源表示"
      }, {
         name: "World Health Organization",
         url: "http://www.who.int/gho/en/",
         introduce: "探索健康数据的世界"
      }, {
         name: "Kaggle",
         url: "https://www.kaggle.com/datasets",
         introduce: "是由联合创始人、首席执行官安东尼·高德布卢姆（Anthony Goldbloom）2010年在墨尔本创立的，主要为开发商和数据科学家提供举办机器学习竞赛、托管数据库、编写和分享代码的平台。该平台已经吸引了80万名数据科学家的关注，这些用户资源或许正是吸引谷歌的主要因素"
      }, {
         name: "LILA BC",
         url: "http://lila.science/",
         introduce: "是一个与生物学和保护相关的数据集的存储库，旨在为机器学习（ML）研究人员和希望利用ML进行生物学和保护的研究人员提供资源。 "
      }, {
         name: "mendeley Data",
         url: "https://data.mendeley.com/",
         introduce: "是一个安全的基于云的存储库，您可以存储您的数据，确保无论您身在何处，都可以轻松共享、访问和引用数据"
      }, {
         name: "NIST Chemistry WebBook",
         url: "https://www.nist.gov/",
         introduce: "包括原子和分子物理、化学和晶体结构、流体、材料特性、生物技术、光学字符识别等"
      }, {
         name: "HARVARD Dataverse",
         url: "https://dataverse.harvard.edu/",
         introduce: "是一个免费的数据存储库，向哈佛社区内外任何学科的所有研究人员开放"
      }, {
         name: "有机化合物核磁共振碳谱数据库",
         url: "http://210.32.137.90/s/lib/libtb/turning/1373",
         introduce: "微谱数据，即有机化合物核磁共振碳谱数据库，是专门为天然产物研究、有机合成和药物开发等研究工作设计的数据库。现已收录有机化合物约88万余个，是全球最大的有机化合物核磁共振碳谱数据库，适用于药学、化学、生命科学等相关专业"
      }, {
         name: "DDBJ",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttps%253A%252F%252Fwww.ddbj.nig.ac.jp%252Findex-e.html",
         introduce: "生物信息和DDBJ中心为生命科学研究和科学进步的数据提供共享和分析服务"
      }, {
         name: "Bioinformatics.org",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttp%253A%252F%252Fwww.bioinformatics.org",
         introduce: "国际生物信息学动态及会议"
      }, {
         name: "RefSeq",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttps%253A%252F%252Fwww.ncbi.nlm.nih.gov%252Frefseq%252F",
         introduce: "一整套全面，完整，非冗余，带有注释的参考序列，包括基因组，转录本和蛋白质。"
      }, {
         name: "PIR",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttps%253A%252F%252Fproteininformationresource.org",
         introduce: "为科学界提供了单一的，集中的，权威的蛋白质序列和功能信息资源"
      }, {
         name: "UniProt",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttps%253A%252F%252Fwww.uniprot.org",
         introduce: "为科学界提供蛋白质序列和功能信息的全面，高质量且可自由访问的资源"
      }, {
         name: "InterPro",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttp%253A%252F%252Fwww.ebi.ac.uk%252Finterpro%252F",
         introduce: "通过将蛋白质分类为家族并预测领域和重要部位来提供蛋白质的功能分析"
      }, {
         name: "ExPASy",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttps%253A%252F%252Fprosite.expasy.org",
         introduce: "是蛋白质家族和结构域的数据库。 基于这样的观察，尽管存在大量不同的蛋白质，但根据其序列的相似性，大多数蛋白质可以分为有限的家族。 属于特定家族的蛋白质或蛋白质结构域通常共享功能属性，并且源自共同的祖先"
      }, {
         name: "RCSB PDB",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttp%253A%252F%252Fwww.rcsb.org",
         introduce: "该资源由蛋白质数据库提供的有关蛋白质，核酸和复杂装配体3D形状的档案信息提供支持，可帮助学生和研究人员了解生物医学和农业的各个方面，从蛋白质合成到健康和疾病。"
      }, {
         name: "SCOP",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttp%253A%252F%252Fscop.mrc-lmb.cam.ac.uk",
         introduce: "旨在详细和全面地描述蛋白质之间的结构和进化关系，其三维结构已知并存入蛋白质数据库"
      }, {
         name: "PDBe",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttps%253A%252F%252Fwww.ebi.ac.uk%252Fpdbe%252F",
         introduce: "PDBe是用于收集，组织和传播生物大分子结构数据的欧洲资源"
      }, {
         name: "ConsensusPathDB",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttp%253A%252F%252Fcpdb.molgen.mpg.de",
         introduce: "将人与人之间的相互作用网络整合在一起，包括二元和复杂的蛋白质-蛋白质，遗传，代谢，信号传导，基因调控和药物-靶标相互作用，以及生化途径"
      }, {
         name: "CORUM",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttps%253A%252F%252Fmips.helmholtz-muenchen.de%252Fcorum%252F",
         introduce: "提供了来自哺乳动物的人工注释的蛋白质复合物的资源。 注释包括蛋白质复合物功能，定位，亚基组成，文献参考等。 所有信息均来自发表在科学文章中的单个实验，不包括高通量实验的数据"
      }, {
         name: "KEGG",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttps%253A%252F%252Fwww.genome.jp%252Fkegg%252F",
         introduce: "用于从分子水平信息，特别是基因组测序和其他高通量实验技术产生的大规模分子数据集中了解生物系统的高层次功能和实用功能，如细胞、生物体和生态系统"
      }, {
         name: "Plantcy",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttps%253A%252F%252Fwww.plantcyc.org",
         introduce: "PMN当前包含一个称为PlantCyc的多物种参考数据库和126种特定物种的生物分类数据库"
      }, {
         name: "SMPDB",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttps%253A%252F%252Fsmpdb.ca",
         introduce: "是一个交互式的可视化数据库，其中包含仅在人类中发现的3万多个小分子途径。SMPDB专为支持代谢组学，转录组学，蛋白质组学和系统生物学中的途径阐明和途径发现而设计"
      }, {
         name: "piRNABank",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttp%253A%252F%252Fpirnabank.ibab.ac.in",
         introduce: "可提供有关三种被广泛研究的哺乳动物（人，小鼠，大鼠和一种果蝇果蝇）中piRNA的全面信息。 它编译了所有可能的piRNA簇，还描绘了piRNA以及相关的基因组元件（如基因），并在全基因组范围内重复显示"
      }, {
         name: "miRBase",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttp%253A%252F%252Fwww.mirbase.org",
         introduce: "是已发布的miRNA序列和注释的可搜索数据库。 miRBase序列数据库中的每个条目均代表miRNA转录本的预测发夹部分（在数据库中称为mir），其中包含有关成熟miRNA序列（称为miR）的位置和序列的信息。"
      }, {
         name: "GtRNAdb",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttp%253A%252F%252Fgtrnadb.ucsc.edu",
         introduce: "基因组tRNA数据库包含tRNAscan-SE对完整或几乎完整的基因组进行的tRNA基因预测"
      }, {
         name: "Silva",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttps%253A%252F%252Fwww.arb-silva.de",
         introduce: "为所有三个生命域（细菌、古细菌和真核生物）提供全面、质量检查和定期更新的小（16S/18S，SSU）和大亚基（23S/28S，LSU）核糖体RNA（rRNA）序列数据集。"
      }, {
         name: "NONCODE",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttp%253A%252F%252Fwww.noncode.org",
         introduce: "是专用于非编码RNA（tRNA和rRNA除外）的集成知识数据库"
      }, {
         name: "LncRBase",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttp%253A%252F%252Fbicresources.jcbose.ac.in%252Fzhumur%252Flncrbase%252F",
         introduce: "是人和小鼠中长非编码RNA（lncRNA）的综合数据库"
      }, {
         name: "GO",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttp%253A%252F%252Fgeneontology.org",
         introduce: "是有关基因功能的全球最大信息来源。 这些知识既是人类可读的，也是机器可读的，并且是生物医学研究中大规模分子生物学和遗传学实验的计算分析的基础"
      }, {
         name: "DAVID",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttps%253A%252F%252Fdavid.ncifcrf.gov",
         introduce: "为研究人员提供了一套全面的功能注释工具，以了解大量基因背后的生物学含义"
      }, {
         name: "GeneCard",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttps%253A%252F%252Fwww.genecards.org%252F",
         introduce: "自动整合125个数据库，包含基因组、转录组、蛋白组、遗传、临床和功能信息的庞大人基因组数据库"
      }, {
         name: "GENCODE",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttps%253A%252F%252Fwww.gencodegenes.org%252F",
         introduce: "人和小鼠基因组中的所有基因特征进行识别和分类，并释放这些注释以利于生物医学研究和基因组解释"
      }, {
         name: "mBiobank",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttp%253A%252F%252Fwww.mbiobank.com%252F",
         introduce: "中国代谢解析计划"
      }, {
         name: "Human protein atlas",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttps%253A%252F%252Fwww.proteinatlas.org",
         introduce: "人体蛋白在细胞、组织、病理条件下的表达"
      }, {
         name: "Personal Genome Project",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttps%253A%252F%252Fwww.personalgenomes.org",
         introduce: "来自世界各地的100,00名志愿者的人类基因组计划"
      }, {
         name: "OMIM",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttps%253A%252F%252Fomim.org",
         introduce: "一个管理人类基因和人类遗传疾病特征的数据库"
      }, {
         name: "Legume Information System",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttps%253A%252F%252Flegumeinfo.org",
         introduce: "豆科植物的基因组数据库"
      }, {
         name: "Rat Genome Database",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttps%253A%252F%252Frgd.mcw.edu%252F",
         introduce: "大鼠基因组数据库"
      }, {
         name: "Mouse genome",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttp%253A%252F%252Fwww.informatics.jax.org",
         introduce: "可以比较小鼠和人类基因组的区别；查找基因功能及敲除某个基因后导致的表型；人类-小鼠相关疾病；基因表达等"
      }, {
         name: "Mouse Genome Database",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttp%253A%252F%252Fwww.nervenet.org%252Fmain%252Fdictionary.html",
         introduce: "整合了国际上实验室小鼠生物数据的资源库，提供小鼠相关的基因组、综合遗传等信息"
      }, {
         name: "FlyBase",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttp%253A%252F%252Fflybase.org",
         introduce: "模式生物果蝇的基因组数据库"
      }, {
         name: "Zebrafish genome",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttp%253A%252F%252Fzfin.org",
         introduce: "斑马鱼的基因组数据库"
      }, {
         name: "Xenbase",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttp%253A%252F%252Fwww.xenbase.org%252Fentry%252F",
         introduce: "模式生物非洲爪蟾（Xenopus tropicalis）和非洲爪蟾（Xenopus laevis）的基因组数据库"
      }, {
         name: "Wormbase",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttps%253A%252F%252Fwormbase.org%252F%2523012-34-5",
         introduce: "关于线虫模式生物秀丽隐杆线虫的生物学和基因组在线生物数据库，还包含其他相关线虫的信息"
      }, {
         name: "PomBase",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttps%253A%252F%252Fwww.pombase.org%252F",
         introduce: "裂殖酵母(Schizosaccharomyces pombe)的知识库"
      }, {
         name: "Saccharomyces Genome Database",
         url: "https://link.zhihu.com/?target=https%3A//links.jianshu.com/go%3Fto%3Dhttps%253A%252F%252Fwww.yeastgenome.org%252F",
         introduce: "酵母模型生物的基因组数据库"
      }, {
         name: "STD序列数据库",
         url: "http://www.stdgen.lanl.gov/",
         introduce: "粒性结膜炎（沙眼）衣原体，性病支原体，梅毒螺旋体，人类乳头（状）瘤病毒 基因数据库"
      }, {
         name: "Boola Boola Forest bird study",
         url: "http://data.cerdi.edu.au/gippsland_birds.php",
         introduce: "平台提供位于澳大利亚东南部Gippsland地区不同种类林地中鸟类生物多样性数据"
      }, {
         name: "Freshwater Information Platform",
         url: "http://www.freshwaterplatform.eu/",
         introduce: "提供与淡水资源及其生物多样性有关的数据、地图、工具和其他资源的链接的平台"
      }, {
         name: "全球生物多样性信息网络",
         url: "https://link.zhihu.com/?target=https%3A//www.gbif.org/",
         introduce: "可以下载全球范围内的动植物多样性数据"
      }, {
         name: "Dryad",
         url: "https://datadryad.org/",
         introduce: "是一个储存医学、生物学、生态学数据的开放数据知识库，为学术文献提供基础设施，促进其重用，让学术论文的数据可被发现，可自由重复使用和可引用"
      }, {
         name: "家犬和野生犬科动物的综合性数据资源库",
         url: "http://bigd.big.ac.cn/idog/",
         introduce: "是家犬和野生犬的综合性数据资源库，为全球狗研究领域提供各种数据服务。 这包括基因，基因组，SNP，品种/疾病特征，基因表达，GO功能注释，狗 - 人类同源疾病和文献。 此外，iDog还提供用于执行基因组数据可视化和分析的在线工具。"
      }, {
         name: "北京大学生命科学学院生物信息学中心",
         url: "https://opendata.pku.edu.cn/dataverse/CBI",
         introduce: "是欧洲分子生物学网络组织EMBnet的中国国家节点。几年来，已经与多个国家的生物信息中心建立了合作关系，其中包括：欧洲生物信息学研究所（EBI）、国际蛋白质数据库和分析中心（ExPASy） 、国际遗传工程和生物技术研究所、德国生物工程研究所、英国基因组资源中心、英国基因组研究中心（Sanger Centre）、荷兰生物信息中心、澳大利亚基因组信息中心、新加坡生物信息中心等等。目前是国内数据库种类最多，数据量最大的生物信息站点， 为国内外用户提供了多项生物信息服务。"
      }]
   },


   {
      id: 4,
      name: '地空',
      list: [{
         name: "国家空间科学数据中心",
         url: "https://www.nssdc.ac.cn/mhsy/html/index.html",
         introduce: "数据中心以中科院国家空间科学中心为依托单位，联合中科院国家天文台（探月工程中心）、中国科学技术大学、中科院国家授时中心和中科院计算机网络信息中心等单位共同建设和运行"
      }, {
         name: "国家天文科学数据中心",
         url: "https://nadc.china-vo.org/",
         introduce: "国内各大望远镜的观测数据、国际知名数据库的镜像"
      }, {
         name: "国家对地观测科学数据中心",
         url: "http://www.chinageoss.cn/dsp/home/index.jsp",
         introduce: "全国规模最大的地球观测数据免费共享资源"
      }, {
         name: "国家极地科学数据中心",
         url: "https://www.chinare.org.cn/",
         introduce: "主要开展极地雪冰-海洋与全球变化、极区电离层-磁层耦合与空间天气、极地生态环境及其生命过程以及极地科学基础平台技术等领域的研究"
      }, {
         name: "国家地震科学数据中心",
         url: "https://data.earthquake.cn/index.html",
         introduce: "国家科技资源共享服务平台，依托单位中国科学院昆明植物研究所"
      }, {
         name: "国家地球系统科学数据中心",
         url: "http://www.geodata.cn/index.html",
         introduce: "整合集成分布在国内外数据库中心群、高等院校、科研院所以及科学家个人产生的数据资源，引进国际数据资源，接收国家重点科研项目产生的数据资源，在此基础上生产加工数据产品"
      }, {
         name: "北京离子探针中心",
         url: "http://eds.ceode.ac.cn/sjglb/dataservice.htm",
         introduce: "由科技部命名的国家大型科学仪器中心，其核心仪器是两台“高分辨二次离子探针质谱计（SHRIMPⅡ ）”。中心主要从事地质年代学和宇宙年代学研究；发展定年新技术新方法；进行必要的矿物微区稀土地球化学研究；解决重大地球科学研究课题中的时序问题，特别是太阳系和地球的形成及早期历史研究；主要造山带的构造演化研究；地质年代表研究；大型和特殊矿床成矿时代研究并从事科学仪器研发。"
      }, {
         name: "PANGAEA",
         url: "https://www.pangaea.de/",
         introduce: "地球和环境科学数据的发布平台，由德国阿尔弗雷德-魏格纳极地与海洋研究所、布莱梅大学海洋环境科学中心共建"
      }, {
         name: "地理空间数据云",
         url: "http://www.gscloud.cn/",
         introduce: "以中国科学院及国家的科学研究为主要需求，逐渐引进当今国际上不同领域内的国际数据资源，并对其进行加工、整理、集成，最终实现数据的集中式公开服务、在线计算等"
      }, {
         name: "国家综合地球观测数据共享平台",
         url: "http://www.chinageoss.org/dsp/home/index.jsp",
         introduce: "是由科技部主导，国家遥感中心牵头，各卫星中心、数据单位贡献数据、参与建设，数据主要为遥感卫星数据，涵盖主要的国内卫星和国际卫星，也包括相关科学研究数据。"
      }, {
         name: "USGS Data",
         url: "https://earthexplorer.usgs.gov/",
         introduce: "美国地质调查局Landsat、MODIS等卫星影像下载"
      }, {
         name: "ESA's Sentinel",
         url: "https://scihub.copernicus.eu/dhus/#/home",
         introduce: "欧洲航天局哨兵系列卫星，以高分辨率著称"
      }, {
         name: "Landcover 衍生数据",
         url: "http://landcover.org/",
         introduce: "全球土地覆盖研究机构，衍生卫星数据"
      }, {
         name: "DigitalGlobe样本",
         url: "http://www.digitalglobe.com/product-samples",
         introduce: "目前最大的商业卫星数据供应商"
      }, {
         name: "RapidEye",
         url: "https://www.planet.com/",
         introduce: "来自RapidEye的免费卫星数据"
      }, {
         name: "Open Data Inception",
         url: "https://www.directionsmag.com/data-resources/8242",
         introduce: "收录全球不同国家地区2600多数据库"
      }, {
         name: "中国科技共享网",
         url: "https://escience.org.cn/",
         introduce: "涉及到地理学、气象学、微生物学、基因组学、医学、农学、物理学等多个学科"
      }, {
         name: "Atmospheric Science Data Center，NASA Langley Research Center",
         url: "https://eosweb.larc.nasa.gov/",
         introduce: "NASA Langley研究中心的大气科学数据中心（ASDC）负责NASA地球科学数据在辐射预算，云层，气溶胶和对流层构成等领域的处理，存档和分发工作。"
      }, {
         name: "Computational and Information Systems Laboratory Research Data Archive",
         url: "http://rda.ucar.edu/",
         introduce: "由NCAR的计算和信息系统实验室的数据支持部门管理的CISL研究数据存档（RDA），包含大量多样的气象和海洋学系列 观测，运行和再分析模型输出以及支持大气和地球科学研究的遥感数据集，以及地形/测深，植被和土地利用等辅助数据集。"
      }, {
         name: "Crustal Dynamics Data Information System",
         url: "https://cddis.nasa.gov/",
         introduce: "空间大地测量学和地球动力学有关的数据产品"
      }, {
         name: "Data Center for Aurora in NIPR",
         url: "http://polaris.nipr.ac.jp/~aurora/",
         introduce: "数据库包括了截至到2003年12月的极光世界数据中心数据集，数据手册，相关出版物和设施的收集情况"
      }, {
         name: "中国国家地球系统科学数据中心-地球物理分中心",
         url: "http://geospace.geodata.cn/",
         introduce: "提供电离层、地磁、中高层大气、固体地球等方面的数据"
      }, {
         name: "FLUXNET(全球采集的通量和气象数据)",
         url: "https://fluxnet.fluxdata.org/",
         introduce: "全球采集的通量和气象数据已提交给中央数据库（www.fluxdata.org）。每个塔站点，涡流协方差方法用于量化生物圈和大气之间的标量通量（例如CO2，CH4，水蒸气）和能量（例如，敏感，潜热）。此外，从高时间分辨率的大量传感器获取辅助物理变量（例如，气温，降水，辐射）的连续测量。"
      }, {
         name: "GGOS Atmosphere(全球大地测量观测系统的大气信息)",
         url: "http://ggosatm.hg.tuwien.ac.at/proj_ggosatm.html",
         introduce: "由奥地利维也纳大学测绘与地理信息系运营，用于归档，发布和分发描述空间大地测量学中大气效应的参数。 这些影响包括大气中的传播延迟（例如，GPS信号），大气负载或地球旋转的大气激发。"
      }, {
         name: "GISS Surface Temperature Analysis(GISTEMP，GISS表面温度分析)",
         url: "http://data.giss.nasa.gov/gistemp/",
         introduce: "GISS的计划领域大致可以分为气候强迫类别、 气候模式发展、 地球观测、 大气辐射、 大气化学、 气候影响、 行星大气层，系外行星和天体生物学、 古气候 和其他学科。"
      }, {
         name: "Goddard Earth Sciences Data and Information Services Center(戈达德地球科学数据和信息服务中心)",
         url: "https://daac.gsfc.nasa.gov/",
         introduce: "数据中心作为NASA科学任务监测（SMD）之一，为研究科学家、应用科学家、应用用户和学生提供地球科学数据、信息和服务。 GES DISC是NASA降水和水文学的归档，以及大气组成和动力学遥感数据和信息。"
      }, {
         name: "Indian Space Science Data Center（ISSDC，印度空间科学数据中心）",
         url: "http://www.issdc.gov.in/",
         introduce: "有效载荷数据及相关辅助数据空间科学任务的数据"
      }, {
         name: "Civil Aircraft for the Regular Investigation of the atmosphere Based on an Instrument Container",
         url: "http://www.caribic-atmospheric.com/",
         introduce: "CARIBIC是基于飞机的科学项目，研究全球大气成分和气候变化，是EUROPE的全球大气监测解决方案。CARIBIC容器包括臭氧，一氧化碳，二氧化碳，氮氧化物，甲烷，水蒸气，水同位素，丙酮和乙腈，汞，生物气溶胶，尘埃颗粒等等的现场测量设备。CARIBIC容器包括一个用于收集超过100个空气样本的系统。这些空气样本在每个飞行系列后都会在实验室进行分析。对于每个样品，我们测量超过40种气体，包括臭氧杀手（CFC's）和所有温室气体。"
      }, {
         name: "中国遥感数据共享网",
         url: "http://rs.ceode.ac.cn/",
         introduce: "国内存档周期最长的数据网站，对Landsat数据免费共享，也可订购国外商业卫星数据。注册账号，通过审核就可直接下载"
      }, {
         name: "中国资源卫星应用中心",
         url: "http://www.cresda.com/CN/sjfw/zxsj/index.shtml",
         introduce: "我国三大卫星应用中心之一，汇集国产卫星数据，注册后可下载HJ卫星数据，高分数据下载需要审核。"
      }, {
         name: "全球变化科学研究数据出版系统",
         url: "http://www.geodoi.ac.cn/WebCn/Default.aspx",
         introduce: "数据种类非常丰富，涉及领域较多，根据需要自己查找。账号注册，直接下载即可"
      }, {
         name: "空间科学虚拟观测台",
         url: "http://vsso.cssdc.ac.cn",
         introduce: "空间科学虚拟观测台数据资源覆盖空间物理、空间天文、行星科学、空间地球科学四大学科，已建成24个专业数据库"
      }, {
         name: "中国南北极数据中心",
         url: "http://www.chinare.org.cn/en/index/",
         introduce: "国家海洋局中国极地中心主办的极地数据共享与服务管理平台，开展极地科学数据的国内外交流与共享"
      }, {
         name: "地理国情监测云平台",
         url: "http://www.dsac.cn/",
         introduce: "云平台主要包含两部分——时空数据平台和数值模拟研究平台，特别是在现已建成的生态环境科学模型库的基础上，发展了数值模拟相关的工具库，并与时空数据平台进行集成，形成了具有国内领先水平的生态环境科学数值研究环境。"
      }, {
         name: "遥感集市",
         url: "http://www.rscloudmart.com/",
         introduce: "是遥感行业首个一站式遥感云服务平台，由中科遥感集团与中国资源卫星应用中心联合发布"
      }, {
         name: "美国地质调查局USGS",
         url: "https://www.usgs.gov/",
         introduce: "数据资源有Landsat、MODIS、时序产品等"
      }, {
         name: "欧空局ESA 哨兵数据",
         url: "https://scihub.copernicus.eu/",
         introduce: "sentinel-1号SAR数据和sentinel-2号光学数据免费下载，数据质量很好"
      }, {
         name: "美国国家航空航天局地球观测系统数据和信息系统",
         url: "http://sedac.ciesin.columbia.edu/data/sets/browse",
         introduce: "NASA联合美国大学做的社会经济数据共享系统，涉及的领域广泛，数据的描述十分详细"
      }, {
         name: "Open Topography",
         url: "http://www.opentopography.org",
         introduce: "提供高空间分辨率的地形数据和操作工具的门户网站，可以在此下载Lidar数据"
      }, {
         name: "Landsat8下载共享系统",
         url: "http://ids.ceode.ac.cn/query.html",
         introduce: "提供THEOS-1、LANDSAT系列、IRS-P6、ENVISAT-1、ERS-2数据"
      }, {
         name: "欧洲航天局对地观测目标订购服务中心",
         url: "https://earth.esa.int/web/guest/eoli",
         introduce: "收录数据主要包括Envisat, ERS, IKONOS, DMC, ALOS, SPOT, Kompsat, Proba, IRS, SCISAT等"
      }, {
         name: "National Institute for Space Research",
         url: "http://www.dgi.inpe.br/CDSR/",
         introduce: "是由中国与巴西合作支持，主要数据源自中巴合作项目，两颗资源卫星CBERS-2和CBERS-2b，也收录了CBERS、Landsat,、ResourceSat、S-NPP、Terra & UK-DMC 2等项目的数据，但所包含数据仅局限在南美以及非洲地区"
      }, {
         name: "印度航天研究组织Bhuvan Indian Geo-Platform of ISRO",
         url: "http://bhuvan.nrsc.gov.in/data/download/index.php",
         introduce: "主要数据源为IMS-1、Cartosat、OceanSat、ResourceSat等印度自己的卫星。该平台还提供印度以外的全球NDVI指数，南亚地区的3R1数据和印度洋周边国家的气候产品数据"
      }, {
         name: "JAXA’s Global ALOS 3D World–日本宇宙航空研究机构",
         url: "http://www.eorc.jaxa.jp/ALOS/en/aw3d30/",
         introduce: "ALOS World 3D是一个30米空间分辨率的数字地表模型"
      }, {
         name: "Global Land Cover Facility",
         url: "http://landcover.org/",
         introduce: "提供由Landsat、MODIS和AVHRR遥感影像衍生的数据产品GLCF Landcover"
      }, {
         name: "Geo-Airbus",
         url: "http://www.intelligence-airbusds.com/en/23-sample-imagery",
         introduce: "提供的卫星数据源包括SPOT、Pleiades,、RapidEye以及TerraSAR等"
      }, {
         name: "UNAVCO Research Data",
         url: "http://www.unavco.org/data/imaging/sar/data-access-methods/SarArchive/flexweb/SearchSarScene.html",
         introduce: "主要利用合成孔径雷达卫星数据研究洪水、板块构造、地震等方面，直接注册，数据免费。"
      }, {
         name: "Alaska Science Center",
         url: "http://alaska.usgs.gov/index.php",
         introduce: "通过提供及时客观的动植物、矿产资源的观测数据及研究成果，帮助政府和公众理解高度复杂多样化的阿拉斯加-北极生态系统综合景观，为矿产和能源开发决策提供数据支持"
      }, {
         name: "British Antarctic Survey",
         url: "https://www.bas.ac.uk/data/our-data/",
         introduce: "提供南极大陆的大气、生物圈、岩石圈、水圈、冰雪圈、太阳和地球相互作用的元数据和数据"
      }, {
         name: "中国国家地球系统科学数据中心",
         url: "http://geospace.geodata.cn/",
         introduce: "提供电离层、地磁、中高层大气、固体地球等方面的数据"
      }, {
         name: "全国地理信息资源目录服务系统",
         url: "https://link.zhihu.com/?target=http%3A//www.webmap.cn/main.do%3Fmethod%3Dindex",
         introduce: "各类空间数据，包括地表覆盖、测量成果、遥感影像、高程模型、栅格地图等"
      }, {
         name: "自然资源部标准地图服务",
         url: "https://link.zhihu.com/?target=http%3A//bzdt.ch.mnr.gov.cn/",
         introduce: "标准地图依据中国和世界各国国界线画法标准编制而成。社会公众可以免费浏览、下载标准地图，直接使用标准地图时需要标注审图号"
      }, {
         name: "资源环境数据云平台",
         url: "https://link.zhihu.com/?target=http%3A//www.resdc.cn/",
         introduce: "中科院建立的资源环境科学数据集成、派生、共享与数值模拟的平台。数据与我国现状契合度很高。"
      }, {
         name: "土地调查成果共享应用服务平台",
         url: "https://link.zhihu.com/?target=http%3A//tddc.mnr.gov.cn/to_Login",
         introduce: "以行政区为划分依据，提供包含土地利用数据在内的多种数据。"
      }, {
         name: "Natural Earth",
         url: "https://link.zhihu.com/?target=http%3A//www.naturalearthdata.com/",
         introduce: "提供了全球范围内的矢量和影像数据，且数据是开放性的"
      }, {
         name: "OpenStreetMap",
         url: "https://link.zhihu.com/?target=https%3A//www.openstreetmap.org/",
         introduce: "一款由网络大众共同打造的免费开源、可编辑的地图服务，可以下载到很多建筑、道路等数据。"
      }, {
         name: "天地图-国家地理信息公共服务平台",
         url: "https://link.zhihu.com/?target=https%3A//www.tianditu.gov.cn/",
         introduce: "可以下载矢量地图、影像地图、地形渲染、地名注记等内容的开放性资源。"
      }, {
         name: "OpenTopography",
         url: "https://link.zhihu.com/?target=https%3A//opentopography.org/",
         introduce: "提供全球范围内高空间分辨率的地形数据和操作工具的门户网站"
      }, {
         name: "SRTM数据",
         url: "https://link.zhihu.com/?target=https%3A//opentopography.org/",
         introduce: "由美国航空航天局（NASA）和国防部国家测绘局（NIMA)联合测量的，SRTM的全称Shuttle Radar Topography Mission，即航天飞机雷达地形测绘使命。SRTM的数据是用16位的数值表示高程数值的，最大的正高程9000m，负高程海平面以下12000m。"
      }, {
         name: "中国科学院遥感与数字地球研究所对地观测数据共享计划",
         url: "https://link.zhihu.com/?target=http%3A//ids.ceode.ac.cn/",
         introduce: "中国科学院空天信息创新研究院开发，数据主要是不同时期的影像地图等"
      }, {
         name: "国家地理空间信息中心",
         url: "https://link.zhihu.com/?target=http%3A//sgic.geodata.gov.cn/",
         introduce: "提出地理空间信息整合共享的政策措施和标准规范；承担国家自然资源和地理空间基础信息库建设与运行的具体工作；为国家及有关部门和社会提供地理空间信息产品及相关服务；指导地方开展自然资源和地理空间基础信息库建设与应用服务。"
      }, {
         name: "USGS Earthquake Hazards Program",
         url: "https://link.zhihu.com/?target=https%3A//earthquake.usgs.gov/",
         introduce: "记录的所有地震的KML文件。可用作一个数据集或按大小或年份分组。"
      }, {
         name: "全球地震灾害评估计划(GSHAP)",
         url: "https://link.zhihu.com/?target=http%3A//gmo.gfz-potsdam.de/",
         introduce: "通过网格化数据展示全球地震活动的危险风险"
      }, {
         name: "中分辨率成像光谱仪（MODIS）",
         url: "https://link.zhihu.com/?target=https%3A//modis.gsfc.nasa.gov/",
         introduce: "是美国宇航局研制大型空间遥感仪器，以了解全球气候的变化情况以及人类活动对气候的影响。"
      }, {
         name: "寒区旱区科学数据中心",
         url: "https://link.zhihu.com/?target=http%3A//data.casnw.net/portal/",
         introduce: "包含冰川、冻土、积雪、气象、水文、基础地理多个子库。收集、整合我国冰雪、多年冻土各类调查和监测资料以及生态和社会经济发展基础数据以及已建立的不同类型的数据库数据资源和各重大项目已经产生、正在产生和持续采集的冰川、冻土、积雪、水文、生态、社会经济相关数据。"
      }, {
         name: "CASEarth Databank",
         url: "http://databank.casearth.cn/",
         introduce: "提供长时序的多源对地观测数据即得即用产品集，包括1986年中国遥感卫星地面站建设以来20万景（每景12种产品，共计240万个产品）的长时序陆地卫星数据产品，基于高分卫星1/2、资源3号卫星等国产高分辨率遥感卫星数据制作的2米分辨率动态全国一张图，利用高分卫星、陆地卫星等国内外卫星数据制作的30米分辨率动态全球一张图，以及重点区域的亚米级即得即用产品集等。"
      }, {
         name: "环境云",
         url: "http://www.envicloud.cn/home?title=0",
         introduce: "提供气象、环境、灾害、地理数据共四个方面的内容"
      }, {
         name: "北京大学地理数据平台",
         url: "https://geodata.pku.edu.cn/",
         introduce: "是由北京大学城市与环境学院于2017年建立。平台建立的宗旨是为本院和地理专业的学生、教师以及科研人员提供全面、丰富、专业的数据信息，力图搭建一个地理数据共享交流的平台，构建从不同方向描述地球系统的数据库。数据包括自然地理、人文地理、历史地理等多个地理专业的图形图像、统计图表、文本文件及监测和模型运算得到的数据，数据范围包括全国及全球范围，数据来源于我院科研成果和积累的数据，以及平台收集整理的数据。数据平台的建设得到了学校科研部、设备部的大力支持，也得到了中国宇航学会卫星专业委员会提供的帮助。平台管理由北京大学城市与环境学院教授组成的地理数据平台小组负责，平台运行维护由人地系统与环境遥感实验室负责"
      }]
   },


   {
      id: 5,
      name: '环境',
      list: [{
         name: "国家极地科学数据中心",
         url: "https://www.chinare.org.cn/",
         introduce: "主要开展极地雪冰-海洋与全球变化、极区电离层-磁层耦合与空间天气、极地生态环境及其生命过程以及极地科学基础平台技术等领域的研究"
      }, {
         name: "国家青藏高原科学数据中心",
         url: "https://data.tpdc.ac.cn/zh-hans/",
         introduce: "数据中心以青藏高原及周边地区各类科学数据为主，已整合的数据资源包括：大气、冰冻圈、水文、生态、地质、地球物理、自然资源、基础地理、社会经济等，开发了在线大数据分析、模型应用等功能，实现青藏高原科学数据、方法、模型与服务的广泛集成。"
      }, {
         name: "国家生态科学数据中心",
         url: "http://www.cnern.org/",
         introduce: "野外生态系统观测研究台站资源整合、标准化和规范化"
      }, {
         name: "国家气象科学数据中心",
         url: "http://data.cma.cn",
         introduce: "中国气象局面向国内和全球用户开放气象数据资源的权威的、统一的共享服务平台"
      }, {
         name: "国家地球系统科学数据中心",
         url: "http://www.geodata.cn/index.html",
         introduce: "整合集成分布在国内外数据库中心群、高等院校、科研院所以及科学家个人产生的数据资源，引进国际数据资源，接收国家重点科研项目产生的数据资源，在此基础上生产加工数据产品"
      }, {
         name: " 国家冰川冻土沙漠科学数据中心",
         url: "http://www.ncdc.ac.cn/portal/",
         introduce: "收集整理、存储我国乃至世界范围内寒区旱区领域的科学数据，为我国寒区旱区科学研究服务"
      }, {
         name: "国家材料腐蚀与防护科学数据中心",
         url: "http://www.corrdata.org.cn/index.php",
         introduce: "由北京科技大学牵头，联合民口部门和国防部门共同建设运行的国家级材料环境腐蚀和防护数据生产积累平台"
      }, {
         name: "国家农业科学数据中心",
         url: "https://www.agridata.cn/#/home",
         introduce: "由中国农业科学院农业信息研究所主持，中国农业科学院部分专业研究所、中国水产科学研究所、中国热带农业科学院等单位参加"
      }, {
         name: "国家林业和草原科学数据中心",
         url: "http://www.forestdata.cn/index.html",
         introduce: "集成并建立了168个数据库，数据实体总量达1111GB，初步形成了全面、系统的林业科学数据体系。"
      }, {
         name: "国家海洋科学数据中心",
         url: "http://mds.nmdis.org.cn/",
         introduce: "国家海洋信息中心、北海分局信息中心、东海分局信息中心及南海分局信息中心组成平台主中心，负责统筹海洋科学数据汇交和国家级平台建设运维工作"
      }, {
         name: "青悦开放环境数据中心",
         url: "http://data.epmap.org/",
         introduce: "政府发布的公开数据，青悦获取并整理、开放。"
      }, {
         name: "全球科研项目数据库",
         url: "http://project.llas.ac.cn/",
         introduce: "汇聚了全球主要发达国家重要基金组织2000年以来的科研项目数据200多万条"
      }, {
         name: "中国通量观测研究网络",
         url: "http://www.chinaflux.org/index.aspx",
         introduce: "8个生态站所获取的常规气象数据、通量数据以及部分辅助数据。"
      }, {
         name: "资源环境科学与数据中心",
         url: "http://www.resdc.cn/Default.aspx",
         introduce: "“中心本部”和9个“分中心”，一个科学数据集与共享平台"
      }, {
         name: "资源学科创新平台",
         url: "http://www.data.ac.cn/",
         introduce: "以人口、资源、环境和发展（PRED）为核心的数据库服务"
      }, {
         name: "AWS公共数据集",
         url: "https://registry.opendata.aws/",
         introduce: "地理空间和环境、基因组和生命科学、机器学习数据集"
      }, {
         name: "欧洲开放数据门户",
         url: "https://data.europa.eu/euodp/en",
         introduce: "社会、科学、环境、经济、金融等数据"
      }, {
         name: "PANGAEA",
         url: "https://www.pangaea.de/",
         introduce: "地球和环境科学数据的发布平台，由德国阿尔弗雷德-魏格纳极地与海洋研究所、布莱梅大学海洋环境科学中心共建"
      }, {
         name: "地理空间数据云",
         url: "http://www.gscloud.cn/",
         introduce: "以中国科学院及国家的科学研究为主要需求，逐渐引进当今国际上不同领域内的国际数据资源，并对其进行加工、整理、集成，最终实现数据的集中式公开服务、在线计算等"
      }, {
         name: "NOAA CLASS",
         url: "https://www.avl.class.noaa.gov/saa/products/welcome;jsessionid=B3B84D7D221AF40B4FC13BBAEADB34C0",
         introduce: "美国国家海洋和大气管理局，面向大海的星辰"
      }, {
         name: "Data Access Viewer",
         url: "https://coast.noaa.gov/dataviewer/#",
         introduce: "NOAA美国国家海洋和大气管理局"
      }, {
         name: "国务院",
         url: "http://www.gov.cn/index.htm",
         introduce: "各类全国基础数据，34个职部委职能相关数据"
      }, {
         name: "欧盟数据门户",
         url: "https://www.europeandataportal.eu/",
         introduce: "包括地理、统计、气象数据，以及来自公共资金研究项目的数据和数字化图书等，平台中的数据集均由欧盟收集、购买，再免费提供给所有的用户，来自欧盟以及其他地区的用户都可以到平台上直接下载数据用于分析、开发创新服务或应用等用途。"
      }, {
         name: "和鲸社区",
         url: "https://www.heywhale.com/home/dataset",
         introduce: "是国内知名的开放数据科学社区,是最早一批专注于大数据算法比赛的平台,支持在线分享代码、项目和数据集"
      }, {
         name: "中国科技共享网",
         url: "https://escience.org.cn/",
         introduce: "涉及到地理学、气象学、微生物学、基因组学、医学、农学、物理学等多个学科"
      }, {
         name: "中国工程科技知识中心",
         url: "http://www.ckcest.cn/home/",
         introduce: "收录了全球250个国家/地区、20多个国内外主要机构的770条指标、近700万条数据,覆盖经济、人口、资源、科技、环境等领域"
      }, {
         name: "Atlantic Oceanographic and Meteorological Laboratory",
         url: "http://www.aoml.noaa.gov/data/",
         introduce: "大西洋海洋与气象实验室的环境数据服务器（ENVIDS）提供交互式在线访问位于AOML的各种海洋和大气数据集。内部数据集包括大西洋消耗热水图（XBT），全球拉格朗日漂流浮标，飓风飞行高度和大西洋飓风曲目（北大西洋最佳轨道和天气）。另外，可用数据集还包括太平洋电导/温度/深度记录仪（CTD）和1998年世界海洋图集。"
      }, {
         name: "Atmospheric Infrared Sounder",
         url: "https://airs.jpl.nasa.gov/data/overview",
         introduce: "AIRS进行气候研究和天气预测，美国宇航局地球观测系统的一部分，是六个仪器之一。与微波仪器先进微波探测装置单元AMSU-A一起，代表在太空中部署的最先进的大气探测系统。这些仪器一起观测全球水和能源循环、气候变化和趋势，以及气候系统对温室气体增加的反应。"
      }, {
         name: "Atmospheric Science Data Center，NASA Langley Research Center",
         url: "https://eosweb.larc.nasa.gov/",
         introduce: "NASA Langley研究中心的大气科学数据中心（ASDC）负责NASA地球科学数据在辐射预算，云层，气溶胶和对流层构成等领域的处理，存档和分发工作。"
      }, {
         name: "British Geological Survey（英国地质调查局）",
         url: "http://www.bgs.ac.uk/",
         introduce: "是世界上最古老的国家地质调查，有超过400个数据集，包括环境监测数据，数字数据库，物理收集（钻孔核心，岩石，矿物和化石）的记录和档案。"
      }, {
         name: "Clouds and the Earth's Radiant Energy System(云和地球的辐射能量系统)",
         url: "http://ceres.larc.nasa.gov/index.php",
         introduce: "云和地球辐射能量系统（CERES）是地球观测系统（EOS）计划的关键组成部分。 CERES仪器从三个宽带通道提供地球大气的辐射测量。 CERES产品包括从大气顶部到地球表面的太阳能反射和地球发射的辐射。"
      }, {
         name: "Computational and Information Systems Laboratory Research Data Archive",
         url: "http://rda.ucar.edu/",
         introduce: "由NCAR的计算和信息系统实验室的数据支持部门管理的CISL研究数据存档（RDA），包含大量多样的气象和海洋学系列 观测，运行和再分析模型输出以及支持大气和地球科学研究的遥感数据集，以及地形/测深，植被和土地利用等辅助数据集。"
      }, {
         name: "FLUXNET(全球采集的通量和气象数据)",
         url: "https://fluxnet.fluxdata.org/",
         introduce: "全球采集的通量和气象数据已提交给中央数据库（www.fluxdata.org）。每个塔站点，涡流协方差方法用于量化生物圈和大气之间的标量通量（例如CO2，CH4，水蒸气）和能量（例如，敏感，潜热）。此外，从高时间分辨率的大量传感器获取辅助物理变量（例如，气温，降水，辐射）的连续测量。"
      }, {
         name: "GISS Surface Temperature Analysis(GISTEMP，GISS表面温度分析)",
         url: "http://data.giss.nasa.gov/gistemp/",
         introduce: "GISS的计划领域大致可以分为气候强迫类别、 气候模式发展、 地球观测、 大气辐射、 大气化学、 气候影响、 行星大气层，系外行星和天体生物学、 古气候 和其他学科。"
      }, {
         name: "Goddard Earth Sciences Data and Information Services Center(戈达德地球科学数据和信息服务中心)",
         url: "https://daac.gsfc.nasa.gov/",
         introduce: "数据中心作为NASA科学任务监测（SMD）之一，为研究科学家、应用科学家、应用用户和学生提供地球科学数据、信息和服务。 GES DISC是NASA降水和水文学的归档，以及大气组成和动力学遥感数据和信息。"
      }, {
         name: "GOES Space Environment Monitor(GOES空间环境监测)",
         url: "http://www.ngdc.noaa.gov/stp/satellite/goes/",
         introduce: "GOES空间环境监测档案是国家空间气象计划的重要组成部分 - 提供及时可靠的空间环境观测和预报机构间方案。 GOES卫星载有一个空间环境监测子系统，用于测量航天器的X射线，能量粒子和磁场。"
      }, {
         name: "International Arctic Systems for Observing the Atmosphere(国际北极观测系统)",
         url: "http://www.esrl.noaa.gov/psd/iasoa/dataataglance",
         introduce: "国际北极观测系统（IASOA）提供并收集了有关北极大气条件的多年连续数据。国际极地年（IPY）与来自瑞典，加拿大，美国，俄罗斯，挪威，芬兰和格陵兰的北极天文台保持合作。数据内容有标准气象，温室气体，大气辐射，云，污染物，化学，气溶胶和地表能量平衡。"
      }, {
         name: "Bedford Institute of Oceanography - Oceanographic Databases",
         url: "http://www.bio.gc.ca/science/data-donnees/base/index-en.php",
         introduce: "有海洋观测数据"
      }, {
         name: "British Geological Survey",
         url: "http://www.bgs.ac.uk/",
         introduce: "英国地质调查局（BGS）是世界上最古老的国家地质调查，有超过400个数据集，包括环境监测数据，数字数据库，物理收集（钻孔核心，岩石，矿物和化石）的记录和档案。"
      }, {
         name: "Civil Aircraft for the Regular Investigation of the atmosphere Based on an Instrument Container",
         url: "http://www.caribic-atmospheric.com/",
         introduce: "CARIBIC是基于飞机的科学项目，研究全球大气成分和气候变化，是EUROPE的全球大气监测解决方案。CARIBIC容器包括臭氧，一氧化碳，二氧化碳，氮氧化物，甲烷，水蒸气，水同位素，丙酮和乙腈，汞，生物气溶胶，尘埃颗粒等等的现场测量设备。CARIBIC容器包括一个用于收集超过100个空气样本的系统。这些空气样本在每个飞行系列后都会在实验室进行分析。对于每个样品，我们测量超过40种气体，包括臭氧杀手（CFC's）和所有温室气体。"
      }, {
         name: "CUAHSI ",
         url: "https://www.cuahsi.org/",
         introduce: "提供水文分析工具标准平台及数据等。提供一些水资源相关的数据门户"
      }, {
         name: "Database for Hydrological Time Series of Inland Waters",
         url: "http://dahiti.dgfi.tum.de/en/",
         introduce: "提供湖泊、水库、江河等水温数据"
      }, {
         name: "Earth System Research Laboratory Global Monitoring Division",
         url: "https://www.esrl.noaa.gov/gmd/dv/data/",
         introduce: "美国国家海洋和大气管理局NOAA / ESRL全球监测司（前称CMDL）检测改变地球气候的大气成分的来源、趋势、全球分布，研究大气辐射环境、可能会导致全球臭氧层枯竭的因素，以及影响基准空气质量的因素等等。GMD主要通过长期测量全球各地的关键大气成分，包括二氧化碳、一氧化碳、甲烷、一氧化二氮、地表和平流层臭氧、CFC替代物在内的卤化物、碳氢化合物、含硫气体、气溶胶以及太阳和红外辐射"
      }, {
         name: "ECCAD",
         url: "http://eccad.aeris-data.fr/",
         introduce: "ECCAD项目的主要目标是为科学和政策用户提供大气化合物的表面排放数据及辅助数据，即估计或量化表面排放所需的数据。辅助数据例如人口密度图，火灾地图，燃烧区域，土地覆盖物等可以帮助改善和鼓励新的排放数据集的开发。ECCAD是一个动态和交互式数据库，提供最新的数据集，包括正在进行的项目中使用的数据。"
      }, {
         name: "EDGAR",
         url: "http://edgar.jrc.ec.europa.eu/",
         introduce: "全球大气排放研究数据库(EDGAR)提供了全球过去和现今人为排放温室气体和空气污染物的国家和空间网格分布。"
      }, {
         name: "Environmental Information Platform",
         url: "http://www.nerc.ac.uk/",
         introduce: "提供有水质的相关数据"
      }, {
         name: "European Soil Data Centre",
         url: "https://esdac.jrc.ec.europa.eu/",
         introduce: "提供与欧洲土壤相关的数据"
      }, {
         name: "Experimental Lakes Area",
         url: "https://www.iisd.org/ela/",
         introduce: "淡水研究"
      }, {
         name: "Historical hydrographic data from BSH",
         url: "http://icdc.cen.uni-hamburg.de/daten/ocean/historical-hydrographic-bsh.html",
         introduce: "海洋水文研究相关数据"
      }, {
         name: "Woods Hole Open Access Server",
         url: "http://darchive.mblwhoilibrary.org/",
         introduce: "该网站是伍兹霍尔的数据仓储，提供有海洋水文相关的数据"
      }, {
         name: "Ozone Mapping and Profiler Suite",
         url: "http://ozoneaq.gsfc.nasa.gov/omps/",
         introduce: "测量上层大气中的臭氧层，跟踪全球臭氧分布状况，包括“臭氧空洞”，还监测对流层的臭氧水平"
      }, {
         name: "地球系统科学数据共享网、可再生资源与环境世界数据中心",
         url: " http://eng.wdc.cn/",
         introduce: "中国科学院地理科学与资源研究所主办，中国资源和环境领域的长期数据归档和共享中心"
      }, {
         name: "VITO Vision",
         url: "http://www.vito-eodata.be/PDF/portal/Application.html#Home",
         introduce: "该网站提供全球的低分辨率植被指数"
      }, {
         name: "NOAA Digital Coast",
         url: "https://coast.noaa.gov/digitalcoast/",
         introduce: "提供沿海区域的数据，主要包括海底数据、海拔、图像、土地覆盖以及社会经济数据等，数据包括雷达、红外和真彩色影像数据等"
      }, {
         name: "Aquastat",
         url: "http://www.fao.org/nr/water/aquastat/main/index.stm",
         introduce: "粮农组织的全球水信息系统，提供水资源、水利用、灌溉和排水、废水处理等各个主题的数据"
      }, {
         name: "World Health Organization",
         url: "http://www.who.int/gho/en/",
         introduce: "探索健康数据的世界"
      }, {
         name: "全球大气研究排放数据库",
         url: "https://data.jrc.ec.europa.eu/collection/EDGAR",
         introduce: "包括所有温室气体，空气污染物和气溶胶的排放。列出了所有国家/地区的数据，并按主要排放源类别提供了排放量，并在全球范围内按0.1x0.1网格对空间进行了分配。"
      }, {
         name: "Centre for Ecology & Hydrology: Data",
         url: "http://www.ceh.ac.uk/data",
         introduce: "英国生态水文中心提供的长期的全国规模的包括降雨、干旱、湖泊等水数据"
      }, {
         name: "Eurostat Water statistics",
         url: "http://ec.europa.eu/eurostat/statistics-explained/index.php/Water_statistics",
         introduce: "提供欧盟淡水资源、取水、用水和废水处理和处置相关的统计数据"
      }, {
         name: "FAO GeoNetwork",
         url: "http://www.fao.org/geonetwork/srv/en/main.home",
         introduce: "一个全球地理信息系统数据集，提供农业、渔业、土地资源相关的GIS数据以及相关卫星图像数据"
      }, {
         name: "Fishbase",
         url: "http://www.fishbase.org/home.htm",
         introduce: "Fishbase是拥有超过30,000种鱼类物种信息的全球鱼类百科全书。fishbase的信息包括鱼类的常用name、属名、物种名、地理分布、生态系统地位、相关参考文献等"
      }, {
         name: "Global Groundwater Information System",
         url: "https://www.un-igrac.org/global-groundwater-information-system-ggis",
         introduce: "提供地下水相关信息"
      }, {
         name: "History of Marine Animal Populations",
         url: "https://hydra.hull.ac.uk/resources?utf8=%E2%9C%93&search_field=all_fields&q=hmap",
         introduce: "HMAP数据页面向研究者提供渔业捕获量随时间空间的变化数据，旨在揭示生态环境动态改变、人类活动对生态环境的影响、海洋资源在人类社会发展过程中的作用"
      }, {
         name: "WorldClim",
         url: "https://link.zhihu.com/?target=https%3A//www.worldclim.org/",
         introduce: "世界范围内的气候变化数据"
      }, {
         name: "国NCEP/NCAR气候变化情景",
         url: "https://link.zhihu.com/?target=https%3A//ral.ucar.edu/solutions/products/gis-climate-change-scenarios",
         introduce: "针对NCEP/NCAR再分析数据集是由美国气象环境预报中心（NCEP）和美国国家大气研究中心（NCAR）联合制作的，他们采用了当今最先进的全球资料同化系统和完善的数据库，对各种来源（地面、船舶、无线电探空、测风气球、飞机、卫星等）的观测资料进行质量控制和同化处理，获得了一套完整的再分析资料集。"
      }, {
         name: "世界海洋气候学数据库（CLIWOC）",
         url: "https://link.zhihu.com/?target=https%3A//link.springer.com/article/10.1007/s10584-005-6952-6",
         introduce: "从1750年至1850年的航行期间编制的船舶日志汇编的数据。包括按日期，船舶和年份分类的各种气象观测资料。"
      }, {
         name: "全球水库和大坝数据库",
         url: "https://link.zhihu.com/?target=https%3A//www.taodocs.com/p-39742537.html",
         introduce: "文字版数据"
      }, {
         name: "IIWQ",
         url: "https://link.zhihu.com/?target=http%3A//sdg6.worldwaterquality.org/",
         introduce: "提供几乎全球每个角落海岸线的四项水质情况数据(南极洲和北极圈部分陆域除外)，所涉及的水质指标包括浊度(Turbidity)、叶绿素a(Chlorophyll-a)、有害藻类指数(HAB Indicator)和总吸收度(Total Absorption)，通过不同的颜色表真相应的浓度级别，通过地图可直观了解全球各地区水质情况。"
      }, {
         name: "生物圈图集",
         url: "https://link.zhihu.com/?target=http%3A//www.mab.cas.cn/tpk/",
         introduce: "网格化人类数据"
      }, {
         name: "ReefBase GIS",
         url: "https://link.zhihu.com/?target=http%3A//www.reefbase.org/gis_maps/datasets.aspx",
         introduce: "全球珊瑚礁的GIS数据，包括广泛的属性数据。"
      }, {
         name: "世界土壤数据库",
         url: "https://link.zhihu.com/?target=http%3A//westdc.westgis.ac.cn/data/611f7d50-b419-4d14-b4dd-4a944b141175",
         introduce: "将来自许多国家的区域和国家土壤数据库和地图结合起来，分辨率为30弧秒。"
      }, {
         name: "Dryad",
         url: "https://datadryad.org/",
         introduce: "是一个储存医学、生物学、生态学数据的开放数据知识库，为学术文献提供基础设施，促进其重用，让学术论文的数据可被发现，可自由重复使用和可引用"
      }, {
         name: "数字丝路地球大数据平台",
         url: "http://www.opendbar.cn/",
         introduce: "该平台以“数字连接，共享繁荣”为主旨，包括“一带一路”资源、环境、气候、灾害、遗产等专题数据集94套、自主知识产权数据产品57类"
      }, {
         name: "微生物组数据云平台",
         url: "https://gcmeta.wdcm.org/",
         introduce: "是一个微生物基因组及微生物组数据的管理、分析和发布平台，为国内外用户提供一站式的从数据存储、数据分析到数据发布的服务，目前已经整合了来自中国科学院微生物组计划及国内外多个重要项目的数据"
      }, {
         name: "环境云",
         url: "http://www.envicloud.cn/home?title=0",
         introduce: "提供气象、环境、灾害、地理数据共四个方面的内容"
      }, {
         name: "IPE",
         url: "http://www.ipe.org.cn/index.html",
         introduce: "在分享数据之余，还出具相关报告，对企业『绿色供应链』、『绿色金融』等进行评估"
      }, {
         name: "EPS数据平台",
         url: "http://www.epsnet.com.cn/",
         introduce: "是由北京福卡斯特信息技术有限公司提供的“专业数据+预测分析”平台。收录国内外的经济、贸易、行业、科技领域的统计数据；利用内嵌的数据分析预测软件可完成对数据的分析和预测"
      }, {
         name: "北京大学地理数据平台",
         url: "https://geodata.pku.edu.cn/",
         introduce: "是由北京大学城市与环境学院于2017年建立。平台建立的宗旨是为本院和地理专业的学生、教师以及科研人员提供全面、丰富、专业的数据信息，力图搭建一个地理数据共享交流的平台，构建从不同方向描述地球系统的数据库。数据包括自然地理、人文地理、历史地理等多个地理专业的图形图像、统计图表、文本文件及监测和模型运算得到的数据，数据范围包括全国及全球范围，数据来源于我院科研成果和积累的数据，以及平台收集整理的数据。数据平台的建设得到了学校科研部、设备部的大力支持，也得到了中国宇航学会卫星专业委员会提供的帮助。平台管理由北京大学城市与环境学院教授组成的地理数据平台小组负责，平台运行维护由人地系统与环境遥感实验室负责"
      }]
   },

   {
      id: 6,
      name: '医学',
      list: [{
         name: " 国家人口健康科学数据中心",
         url: "https://www.ncmi.cn/index.html",
         introduce: "承担起国家科技重大专项、科技计划、重大公益专项等人口健康领域科学数据汇交、数据加工、数据存储、数据挖掘和数据共享服务的任务。"
      }, {
         name: "全球科研项目数据库",
         url: "http://project.llas.ac.cn/",
         introduce: "汇聚了全球主要发达国家重要基金组织2000年以来的科研项目数据200多万条"
      }, {
         name: "中医药学科学数据中心",
         url: "http://dbcenter.cintcm.com/",
         introduce: "中医药学领域各类科学数据的共建挖掘分析工作"
      }, {
         name: "公共卫生科学数据",
         url: "http://www.phsciencedata.cn/",
         introduce: "中国疾控中心信息中心负责"
      }, {
         name: "基础医学科学数据",
         url: "http://www.bmicc.cn/",
         introduce: "基础医学研究数据的收集、加工和汇总"
      }, {
         name: "国家药学科学数据",
         url: "http://www.pharmdata.ac.cn/",
         introduce: "药学及相关学科科学数据实现共享的管理和技术服务"
      }, {
         name: "临床医学科学数据",
         url: "http://101.201.55.39/",
         introduce: "由北京协和医院和中国人民解放军总医院共同承担"
      }, {
         name: "地方服务中心",
         url: "http://www.hcn2020.cn/",
         introduce: "为广大农村、社区居民等提供一个医学信息服务平台"
      }, {
         name: "和鲸社区",
         url: "https://www.heywhale.com/home/dataset",
         introduce: "是国内知名的开放数据科学社区,是最早一批专注于大数据算法比赛的平台,支持在线分享代码、项目和数据集"
      }, {
         name: "中国产业信息",
         url: "https://www.chyxx.com/",
         introduce: "工业和信息化部主管,人民邮电报社主办,是我国通信行业唯一拥有国务院新闻办授予新闻发布权的新闻网站。"
      }, {
         name: "Behavioral Risk Factor Surveillance System",
         url: "http://www.cdc.gov/brfss/",
         introduce: "是全美通过电话进行健康调查的第一大系统，调查收集美国居民的健康相关危险因素、慢性疾病以及对预防服务的使用。调查每年完成对超过40万人的采访，是世界最大的持续性健康调查系统。"
      }, {
         name: "European Prospective Investigation into Cancer and Nutrition Study",
         url: "http://epic.iarc.fr/index.php",
         introduce: "欧洲癌症和营养的前瞻研究，来自欧洲10多个国家23个中心52万多人参与研究，是世界上最大的有关癌症和其他慢性病的生化和遗传学调查的数据库之一，包括饮食、营养、生活方式、环境等因素与癌症和其他慢性病之间的关系。"
      }, {
         name: "National Addiction & HIV Data Archive Program",
         url: "http://www.icpsr.umich.edu/icpsrweb/NAHDAP/",
         introduce: "全国吸毒与艾滋病数据库收集、保存和分享与药物成瘾和艾滋病研究相关的数据。数据范围包括合法和非法的各种药物以及对这些药物的使用方式和使用结果预测。该数据库可为学者提供关于社会重大问题、行为科学与公共政策等方面的二次分析的机会。"
      }, {
         name: "Surveillance Epidemiology and End Results",
         url: "http://seer.cancer.gov/",
         introduce: "SEER是美国癌症统计数据的主要来源，收集与发病率、患病率和生存率相关的信息，这些地区占总人口的28%，编制有关国家癌症死亡率的相关报告。他们的目标是提供与癌症统计数据相关的信息，并减少国家人口中癌症的负担。SEER从1973年起就开始收集癌症病例的数据。"
      }, {
         name: "Medical Expenditure Panel Survey",
         url: "http://meps.ahrq.gov/mepsweb/",
         introduce: "医疗支出小组调查(MEPS)是对美国各地的家庭和个人，及其医疗提供者和雇主进行的大规模调查，是关于医疗保健和医疗保险的成本和使用的最完整的数据来源"
      }, {
         name: "癌症图像档案",
         url: "https://www.cancerimagingarchive.net/",
         introduce: "是一项可以de-identify和托管可供公众下载的大量癌症医学图像的服务"
      }, {
         name: "中经网产业数据库",
         url: "https://cyk.cei.cn/",
         introduce: "是国家信息中心中经网精心打造的、覆盖机械、汽车、电子通讯、医药、石油化工、能源、交通、房地产、钢铁、旅游等10多个产业集群的全新数据库产品。"
      }, {
         name: "Agency for Healthcare Research and Quality, Data & Surveys",
         url: "http://www.ahrq.gov/research/data/index.html",
         introduce: "包含美国健康信息知识库(USHIK)、系统评价数据存储库(SRDR)以及有关医疗和医疗保险的成本、质量、可访问性和评估等其他信息，帮助人们做出更加明智的决策，提高卫生保健服务的质量。"
      }, {
         name: "疾病预防控制中心",
         url: "https://data.cdc.gov/Case-Surveillance/COVID-19-Case-Surveillance-Public-Use-Data/vbim-akqf",
         introduce: "是由政府举办的实施国家级疾病预防控制与公共卫生技术管理和服务的公益事业单位。其使命是通过对疾病、残疾和伤害的预防控制，创造健康环境，维护社会稳定，保障国家安全，促进人民健康；其宗旨是以科研为依托、以人才为根本、以疾控为中心"
      }, {
         name: "美国农业部",
         url: "https://healthdata.gov/dataset/covid-19-reported-patient-impact-and-hospital-capacity-facility?SorourMo/38-Cloud-A-Cloud-Segmentation-Dataset",
         introduce: "通过获取、策划和传播解决当前和未来农业挑战所需的信息，促进农业知识的创造"
      }, {
         name: "The COVID Tracking Project ",
         url: "https://covidtracking.com/",
         introduce: "COVID 跟踪项目收集并发布美国各州和地区最完整的测试数据"
      }, {
         name: "EHDP",
         url: "http://www.ehdp.com/vitalnet/datasets.htm",
         introduce: "是用于分析健康数据集的现成的商用数据仓库/数据查询软件"
      }, {
         name: "NCI 的基因组数据共享 （GDC）",
         url: "https://gdc.cancer.gov/",
         introduce: "为癌症研究界提供了统一的数据存储库，使癌症基因组研究的数据共享能够支持精密医学。"
      }, {
         name: "The Centers for Medicare & Medicaid Services",
         url: "https://data.medicare.gov/",
         introduce: "探索和下载医疗保险提供商数据"
      }, {
         name: "OpenPaymentsData",
         url: "https://openpaymentsdata.cms.gov/",
         introduce: "用于搜索药品和医疗器械公司向医生和教学医院支付的款项"
      }, {
         name: "PhysioNet",
         url: "https://www.physionet.org/physiobank/database/",
         introduce: "开展和催化生物医学研究和教育，部分通过提供免费获取大量生理和临床数据以及相关的开源软件"
      }, {
         name: "The Cancer Imaging Archive",
         url: "https://portal.gdc.cancer.gov/",
         introduce: "可识别并托管大量癌症医学图像的存档，以供公众下载"
      }, {
         name: "Kaggle",
         url: "https://www.kaggle.com/datasets",
         introduce: "是由联合创始人、首席执行官安东尼·高德布卢姆（Anthony Goldbloom）2010年在墨尔本创立的，主要为开发商和数据科学家提供举办机器学习竞赛、托管数据库、编写和分享代码的平台。该平台已经吸引了80万名数据科学家的关注，这些用户资源或许正是吸引谷歌的主要因素"
      }, {
         name: "ALLEN BRAIN MAP",
         url: "https://portal.brain-map.org/",
         introduce: "提供神经影像学数据"
      }, {
         name: "CRCNS",
         url: "http://crcns.org/data-sets",
         introduce: "计算神经科学合作研究数据"
      }, {
         name: "HCP",
         url: "https://www.humanconnectome.org/study/hcp-young-adult",
         introduce: "包括1100多个健康年轻人的高质量神经影像数据"
      }, {
         name: "NIMH Data Archive",
         url: "https://nda.nih.gov/",
         introduce: "提供了从许多科学领域的数百个研究项目中收集到的人类受试者数据"
      }, {
         name: "NEURODATA",
         url: "https://neurodata.io/",
         introduce: "目标是与神经实验学家合作，通过构建和部署大规模运行的开源数据驱动工具，来发现控制思想与大脑之间关系的基本原理"
      }, {
         name: "NeuroMorpho.Org",
         url: "http://neuromorpho.org/",
         introduce: "是可公开访问的3D神经元重建和相关元数据的最大集合"
      }, {
         name: "NeuroElectro ",
         url: "https://neuroelectro.org/",
         introduce: "是从现有文献中提取有关各种神经元类型的电生理特性（例如静息膜电位和膜时间常数）的信息，并将其放入中央数据库中。 "
      }, {
         name: "OASIS-3",
         url: "https://www.oasis-brains.org/",
         introduce: "是影像研究开放获取系列（OASIS）中的最新版本，旨在使科学界免费使用神经影像数据集"
      }, {
         name: "OpenfMRI.org",
         url: "https://openfmri.org/",
         introduce: "是一个致力于自由共享原始磁共振成像（MRI）数据集的项目。 "
      }, {
         name: "GENIE",
         url: "https://www.synapse.org/#!Synapse:syn7222066/wiki/405659",
         introduce: "是一个可公开访问的国际癌症注册中心，它通过世界19个领先癌症中心之间的数据共享来组装真实数据"
      }, {
         name: "mendeley Data",
         url: "https://data.mendeley.com/",
         introduce: "是一个安全的基于云的存储库，您可以存储您的数据，确保无论您身在何处，都可以轻松共享、访问和引用数据"
      }, {
         name: "Tissue Image Analytics (TIA) Centre",
         url: "https://warwick.ac.uk/fac/cross_fac/tia/data/crchistolabelednucleihe/",
         introduce: "此数据集涉及 100 张结肠直肠癌的 H&E 染色组织学图像"
      }, {
         name: "DRIVE",
         url: "https://drive.grand-challenge.org/",
         introduce: "DRIVE数据库的照片来自荷兰的糖尿病视网膜病变筛查项目"
      }, {
         name: "European Prospective Investigation into Cancer and Nutrition Study ",
         url: "http://epic.iarc.fr/index.php",
         introduce: "是世界上最大的有关癌症和其他慢性病的生化和遗传学调查的数据库之一，包括饮食、营养、生活方式、环境等因素与癌症和其他慢性病之间的关系。"
      }, {
         name: "阿里云天池",
         url: "https://tianchi.aliyun.com/dataset/",
         introduce: "阿里旗下数据科学竞赛平台"
      }, {
         name: "HARVARD Dataverse",
         url: "https://dataverse.harvard.edu/",
         introduce: "是一个免费的数据存储库，向哈佛社区内外任何学科的所有研究人员开放"
      }, {
         name: "有机化合物核磁共振碳谱数据库",
         url: "http://210.32.137.90/s/lib/libtb/turning/1373",
         introduce: "微谱数据，即有机化合物核磁共振碳谱数据库，是专门为天然产物研究、有机合成和药物开发等研究工作设计的数据库。现已收录有机化合物约88万余个，是全球最大的有机化合物核磁共振碳谱数据库，适用于药学、化学、生命科学等相关专业"
      }, {
         name: "Medscape",
         url: "https://link.zhihu.com/?target=https%3A//reference.medscape.com/",
         introduce: "是世界上最大的免费提供临床医学全文文献和提供最新的医学新闻和专家视角；基本的护理药物和疾病信息；以及相关的专业教育和cme。也是最早的优秀医学专业门户之一，主要为临床医生和医务工作者提供高质量的专业医学信息"
      }, {
         name: "ClinicalTrials.gov",
         url: "https://clinicaltrials.gov/",
         introduce: "临床试验数据库"
      }, {
         name: "Dailymed",
         url: "https://link.zhihu.com/?target=https%3A//dailymed.nlm.nih.gov/dailymed/index.cfm",
         introduce: "该数据库可以检索到几乎所有药品的说明书"
      }, {
         name: "中国健康网",
         url: "http://www.healthoo.com/",
         introduce: "以健康,营养,健康知识为主体的,为网民提供健康、营养、医疗等健康信息和服务"
      }, {
         name: "CDC",
         url: "https://www.cdc.gov/",
         introduce: "美国疾控中心"
      }, {
         name: "National Center for Biotechnology Information (NCBI)",
         url: "http://www.ncbi.nlm.nih.gov/",
         introduce: "美国国家生物技术信息中心有基因库、（毒物）分子数据库、文献数据库等"
      }, {
         name: "MDchoice",
         url: "http://www.mdchoice.com/",
         introduce: "是一个由专家手工编制的医学专业搜索引擎，可按主题词进行检索，也可按提供的分类进行检索，其特点是影像学方面的内容特别丰富， 该网站提供了大量的病理切片、ECG、CT、MRI、核医学、超声医学方面的内容，其形式包括各种图片、照片、视频和动画"
      }, {
         name: "约翰霍普金斯传染病数据库",
         url: "http://www.hopkins-id.edu/",
         introduce: "包括肝炎、艾滋病、结核病"
      }, {
         name: "加利福尼亚大学，圣地亚哥-生物化学基因实验室 ",
         url: "https://www.ucsdbglab.org/",
         introduce: "为先天代谢缺陷提供各种诊断测试，有一数据库可帮你找到能做某种测试的实验室"
      }, {
         name: "STD序列数据库",
         url: "http://www.stdgen.lanl.gov/",
         introduce: "粒性结膜炎（沙眼）衣原体，性病支原体，梅毒螺旋体，人类乳头（状）瘤病毒 基因数据库"
      }, {
         name: "Dryad",
         url: "https://datadryad.org/",
         introduce: "是一个储存医学、生物学、生态学数据的开放数据知识库，为学术文献提供基础设施，促进其重用，让学术论文的数据可被发现，可自由重复使用和可引用"
      }, {
         name: "生命与健康大数据中心",
         url: "http://bigd.big.ac.cn/",
         introduce: "面向我国人口健康和社会可持续发展的重大战略需求，围绕国家精准医学和重要战略生物资源的组学数据，建立海量生物组学大数据储存、整合与挖掘分析研究体系，发展组学大数据系统构建、挖掘与分析的新技术、新方法，建设组学大数据汇交、应用与共享平台"
      }, {
         name: "ClinicalTrials",
         url: "https://www.clinicaltrials.gov/",
         introduce: "是一种基于网络的资源，为患者，其家庭成员，医疗保健专业人员，研究人员和公众提供方便地获取有关各种疾病和病症的公共和私人支持的临床研究的信息"
      }, {
         name: "中研网数据",
         url: "https://link.zhihu.com/?target=http%3A//www.chinairn.com/data/",
         introduce: "提供医疗、房产、制造业、服务业、零售消费、车辆等全行业数据"
      }, {
         name: "CINAHL",
         url: "http://nrs.harvard.edu/urn-3:hul.eresource:cinahlpl",
         introduce: "护理和其他健康相关信息；患者护理问题的绝佳来源"
      }, {
         name: "WHO Global Index Medicus",
         url: "https://www.globalindexmedicus.net/",
         introduce: "搜索世卫组织所有区域索引，包括东南亚和西太平洋区域数据库。"
      }]
   },
   {
      id: 7,
      name: '心理',
      list: [{
         name: "Aberdeen Birth Cohorts ",
         url: "http://www.abdn.ac.uk/birth-cohorts/",
         introduce: "跟踪1921年、1936年以及 1950-1956年在阿伯丁出生的孩子的成长、变老过程，研究生物和社会对人一生以及两代人健康的影响。"
      }, {
         name: "Pacific Islands Families Study ",
         url: "http://www.niphmhr.aut.ac.nz/research-centres/centre-for-pacific-health-and-development-research/pacific-islands-families-study",
         introduce: "太平洋岛屿家庭研究是对1398名儿童13岁之前开展的一项纵向研究项目，主要涉及这些儿童以及他们的家庭在不同时间阶段的健康状况以及心理社会功能。"
      }, {
         name: "Tracking Adolescents' Individual Lives Survey ",
         url: "http://www.trails.nl/",
         introduce: "青少年个人生活追踪调查是对青少年心理、社会、生理发展进行的一种多学科研究，有2500多名青少年自10、11岁之后参加了调查，这些参与者在过去的15年中每隔2、3年通过问卷调查、访谈、测试以及物理检测来接受一次检查。该调查近期发起的一项研究将重点放在这些参与者的孩子身上"
      }, {
         name: "Korean Social Science Data Archive",
         url: "http://www.kossda.or.kr/",
         introduce: "研究领域：政治、公共行政和法律、经济和商业管理、社会文化、社会问题、社会福利、心理学和教育和区域研究。"
      }, {
         name: "American Psychological Association",
         url: "http://www.apa.org/research/responsible/data-links.aspx",
         introduce: "拥有大量的共享数据可用于心理学的科学研究，并且这些数据涵盖了多种研究方向"
      }, {
         name: "UCLA Library",
         url: "https://www.library.ucla.edu/",
         introduce: "网站中关于心理学数据集共有三个子目录可供选择：数据集和存储库列表(Data Set and Repository Lists)、其他数据存储库和资源(Other Data Repositories and Resources)及心理学数据资源(Sources for Psychological Datasets)"
      }, {
         name: "Inter-university Consortium for Political and Social Research",
         url: "https://www.icpsr.umich.edu/icpsrweb/content/about/",
         introduce: "ICPSR是一个由超过750个学术机构和研究机构组成的国际联盟，其目的是为社会科学研究提供开源的数据访问、管理以及分析方法的领导和培训"
      }, {
         name: "The Journal of Open Psychology Data",
         url: "https://openpsychologydata.metajnl.com",
         introduce: "该杂志的特点是可对具有高复用潜力的心理学数据集进行同行评议。其中，数据可以使未发表研究的数据（包括重复实验），也可以是传统期刊上发表的论文"
      }, {
         name: "Curated list of open-access databases with human structural MRI data",
         url: "https://github.com/cMadan/openMorph",
         introduce: "本网站为开放式的人体结构MRI数据库，并且登陆后可随时添加/更新数据，操作十分人性化。并且，网站中还介绍了关于将大脑数据开放储存的众多益处，值得一读。"
      }, {
         name: "Integrated Analysis of Longitudinal Studies of Aging",
         url: "http://www.ialsa.org/Home",
         introduce: "主要为关于人类一生的可重复的纵向研究，其研究包括健康、人格、幸福感等等"
      }, {
         name: "myPersonality Project",
         url: "https://mypersonality.org",
         introduce: "通过这个程序用户可以进行真实的心理测试，并允许我们记录他们的测试结果和个人信息。目前，这个数据库收集了超过6,000,000个测试结果，以及超过4,000,000个Facebook个人资料。"
      }, {
         name: "Wisconsin Longitudinal Study",
         url: "https://www.ssc.wisc.edu/wlsresearch/",
         introduce: "WLS为研究者提供了一个研究纵向生命历程、代际关系、家庭功能、身体和心理健康及幸福感的机会。"
      }, {
         name: "DHS Data Access ",
         url: "http://www.dhsdata.nl/site/users/login",
         introduce: "CentERdata每年通过DNB家庭调查来收集经济数据，调查的目的是研究家庭储蓄行为的经济和心理因素，数据通过CentERpanel来收集，每周是不同的主题"
      }, {
         name: "Estonian Social Science Data Archive",
         url: "http://esta.ut.ee/",
         introduce: "由塔尔图大学的一些社会学家、心理学家、政治学家和人文地理学家共同建立。"
      }, {
         name: "World Health Organization",
         url: "http://www.who.int/gho/en/",
         introduce: "探索健康数据的世界"
      }, {
         name: "Wilma Bainbridge",
         url: "http://wilmabainbridge.com/index.html",
         introduce: "致力于通过心理物理实验，神经影像学和绘画研究的视角来检验感知与记忆之间的桥梁"
      }, {
         name: "HARVARD Dataverse",
         url: "https://dataverse.harvard.edu/",
         introduce: "是一个免费的数据存储库，向哈佛社区内外任何学科的所有研究人员开放"
      }, {
         name: "British Social Attitudes Survey",
         url: "https://link.zhihu.com/?target=http%3A//www.bsa.natcen.ac.uk/%3F_ga%3D1.30131903.1647202288.1447343086",
         introduce: "收入较低的人群最关心的问题是什么？登陆网站，用数据说话"
      }, {
         name: "EVS",
         url: "https://link.zhihu.com/?target=http%3A//www.europeanvaluesstudy.eu/",
         introduce: "是一项关于人类价值观的大规模的、跨国家和纵向的调查研究项目。它提供了欧洲各地公民的思想、信仰、偏好、态度、价值观和观点的信息数据。"
      }, {
         name: "IPSR",
         url: "https://link.zhihu.com/?target=https%3A//ipsr.berkeley.edu/",
         introduce: "是一个全球性的人格和社会方向的研究中心"
      }, {
         name: "MIDUS",
         url: "https://link.zhihu.com/?target=http%3A//midus.wisc.edu/",
         introduce: "是一项关于健康及幸福感的国际纵向研究"
      }, {
         name: "NatCen British Social Attitudes",
         url: "https://link.zhihu.com/?target=http%3A//www.britsocat.com/",
         introduce: "每年英国社会态度调查都会询问3000多人生活在英国的感觉，以及他们认为英国是如何“运作”的"
      }, {
         name: "Raw personality datasets",
         url: "https://link.zhihu.com/?target=http%3A//personality-testing.info/_rawdata/",
         introduce: "拥有丰富的人格测试数据及信息资源"
      }, {
         name: "Social Justice Sexuality Project",
         url: "https://link.zhihu.com/?target=http%3A//socialjusticesexuality.com/projects/",
         introduce: "是对黑人、拉丁裔、亚裔和太平洋岛屿居民、多种族女同性恋、男同性恋、双性恋和变性人(LGBT)的全国性调查中规模最大的一次。在超过5000个受访者中，最终的样本包括来自五十个州的受访者：华盛顿、哥伦比亚特区和波多黎各等，并且其地区包含有农村、郊区以及城市。项目从不同的年龄、种族身份、性取向、性别认同出发，获取了大量的数据信息。"
      }, {
         name: "1958 National Child Development Study",
         url: "https://cls.ucl.ac.uk/cls-studies/1958-national-child-development-study-2/",
         introduce: "跟踪了1958年在英格兰、苏格兰和威尔士出生的最初17，415人的生活。收集了关于他们身心发展、经济环境、就业、家庭生活、健康行为、福祉、社会参与和态度的信息。"
      }, {
         name: "1970 British Cohort Study",
         url: "https://cls.ucl.ac.uk/cls-studies/1970-british-cohort-study/",
         introduce: "跟踪了1970年一周内在英格兰、苏格兰和威尔士出生的大约17，000人的生活,收集了有关健康、身体、教育和社会发展以及经济状况等因素的信息"
      }, {
         name: "Adolescent Brain Cognitive Development",
         url: "https://www.drugabuse.gov/drug-topics/adolescent-brain/longitudinal-study-adolescent-brain-cognitive-development-abcd-study",
         introduce: "是美国国家卫生研究院发布的一个资料库，其数据来自于对青少年大脑发育的大规模研究"
      }, {
         name: "Baylor Religion Survey",
         url: "https://www.baylor.edu/mediacommunications/index.php?id=93052",
         introduce: "是有史以来对美国宗教态度、行为和信仰进行的最广泛和最敏感的研究"
      }, {
         name: "Born in Bradford",
         url: "https://borninbradford.nhs.uk/",
         introduce: "世界上最大的研究之一，跟踪超过30，000名布拉德福德人的生活，找出什么影响家庭的健康和福祉"
      }, {
         name: "Centre for Longitudinal Studies",
         url: "https://www.ucl.ac.uk/",
         introduce: "是一个经济和社会研究中心（ESRC网站）资源中心"
      }, {
         name: "Danish twin registry",
         url: "https://www.sdu.dk/en/om_sdu/institutter_centre/ist_sundhedstjenesteforsk/centre/dtr/researcher/data",
         introduce: "由140个出生群体中的大约175，000对双胞胎组成。登记册包括广泛的调查数据，包括对同一个人的重复测量，并收集了一部分双胞胎的生物样本。"
      }, {
         name: "English Longitudinal Study of Aging",
         url: "https://www.elsa-project.ac.uk/",
         introduce: "收集有关人们身心健康、福祉、财务状况和老龄化态度的信息，以及这些信息如何随着时间而变化"
      }, {
         name: "European Social Survey",
         url: "https://www.europeansocialsurvey.org/about/",
         introduce: "调查衡量了三十多个国家不同人口的态度、信仰和行为模式"
      }, {
         name: "世界价值观调查数据中国卷",
         url: "http://www.cpc.unc.edu/projects/china",
         introduce: "社会价值观、社会规范、社会距离、工作问题、劳工组织、就业问题等"
      }, {
         name: "北京大学健康老龄与发展研究中心",
         url: "https://opendata.pku.edu.cn/dataverse/CHADS",
         introduce: "调查范围覆盖全国23个省市自治区，调查对象为65岁及以上老年人和35-64岁成年子女，调查问卷分为存活被访者问卷和死亡老人家属问卷两种。存活被访者问卷的调查内容包括老人及家庭基本状况、社会经济背景及家庭结构、经济来源和经济状况、健康和生活质量自评、认知功能、性格心理特征、日常活动能力、生活方 式、生活照料、疾病治疗和医疗费承担；死亡老人家属问卷的调查内容包括老人死亡时间、死因等内容"
      }, {
         name: "PsycINFO",
         url: "http://nrs.harvard.edu/urn-3:hul.eresource:psycinfo",
         introduce: "认知疗法和行为疗法都有很好的介绍"
      }]
   },
   {
      id: 8,
      name: '社会',
      list: [{
         name: "国家统计局",
         url: "https://data.stats.gov.cn/",
         introduce: "国家统计数据库，GDP PMI CPI PPT，大中城市房价"
      }, {
         name: "世界银行公开数据",
         url: "https://data.worldbank.org.cn/",
         introduce: "免费公开获取世界各国的发展数据"
      }, {
         name: "联合国综合数据库",
         url: "http://data.un.org/",
         introduce: "联合国数据库（UN data）是世界上重要的数据库，涵盖政治、经济、人口、交通、能源等"
      }, {
         name: "财富500强",
         url: "http://www.fortunechina.com/rankings/index.htm",
         introduce: "《财富》杂志推出的全球最大五百家公司排行榜"
      }, {
         name: "搜数网",
         url: "http://www.soshoo.com/index.do#",
         introduce: "专门面向统计和调查数据的专业垂直搜索网站"
      }, {
         name: "全球科研项目数据库",
         url: "http://project.llas.ac.cn/",
         introduce: "汇聚了全球主要发达国家重要基金组织2000年以来的科研项目数据200多万条"
      }, {
         name: "资源学科创新平台",
         url: "http://www.data.ac.cn/",
         introduce: "以人口、资源、环境和发展（PRED）为核心的数据库服务"
      }, {
         name: "中国学术调查数据资料库",
         url: "http://cnsda.ruc.edu.cn/index.php",
         introduce: "受中国国家自然科学基金重点项目资助的、由中国人民大学中国调查与数据中心（National Survey Research Center, NSRC)负责执行的经济与社会数据共享平台"
      }, {
         name: "欧洲开放数据门户",
         url: "https://data.europa.eu/euodp/en",
         introduce: "社会、科学、环境、经济、金融等数据"
      }, {
         name: "社科网",
         url: "https://www.sinoss.net/",
         introduce: "为人文科学服务的专业性门户网站"
      }, {
         name: "国务院",
         url: "http://www.gov.cn/index.htm",
         introduce: "各类全国基础数据，34个职部委职能相关数据"
      }, {
         name: "欧盟数据门户",
         url: "https://www.europeandataportal.eu/",
         introduce: "包括地理、统计、气象数据，以及来自公共资金研究项目的数据和数字化图书等，平台中的数据集均由欧盟收集、购买，再免费提供给所有的用户，来自欧盟以及其他地区的用户都可以到平台上直接下载数据用于分析、开发创新服务或应用等用途。"
      }, {
         name: "Open Data 500",
         url: "http://www.opendata500.com/us/",
         introduce: "首个以开放政府数据来产生新的业务和开发新的产品和服务的综合研究性美国公司。研究机构可以发布网站上收集的数据，企业、组织和公民也可以分析网站上的数据，并使用这些信息。"
      }, {
         name: "IPUMS",
         url: "https://www.ipums.org/",
         introduce: "是普查数据的集成网站，用户可以查询包括人口、地理、居民生活、收入和消费信息等普查数据"
      }, {
         name: "数据世界",
         url: "https://ourworldindata.org/",
         introduce: "涵盖了健康趋势，粮食供应，收入的增长和分配，暴力，权利，战争，文化，能源使用等主题的分析和可视化数据，所有用户可以在上面免费下载数据和代码"
      }, {
         name: "github",
         url: "https://github.com/caesar0301/awesome-public-datasets",
         introduce: "为用户整理了非常全面的数据获取渠道，包含各个细分领域的数据库资源，自然科学和社会科学的覆盖都很全面，用户可以自己获取数据去做研究和数据分析"
      }, {
         name: "优易数据",
         url: "http://www.youedata.com/index.html",
         introduce: "由国家信息中心发起，拥有国家级信息资源的数据平台，国内领先的数据交易平台。平台有B2B、B2C两种交易模式，包含政务、社会、社交、教育、消费、交通、能源、金融、健康等多个领域的数据资源"
      }, {
         name: "UCI数据集",
         url: "https://link.zhihu.com/?target=https%3A//archive.ics.uci.edu/ml/index.php",
         introduce: "是加州大学欧文分校(University of CaliforniaIrvine)提出的用于机器学习的数据库，这个数据库目前共有559个数据集，其数目还在不断增加"
      }, {
         name: "中国工程科技知识中心",
         url: "http://www.ckcest.cn/home/",
         introduce: "收录了全球250个国家/地区、20多个国内外主要机构的770条指标、近700万条数据,覆盖经济、人口、资源、科技、环境等领域"
      }, {
         name: "Census of Agriculture",
         url: "https://www.agcensus.usda.gov/",
         introduce: "提供美国各州和地区的农业人口普查数据，美国农业人口普查每5年进行一次，它收集关于美国农场和牧场及其经营者的统一、详细的数据"
      }, {
         name: "Data Sharing for Demographic Research",
         url: "http://www.icpsr.umich.edu/icpsrweb/DSDR/",
         introduce: "由尤尼斯·肯尼迪·施莱佛儿童健康与人类发展国家研究院的人口动态分院支持建立，它的目标是：提供人口研究数据的获取、传播和保存服务；改善数据共享；支持共享限制使用的数据；为机构及个人提供支持服务以及扩展服务。"
      }, {
         name: "Fragile Families and Child Wellbeing Study ",
         url: "http://www.fragilefamilies.princeton.edu/",
         introduce: "脆弱家庭（未婚家长与孩子组成的家庭）与孩子福利”项目跟踪研究1998-2000年出生在美国大城市的近5000名儿童（大约四分之三是未婚父母所生），这些儿童所在的“家庭”比普通家庭面临更大的分裂和贫困的危险。"
      }, {
         name: "Gateway to Global Aging Data",
         url: "http://g2aging.org/",
         introduce: "全球老龄化数据门户是有关全球老龄化调查的一个平台。该门户提供相关调查问卷的数字图书馆，提供对于跨调查中一些可比较问题的查询。"
      }, {
         name: "Health and Retirement Study",
         url: "http://hrsonline.isr.umich.edu/",
         introduce: "健康和退休研究”是一个纵向研究小组，每两年研究调查具有代表性的26000多万50岁以上的美国人，收集其收入、工作、资产、养老金计划、健康保险、残疾、认知功能、遗传信息和卫生保健支出等信息。"
      }, {
         name: "Human Mortality Database",
         url: "http://www.mortality.org/",
         introduce: "该项目起源于美国加州大学伯克利分校人口学系以及德国罗斯托克马克斯普朗克人口研究所的早期项目。人类死亡率数据库提供39个国家详细的死亡率和人口数据。"
      }, {
         name: "Human Sciences Research Council Research Data Service",
         url: "http://datacuration.hsrc.ac.za/",
         introduce: "通过广泛收集数据，为相关研究、政策制定和社会发展问题的公开讨论等提供数据支持，通过编制全面的元数据和向合适的受众传播数据及相关文件来促进数据的使用。"
      }, {
         name: "Survey of Health, Ageing and Retirement in Europe",
         url: "http://www.share-project.org/",
         introduce: "欧洲健康、老龄化和退休调查是一个多学科和跨国家的有关12万50岁以上老年人的健康、社会经济状况和社会与家庭网络的数据库，涵盖了27个欧洲国家和以色列。"
      }, {
         name: "UK Data Archive",
         url: "http://www.data-archive.ac.uk/",
         introduce: "大规模的政府调查，例如劳动力调查；英国主要的调查，例如英国家庭小组调查、民意测验以及环境研究项目的多媒体定性数据资源。"
      }, {
         name: "Data.gov.uk",
         url: "https://data.gov.uk/data/search",
         introduce: "由英国政府创办的可以快速查找政府服务和信息，英国政府部门，英国相关政策等与政府和民生息息相关的网站，并且所有政府部门和许多其他机构、公共机构的网站都被收录到了这里"
      }, {
         name: "nter-university Consortium for Political and Social Research",
         url: "http://www.icpsr.umich.edu",
         introduce: "以大量收集系统化的社会科学数据著称，是世界上最大的社会科学数据中心"
      }, {
         name: "National Data Archive on Child Abuse and Neglect",
         url: "http://www.ndacan.cornell.edu",
         introduce: "由美国康奈尔大学人类生态学学院创建。是一个用于促进儿童虐待领域研究者之间进行学术交流的资源库"
      }, {
         name: "中国国家调查数据库",
         url: "http://www.cnsda.org/",
         introduce: "数据涵盖了经济、综合、健康、社会、教育、企业、宗教、政治、科学和历史等领域"
      }, {
         name: "Korean Social Science Data Archive",
         url: "http://www.kossda.or.kr/",
         introduce: "研究领域：政治、公共行政和法律、经济和商业管理、社会文化、社会问题、社会福利、心理学和教育和区域研究。"
      }, {
         name: "Gateway to Global Aging",
         url: "https://g2aging.org/?",
         introduce: "可以看到全球老龄化的相关数据"
      }, {
         name: "PolData: A dataset with political datasets",
         url: "https://github.com/erikgahner/PolData",
         introduce: "这是一个政治数据库的集合。这些数据包括：内阁、公民、宪法、政治机构、政党和政治家、民主、经济、选举、国际关系、媒体、政策等。所有数据都在网页中按列表展示，一目了然，非常便捷。"
      }, {
         name: "British Social Attitudes Survey",
         url: "http://www.bsa.natcen.ac.uk/?_ga=1.30131903.1647202288.1447343086",
         introduce: "收入较低的人群最关心的问题是什么？登陆网站，用数据说话。"
      }, {
         name: "EVS",
         url: "http://www.europeanvaluesstudy.eu/",
         introduce: "它提供了欧洲各地公民的思想、信仰、偏好、态度、价值观和观点的信息数据。"
      }, {
         name: "National Survey of Children’ s Health and National Survey of Children with Special Healthcare Needs",
         url: "http://www.childhealthdata.org/",
         introduce: "本站的数据还可以根据地图（美国）对所需数据进行搜索。"
      }, {
         name: "NHANES",
         url: "http://www.cdc.gov/nchs/nhanes.htm",
         introduce: "是一项旨在评估美国成年人和儿童健康和营养状况的研究项目。这项调查的独特之处在于它结合了面试和体检两个方面的数据资料。"
      }, {
         name: "National Historical Geographic Information System",
         url: "https://www.nhgis.org/",
         introduce: "提供1790年至今的免费的美国人口普查和其他全国性调查的在线汇总统计和地理信息系统边界文件。包括带有地理空间属性信息的人口、农业、经济等方面的数据"
      }, {
         name: "London Datastore",
         url: "http://data.london.gov.uk/",
         introduce: "是由大伦敦当局(GLA)发起的一项倡议，目的是释放尽可能多的数据。数据主题涉及伦敦的人口、就业、环境、住房、交通、规划等各个主题"
      }, {
         name: "Government of Canada—Open Data",
         url: "http://open.canada.ca/en/open-data",
         introduce: "包括经济、健康、教育等各方面的政府公开数据。包括历次人口普查的数据"
      }, {
         name: "Databank of Official Statistics on Quebec ",
         url: "http://www.bdso.gouv.qc.ca/",
         introduce: "包括农业和生物食品产业、文化与传播、经济、教育、培训和儿童保育、能量、环境、金融与非金融合作、林业、健康、福利、卫生和社会服务、劳动补偿、生活条件与社会、制造业、采矿业、市政事务、人口、科学技术创新、可持续发展、旅游、交通、用户和基础设施等多方面的统计数据和信息"
      }, {
         name: "The German Socio-Economic Panel Study",
         url: "http://www.diw.de/en/diw_02.c.221180.en/research_data_center_soep.html",
         introduce: "每年超过2万人到近1.1万户家庭进行现场数据调查。数据包含所有家庭成员信息，涵盖住在新旧德国的德国人、外国人和最近的移民，调研数据包括家庭组成、职业、就业、收入、健康和满意度等指标，并提供使用这些数据进行分析的研究论文。"
      }, {
         name: "The Centro de Investigaciones Sociológicas",
         url: "http://www.cis.es/cis/opencms/EN/index.html",
         introduce: "CIS收集从民意调查到应用研究等不同领域的研究数据，因进行各种调查而闻名，通过调查来搜集关于西班牙社会及其演变的知识。CIS数据银行将CIS进行的所有研究提供给大众，公众可以通过本网站免费访问该数据库"
      }, {
         name: "Estonian Social Science Data Archive",
         url: "http://esta.ut.ee/",
         introduce: "由塔尔图大学的一些社会学家、心理学家、政治学家和人文地理学家共同建立。"
      }, {
         name: "Gapminder",
         url: "https://www.gapminder.org/",
         introduce: "是瑞士 Gapminder 基金会开发的一个统计软件，你可以形象地看见用世界银行提供数据绘制的的世界各国各项发展指数"
      }, {
         name: "World Health Organization",
         url: "http://www.who.int/gho/en/",
         introduce: "探索健康数据的世界"
      }, {
         name: "ALLBUS",
         url: "https://www.gesis.org/allbus/download/download-querschnitte/",
         introduce: "收集德国在态度、行为和社会结构方面最新的数据"
      }, {
         name: "Avon Longitudinal Study of Parents and Children",
         url: "http://www.bristol.ac.uk/alspac/",
         introduce: "以布里斯托大学为基础，埃文河父母与子女纵向研究也被称为一个世界领先的对90后孩子进行研究的项目。从90年代初到现在，该项目已经对1991年4月至1992年12月间出生的研究对象进行了二十多年的跟踪研究。该项研究可以为国际研究界提供用于研究影响人类健康和大战的环境及遗传因素的丰富资源，并为后代创造更好的生活"
      }, {
         name: "北京大学开放研究数据平台",
         url: "https://opendata.pku.edu.cn/",
         introduce: "主要是社会学相关的数据"
      }, {
         name: "HARVARD Dataverse",
         url: "https://dataverse.harvard.edu/",
         introduce: "是一个免费的数据存储库，向哈佛社区内外任何学科的所有研究人员开放"
      }, {
         name: "国家健康和营养检查调查",
         url: "https://link.zhihu.com/?target=http%3A//www.cdc.gov/nchs/nhanes.htm",
         introduce: "是一项旨在评估美国成年人和儿童健康和营养状况的研究项目。这项调查的独特之处在于它结合了面试和体检两个方面的数据资料"
      }, {
         name: "Wisconsin Longitudinal Study",
         url: "https://link.zhihu.com/?target=https%3A//www.ssc.wisc.edu/wlsresearch/",
         introduce: "是一项对10317名男性和女性随机抽样的长期调查研究，他们都是1957年从威斯康森高中毕业的。WLS为研究者提供了一个研究纵向生命历程、代际关系、家庭功能、身体和心理健康及幸福感的机会。WLS数据中还包括了人们的社会背景、抱负、教育、从军、劳动、家庭特征、社会参与情况、心理特征和退休等因素。"
      }, {
         name: "Centre for Longitudinal Studies",
         url: "https://www.ucl.ac.uk/",
         introduce: "是一个经济和社会研究中心（ESRC网站）资源中心"
      }, {
         name: "Danish twin registry",
         url: "https://www.sdu.dk/en/om_sdu/institutter_centre/ist_sundhedstjenesteforsk/centre/dtr/researcher/data",
         introduce: "由140个出生群体中的大约175，000对双胞胎组成。登记册包括广泛的调查数据，包括对同一个人的重复测量，并收集了一部分双胞胎的生物样本。"
      }, {
         name: "青少年科学学习调查数据库",
         url: "http://css.sysu.edu.cn/Data",
         introduce: "该调查由中山大学社会学与社会工作系王进副教授主持，得到中国科普研究所、瑞典哥德堡大学和中山大学社会科学调查中心资助。"
      }, {
         name: "国人民大学中国调查与数据中心",
         url: "http://ceps.ruc.edu.cn/",
         introduce: "揭示家庭、学校、社区以及宏观社会结构对于个人教育产出的影响"
      }, {
         name: "WorldPop",
         url: "https://link.zhihu.com/?target=https%3A//www.worldpop.org/",
         introduce: "全球多个大洲人口的高分辨率当代数据合集。"
      }, {
         name: "GeoHive",
         url: "https://link.zhihu.com/?target=https%3A//geohive.ie/",
         introduce: "全球人口和国家统计数据，CSV格式"
      }, {
         name: "EPS数据平台",
         url: "http://www.epsnet.com.cn/",
         introduce: "是由北京福卡斯特信息技术有限公司提供的“专业数据+预测分析”平台。收录国内外的经济、贸易、行业、科技领域的统计数据；利用内嵌的数据分析预测软件可完成对数据的分析和预测"
      }, {
         name: "北京大学地理数据平台",
         url: "https://geodata.pku.edu.cn/",
         introduce: "是由北京大学城市与环境学院于2017年建立。平台建立的宗旨是为本院和地理专业的学生、教师以及科研人员提供全面、丰富、专业的数据信息，力图搭建一个地理数据共享交流的平台，构建从不同方向描述地球系统的数据库。数据包括自然地理、人文地理、历史地理等多个地理专业的图形图像、统计图表、文本文件及监测和模型运算得到的数据，数据范围包括全国及全球范围，数据来源于我院科研成果和积累的数据，以及平台收集整理的数据。数据平台的建设得到了学校科研部、设备部的大力支持，也得到了中国宇航学会卫星专业委员会提供的帮助。平台管理由北京大学城市与环境学院教授组成的地理数据平台小组负责，平台运行维护由人地系统与环境遥感实验室负责"
      }, {
         name: "北京大学中国调查数据资料库",
         url: "https://opendata.pku.edu.cn/dataverse/CSDA;jsessionid=4ef9c4ece41184276bf1b0a1724d",
         introduce: "成立于2015年，是国家自然科学基金-北京大学管理科学数据中心根据国内管理科学量化研究对于调查数据的需求而设立的项目。该项目的目标是收录整合国内优质调查科研数据资源，通过专业的数据管理与监护，实现便捷的数据共享，为科学研究和决策管理提供高水平的数据服务"
      }, {
         name: "中国家庭追踪调查",
         url: "https://opendata.pku.edu.cn/dataverse/CFPS;jsessionid=4ef9c4ece41184276bf1b0a1724d",
         introduce: "旨在通过跟踪收集个体、家庭、社区三个层次的数据，反映中国社会、经济、人口、教育和健康的变迁，为学术研究和公共政策分析提供数据基础"
      }, {
         name: "中国健康与养老追踪调查",
         url: "https://opendata.pku.edu.cn/dataverse/CHARLS",
         introduce: "旨在收集一套代表中国45岁及以上中老年人家庭和个人的高质量微观数据，用以分析我国人口老龄化问题，推动老龄化问题的跨学科研究。CHARLS全国基线调查于2011年开展，覆盖150个县级单位，450个村级单位，约1万户家庭中的1.7万人。这些样本以后每两年追踪一次，调查结束一年后，数据将对学术界展开。"
      }, {
         name: "北京大学中国国情研究中心",
         url: "https://opendata.pku.edu.cn/dataverse/RCCC",
         introduce: "其宗旨在于发挥北京大学的人才和知识密集的优势，组织和开展多种研究项目、学术交流活动和培训工作，推进对中国社会、政治、经济发展状况的多学科综合研究。重点在于推动实证的、量化的科学方法在学术界、政策研究，以及市场调研等方面的应用。"
      }, {
         name: "北京大学健康老龄与发展研究中心",
         url: "https://opendata.pku.edu.cn/dataverse/CHADS",
         introduce: "调查范围覆盖全国23个省市自治区，调查对象为65岁及以上老年人和35-64岁成年子女，调查问卷分为存活被访者问卷和死亡老人家属问卷两种。存活被访者问卷的调查内容包括老人及家庭基本状况、社会经济背景及家庭结构、经济来源和经济状况、健康和生活质量自评、认知功能、性格心理特征、日常活动能力、生活方 式、生活照料、疾病治疗和医疗费承担；死亡老人家属问卷的调查内容包括老人死亡时间、死因等内容"
      }, {
         name: "Sociological Abstracts",
         url: "http://nrs.harvard.edu/urn-3:hul.eresource:sociofil",
         introduce: "社会学文献的主要索引。可能对社区相关研究或人际问题有用"
      }, {
         name: "3ie Impact Evaluation Repository",
         url: "http://www.3ieimpact.org/en/evidence/impact-evaluations/impact-evaluation-repository/",
         introduce: "调查经济或社会干预，用于证明低收入和中等收入国家在国际发展中的作用。"
      }]
   },
   {
      id: 9,
      name: '经济',
      list: [{
         name: "国家青藏高原科学数据中心",
         url: "https://data.tpdc.ac.cn/zh-hans/",
         introduce: "数据中心以青藏高原及周边地区各类科学数据为主，已整合的数据资源包括：大气、冰冻圈、水文、生态、地质、地球物理、自然资源、基础地理、社会经济等，开发了在线大数据分析、模型应用等功能，实现青藏高原科学数据、方法、模型与服务的广泛集成。"
      }, {
         name: "国家统计局",
         url: "https://data.stats.gov.cn/",
         introduce: "国家统计数据库，GDP PMI CPI PPT，大中城市房价"
      }, {
         name: "CEIC",
         url: "https://www.ceicdata.com/en",
         introduce: "195+个国家的经济数据库，GDP、GNP、FDI和CPI等"
      }, {
         name: "联合国综合数据库",
         url: "http://data.un.org/",
         introduce: "联合国数据库（UN data）是世界上重要的数据库，涵盖政治、经济、人口、交通、能源等"
      }, {
         name: "全球科研项目数据库",
         url: "http://project.llas.ac.cn/",
         introduce: "汇聚了全球主要发达国家重要基金组织2000年以来的科研项目数据200多万条"
      }, {
         name: "资源环境科学与数据中心",
         url: "http://www.resdc.cn/Default.aspx",
         introduce: "“中心本部”和9个“分中心”，一个科学数据集与共享平台"
      }, {
         name: "千顺",
         url: "http://www.qcqs.com.cn/",
         introduce: "金融门户网站。聚焦金融领域，专注金融产业发展和风险防范化解的“大数据+研究+咨询+落地”服务，及系统运维，推动大数据与金融行业深度融合"
      }, {
         name: "中国金融信息网",
         url: "http://dc.xinhua08.com/",
         introduce: "由新华社主管、中国经济信息社主办,是配合新华财经金融信息平台项目打造的国家级专业财经网站,定位于建设中国财经金融信息领域的权威发布和服务平台。"
      }, {
         name: "中国金融期货交易所",
         url: "http://www.cffex.com.cn/",
         introduce: "国务院同意，中国证监会批准设立的，专门从事金融期货、期权等金融衍生品交易与结算的公司制交易所"
      }, {
         name: "欧洲开放数据门户",
         url: "https://data.europa.eu/euodp/en",
         introduce: "社会、科学、环境、经济、金融等数据"
      }, {
         name: "地理空间数据云",
         url: "http://www.gscloud.cn/",
         introduce: "以中国科学院及国家的科学研究为主要需求，逐渐引进当今国际上不同领域内的国际数据资源，并对其进行加工、整理、集成，最终实现数据的集中式公开服务、在线计算等"
      }, {
         name: "howmuch.net",
         url: "https://howmuch.net/",
         introduce: "一个非常有意思的金融数据可视化网站"
      }, {
         name: "全球经济指标",
         url: "https://zh.tradingeconomics.com/",
         introduce: "提供196个国家的准确经济信息，包括超过2000万经济指标、汇率、股票市场指数、政府债券收益率和商品价格的历史数据"
      }, {
         name: "欧盟数据门户",
         url: "https://www.europeandataportal.eu/",
         introduce: "包括地理、统计、气象数据，以及来自公共资金研究项目的数据和数字化图书等，平台中的数据集均由欧盟收集、购买，再免费提供给所有的用户，来自欧盟以及其他地区的用户都可以到平台上直接下载数据用于分析、开发创新服务或应用等用途。"
      }, {
         name: "中国保险网——数据中心",
         url: "http://www.china-insurance.com/info/list.aspx?cid=21",
         introduce: "在中国保险网内的数据中心里，用户可以查到具体一家保险公司的各项数据情况"
      }, {
         name: "Wind",
         url: "http://www.wind.com.cn/",
         introduce: "被誉为中国的Bloomberg，在金融业有着全面的数据覆盖，金融数据的类目更新极快，很受国内的商业分析者和投资人的亲睐。"
      }, {
         name: "亚马逊公共数据集",
         url: "https://amazonaws-china.com/cn/public-datasets/",
         introduce: "包含化学、生物、经济等多个领域的数据集，当数据在 AWS 上公开提供后，任何人都可以分析任意数量的数据，而无需自行下载或存储这些数据。"
      }, {
         name: "优易数据",
         url: "http://www.youedata.com/index.html",
         introduce: "由国家信息中心发起，拥有国家级信息资源的数据平台，国内领先的数据交易平台。平台有B2B、B2C两种交易模式，包含政务、社会、社交、教育、消费、交通、能源、金融、健康等多个领域的数据资源"
      }, {
         name: "Kaggle",
         url: "https://www.kaggle.com/datasets",
         introduce: "主要为开发商和数据科学家提供举办机器学习竞赛、托管数据库、编写和分享代码的平台"
      }, {
         name: "和鲸社区",
         url: "https://www.heywhale.com/home/dataset",
         introduce: "是国内知名的开放数据科学社区,是最早一批专注于大数据算法比赛的平台,支持在线分享代码、项目和数据集"
      }, {
         name: "中国工程科技知识中心",
         url: "http://www.ckcest.cn/home/",
         introduce: "收录了全球250个国家/地区、20多个国内外主要机构的770条指标、近700万条数据,覆盖经济、人口、资源、科技、环境等领域"
      }, {
         name: "中国产业信息",
         url: "https://www.chyxx.com/",
         introduce: "工业和信息化部主管,人民邮电报社主办,是我国通信行业唯一拥有国务院新闻办授予新闻发布权的新闻网站。"
      }, {
         name: "Korean Social Science Data Archive",
         url: "http://www.kossda.or.kr/",
         introduce: "研究领域：政治、公共行政和法律、经济和商业管理、社会文化、社会问题、社会福利、心理学和教育和区域研究。"
      }, {
         name: "世界银行空间数据库",
         url: "http://www.worldbank.org/",
         introduce: "世界银行是全球社会经济数据最全面、最丰富和最权威的数据发布机构，对于研究全球问题需要的社会经济数据有很好的共享。"
      }, {
         name: "NOAA Digital Coast",
         url: "https://coast.noaa.gov/digitalcoast/",
         introduce: "提供沿海区域的数据，主要包括海底数据、海拔、图像、土地覆盖以及社会经济数据等，数据包括雷达、红外和真彩色影像数据等"
      }, {
         name: "国务院发展研究中心信息网",
         url: "http://www.drcnet.com.cn/www/int/",
         introduce: "由国务院发展研究中心信息中心主办，创建于1998年3月，是中国最著名的大型经济类专业网站"
      }, {
         name: "中经网产业数据库",
         url: "https://cyk.cei.cn/",
         introduce: "是国家信息中心中经网精心打造的、覆盖机械、汽车、电子通讯、医药、石油化工、能源、交通、房地产、钢铁、旅游等10多个产业集群的全新数据库产品。"
      }, {
         name: "CSMAR",
         url: "http://cn.gtadata.com/#/index",
         introduce: "CSMAR 数据库已涵盖因子研究、人物特征、绿色经济、股票、公司、海外、资讯、基金、债券、行业、经济、商品期货等18大系列，包含150+个数据库、4000多张表、4万多个字段。涵盖中国证券、期货、外汇、宏观、行业等经济金融主要领域，是投资和实证研究的基础工具"
      }, {
         name: "CVSource数据库",
         url: "https://www.cnrds.com/Home/Login#/",
         introduce: "CVSource数据终端是投中集团旗下专注于中国私募股权、战略投资与并购交易等股权投资市场的在线信息产品"
      }, {
         name: "中国研究数据服务平台",
         url: "http://new.ccerdata.cn/",
         introduce: "参阅各领域重点文献，整合了学术研究过程中可能用到的大部分基础数据。"
      }, {
         name: "中国微观经济数据查询系统",
         url: "http://microdata.sozdata.com/login.html",
         introduce: "是由北京搜知数据科技有限公司提供的一款以企业层面的微观数据为基础，集数据查询、数据匹配、数据可视化于一体的数据查询系统"
      }, {
         name: "PolData: A dataset with political datasets",
         url: "https://github.com/erikgahner/PolData",
         introduce: "这是一个政治数据库的集合。这些数据包括：内阁、公民、宪法、政治机构、政党和政治家、民主、经济、选举、国际关系、媒体、政策等。所有数据都在网页中按列表展示，一目了然，非常便捷。"
      }, {
         name: "National Historical Geographic Information System",
         url: "https://www.nhgis.org/",
         introduce: "提供1790年至今的免费的美国人口普查和其他全国性调查的在线汇总统计和地理信息系统边界文件。包括带有地理空间属性信息的人口、农业、经济等方面的数据"
      }, {
         name: "Government of Canada—Open Data",
         url: "http://open.canada.ca/en/open-data",
         introduce: "包括经济、健康、教育等各方面的政府公开数据。包括历次人口普查的数据"
      }, {
         name: "Databank of Official Statistics on Quebec ",
         url: "http://www.bdso.gouv.qc.ca/",
         introduce: "包括农业和生物食品产业、文化与传播、经济、教育、培训和儿童保育、能量、环境、金融与非金融合作、林业、健康、福利、卫生和社会服务、劳动补偿、生活条件与社会、制造业、采矿业、市政事务、人口、科学技术创新、可持续发展、旅游、交通、用户和基础设施等多方面的统计数据和信息"
      }, {
         name: "UK Data Service",
         url: "http://ukdataservice.ac.uk/",
         introduce: "由ESRC资助的全面资源库，可帮助研究人员、教师和决策者使用高质量的社会和经济数据进行研究与决策。同时，可访问众多二手数据包括政府大调查、国际宏观数据、商业微数据、定性研究、1971年到2011年的人口普查数据等。"
      }, {
         name: "The German Socio-Economic Panel Study",
         url: "http://www.diw.de/en/diw_02.c.221180.en/research_data_center_soep.html",
         introduce: "每年超过2万人到近1.1万户家庭进行现场数据调查。数据包含所有家庭成员信息，涵盖住在新旧德国的德国人、外国人和最近的移民，调研数据包括家庭组成、职业、就业、收入、健康和满意度等指标，并提供使用这些数据进行分析的研究论文。"
      }, {
         name: "The Economics & Business Data Center",
         url: "http://www.cesifo-group.de/ifoHome/facts/EBDC.html",
         introduce: "是德维希马克西米利安慕尼黑大学和IFO研究院联合成立的一个对企业管理和经济学进行实证研究的综合平台，旨在为企业管理和经济学实证研究开辟新领域"
      }, {
         name: "Penn World Table 9.0",
         url: "http://www.rug.nl/ggdc/productivity/pwt/",
         introduce: "宾夕法尼亚大学世界数据库9.0版包含全球182个国家1950-2014年的收入、投入、产出和生产效率等数据信息。"
      }, {
         name: "International Data Service Center of the Institute for the Study of Labor ",
         url: "http://idsc.iza.org/",
         introduce: "IDSC的目标是成为有经济头脑的技术专家以及技术高超的经济学家寻求劳动经济学相关数据支持、数据获取和服务的地方。IDSC积极参与面向数据专家、数据分析师、科研数据使用者以及乐于讨论和分享研究结论的年轻研究人员的各种活动。"
      }, {
         name: "DHS Data Access ",
         url: "http://www.dhsdata.nl/site/users/login",
         introduce: "CentERdata每年通过DNB家庭调查来收集经济数据，调查的目的是研究家庭储蓄行为的经济和心理因素，数据通过CentERpanel来收集，每周是不同的主题"
      }, {
         name: "美国经济协会",
         url: "https://www.aeaweb.org/resources/data",
         introduce: "是在位于美国纽约州东部的村落——萨拉托加（Saratoga, New York）的一次会议上由一小部分对经济学感兴趣的人组织起来"
      }, {
         name: "Inforum",
         url: "http://inforumweb.umd.edu/econdata/econdata.html",
         introduce: "是50多年前由马里兰大学名誉教授克洛珀·阿尔蒙博士创立的。它致力于改善商业规划、政府政策分析和对经济环境的普遍了解"
      }, {
         name: "FRASER INSTITUTE",
         url: "https://www.fraserinstitute.org/",
         introduce: "是一家加拿大非盈利智库，成立于1974年，以弗雷泽河命名"
      }, {
         name: "DBnomics ",
         url: "https://db.nomics.world/",
         introduce: "世界经济数据库"
      }, {
         name: "JEDH",
         url: "http://www.jedh.org/",
         introduce: "由国际清算银行、国际货币基金组织（货币基金组织）、经济合作与发展组织（经合组织）和世界银行（世界银行）联合开发的联合外债中心汇集了外债数据以及来自国际债权国/市场和国家债务来源的选定外国资产"
      }, {
         name: "OpenCorporates",
         url: "https://opencorporates.com/",
         introduce: "世界需要的高质量公司数据"
      }, {
         name: "Our World in Data",
         url: "http://ourworldindata.org/",
         introduce: "是由英国牛津大学的Leszell创建的网站，他致力于研究几十年来各国关于人类生活水平的数据，以揭示全世界的生活状况是如何潜移默化地发生变化的，并对未来产生了什么影响"
      }, {
         name: "ATLAS OF ECONOMIC COMPLEXITY",
         url: "http://atlas.cid.harvard.edu/",
         introduce: "哈佛增长实验室的研究和数据可视化工具用于了解全球每个国家的经济动态和新的增长机会。"
      }, {
         name: "The Center For International Data",
         url: "http://cid.econ.ucdavis.edu/",
         introduce: "该中心的目的是收集、加强、创建和传播国际经济数据，包括在线和离线分发"
      }, {
         name: "UN Comtrade Database",
         url: "https://comtrade.un.org/data/",
         introduce: "是官方国际贸易统计数据和相关分析表的储存库"
      }, {
         name: "联合国人类发展报告",
         url: "http://hdr.undp.org/en",
         introduce: "旨在将发展经济学的重点从国民收入核算转移到以人为本的政策"
      }, {
         name: "World Health Organization",
         url: "http://www.who.int/gho/en/",
         introduce: "探索健康数据的世界"
      }, {
         name: "中国人民银行",
         url: "http://www.pbc.gov.cn/diaochatongjisi/116219/index.html",
         introduce: "主要包括社会融资规模、金融统计数据、货币统计、金融机构信贷收支统计、金融市场统计、企业商品价格指数等等，数据权威且容易查找，实用性强"
      }, {
         name: "中国银行业监督管理委员会",
         url: "http://www.cbrc.gov.cn/chinese/home/docViewPage/110009.html",
         introduce: "主要包括银行业的数据统计，包括资产负债规模、主要监管数据等"
      }, {
         name: "联合国商品贸易统计数据库",
         url: "http://comtrade.un.org/",
         introduce: "全球最大、最权威的国际商品贸易数据型资源每年超过200个国家和地区提供其官方年度商品贸易数据涵盖全球99%的商品贸易收集超过6000种商品、数据回溯至1962年各国上报的数据均转换成统一格式"
      }, {
         name: "中国私营企业调查",
         url: "http://finance.sina.com.cn/nz/pr/",
         introduce: "针对私营企业和私营企业主的综合状况进行调查，内容详细，关注和研究我国私营企业发展"
      }, {
         name: "世界银行中国企业调查数据",
         url: "http://www.enterprisesurveys.org/data",
         introduce: "主要关注一个国家的商业环境变化以及公司效率和性能特征"
      }, {
         name: "中国小微企业调查数据CMES",
         url: "http://chfs.swufe.edu.cn/",
         introduce: "小微企业和个体工商户经营"
      }, {
         name: "中国专利数据库",
         url: "http://new.ccerdata.cn/Home/Special#h3",
         introduce: "准确地反映中国最新的专利发明"
      }, {
         name: "中国工业企业数据库",
         url: "http://www.allmyinfo.com/data/zggyqysjk.asp",
         introduce: "全国最为详细、所用最为广泛的微观数据库，主要研究工业企业的相关问题"
      }, {
         name: "海关数据",
         url: "http://new.ccerdata.cn/Home/Special",
         introduce: "主要是海关履行进出口贸易统计职能中产生的各项进出口统计数据，专注企业进出口情况"
      }, {
         name: "农业部农村经济研究中心",
         url: "http://www.moa.gov.cn/sydw/ncjjzx/gcdgzdt/gzdtg/201302/t20130225_3225848.htm",
         introduce: "通过观察点对农村社会经济进行长期的连续调查，掌握生产力、生产关系和上层建筑领域的变化，了解不同村庄和农户的动态、要求，从而取得系统周密的资料。"
      }, {
         name: "中国城乡流动调查数据",
         url: "https://www.iza.org/en/research/idsc/services",
         introduce: "本项目主要在人口流出或流入的大省进行调查。其中，农村住户调查是在以下9个省市进行：安徽，重庆，广东，河北，河南，湖北，江苏，四川，浙江；城市流动人口调查是在以下15个城市进行：蚌埠，成都，重庆，东莞，广州，合肥，杭州，洛阳，南京，宁波，上海，深圳，武汉，无锡，郑州。城镇住户调查是在19个城市进行，包括城市流动人口调查以外的四个城市：安阳市，建德市，乐山，绵阳。"
      }, {
         name: "中山大学社会科学调查中心",
         url: "http://css.sysu.edu.cn/Data",
         introduce: "系统地监测社区社会结构和家庭、劳动力个体的变化与相互影响"
      }, {
         name: "城市农民工调查数据库",
         url: "http://css.sysu.edu.cn/Data",
         introduce: "由中山大学社会学与社会工作系蔡禾教授主持，得到国家哲学社会科学基金和中山大学社会科学调查中心资助。"
      }, {
         name: "北京大学中国社会科学调查中心",
         url: "http://www.isss.edu.cn/cfps/",
         introduce: "中国社会、经济、人口、教育和健康的变迁"
      }, {
         name: "阿里云天池",
         url: "https://tianchi.aliyun.com/dataset/",
         introduce: "阿里旗下数据科学竞赛平台"
      }, {
         name: "BankFocus",
         url: "http://210.32.137.90/s/lib/libtb/turning/1806",
         introduce: "是银行研究的专业解决方案，是全球银行数据研究的一种新方法，结合了来自Bureau van Dijk (BvD)和穆迪投资者服务公司的核心数据，以及穆迪分析公司的专业知识，创造的一个全面的银行数据库，您可以使用它来识别、分析和研究银行和其他金融机构"
      }, {
         name: "Osiris",
         url: "http://210.32.137.90/s/lib/libtb/turning/226",
         introduce: "是研究全球各国证券交易所内155个国家超过80,000家上市公司的大型专业财务分析库(含中国深/沪及海外上市公司数据)，向专业用户提供了深入分析各国上市公司所需的详细财务经营报表与分析比率、股权结构、企业评级数据、历年股价系列、企业行业分析报告等（含已下市公司数据）"
      }, {
         name: "同花顺数据中心",
         url: "https://link.zhihu.com/?target=http%3A//data.10jqka.com.cn/",
         introduce: "提供股票债券等金融数据"
      }, {
         name: "和讯数据",
         url: "https://link.zhihu.com/?target=http%3A//data.hexun.com/",
         introduce: "提供各种股票、基金、外汇、债券等实时数据，有付费，有免费"
      }, {
         name: "零壹财经",
         url: "https://link.zhihu.com/?target=https%3A//www.01caijing.com/data/index.htm",
         introduce: "大大小小的网贷数据、排行榜等"
      }, {
         name: "金融数据网",
         url: "https://link.zhihu.com/?target=http%3A//dc.xinhua08.com/",
         introduce: "提供每日的黄金、汇率、农产品、汽油价格的相关数据"
      }, {
         name: "萝卜投研",
         url: "https://link.zhihu.com/?target=https%3A//robo.datayes.com/v2/home",
         introduce: "提供股市、证券等数据，多以数据研究报告形式展示"
      }, {
         name: "金融界",
         url: "https://link.zhihu.com/?target=http%3A//www.jrj.com.cn/",
         introduce: "提供股市、融资等各种资金流向数据，以及丰富的财报和研报"
      }, {
         name: "东方财富网",
         url: "https://link.zhihu.com/?target=http%3A//www.eastmoney.com/",
         introduce: "提供多国的股票、财税、行业、消费等大量丰富的经济数据"
      }, {
         name: "吉林金融网",
         url: "https://link.zhihu.com/?target=https%3A//www.51test.net/jinrong/jilin/jilin/",
         introduce: "提供吉林的融资、市场数据"
      }, {
         name: "搜狐证券",
         url: "https://link.zhihu.com/?target=http%3A//q.stock.sohu.com/zs/000001/index.shtml",
         introduce: "提供货币、外汇、行业、市场等宏观数据"
      }, {
         name: "CCER经济金融数据库",
         url: "https://link.zhihu.com/?target=http%3A//www.wisers.com.cn/finance",
         introduce: "提供企业财务年度数据、股票收益数据等数据库下载"
      }, {
         name: "香港金融管理局",
         url: "https://link.zhihu.com/?target=https%3A//www.hkma.gov.hk/chi/",
         introduce: "提供香港的各种经济及金融数据，以宏观数据为主"
      }, {
         name: "世纪未来",
         url: "https://link.zhihu.com/?target=http%3A//www.wefore.com/show-model3-7.html",
         introduce: "数据服务平台，提供银行业务研究、金融大数据等各种付费数据"
      }, {
         name: "新浪财经",
         url: "https://link.zhihu.com/?target=http%3A//finance.sina.com.cn/mac/",
         introduce: "提供国内国民经济、行业信息、对外经贸、居民收入等各种宏观的经济数据"
      }, {
         name: "司尔亚司数据信息有限公司",
         url: "https://link.zhihu.com/?target=https%3A//www.ceicdata.com/zh-hans",
         introduce: "覆盖超过195+个国家的经济数据库，数据全面但收费"
      }, {
         name: "INSEE数据",
         url: "https://link.zhihu.com/?target=https%3A//www.insee.fr/en/accueil",
         introduce: "法国统计与经济研究院公开数据"
      }, {
         name: "投中研究院",
         url: "https://link.zhihu.com/?target=https%3A//www.chinaventure.com.cn/cmsmodel/report/list.shtml",
         introduce: "提供投资领域的分析报告，网站更新频率较高"
      }, {
         name: " BvD",
         url: "https://www.bvdinfo.com/en-gb",
         introduce: "是欧洲著名的全球金融与企业资信分析数据库电子提供商。BvD为各国政府金融监管部门、银行与金融机构、证券投资公司等提供国际金融与各国宏观经济走势分析等专业数据"
      }, {
         name: "EPS数据平台",
         url: "http://www.epsnet.com.cn/",
         introduce: "是由北京福卡斯特信息技术有限公司提供的“专业数据+预测分析”平台。收录国内外的经济、贸易、行业、科技领域的统计数据；利用内嵌的数据分析预测软件可完成对数据的分析和预测"
      }, {
         name: "塔塔统计数据库",
         url: "https://cndata.datesdata.com.cn/",
         introduce: "其数据主要来源：国家统计局、国家海关总署、工商总局、税务总局；中国工业和信息化部、发改委、文化部、教育部、商务部等部门；中国证监会、中国保监会、中国银监会及各个行业协会；塔塔资讯自有的信息采集系统、市场调查机构、研究机构等。"
      }, {
         name: "Compustat",
         url: "http://wrds-web.wharton.upenn.edu/wrds/",
         introduce: "可提供美加地区超过24000家上市公司的详细季度和年度财务报表与财务指标的历史数据。该库收录北美及全球上市公司近20年的财务数据，另外亦提供北美回溯版，包括400家公司自1950年以来的财务资料；提供约180种模板报表、上市或下市公司财务数据等信息；并可整合最新的或历史性的主要财务数据以制作所需的报表和图表。应用的研究领域包括：资产分析、计量分析、竞争者分析、公司资本结构、财务比率、合并与购并、R&D、资本及存货投资、股市报酬、及资本市场效率等主题。"
      }, {
         name: "国家信息中心：土地市场数据库",
         url: "http://tlink.lib.tsinghua.edu.cn/go?url=http://www.crei.cn/tudi/",
         introduce: "全国及各城市土地市场监测数据，包括地块详情、土地供应与成交数据、重点城市地价数据及基准地价信息等。可查询2007年起土块招拍挂的85万条地块信息（含地块名称、位置、面积、土地类型、成交价款、溢价率、成交人、成交日期等）"
      }, {
         name: " RESSET金融研究数据库",
         url: "http://tlink.lib.tsinghua.edu.cn/go?url=http://db.resset.com/UserLogin?loginName=qhlib&loginPwd=qhlib123",
         introduce: "内容涵盖股票、债券、基金、研究报告、融资融券、宏观、行业、金融统计、外汇、期货、黄金等11大系列。包含近百个子库，包括中英文各数百张表，近两万个字段的内容，囊括了经济、金融、会计实证与投资研究所需的绝大部分数据"
      }, {
         name: "北京大学地理数据平台",
         url: "https://geodata.pku.edu.cn/",
         introduce: "是由北京大学城市与环境学院于2017年建立。平台建立的宗旨是为本院和地理专业的学生、教师以及科研人员提供全面、丰富、专业的数据信息，力图搭建一个地理数据共享交流的平台，构建从不同方向描述地球系统的数据库。数据包括自然地理、人文地理、历史地理等多个地理专业的图形图像、统计图表、文本文件及监测和模型运算得到的数据，数据范围包括全国及全球范围，数据来源于我院科研成果和积累的数据，以及平台收集整理的数据。数据平台的建设得到了学校科研部、设备部的大力支持，也得到了中国宇航学会卫星专业委员会提供的帮助。平台管理由北京大学城市与环境学院教授组成的地理数据平台小组负责，平台运行维护由人地系统与环境遥感实验室负责"
      }, {
         name: "北京大学中国调查数据资料库",
         url: "https://opendata.pku.edu.cn/dataverse/CSDA;jsessionid=4ef9c4ece41184276bf1b0a1724d",
         introduce: "成立于2015年，是国家自然科学基金-北京大学管理科学数据中心根据国内管理科学量化研究对于调查数据的需求而设立的项目。该项目的目标是收录整合国内优质调查科研数据资源，通过专业的数据管理与监护，实现便捷的数据共享，为科学研究和决策管理提供高水平的数据服务"
      }, {
         name: "中国家庭追踪调查",
         url: "https://opendata.pku.edu.cn/dataverse/CFPS;jsessionid=4ef9c4ece41184276bf1b0a1724d",
         introduce: "旨在通过跟踪收集个体、家庭、社区三个层次的数据，反映中国社会、经济、人口、教育和健康的变迁，为学术研究和公共政策分析提供数据基础"
      }, {
         name: "北京大学中国国情研究中心",
         url: "https://opendata.pku.edu.cn/dataverse/RCCC",
         introduce: "其宗旨在于发挥北京大学的人才和知识密集的优势，组织和开展多种研究项目、学术交流活动和培训工作，推进对中国社会、政治、经济发展状况的多学科综合研究。重点在于推动实证的、量化的科学方法在学术界、政策研究，以及市场调研等方面的应用。"
      }, {
         name: "EconLit",
         url: "http://nrs.harvard.edu/urn-3:hul.eresource:econlitx",
         introduce: "经济学家们几乎对任何社会干预和许多医疗干预都进行了研究"
      }]
   },
   {
      id: 10,
      name: '图像',
      list: [{
         name: "Violent-Flows ",
         url: "https://www.openu.ac.il/home/hassner/data/violentflows/",
         introduce: "真实世界的数据库，人群暴力视频片段以及旨在测试暴力/非暴力分类和暴力爆发检测的标准基准协议"
      }, {
         name: "ALLEN BRAIN MAP",
         url: "https://portal.brain-map.org/",
         introduce: "提供神经影像学数据"
      }, {
         name: "BBBC",
         url: "https://www.broadinstitute.org/bbbc",
         introduce: "是可自由下载的显微镜图像集的集合"
      }, {
         name: "CELL IMAGE LIBRARY",
         url: "http://www.cellimagelibrary.org/",
         introduce: "由美国NIH资助的公开免费的资源数据库，其中包含细胞的图像、视频和动画，可以捕获多种生物、细胞类型和细胞过程"
      }, {
         name: "Danbooru2020 ",
         url: "https://www.gwern.net/Danbooru",
         introduce: "一个大型动漫图像数据库"
      }, {
         name: "Digital Lunar Orbiter Photographic Atlas of the Moon",
         url: "http://www.lpi.usra.edu/resources/lunar_orbiter/",
         introduce: "月球轨道器摄影卫星阿特拉斯是一个参考手册。阿特拉斯中包含的图像可用于研究月球形态"
      }, {
         name: "EMPIAR",
         url: "http://www.ebi.ac.uk/pdbe/emdb/empiar/",
         introduce: "电子显微镜公共图像档案馆，是原始二维电子显微镜图像的公共资源"
      }, {
         name: "ESA's Sentinel",
         url: "https://scihub.copernicus.eu/dhus/#/home",
         introduce: "欧洲航天局哨兵系列卫星，以高分辨率著称"
      }, {
         name: "Face Image Project",
         url: "http://www.openu.ac.il/home/hassner/Adience/data.html",
         introduce: "提供开放的研究资源，以反映现代人脸识别和处理任务的挑战。"
      }, {
         name: "FAO GeoNetwork",
         url: "http://www.fao.org/geonetwork/srv/en/main.home",
         introduce: "一个全球地理信息系统数据集，提供农业、渔业、土地资源相关的GIS数据以及相关卫星图像数据"
      }, {
         name: "Figshare",
         url: "https://figshare.com/",
         introduce: "一个在线数据知识库，用户可以存储、分享和发现科研数据的平台"
      }, {
         name: "GDC",
         url: "https://portal.gdc.cancer.gov/",
         introduce: "GDC 数据门户是一个强大的数据驱动平台，允许癌症研究人员和生物信息学家搜索和下载癌症数据进行分析"
      }, {
         name: "Goddard Space Flight Center（戈达德太空飞行中心）",
         url: "https://www.nasa.gov/goddard",
         introduce: "哈勃望远镜和詹姆斯·韦伯空间望远镜数据。包含图片、视频、和多媒体资源三个子库"
      }, {
         name: "Google 地图",
         url: "http://ditu.google.com/",
         introduce: "截获Goole Map卫星影像、地图、地形图，能够进行海量下载，自由拼图，关键还在于能够赋予精确的投影坐标"
      }, {
         name: "HCP",
         url: "https://www.humanconnectome.org/study/hcp-young-adult",
         introduce: "包括1100多个健康年轻人的高质量神经影像数据"
      }, {
         name: "Indoor Scene Recognition",
         url: "http://web.mit.edu/torralba/www/indoor.html",
         introduce: "该数据库包含67个室内类别，共15620张图像。 图像的数量因类别而异，但每个类别至少有100张图像"
      }, {
         name: "Insect Images",
         url: "https://www.insectimages.org/",
         introduce: "昆虫图像提供昆虫学相关的高质量图像档案，数据由俄亥俄州立大学、南部森林昆虫工作会议、佛罗里达州农业和消费者服务部等共同提供。"
      }, {
         name: "Kaggle",
         url: "https://www.kaggle.com/datasets",
         introduce: "是由联合创始人、首席执行官安东尼·高德布卢姆（Anthony Goldbloom）2010年在墨尔本创立的，主要为开发商和数据科学家提供举办机器学习竞赛、托管数据库、编写和分享代码的平台。该平台已经吸引了80万名数据科学家的关注，这些用户资源或许正是吸引谷歌的主要因素"
      }, {
         name: "LILA BC",
         url: "http://lila.science/",
         introduce: "是一个与生物学和保护相关的数据集的存储库，旨在为机器学习（ML）研究人员和希望利用ML进行生物学和保护的研究人员提供资源。 "
      }, {
         name: "mendeley Data",
         url: "https://data.mendeley.com/",
         introduce: "是一个安全的基于云的存储库，您可以存储您的数据，确保无论您身在何处，都可以轻松共享、访问和引用数据"
      }, {
         name: "NOAA Digital Coast",
         url: "https://coast.noaa.gov/digitalcoast/",
         introduce: "提供沿海区域的数据，主要包括海底数据、海拔、图像、土地覆盖以及社会经济数据等，数据包括雷达、红外和真彩色影像数据等"
      }, {
         name: "OpenfMRI.org",
         url: "https://openfmri.org/",
         introduce: "是一个致力于自由共享原始磁共振成像（MRI）数据集的项目。 "
      }, {
         name: "Stanford Dogs Dataset",
         url: "http://vision.stanford.edu/aditya86/ImageNetDogs/",
         introduce: "包含来自世界各地的120种犬的图像"
      }, {
         name: "studyforrest",
         url: "https://www.studyforrest.org/",
         introduce: "包含了数小时的fMRI扫描，脑部结构扫描，眼动数据以及电影的大量注释"
      }, {
         name: "SUN",
         url: "http://groups.csail.mit.edu/vision/SUN/hierarchy.html",
         introduce: "目标是为计算机视觉，人类感知，认知和神经科学，机器学习和数据挖掘，计算机图形学和机器人技术的研究人员提供涵盖各种环境场景，地点和环境的带注释图像的全面集合"
      }, {
         name: "SVIRO",
         url: "https://sviro.kl.dfki.de/",
         introduce: "是用于车辆内部后排座椅占用率检测和分类的综合数据集"
      }, {
         name: "The Action Similarity Labeling (ASLAN) Challenge",
         url: "https://talhassner.github.io/home/projects/ASLAN/ASLAN-main.html",
         introduce: "是一个视频动作数据库和用于研究视频动作相似性的完整测试协议"
      }, {
         name: "The Animals with Attributes dataset",
         url: "https://cvml.ist.ac.at/AwA2/",
         introduce: "由 50 个动物类的 37322 张图像组成，每个图像都有预提取的特征表示"
      }, {
         name: "The Cancer Imaging Archive",
         url: "https://portal.gdc.cancer.gov/",
         introduce: "可识别并托管大量癌症医学图像的存档，以供公众下载"
      }, {
         name: "The Oxford-IIIT Pet Dataset",
         url: "https://www.robots.ox.ac.uk/~vgg/data/pets/",
         introduce: "创建了37个类别的宠物数据集，每个类别大约有200张图像。 图像在比例，姿势和照明方面有很大的差异。 所有图像均具有品种，头部ROI和像素级三图分割的相关地面真相注释"
      }, {
         name: "USGS Data",
         url: "https://earthexplorer.usgs.gov/",
         introduce: "美国地质调查局Landsat、MODIS等卫星影像下载"
      }, {
         name: "Visual Genome",
         url: "http://visualgenome.org/about",
         introduce: "视觉基因组是一个数据集，一个知识库，将结构化图像概念与语言联系在一起"
      }, {
         name: "Wilma Bainbridge",
         url: "http://wilmabainbridge.com/index.html",
         introduce: "致力于通过心理物理实验，神经影像学和绘画研究的视角来检验感知与记忆之间的桥梁"
      }, {
         name: "World Health Organization",
         url: "http://www.who.int/gho/en/",
         introduce: "探索健康数据的世界"
      }, {
         name: "YouTube Faces DB",
         url: "http://www.cs.tau.ac.il/~wolf/ytfaces/",
         introduce: "是一个人脸视频数据库，旨在研究视频中人脸识别不受约束的问题"
      }, {
         name: "癌症图像档案",
         url: "https://www.cancerimagingarchive.net/",
         introduce: "是一项可以de-identify和托管可供公众下载的大量癌症医学图像的服务"
      }, {
         name: "北京大学地理数据平台",
         url: "https://geodata.pku.edu.cn/",
         introduce: "是由北京大学城市与环境学院于2017年建立。平台建立的宗旨是为本院和地理专业的学生、教师以及科研人员提供全面、丰富、专业的数据信息，力图搭建一个地理数据共享交流的平台，构建从不同方向描述地球系统的数据库。数据包括自然地理、人文地理、历史地理等多个地理专业的图形图像、统计图表、文本文件及监测和模型运算得到的数据，数据范围包括全国及全球范围，数据来源于我院科研成果和积累的数据，以及平台收集整理的数据。数据平台的建设得到了学校科研部、设备部的大力支持，也得到了中国宇航学会卫星专业委员会提供的帮助。平台管理由北京大学城市与环境学院教授组成的地理数据平台小组负责，平台运行维护由人地系统与环境遥感实验室负责"
      }, {
         name: "地理空间数据云",
         url: "http://www.gscloud.cn/",
         introduce: "以中国科学院及国家的科学研究为主要需求，逐渐引进当今国际上不同领域内的国际数据资源，并对其进行加工、整理、集成，最终实现数据的集中式公开服务、在线计算等"
      }, {
         name: "国家综合地球观测数据共享平台",
         url: "http://www.chinageoss.org/dsp/home/index.jsp",
         introduce: "是由科技部主导，国家遥感中心牵头，各卫星中心、数据单位贡献数据、参与建设，数据主要为遥感卫星数据，涵盖主要的国内卫星和国际卫星，也包括相关科学研究数据。"
      }, {
         name: "和鲸社区",
         url: "https://www.heywhale.com/home/dataset",
         introduce: "是国内知名的开放数据科学社区,是最早一批专注于大数据算法比赛的平台,支持在线分享代码、项目和数据集"
      }, {
         name: "加州理工学院行人数据集",
         url: "http://www.vision.caltech.edu/Image_Datasets/CaltechPedestrians/",
         introduce: "包括大约 10 小时的 640x480 30Hz 视频，这些视频取自在城市环境中通过正常交通行驶的车辆"
      }, {
         name: "数据堂",
         url: "https://www.datatang.com/",
         introduce: "专注于互联网综合数据交易，提供数据交易、处理和数据API服务，包含语音识别、医疗健康、交通地理、电子商务、社交网络、图像识别等方面的数据。"
      }, {
         name: "中国科学院遥感与数字地球研究所",
         url: "http://eds.ceode.ac.cn/sjglb/dataservice.htm",
         introduce: "提供多种标准景卫星遥感数据产品免费下载服务"
      }, {
         name: "中国植物图像库",
         url: "http://www.plantphoto.cn/",
         introduce: "已收录植物图片482科4582属28220种4001809幅"
      }, {
         name: "Medical segmentation decathlon (MSD)",
         url: "http://medicaldecathlon.com/index.html",
         introduce: "Simpson 等人创建了各种解剖部件的大型开源，手工注释的医学图像数据集"
      }, {
         name: "JSRT",
         url: "http://db.jsrt.or.jp/eng-01.php",
         introduce: "JSRT致力于放射科学和技术的发展，推动了从日本到世界其他地区的发展和进步。放射技术的学术范围与医学、工程学和物理学密切相关，因此放射技术的发展需要广阔的视野。"
      }, {
         name: "Graviti",
         url: "https://gas.graviti.cn/open-datasets",
         introduce: "提供面向机器学习的数据管理 SaaS 产品和构建以 AI 开发者为核心的开源数据集社区，降低运用机器学习创新的团队和个人在AI开发过程中因处理非结构化数据带来的不断增长的数据获取、存储和处理成本，加速AI开发流程，提供坚实的人工智能基础设施"
      }, {
         name: "ISLES",
         url: "http://www.isles-challenge.org/",
         introduce: "缺血性中风病变分割"
      }, {
         name: "LiTS",
         url: "https://competitions.codalab.org/competitions/17094",
         introduce: "这些数据和细分由世界各地的不同临床站点提供，用于肝肿瘤和肝肿瘤的分割"
      }, {
         name: "PROMISE12",
         url: "https://promise12.grand-challenge.org/",
         introduce: "此数据集用于前列腺分割。这些数据包括良性疾病（如良性前列腺增生）和前列腺癌患者"
      }, {
         name: "LIDC-IDRI",
         url: "https://wiki.cancerimagingarchive.net/display/Public/LIDC-IDRI",
         introduce: "包括诊断和肺癌筛查胸腔计算断层扫描 （CT） 扫描与标记注释病变。它是一个可上网的国际资源，用于开发、培训和评估用于肺癌检测和诊断的计算机辅助诊断 （CAD） 方法"
      }, {
         name: "OASIS",
         url: "https://www.oasis-brains.org/",
         introduce: "是一个旨在使大脑的神经成像数据集免费提供给科学界的项目。通过编制和自由分发神经成像数据集，希望促进基础和临床神经科学的未来发现"
      }, {
         name: "Sunnybrook cardiac data",
         url: "https://hyper.ai/datasets/5413",
         introduce: "又称 2009 年心脏 MR 左心室分割挑战数据集，被用于心脏病研究，它由来自患者和病例样本的 45 个影像组成，分别为健康、肥大、心肌梗塞和心脏衰竭"
      }, {
         name: " MIAS",
         url: "https://www.repository.cam.ac.uk/",
         introduce: "是一个乳腺癌X射线图像数据库，由英国一个研究机构于1995年创建。"
      }, {
         name: "BRATS",
         url: "https://www.med.upenn.edu/sbia/brats2018/data.html",
         introduce: "此数据集是脑肿瘤分割竞争数据集，与 MICCAI 会议相结合。为了评估最好的脑肿瘤分割方法和比较不同的方法，自2012年以来每年举行一次。"
      }, {
         name: "DDSM",
         url: "http://www.eng.usf.edu/cvprg/Mammography/Database.html",
         introduce: "是乳腺造影图像分析研究界使用的资源，被研究人员广泛使用"
      }, {
         name: "DRIVE",
         url: "https://drive.grand-challenge.org/",
         introduce: "此数据集用于比较视网膜图像中血管的分割"
      }, {
         name: "HVSMR2018 ",
         url: "http://segchd.csail.mit.edu/",
         introduce: "MICCAI 先天性心脏病 3D 心血管 MRI 全心和大血管分割研讨会"
      }, {
         name: "SKI10",
         url: "http://www.ski10.org/",
         introduce: "SKI10 的目标是比较膝盖 MRI 数据中软骨和骨细分的不同算法。膝软骨分割是近年来相当重要的临床相关分割问题。"
      }, {
         name: "CHASEDB1",
         url: "https://blogs.kingston.ac.uk/retinal/chasedb1/",
         introduce: "视网膜图像数据库"
      }, {
         name: "STARE",
         url: "http://homes.esat.kuleuven.be/~mblaschk/projects/retina/",
         introduce: ""
      }]
   },
   {
      id: 11,
      name: '其他',
      list: [{
         name: "NASA Reverb ",
         url: "http://reverb.echo.nasa.gov/reverb/",
         introduce: "美国航空航天局，此地数据源来自业界宗师"
      }, {
         name: "交通科技信息资源",
         url: "http://www.transst.cn/PlatForm/Resource.aspx",
         introduce: "交通运输部科学研究院建设信息资源共享平台"
      }, {
         name: "数据堂",
         url: "https://www.datatang.com/",
         introduce: "专注于互联网综合数据交易，提供数据交易、处理和数据API服务，包含语音识别、医疗健康、交通地理、电子商务、社交网络、图像识别等方面的数据。"
      }, {
         name: "猫眼专业版",
         url: "http://piaofang.maoyan.com/dashboard",
         introduce: "电影票房统计分析平台，猫眼专业版有实时的票房统计，影片的排盘情况、上座率和影院数据，对于当前电影的分析是必不可少的"
      }, {
         name: "中国产业信息",
         url: "https://www.chyxx.com/",
         introduce: "工业和信息化部主管,人民邮电报社主办,是我国通信行业唯一拥有国务院新闻办授予新闻发布权的新闻网站。"
      }, {
         name: "国家材料科学数据共享",
         url: "http://www.materdata.cn/",
         introduce: "涵盖逾3000种钢铁材料及材料基础的高质量数据近11万条"
      }, {
         name: "FAOSTAT",
         url: "http://www.fao.org/faostat/en/#home",
         introduce: "提供1961年到现在的关于农业、营养、渔业、林业和粮食援助的时间序列数据，是一个多语言数据库，可以搜索、浏览和下载数据。"
      }, {
         name: "国家林业和草原科学数据中心",
         url: "http://www.forestdata.cn/",
         introduce: "林业数据中心整合了森林资源、草原资源、湿地资源、荒漠化资源、自然保护资源、林业生态环境资源、森林保护、森林培育、木材科学与技术、林业科技文献、林业科技项目及林业行业发展等科学数据，以提供数据共享服务。"
      }, {
         name: "中经网产业数据库",
         url: "https://cyk.cei.cn/",
         introduce: "是国家信息中心中经网精心打造的、覆盖机械、汽车、电子通讯、医药、石油化工、能源、交通、房地产、钢铁、旅游等10多个产业集群的全新数据库产品。"
      }, {
         name: "Databank of Official Statistics on Quebec ",
         url: "http://www.bdso.gouv.qc.ca/",
         introduce: "包括农业和生物食品产业、文化与传播、经济、教育、培训和儿童保育、能量、环境、金融与非金融合作、林业、健康、福利、卫生和社会服务、劳动补偿、生活条件与社会、制造业、采矿业、市政事务、人口、科学技术创新、可持续发展、旅游、交通、用户和基础设施等多方面的统计数据和信息"
      }, {
         name: "Aquastat",
         url: "http://www.fao.org/nr/water/aquastat/main/index.stm",
         introduce: "粮农组织的全球水信息系统，提供水资源、水利用、灌溉和排水、废水处理等各个主题的数据"
      }, {
         name: "Agency for Healthcare Research and Quality, Data & Surveys",
         url: "http://www.ahrq.gov/research/data/index.html",
         introduce: "包含美国健康信息知识库(USHIK)、系统评价数据存储库(SRDR)以及有关医疗和医疗保险的成本、质量、可访问性和评估等其他信息，帮助人们做出更加明智的决策，提高卫生保健服务的质量。"
      }, {
         name: "American National Election Studies",
         url: "http://www.electionstudies.org/",
         introduce: "美国国家选举研究(ANES)进行全国调查和试点研究，提供各种类型的数据集"
      }, {
         name: "The China Biographical Database",
         url: "https://projects.iq.harvard.edu/cbdb",
         introduce: "其中包含截至2020年11月（主要是从7世纪到19世纪）约471,000个人的传记信息"
      }, {
         name: "mendeley Data",
         url: "https://data.mendeley.com/",
         introduce: "是一个安全的基于云的存储库，您可以存储您的数据，确保无论您身在何处，都可以轻松共享、访问和引用数据"
      }, {
         name: "维基百科语料",
         url: "https://yq.aliyun.com/go/articleRenderRedirect?url=https%3A%2F%2Fcorpus.byu.edu%2Fwiki%2F",
         introduce: "它包含来自400多万篇文章的将近19亿字。这个强大的NLP数据集你可以通过单词，短语或段落本身的一部分进行搜索"
      }, {
         name: "中国互联网信息中心",
         url: "http://www.cnnic.cn/",
         introduce: "主要包括互联网发展相关基础数据，相对第三方机构的互联网数据而言，数据更宏观且权威。"
      }, {
         name: "植物表型数据集",
         url: "http://www.plant-phenotyping.org/datasets-home",
         introduce: "适用于植物和叶片检测，分割，跟踪和物种识别的植物数据"
      }, {
         name: "History of Marine Animal Populations",
         url: "https://hydra.hull.ac.uk/resources?utf8=%E2%9C%93&search_field=all_fields&q=hmap",
         introduce: "HMAP数据页面向研究者提供渔业捕获量随时间空间的变化数据，旨在揭示生态环境动态改变、人类活动对生态环境的影响、海洋资源在人类社会发展过程中的作用。"
      }, {
         name: "阿里云天池",
         url: "https://tianchi.aliyun.com/dataset/",
         introduce: "阿里旗下数据科学竞赛平台"
      }, {
         name: "PolData",
         url: "https://link.zhihu.com/?target=https%3A//github.com/erikgahner/PolData",
         introduce: "这是一个政治数据库的集合。这些数据包括：内阁、公民、宪法、政治机构、政党和政治家、民主、经济、选举、国际关系、媒体、政策等。所有数据都在网页中按列表展示，一目了然，非常便捷。"
      }, {
         name: "STR",
         url: "https://link.zhihu.com/?target=http%3A//ki.se/en/research/for-researchers",
         introduce: "来自瑞典卡罗林斯卡学院，其包含了双生子信息数据的注册和研究"
      }, {
         name: "社会网与求职调查数据库",
         url: "http://css.sysu.edu.cn/Data",
         introduce: "中国大城市社会网与求职调查是由美国明尼苏达大学边燕杰教授发起，在中国八大城市（广州、上海、西安、天津、兰州、厦门、济南、长春）开展的一项调查，其广州地区的调查由中山大学社会学与社会工作系梁玉成副教授主持，得到中山大学社会科学调查中心资助。"
      }, {
         name: "中国人民大学社会学系",
         url: "http://www.sachina.edu.cn/modules/datacenter/viewdata.php?channelid=5&projid=13&dataid=119",
         introduce: "主要研究大学生在校行为与未来发展的关系"
      }, {
         name: "中国电信股份有限公司北京研究院",
         url: "http://www.ctbigdata.cn/BackgroundInvestigation/",
         introduce: "个人信用背景调查、画像分析"
      }, {
         name: "Global Carbon Budget",
         url: "https://www.icos-cp.eu/global-carbon-budget-2019",
         introduce: "数据库详细描述了数据库的所有数据集和模型结果，将对全球碳预算做出贡献的所有数据集集成在两个Excel文件中，一个为全球碳预算，一个为国家层面排放清单"
      }, {
         name: "二氧化碳信息分析中心",
         url: "https://doi.org/10.3334/CDIAC/00001_V2017",
         introduce: "提供的化石燃料二氧化碳排放数据形式丰富，包括了多尺度年度时间序列、全球碳预算（2016年之前）、全球历史数据、网格数据、高频数据等各种类型"
      }, {
         name: "英国石油公司",
         url: "https://www.bp.com/en/global/corporate/energy-economics/statistical-review-of-world-energy.html",
         introduce: "每年发布的报告不仅对世界能源相关形势进行深入分析，同时公开其收集且使用各类能源相关数据包括排放数据"
      }, {
         name: "AMPds ",
         url: "http://ampds.org/",
         introduce: "包含电力、水和天然气测量数据"
      }, {
         name: "COMBED",
         url: "http://combed.github.io/",
         introduce: "COMBED 是来自商业建筑的第一个与能源相关的数据集"
      }, {
         name: "ORBIS AMERICA",
         url: "http://210.32.137.90/s/lib/libtb/turning/279",
         introduce: "是一个包含了美洲地区1亿家企业的财务、管理层、董监高管、股权关系、评级报告、原始财务报表、并购记录和行业信息的大型企业分析库。"
      }, {
         name: "BYU语料库",
         url: "http://210.32.137.90/s/lib/libtb/turning/1611",
         introduce: "由Brigham Young University（杨百翰大学）语言学教授Mark Davies创立，每月使用人数超过13万人，已成为目前使用最广泛的语料库之一"
      }, {
         name: "LDC语料库",
         url: "http://210.32.137.90/s/lib/libtb/turning/1545",
         introduce: "包括阿拉伯语、汉语和英语新闻文本、布朗语料库全文、来自交换机与费舍库（Fisher Collection）数以百万计的英语电话语音以及美国英语口语词汇"
      }, {
         name: "Metropolitan Travel Survey Archive",
         url: "http://www.surveyarchive.org/",
         introduce: "通过互联网进行美国都市圈、州际间以及小地区的旅行调查，并存储相关的调研数据"
      }, {
         name: "HJ Andrews Experimental Forest ",
         url: "http://andrewsforest.oregonstate.edu/",
         introduce: "HJ安德鲁斯成立于1948年，位于俄勒冈州卡斯卡德山脉西部，占地16000英亩，作为生态研究基地提供关于森林，溪流及流域的相关信息，促进生态系统与科学，教育，自然资源管理和人文科学之间的密切合作"
      }, {
         name: "ACLED",
         url: "https://link.zhihu.com/?target=https%3A//acleddata.com/%23/dashboard",
         introduce: "全球武装冲突地点和事件数据"
      }, {
         name: "Global Terrorism Database",
         url: "https://link.zhihu.com/?target=https%3A//www.start.umd.edu/gtd/",
         introduce: "迄今为止全球恐怖主义研究领域涵盖恐怖主义事件最为全面的数据库，收录了1970-2017年（每年更新）超过17万条恐怖主义袭击事件相关数据，并且针对每起恐怖袭击事件都有近135个变量来记录其相关情况"
      }, {
         name: "数字丝路地球大数据平台",
         url: "http://www.opendbar.cn/",
         introduce: "该平台以“数字连接，共享繁荣”为主旨，包括“一带一路”资源、环境、气候、灾害、遗产等专题数据集94套、自主知识产权数据产品57类"
      }, {
         name: "全球研究标识符数据库",
         url: "https://www.grid.ac/",
         introduce: "是一个可供公众访问的全球教育和研究组织数据库"
      }, {
         name: "5118",
         url: "https://link.zhihu.com/?target=https%3A//www.5118.com/",
         introduce: "支持查询网站排名及发展趋势、百度收录情况等信息"
      }, {
         name: "百度指数",
         url: "https://link.zhihu.com/?target=http%3A//index.baidu.com/v2/index.html%23/",
         introduce: "百度旗下/以百度网民行为数据为基础的数据分享平台，支持查询需求图谱"
      }, {
         name: "移动观象台",
         url: "https://link.zhihu.com/?target=http%3A//mi.talkingdata.com/",
         introduce: "基于移动设备用户的操作行为，提供应用、公众号排行等，以及大量数据报告"
      }, {
         name: "中研网数据",
         url: "https://link.zhihu.com/?target=http%3A//www.chinairn.com/data/",
         introduce: "提供医疗、房产、制造业、服务业、零售消费、车辆等全行业数据"
      }, {
         name: "CBO中国票房",
         url: "https://link.zhihu.com/?target=http%3A//www.cbooo.cn/",
         introduce: "基于国内票房数据，统计票房排行、上座率等信息"
      }, {
         name: "易车指数",
         url: "https://link.zhihu.com/?target=http%3A//index.bitauto.com/login",
         introduce: "反映国内汽车销售市场，为购车者或汽车从业者提供参考的数据指数"
      }, {
         name: "房天下",
         url: "https://link.zhihu.com/?target=https%3A//fdc.fang.com/index/",
         introduce: "提供中国指数研究院和CREIS中指数据，展示国内房地产数据情况"
      }, {
         name: "优易数据",
         url: "https://www.youedata.com/",
         introduce: "拥有国家级信息资源的数据平台。包含政务、社会、社交、教育、消费、交通、能源、金融、健康等多个领域的数据资源"
      }, {
         name: "HotspotQA数据集",
         url: " https://hotpotqa.github.io/",
         introduce: "具有自然、多跳问题的问答数据集，具有支持事实的强大监督，以实现更易于解释的问答系统"
      }, {
         name: "安然数据集",
         url: "https://link.zhihu.com/?target=https%3A//www.cs.cmu.edu/~./enron/",
         introduce: "来自安然高级管理层的电子邮件数据"
      }, {
         name: "Gutenberg电子书列表",
         url: "https://link.zhihu.com/?target=http%3A//www.gutenberg.org/wiki/Gutenberg%3AOffline_Catalogs",
         introduce: "Gutenberg项目中带注释的电子书书单"
      }, {
         name: " Hansards加拿大议会文本",
         url: "https://link.zhihu.com/?target=http%3A//www.isi.edu/natural-language/download/hansard/",
         introduce: "来自第36届加拿大议会记录的130万组文本"
      }, {
         name: "eopardy",
         url: " http://www.reddit.com/r/datasets/comments/1uyd0t/200000_jeopardy_questions_in_a_json_file/",
         introduce: "来自问答节目Jeopardy的超过200,000个问题的归档"
      }, {
         name: "英文垃圾短信收集",
         url: "https://link.zhihu.com/?target=http%3A//www.dt.fee.unicamp.br/~tiago/smsspamcollection/",
         introduce: "由5574条英文垃圾短信组成的数据集"
      }, {
         name: "UCI的Spambase",
         url: "https://link.zhihu.com/?target=https%3A//archive.ics.uci.edu/ml/datasets/Spambase",
         introduce: "一个大型垃圾邮件数据集，对垃圾邮件过滤非常有用"
      }, {
         name: "Sentiment140",
         url: "https://yq.aliyun.com/go/articleRenderRedirect?url=http%3A%2F%2Fhelp.sentiment140.com%2Ffor-students%2F",
         introduce: "一个可用于情感分析的数据集"
      }, {
         name: "维基百科语料库",
         url: "https://yq.aliyun.com/go/articleRenderRedirect?url=https%3A%2F%2Fcorpus.byu.edu%2Fwiki%2F",
         introduce: "该数据集是维基百科全文的集合。它包含来自400多万篇文章的将近19亿字。这个强大的NLP数据集你可以通过单词，短语或段落本身的一部分进行搜索"
      }, {
         name: "The GDELT Project ",
         url: "https://www.gdeltproject.org/data.html",
         introduce: "是有史以来建立的最大，最全面，最高分辨率的人类社会开放数据库。 仅2015年的数据就记录了近四千亿兆的情感快照和超过15亿的位置引用，而其总存档时间超过215年，使其成为现有的最大的开放式时空数据集之一，并推动了 全球人类社会“大数据”研究的边界。 它的全球知识图谱将全世界的人们，组织，位置，主题，数量，图像和情感连接到整个星球上的单个整体网络中"
      }, {
         name: "GSS",
         url: "http://gss.norc.org/",
         introduce: "为政治家、决策者和学者提供了清晰和公正的观点，了解美国人对国家支出优先事项、犯罪和惩罚、群体间关系和对机构的信心等问题的看法和感受。"
      }, {
         name: "GLOBAL RELIGIOUS FUTURS",
         url: "http://www.globalreligiousfutures.org/",
         introduce: "探索到2050年世界迅速变化的宗教形象"
      }, {
         name: "INED",
         url: "https://www.ined.fr/en/",
         introduce: "是一家专门从事人口研究的公共研究所，与国家和国际一级的学术界和研究界合作"
      }, {
         name: "TRAC",
         url: "https://www.trackingterrorism.org/",
         introduce: "是世界上最全面的恐怖组织和活动信息和分析简编之一"
      }, {
         name: "Materials Projec",
         url: "https://materialsproject.org/",
         introduce: "利用超级计算的力量和先进的电子结构方法，Materials Project提供了基于Web的开放式访问，可以访问已知和预测的材料上的计算信息，还可以使用强大的分析工具来激发和设计新颖的材料。"
      }, {
         name: "北京大学地理数据平台",
         url: "https://geodata.pku.edu.cn/",
         introduce: "是由北京大学城市与环境学院于2017年建立。平台建立的宗旨是为本院和地理专业的学生、教师以及科研人员提供全面、丰富、专业的数据信息，力图搭建一个地理数据共享交流的平台，构建从不同方向描述地球系统的数据库。数据包括自然地理、人文地理、历史地理等多个地理专业的图形图像、统计图表、文本文件及监测和模型运算得到的数据，数据范围包括全国及全球范围，数据来源于我院科研成果和积累的数据，以及平台收集整理的数据。数据平台的建设得到了学校科研部、设备部的大力支持，也得到了中国宇航学会卫星专业委员会提供的帮助。平台管理由北京大学城市与环境学院教授组成的地理数据平台小组负责，平台运行维护由人地系统与环境遥感实验室负责"
      }, {
         name: "北京大学中国调查数据资料库",
         url: "https://opendata.pku.edu.cn/dataverse/CSDA;jsessionid=4ef9c4ece41184276bf1b0a1724d",
         introduce: "成立于2015年，是国家自然科学基金-北京大学管理科学数据中心根据国内管理科学量化研究对于调查数据的需求而设立的项目。该项目的目标是收录整合国内优质调查科研数据资源，通过专业的数据管理与监护，实现便捷的数据共享，为科学研究和决策管理提供高水平的数据服务"
      }, {
         name: "综合型语言知识库",
         url: "https://opendata.pku.edu.cn/dataverse/clkb",
         introduce: "属于文理结合的\"中国语言文字信息处理\"学科。语言信息处理旨在让机器理解和运用人类语言。我国语言信息处理的上规模研究始于20世纪80年代中期，当时国内外汉语语言基础资源几乎是空白。与英语、日语不同，汉语缺乏形式标记，汉语语言知识库的建设尤显迫切和艰巨。基于对母语知识与文化的认知优势，项目组从1986年开始研究汉语计算模型和语言知识形式化描述方法，并构建语言知识库。历时20余年，建成综合型语言知识库，有力地支持了中文信息处理的原创性科学研究和应用技术开发。"
      }, {
         name: "北京大学可视化与可视分析研究",
         url: "https://opendata.pku.edu.cn/dataverse/vis",
         introduce: "主要从事科学可视化、信息可视化与可视分析的相关研究"
      }, {
         name: "北京大学数据与信息管理研究组",
         url: "https://opendata.pku.edu.cn/dataverse/DAIM",
         introduce: "隶属于北京大学信息学院网络与信息系统研究所，负责人为崔斌研究员。主要研究方向包括面向互联网应用的大规模数据库管理系统、社交网络和社会化媒体的数据管理与挖掘等。我们的研究受核高基、国家自然科学基金、863项目以及微软亚洲研究院等资助和支持。欢迎访问研究组主页：http://daim.pku.edu.cn。 主要提供：社交网络、社会化媒体、多媒体数据、存储日志等"
      }, {
         name: "北京大学中国古代史研究中心",
         url: "https://opendata.pku.edu.cn/dataverse/crach",
         introduce: "中国历代人物传记资料库"
      }, {
         name: "北京大学软件与微电子学院自然语言处理研究组",
         url: "https://opendata.pku.edu.cn/dataverse/SSNLP",
         introduce: "双语句子与文章"
      }, {
         name: "科学评价与大数据应用实验室",
         url: "https://opendata.pku.edu.cn/dataverse/scie",
         introduce: "学科数据"
      }, {
         name: "全国高校数据驱动创新研究大赛",
         url: "https://opendata.pku.edu.cn/dataverse/contest",
         introduce: "由国家信息中心大数据发展部、北京市信息资源管理中心作为行业指导单位，北京大学图书馆、北京大学信息管理系、北京大学中国社会科学调查中心、重庆西部大数据前沿应用研究院主办，高等教育文献保障系统、重庆大学图书馆协办，面向高校、研究院（所）在读学生，开展数据驱动创新研究大赛"
      }]
   }
]