<template>
  <div class="home-title">
    <div id="wrap">
      <div
        class="title"
        :style="[
          { 'font-weight': weight === 0 ? 0 : 400 },
          { width: width },
          { 'font-size': fontSize },
        ]"
      >
        {{ name }}
      </div>
      <div
        id="right"
        v-if="is_right"
        @mouseenter="enter"
        @mouseleave="mouseenter = false"
        @click="copyText"
        :class="mouseenter ? 'cur' : ''"
      >
        复制链接，推荐身边人（数据库将持续新增）
      </div>
    </div>
    <el-progress
      :percentage="percentage"
      :show-text="false"
      :stroke-width="strokeWidth"
    ></el-progress>
  </div>
</template>
<script>
export default {
  props: {
    width: {
      type: String,
      require: true,
    },
    fontSize: {
      type: String,
    },
    name: {
      type: String,
      required: true,
    },
    percentage: {
      type: Number,
      require: true,
    },
    weight: {
      type: Number,
      default: 0,
    },
    is_right: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      strokeWidth: 2,
      mouseenter: false,
    };
  },
  mounted() {},
  methods: {
    enter() {
      this.mouseenter = true;
    },
    copyText() {
      var input = document.createElement("input"); // js创建一个input输入框
      input.value =`用AI用数据，搜AIforScience  https://www.aiforscience.cn`; // 将需要复制的文本赋值到创建的input输入框中
      document.body.appendChild(input); // 将输入框暂时创建到实例里面
      input.select(); // 选中输入框中的内容
      document.execCommand("Copy"); // 执行复制操作
      document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
      this.$message({
        message: "复制成功",
        type: "success",
      });
      this.mouseenter = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.home-title {
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
  #wrap {
    display: flex;
    .title {
      // width: 36px;
      height: 24px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #1f1f1f;
      line-height: 24px;
      // float: left;
    }
    #right {
      flex: 1;
      height: 24px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #2977ff;
      line-height: 24px;
      display: flex;
      justify-content: flex-end;
    }
    .cur {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .el-progress {
    margin: 8px 0;
  }
}
.clearfix ::before {
  content: "";
  height: 0;
  line-height: 0;
  display: block;
  clear: both;
  visibility: hidden; /*将元素隐藏起来*/
  /*在页面的clearfix元素后面添加了一个空的块级元素，这个元素清除了浮动*/
}
</style>
