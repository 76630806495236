<template>
  <div class="home">
    <div class="main">
      <div class="left">
        <subject
          :tabs="tab"
          @changeTab="changeTab"
          :tabIndex="tabIndex"
        ></subject>
        <database :list="lists" @jump="jump" :name="name"></database>
      </div>
      <div class="right">
        <user
          :qrurl="qrurl"
          :loginIs="loginIs"
          @kf="kf"
          @js="js"
          @login="login"
          @RefreshLogin="RefreshLogin"
        ></user>
        <application @show="show" :loginIs="loginIs" :qx="qx"></application>
      </div>
    </div>
    <div class="dialog-box" v-if="Show">
      <div class="dialog dialog_wx" v-if="dialogShow">
        <div class="close" @click="close('wx')">
          <img src="@/assets/images/close.png" alt="" />
        </div>
        <p class="title">微信登录</p>
        <p class="sao">扫码为你定制数据库</p>
        <div class="one">
          <div class="two">
            <img :src="qrurl" alt="" @click="Refresh" />
          </div>
        </div>
        <div class="footer">
          <el-checkbox v-model="checked">我已阅读并同意</el-checkbox>
          <p>
            <span @click="fw">《使用协议》</span>
            <span @click="ys">《隐私政策》</span>
          </p>
        </div>
      </div>
      <div class="dialog dialog_kf" v-if="kfShow">
        <div class="wrap">
          <div class="left">
            <p class="one">一站式<br />科研数据解决方案</p>
            <p class="two">用人工智能探索科研数据的更多可能</p>
            <div class="three">
              <p class="wen">联系客服立即免费试用</p>
              <img src="@/assets/images/right.png" alt="" />
            </div>
          </div>
          <div class="right">
            <div class="close" @click="close('kf')">
              <img src="@/assets/images/close.png" alt="" />
            </div>
            <div class="img">
              <img src="@/assets/images/code.png" alt="" />
            </div>
            <p class="wen">微信客服</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import subject from "@/components/subject.vue";
import database from "@/components/database.vue";
import user from "@/components/user.vue";
import application from "@/components/application.vue";
import list from "./list.js";

export default {
  name: "Home",
  components: {
    subject,
    database,
    user,
    application,
  },
  data() {
    return {
      tab: [
        {
          name: "推荐",
          id: 0,
          icon: require("@/assets/images/tj.png"),
          selectIcon: require("@/assets/images/tj1.png"),
        },
        {
          name: "物理",
          id: 1,
          icon: require("@/assets/images/wl.png"),
          selectIcon: require("@/assets/images/wl1.png"),
        },
        {
          name: "化学",
          id: 2,
          icon: require("@/assets/images/hx.png"),
          selectIcon: require("@/assets/images/hx1.png"),
        },
        {
          name: "生物",
          id: 3,
          icon: require("@/assets/images/sw.png"),
          selectIcon: require("@/assets/images/sw1.png"),
        },
        {
          name: "地空",
          id: 4,
          icon: require("@/assets/images/dk.png"),
          selectIcon: require("@/assets/images/dk1.png"),
        },
        {
          name: "环境",
          id: 5,
          icon: require("@/assets/images/hj.png"),
          selectIcon: require("@/assets/images/hj1.png"),
        },
        {
          name: "医学",
          id: 6,
          icon: require("@/assets/images/yx.png"),
          selectIcon: require("@/assets/images/yx1.png"),
        },
        {
          name: "心理",
          id: 7,
          icon: require("@/assets/images/xl.png"),
          selectIcon: require("@/assets/images/xl1.png"),
        },
        {
          name: "社会",
          id: 8,
          icon: require("@/assets/images/sh.png"),
          selectIcon: require("@/assets/images/sh1.png"),
        },
        {
          name: "经济",
          id: 9,
          icon: require("@/assets/images/jj.png"),
          selectIcon: require("@/assets/images/jj1.png"),
        },
        {
          name: "图像",
          id: 10,
          icon: require("@/assets/images/tx.png"),
          selectIcon: require("@/assets/images/tx1.png"),
        },
        {
          name: "其他",
          id: 11,
          icon: require("@/assets/images/qt.png"),
          selectIcon: require("@/assets/images/qt1.png"),
        },
      ],
      list: list,
      tabIndex: 0,
      lists: [],
      qrurl: "", //二维码的地址
      seneKey: "", //获取二维码之后返回的seneKey
      isToken: false, //是否返回token
      timer: "", //定时器
      Show: false, //弹窗的显示与隐藏
      dialogShow: false, //登录弹窗的显示与隐藏
      checked: true,
      name: "数据库 (推荐)",
      url: "", //子组件的值
      loginIs: false, //是否已经扫码过
      is_quanxian: false, //是否有权限
      kfShow: false, //客服弹唱显示与隐藏
      enterindex: 0, //鼠标悬停时的index
      time: "",
      token: localStorage.getItem("token"),
      qx: false, //是否有权限
    };
  },
  created() {
    this.lists = this.list[0].list;
  },
  watch: {},
  mounted() {
    // 判断是否登录了，如果登录了就会有token
    if (!localStorage.getItem("token")) {
      this.loginIs = false;
      // 获取登录二维码
      this.obtainLogin();
      // 每隔1.5秒执行一次
      this.timer = setInterval(() => {
        this.obtainToken(this.seneKey);
      }, 1500);
      // 二维码失效后自动获取
      this.time = setInterval(() => {
        this.obtainLogin();
      }, 50000);
    } else {
      clearInterval(this.timer);
      clearInterval(this.time);
      this.loginIs = true;
      this.qxFn();
    }
  },
  methods: {
    login() {
      if (!localStorage.getItem("token")) {
        this.obtainLogin();
        setInterval(() => {
          this.obtainToken(this.seneKey);
        }, 2000);
      }
    },
    // 刷新二维码
    RefreshLogin() {
      this.obtainLogin();
    },
    // 请求权限
    async qxFn() {
      let res = await this.$api.jurisdiction();
      console.log(res);
      if (res.data.code === 200) {
        if (res.data.data.length != 0) {
          res.data.data.forEach((item, index) => {
            // 有权限
            if (item == "product:afslab:data") {
              // console.log('实验室有权限');
              this.qx = true;
            }
          });
        } else if (res.data.data.length === 0) {
          // 没有权限
          this.qx = false;
        }
      }
    },
    // 介绍
    js() {
      window.open("https://www.aiforscience.cn/home/product.html", "_blank");
    },
    // 隐私政策
    ys() {
      window.open("https://www.aiforscience.cn/cops/security", "_blank");
    },
    // 服务
    fw() {
      window.open("https://www.aiforscience.cn/cops/clause", "_black");
    },
    // 客服弹窗
    kf() {
      this.dialogShow = false;
      this.kfShow = true;
      this.Show = true;
    },
    // 客服弹窗
    show() {
      if (!localStorage.getItem("token")) {
        this.Show = true;
        this.dialogShow = true;
      } else {
        if (this.qx) {
          window.open("https://www.aiforscience.cn/cops/login", "_blank");
        } else {
          window.open("https://www.aiforscience.cn/cops/login", "_blank");
        }
      }
    },
    // 刷新二维码
    Refresh() {
      this.obtainLogin();
    },
    // 控制弹出框的显示与隐藏
    jump(val, url) {
      this.loginIs = localStorage.getItem("token") ? true : false;
      // false
      this.Show = true;
      this.dialogShow = val;
      this.url = url;
    },
    // 取消事件
    close(val) {
      // console.log(val);
      this.Show = false;
      if (val === "wx") {
      } else if (val === "kf") {
        this.dialogShow = false;
      }
    },
    // 获取登录二维码
    async obtainLogin() {
      let res = await this.$api.loginImg();
      console.log(res);
      if (res.data.code === 200) {
        // 微信二维码
        this.qrurl = res.data.data.qrurl;
        this.seneKey = res.data.data.seneKey;
        // 通过seneKey获取token;
        this.obtainToken(this.seneKey);
      }
    },
    // 获取token
    async obtainToken(seneKey) {
      // console.log(seneKey);
      // 通过seneKey获取token
      let { data: rel } = await this.$api.obtainSeneKe(seneKey);
      console.log(rel);
      if (rel.data) {
        this.isToken = true;
        clearInterval(this.timer);
        localStorage.setItem("token", rel.data.access_token);
        localStorage.setItem("wxname", rel.data.name);
        this.loginIs = true; //登陆成功
        clearInterval(this.timer);
        clearInterval(this.time);
        if (this.url) {
          window.open(this.url, "_blank");
        }
        this.qxFn();
        this.Show = false;
        this.dialogShow = false;
        this.kfShow = false;
        // 判断弹出框是否显示
        if (this.Show) {
          this.Show = false;
          // 如果弹出框显示，就隐藏
          this.dialogShow = false;
        }
      }
    },
    // tab标签页切换
    changeTab(index) {
      this.tabIndex = index;
      let id = this.tab[this.tabIndex].id;
      this.list.forEach((item) => {
        if (item.id == id) {
          this.name = `数据库 (${item.name})`;
          this.lists = item.list;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  width: 1200px;
  margin: 16px auto 0;
  .main {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    // position: relative;
    .left {
      width: 800px;
      .database {
        margin-top: 10px;
      }
    }
    .right {
      flex: 1;
      margin-left: 20px;
      .application {
        margin-top: 20px;
      }
    }
  }
  .dialog-box {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.48);
    .dialog_wx {
      width: 432px;
      height: 504px;
      background: #ffffff;
      box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.12);
      border-radius: 8px;
      margin-left: 537px;
      margin-top: 144px;
      border: 1px solid #f0f0f0;

      .close {
        margin: 24px 24px 0px 384px;
        img {
          width: 24px;
          height: 24px;
        }
      }

      .title {
        width: 96px;
        margin: 24px auto 0;
        height: 32px;
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #1f1f1f;
        line-height: 32px;
      }
      .sao {
        width: 162px;
        margin: 7px auto 0;
        height: 24px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #646a73;
        line-height: 24px;
      }
      .one {
        width: 224px;
        height: 224px;
        background: #f4f6f9;
        border-radius: 8px;
        margin: 15px 103px 24px 105px;
        padding-top: 20px;
        box-sizing: border-box;
        .two {
          width: 184px;
          margin: 0 20px 20px 20px;
          height: 184px;
          background: #ffffff;
          border-radius: 4px;
          img {
            width: 160px;
            height: 162px;
            margin: 11px 12px;
          }
        }
      }
      .footer {
        height: 22px;
        display: flex;
        align-items: center;
        .el-checkbox {
          width: 118px;
          height: 22px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #646a73;
          line-height: 22px;
          margin-left: 72px;
          /deep/.el-checkbox__input.is-checked + .el-checkbox__label {
            color: #646a73;
          }
        }
        p {
          width: 173px;
          height: 22px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #2977ff;
          line-height: 22px;
        }
      }
    }
    .dialog_kf {
      width: 640px;
      height: 320px;
      background: #ffffff;
      box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.12);
      border-radius: 8px;
      border: 1px solid #f0f0f0;
      margin-top: 255px;
      margin-left: 347px;
      .wrap {
        width: 640px;
        height: 320px;
        background: #ffffff;
        box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.12);
        border-radius: 8px;
        border: 1px solid #f0f0f0;
        display: flex;
        .left {
          width: 360px;
          height: 323px;
          background: #0055ff;
          border-radius: 8px 0px 0px 8px;
          padding-top: 67px;
          box-sizing: border-box;
          margin-left: -2px;
          margin-top: -2px;
          .one {
            width: 256px;
            height: 80px;
            font-size: 32px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 40px;
            margin-left: 32px;
          }
          .two {
            width: 288px;
            height: 24px;
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 24px;
            margin-top: 16px;
            margin-left: 32px;
          }
          .three {
            width: 288px;
            height: 48px;
            background: #0055ff;
            border-radius: 4px;
            border: 1px solid #ffffff;
            margin-top: 25px;
            margin-left: 32px;
            display: flex;
            .wen {
              width: 180px;
              height: 24px;
              font-size: 18px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #ffffff;
              line-height: 24px;
              margin-top: 12px;
              margin-left: 34px;
            }
            img {
              width: 24px;
              height: 24px;
              margin-top: 13px;
              margin-right: 34px;
              margin-left: 9px;
            }
          }
        }
        .right {
          width: 280px;
          height: 328px;
          .close {
            height: 24px;
            display: flex;
            justify-content: flex-end;
            margin-top: 24px;
            margin-right: 24px;
            img {
              width: 24px;
              height: 24px;
            }
          }
          .img {
            width: 184px;
            height: 184px;
            background: #ffffff;
            border-radius: 4px;
            margin-top: 8px;
            margin-left: 48px;
            img {
              width: 160px;
              height: 162px;
              margin: 11px 12px;
            }
          }
          .wen {
            width: 72px;
            height: 24px;
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #1f1f1f;
            line-height: 24px;
            margin-top: 17px;
            margin-left: 103px;
          }
        }
      }
    }
  }
}
</style>
